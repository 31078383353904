<template>
  <div class="specialTopicMap">
    <div id="map" style="background-color: black"/>
    <TopShareBar v-if="share === '1'"/>
    <div v-if="mapInfoDetail !== undefined"  id="bottom-sheet">
      <div class="arrow">
        <span @click="changeFoldState()" v-show="!fold" class="iconfontFamily arrow-font">&#xe791;</span>
        <span @click="changeFoldState()" v-show="fold" class="iconfontFamily arrow-font">&#xe792;</span>
      </div>
      <div v-show="!fold" style="font-size: 14px;color: white;height: fit-content;width: 100%;padding-bottom: 20px;">
        {{ mapInfoDetail.introduce }}
        <div v-show="mapInfoDetail.legend !== null" style="margin-top: 10px;">
          <div class="text-style">图例</div>
        </div>
        <!--渐变-->
        <div v-if="mapInfoDetail.legend !== null && mapInfoDetail.legend.type === 'minMax'">
          <div style="width: 100%;height: 20px;margin-top: 10px;" :style="{'background':barBackground}"></div>
          <div style="width: 100%;margin-top: 10px;">
            <div class="text-style2" style="float: left">{{mapInfoDetail.legend.minValue}}</div>
            <div class="text-style2" style="float: right">{{mapInfoDetail.legend.maxValue}}</div>
          </div>
        </div>

        <div v-if="mapInfoDetail.legend !== null && mapInfoDetail.legend.type === 'block'" style="width: 100%;display: flex;flex-wrap: wrap;justify-content: start">
          <div v-for="(item,index) in mapInfoDetail.legend.colorList" :key="index" :style="{'width': subBarWidth}">
            <div style="text-align: center;margin-top: 10px;height: max-content;width: 100%" >
              <div style="height: 20px;width: 100%" :style="{'background':item.hexColor}"></div>
              <div style="margin-top: 6px" class="text-style2">{{item.text}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="mapInfoDetail !== undefined" style="position: fixed;bottom: 0;height: 30px;width: 100%;background: #38383899">
      <div class="text-style" style="padding-right: 20px;text-align: right;">
        {{ mapInfoDetail.code }}
      </div>
    </div>
  </div>
</template>

<script>

import mapboxgl from "mapbox-gl";
import TopShareBar from '@/components/TopShareBar';
import {getMapboxToken} from "@/utils/utils";
import {getWxSignature} from "@/utils/shareConfig";

export default {
  name: "SpecialTopicMap",
  components: {TopShareBar},
  data() {
    return {
      fold:false,
      map: '',
      index: 0,
      share:0,
      mapInfoDetail:undefined,
      baseStyleInfo:{}

    }
  },
  computed:{
    barBackground(){
      if(this.mapInfoDetail.legend === null){
        return
      }else{
        let colorCount = this.mapInfoDetail.legend.colorList.length
        let step = 1/(colorCount - 1)
        let colorStr = 'linear-gradient(90deg, '
        this.mapInfoDetail.legend.colorList.forEach((item,index)=>{
          colorStr = colorStr + item.hexColor + ' ' + step*index*100 + '%'
          if(index !== colorCount - 1){
            colorStr = colorStr + ','
          }
        })
        colorStr = colorStr + ')'
        return colorStr
      }
    },
    subBarWidth(){
      if(this.mapInfoDetail.legend === null){
        return
      }else{
        let subBarWidth = 0
        if(this.mapInfoDetail.legend.colorList.length < 6){
          subBarWidth = 1/this.mapInfoDetail.legend.colorList.length*100 + '%'

        }else {
          subBarWidth = 1/6*100 + '%'
        }
        return subBarWidth
      }
    }
  },
  methods: {
    async getBaseStyleInfo() {
      let response = await this.$http.get('/earthApi/main-info/getBaseStyleInfo');
      if(response.data.status === 200){
        this.baseStyleInfo = response.data.data;
      }
      await this.init();
    },
    getMapInfo(layer){
      this.$http.get('/earthApi/main-info/getSubjectLayer?layer='+layer,).then(response=> {
        if(response.data.status === 200){
          this.mapInfoDetail = response.body.data
          // this.mapInfoDetail.legend.colorList = this.mapInfoDetail.legend.colorList.concat(this.mapInfoDetail.legend.colorList)
          // this.mapInfoDetail.legend.colorList = this.mapInfoDetail.legend.colorList.concat(this.mapInfoDetail.legend.colorList)
          console.log(this.subBarWidth)
          this.getBaseStyleInfo()
        }
      })
    },
    async init() {
      await getMapboxToken();
        mapboxgl.accessToken = this.baseStyleInfo["token"];
        this.map = new mapboxgl.Map({
        container: 'map',
        // style:this.mapInfoDetail.subjectLayerStyle,
        center: [100, 31],
        zoom: 1,
        maxZoom: this.mapInfoDetail.maxZoom,
        minZoom: 0.8,
        attributionControl: false,
        // interactive: false,
        trackResize: false,
        projection: {name: 'globe'},
        transformRequest: (url) => {
          if (url.indexOf('https://api.mapbox.com') > -1) {
            return {
              url: url.replace('https://api.mapbox.com', 'https://earth.jl1.cn/mapbox'),
            };
          }
        }
      });
      this.map.on('load', () => {
        // this.map.setFog({
        //   "horizon-blend": 0.1,
        //   "space-color": 'rgba(0, 0, 0, 1)',
        //   "star-intensity": 0.15
        // })
        this.map.addLayer({
          id: 'sky',
          type: 'sky',
          paint: {
            'sky-type': 'atmosphere',
            'sky-atmosphere-sun': [0.0, 0.0],
            'sky-atmosphere-sun-intensity': 15,
          }
        });
        this.load()
        // if(window["GetSpecialTopicMapInfo"]){
        //   window["GetSpecialTopicMapInfo"].postMessage("");
        // }
      });
    },
    load() {
      this.map.addSource(this.mapInfoDetail.layer, {
        type: 'raster',
        tiles: [
          this.mapInfoDetail.layerUrl
        ],
        tileSize: 256,
        scheme: 'tms',
      });
      this.map.addLayer({
        id: this.mapInfoDetail.layer,
        type: 'raster',
        source: this.mapInfoDetail.layer,
      });
    },
    changeFoldState(){
      this.fold = !this.fold
    }
  },
  mounted() {

    window.specialTopicMap = this;
    if (this.$route.query.name != null) {
      document.title = this.$route.query.name
    }
    if (this.$route.query.share != null) {
      this.share = this.$route.query.share
    }
    if(this.$route.query.topicLayer != null){
      this.getMapInfo(this.$route.query.topicLayer)
    }
    getWxSignature(this.$route.query.topicLayer,"专题图层")

    // this.init()
  }
}
</script>

<style scoped lang="scss">

.specialTopicMap {
  height: 100%;
  width: 100%;
  background-color: black;
  display: flex;
  align-items: center;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#bottom-sheet{
  position: fixed;
  bottom: 30px;
  background-color:#38383899;
  width: calc(100% - 40px);
  height: fit-content;
  border-radius: 16px 16px 0px 0px;
  padding-left: 20px;
  padding-right: 20px;
}

.text-style{
  font-size: 14px;color: white
}

.text-style2{
  font-size: 12px;color: white
}

.arrow{
  text-align: center;width: 100%;padding-top: 3px;padding-bottom: 5px
}

.arrow-font{
  font-size:18px;color: white;cursor: pointer;
}

</style>
