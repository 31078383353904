<template>
  <div></div>
</template>

<script>

export default {
  name: "FootprintMap",
  props: {
    pointData:{
      required: true // 点数据列表，需要geoJson: FutureCollection<Future<Point>>
    },
    map:{
      required: true // mapbox对象
    },
    dataPropertyName:{
      required: true // 属性字段名
    },
    dataMin:{
      required: true // 最大值
    },
    dataMax:{
      required: true // 最小值
    },
    colorList:{
      required: true
    },
    layerOpacity:{
      required: true // 透明度
    },
    hotAreaPos:{
      required: true // 热点地址坐标
    },
  },
  data(){
    return{
      dataRange: 0,
      color:["interpolate", ["linear"], ["heatmap-density"]],
      opacity:this.layerOpacity,
    }
  },
  methods:{
    //初始化图层
    initLayer(){
      this.map.addSource("HeatMap", {
        type: "geojson",
        // 数据为mapbox官方数据
        // 可能访问不到或者跨域，本地测试时可以直接下下来
        data: this.pointData,
      });
      this.map.addLayer({
        "id": "背景",
        "type": "background",
        "paint": {
          "background-color": "rgba(3, 20, 57,0.7)"
        },
        "metadata": {
          "mapbox:group": "92ca48f13df25"
        }
      })
      if(this.dataMin !== this.dataMax && this.colorList.length > 1){
        this.map.addLayer(
            {
              'id': 'HeatMap-heat',
              'type': 'heatmap',
              'source': 'HeatMap',
              'paint':{
                // 根据频率和属性大小增加热力图权重
                "heatmap-weight": [
                  "interpolate",
                  ["linear"],
                  ["get", "pValue"],
                  this.dataMin, 0,
                  this.dataMax, 1,
                ],
                // 根据缩放级别增加热力图权重
                // heatmap-intensity 是热力图权重的乘数
                "heatmap-intensity": ["interpolate", ["linear"], ["zoom"], 3,1,16,2],
                // 色带
                "heatmap-color":this.color,
                // 根据缩放级别调整热力图半径
                "heatmap-radius": ["interpolate", ["linear"], ["zoom"], 3,10,16,40],
                // "heatmap-opacity": ["interpolate", ["linear"], ["zoom"], 0, 1, 9, 1],
                "heatmap-opacity": this.opacity,
              }
            }
        )

      }else {
        this.map.addLayer(
            {
              'id': 'HeatMap-heat',
              'type': 'heatmap',
              'source': 'HeatMap',
              'paint': {
                // 根据频率和属性大小增加热力图权重
                "heatmap-weight": 1,
                // 根据缩放级别增加热力图权重
                // heatmap-intensity 是热力图权重的乘数
                "heatmap-intensity": ["interpolate", ["linear"], ["zoom"], 0, 1, 9, 3],
                // 色带
                // "heatmap-color": this.colorList[0].hexColor,
                // 根据缩放级别调整热力图半径
                "heatmap-radius": ["interpolate", ["linear"], ["zoom"], 0, 2, 9, 20],
                // 透明度
                // "heatmap-opacity": ["interpolate", ["linear"], ["zoom"], 0, 1, 9, 1],
                "heatmap-opacity": this.opacity,
              }
            }
        )
      }//[101.818, 29.646]
      console.log(this.hotAreaPos)
      this.map.flyTo({
        center: this.hotAreaPos,
        zoom: 12,
        bearing: 0,
        pitch: 0
      })
    },
  },
  mounted() {
    if(this.dataMin !==this.dataMax && this.colorList.length >1){
      this.dataRange = parseFloat((1/this.colorList.length).toFixed(1));
      let temp = 0
      this.color.push(0)
      this.color.push('rgba(23,23,23,0)')
      for(let i = this.colorList.length - 1;i >= 0;i--){
        temp = (temp+this.dataRange)
        this.color.push(temp)
        this.color.push(this.colorList[i].hexColor)
      }
    }
    //初始化图层
    this.initLayer();
  },
}
</script>
<style scoped>
</style>
