<template>
  <div class="bkg OPPOSans">
    <top-share-bar v-if="shareType === 1"></top-share-bar>
    <div v-if="shareType === 1" style="position: fixed;display: flex;top: 80px;left: 20px">
      <div class="avatar" :style="{'background-image': 'url(' + userInfo['picture'] + ')'}"></div>
      <div style="margin-left: 20px;display: flex;align-items: center">
        <div>
          <p style="line-height: 16px;height: 16px;margin-top: 0;margin-bottom: 0;color: white;font-weight: 600">{{userInfo['nickName']}}</p>
          <p style="font-size: 10px;line-height: 20px;height: 13px;margin-top: 0;margin-bottom: 0;color: rgba(209,209,209,1);font-weight: 600">用户ID：{{userInfo['userId']}}</p>
        </div>
      </div>
    </div>
    <div style="height: 47%"></div>
    <div style="position: relative;height: 36px" class="title1">
      <p style="left: 2px;top: 2px;color: rgba(111,134,214,1);" class="main-title">
        解锁足迹世界</p>
      <p class="main-title">
        解锁足迹世界</p>
    </div>
    <div style="margin-top: 35px;margin-left: 25px;position: relative;line-height: 10px" class="title1">
      <p>每一次探索都值得期待</p>
      <p>共生地球伴你探索世界</p>
    </div>
    <div
        class="open-btn"
        @click="openFootprint"
    >
      <p style="font-family: YouSheBiaoTiYuan;font-size: 20px">立即查看</p>
    </div>
    <div>
      <div
          v-if="shareType === 0"
          class="btm-info">
        <input v-model="isAuth" type="checkbox" style="background-color: transparent;position: relative;bottom: 1px;">
        <p style="margin-left: 5px">授权共生地球使用相关数据生成报告《</p>
        <p @click="openAuthInfo" style="border-bottom: 1px solid white">授权协议</p>
        <p>》</p>
        <p></p>
      </div>
      <div @click="toOfficialWebsite"
           v-if="shareType === 1"
           class="btm-info">
        <p>本服务由长光卫星技术股份有限公司提供</p>
        <p class="iconfontFamily" style="margin-left: 5px">&#xe79e;</p>
      </div>
    </div>
    <div v-show="toastShow"
         class="toast">
      <p class="iconfontFamily" style="color: white">&#xe79e;</p>
      <p style="margin-left: 7px">请同意下方授权协议</p>
    </div>
  </div>
</template>

<script>
import TopShareBar from "@/components/TopShareBar.vue";

export default {
  name: 'FootprintWorld',
  components: {TopShareBar},
  data() {
    return {
      userId: '',
      shareType: 0,
      isAuth: false,
      toastShow: false,
      userInfo: '',
    }
  },
  methods: {
    openAuthInfo() {
      if (this.shareType === 0) {
        if (window['openAuthInfo']) {
          window['openAuthInfo'].postMessage('')
        } else {
          this.$router.push({
            path: '/UserAuthInfo',
            query: {
              share: this.shareType,
              userId: this.userId
            }
          })
        }
      }
    },
    openFootprint() {
      if (!this.isAuth && this.shareType === 0) {
        this.toastShow = true
        setTimeout(() => {
          this.toastShow = false
        }, 2000)
        return
      }
      if (this.shareType === 0) {
        if (window['openFootprint']) {
          window['openFootprint'].postMessage('')
        }
      } else {
        this.$router.push({
          path: '/FootprintDetail',
          query: {
            share: this.shareType,
            userId: this.userId
          }
        })
      }
    },
    toOfficialWebsite() {
      if (this.shareType === 0) {
        if (window['toOfficialWebsite']) {
          window['toOfficialWebsite'].postMessage()
        }
      } else {
        window.location.href="http://www.jl1.cn/";
      }
    },
    getUserInfo() {
      this.$http.get('/earthApi/data/getPersonalData?userId=' + this.userId).then((resp) => {
        resp = resp.data
        if (resp.status === 200) {
          resp = resp.data
          this.userInfo = resp
        }
      })
    },
  },
  mounted() {
    // 获取shareType 用户信息等
    if (this.$route.query.share) {
      this.shareType = parseInt(this.$route.query.share)
    }
    if (this.$route.query.userId) {
      this.userId = this.$route.query.userId
    }
    document.title = '足迹世界'
    this.getUserInfo()
  }
}
</script>

<style scoped>
.bkg {
  background-image: url('../../../public/app-assets/image/footprint/footprint_world/worldBkg.jpg');
  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  color: white;
  position: relative;
}

.title1 {
  animation: flyin 2s ease-out forwards;
  transform: translate3d(0, 100%, 0);
  opacity: 0;
}
@keyframes flyin {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.avatar {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 3px solid white;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.main-title {
  font-family: YouSheBiaoTiHei;
  font-size: 36px;
  margin-left: 25px;
  position: absolute;
  margin-top: 0;
  margin-bottom: 0;
}

.open-btn {
  position: absolute;
  height: 50px;
  width: 150px;
  border-radius: 8px;
  bottom: 15%;
  transform: translateX(-50%);
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, rgba(22, 67, 161, 1) 0%, rgba(23, 122, 195, 1) 100%)
}

.btm-info {
  display: flex;
  position: absolute;
  bottom: 10%;
  font-size: 12px;
  height: 12px;
  line-height: 12px;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.toast {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: rgba(0, 0, 0, 0.6);
  width: 218px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

input[type=checkbox] {
  cursor: pointer;
  position: relative;
  width: 12px;
  height: 12px;
  font-size: 12px;
}

input[type=checkbox]::after {
  position: absolute;
  top: 0;
  background-color: black;
  color: white;
  width: 12px;
  height: 12px;
  display: inline-block;
  border: 1px solid white;
  visibility: visible;
  text-align: center;
  content: ' ';
}

input[type=checkbox]:checked::after {
  content: "✓";
  font-size: 9px;
  font-weight: bold;
}

</style>