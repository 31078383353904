<template>
  <div id="historical_images">
    <l-map ref="map" style="z-index: 0" :options="option" >
      <l-tile-layer :url="infoUrl" :options="{tms:true}"/>
      <l-tile-layer :url="layer" :options="{tms:true}" style="opacity: 1"/>

      <!--切换图层-->
      <div v-if="share === 1" style="position:fixed;bottom:0;left:0;right:0;display:flex;flex-direction: column-reverse;z-index:500;
      width:100vw;height:292px;background:linear-gradient(0deg, rgba(38, 38, 38, 0.6) 12.96%, rgba(0, 0, 0, 0) 100%);">
        <div style="width:calc(100% - 28px);height:115px;padding:0 14px;margin-bottom:12px;display:flex;justify-content:space-between;">

          <div @click="changeLayer(citeIndex)" v-for="(citeItem,citeIndex) in detail.citeSimpleDtoList" :key="citeIndex" style="width:100px;">

            <div style="width:100%;height:75px;">
              <img :src="citeItem.thumb" :style="{'border':index == citeIndex ? '2px solid #FFF9C2' : '2px solid transparent'}"
                   style="width:100%;height:100%;border-radius:8px;object-fit: cover;">
              <p style="margin:0 0 0 2px;text-align:center;font-size:12px;color:#ffffff;
            word-break: break-all;text-overflow: ellipsis;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;">
                {{citeItem.displayName}}
              </p>
            </div>



          </div>

        </div>
      </div>

    </l-map>
    <TopShareBar v-if="share === 1"/>






    <!--提示-->
    <div v-if="toastShow" class="toast">
      <p style="line-height:32px;margin:0;">点击下方图层 查看多期卫星历史影像</p>
    </div>

  </div>
</template>

<script>

import L from "leaflet";
import {LMap,LTileLayer,} from 'vue2-leaflet';
 import TopShareBar from '@/components/TopShareBar';
import {getWxSignature} from "@/utils/shareConfig";


export default {
  name: "HistoricalImages",
  components: {
    TopShareBar,
    LMap,
    LTileLayer,
  },
  mounted() {
    window.HistoricalImages = this
    this.infoId = this.$route.query.infoId
    this.share = this.$route.query.share
    if(this.$route.query.index !== null && this.$route.query.index !== undefined){
      this.index = this.$route.query.index
      console.log(this.index)
    }
    getWxSignature(this.infoId)
    this.load()
  },
  data() {
    return {
      index:0,
      infoUrl:this.globalJS.world1mUrl_2021,
      option: {zoomControl: false, attributionControl: false, renderer: L.canvas(),zoom:10},
      share: 0,
      infoId: '',
      detail: {},
      layer:'',
      timer:'',

      toastShow:false,
    }
  },
  methods: {
    load() {
      this.$http.get('/earthApi/main-info/getHistoryImageDetail?infoId=' + this.infoId).then(response => {
        if(response.data.status === 200){
          this.detail = response.body.data;
          console.log(this.detail)
          if(window["historicalImagesDetail"]){
            window["historicalImagesDetail"].postMessage(JSON.stringify(this.detail));
          }
          if(this.$route.query.share === "1"){
            this.share = 1

            //隐藏toast
            this.toastShow = true;
            setTimeout(()=>{
              this.toastShow = false;
            },5000)
          }else{
            this.share = 0
          }
          setTimeout(()=>{this.addLayer()}, 100)



        } else {
          this.$router.push(
              {
                path: '/NotExist',
              }
          )
        }
      })
    },
    addLayer(){
      this.layer = this.detail['citeSimpleDtoList'][this.index]['layerName']
      this.setBounds()
      this.$refs.map.mapObject.setMaxBounds(L.geoJSON(JSON.parse(this.detail.geo)).getBounds())
      this.zoom = this.$refs.map.mapObject.getZoom()
      this.$refs.map.mapObject.setMinZoom(this.zoom)
      document.title = this.detail.title
    },
    setBounds(){
      this.$refs.map.mapObject.fitBounds(L.geoJSON(JSON.parse(this.detail.geo)).getBounds())
    },
    changeLayer(index){
      this.index = parseInt(index)
      this.layer = this.detail['citeSimpleDtoList'][this.index]['layerName']
    },
    autoPlay(){
      this.layer = this.detail['citeSimpleDtoList'][this.index]['layerName']
      this.timer = setInterval(this.changeLayerAnimation,2000);
    },
    pause(){
      clearInterval(this.timer)
    },
    changeLayerAnimation(){
      this.index = (this.index + 1)%this.detail['citeSimpleDtoList'].length
      this.layer = this.detail['citeSimpleDtoList'][this.index]['layerName']
      if(window["updateIndex"]){
        window["updateIndex"].postMessage(this.index.toString());
      }
    },
  },
}

</script>
<style scoped lang="scss">

#historical_images {
  font-family: 微软雅黑;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}

#mainTitle{
  img{
    position: absolute;
    left: 24px;
    top:54px;
    z-index: 10;
  }
  span{
    position: absolute;
    left: 50px;
    top:52px;
    z-index: 10;
  }
}

.backButton{
  z-index: 10;
  height:48px;
  width: 48px;
  text-align: center;
  line-height: 48px;
  font-size: 12px;
  position: fixed;
  left: 10px;
  top:40px;
}

.infoImage{
  position: absolute;
  top: calc(50% + 2px);
  left: calc((100vh - 100vw) / 2 - 1px);
  z-index: 9998;
  pointer-events: none;
}


.toast{
  position:absolute;
  top:112px;
  left:calc((100% - 278px) / 2);
  width:278px;
  height:32px;
  background:rgba(255,255,255,0.8);
  border-radius:6px;
  color:#393939;
  font-size:12px;
  text-align:center;
}



</style>
