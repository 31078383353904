<template>
    <div class="toolboxSharePage">

        <l-map ref="toolboxSharePageMap" :maxBounds="maxBounds" :zoom="zoom" :center="center" :options="option">
            <!--<l-tile-layer ref="mapLayer" :url="mapUrl" :options="mapSubdomains" />-->
            <l-tile-layer ref="mapLayer" :url="chinaOneUrl" :options="{tms:true}" />
        </l-map>

        <!--信息栏-->
        <div v-if='JSON.stringify(markInfo) !== "{}"' class="markInfoBar">
            <!--拍摄点信息卡-->
            <div style="width:100%;height:105px;margin-top:21px;padding-left:32px;white-space: nowrap;">
                <div class="markInfoBar-item">
                    <!--<img v-if="type === '路线规划'" :src="markInfo.thumb" class="markInfoBar-item-image">-->
                    <div style="text-align:center;width:calc(100% - 19px);margin-top:15px;margin-left:9px;float:left;">
                        <p class="markInfoBar-item-title">{{markInfo.title}}</p>
                        <p class="markInfoBar-item-poi">
                            <span style="color:#675CB5;" class="iconfontFamily">&#xe61e;</span>
                            {{markInfo.poi}}
                        </p>
                        <p v-if="type === '测量长度'" class="markInfoBar-item-describe">长度：{{markInfo.lenth}}&nbsp;&nbsp;&nbsp;&nbsp;方位角：{{markInfo.angle}}</p>
                        <p v-if="type === '测量面积'" class="markInfoBar-item-describe">面积：{{markInfo.area}}&nbsp;&nbsp;&nbsp;&nbsp;周长：{{markInfo.circumference}}</p>
                        <p v-if="type === '路线规划'" class="markInfoBar-item-describe">路线里程：{{markInfo.mileage}}</p>
                        <p v-if="type === '实时轨迹'" class="markInfoBar-item-describe">里程：{{markInfo.mileage}}&nbsp;&nbsp;&nbsp;&nbsp;用时：{{markInfo.consume}}</p>

                    </div>
                </div>
            </div>


        </div>


        <!--分享按钮-->
        <TopShareBar></TopShareBar>
    </div>

</template>

<script>

    // 引入Leaflet对象 挂载到Vue上，便于全局使用，也可以单独页面中单独引用
    import L from 'leaflet'
    import { LMap, LTileLayer } from 'vue2-leaflet';

    import TopShareBar from '@/components/TopShareBar';
    export default {
        name:"ToolboxSharePage",
        components: {LMap, LTileLayer,TopShareBar},
        data(){
            return{
                zoom: 12,
                center: L.latLng(40.074, 116.604),
                maxBounds: L.latLngBounds([[85,-180], [-85,180]]),
                option: {zoomControl: false, attributionControl: false, renderer: L.canvas()},
                // mapUrl:'https://t{s}.tianditu.gov.cn/DataServer?T=vec_w&X={x}&Y={y}&L={z}&tk=05861044e5fe4135647facbd39abc543',
                chinaOneUrl:this.globalJS.world1mUrl_2021,
                mapSubdomains:{subdomains: ['0', '1', '2', '3']},


                toolboxInfoId:"",
                type:"",

                markInfo:{}


            }

        },
        methods:{
            getDetail(){
                //测量长度 a86d0282cb874cc3963f86c7db1daca5
                //测量面积 3f8ab70eb3fc45018ff199368e2cb2f6
                //路线规划 无标记点202111101455000303095  有标记点202112151531000377393
                //实时轨迹 20211210175200027492
                if(this.type === "测量长度" || this.type === "测量面积"){
                    this.$http.get("/earthApi/draw/getDrawMeasureDetail?drawId=" + this.toolboxInfoId).then(response => {
                        if(response.data.status === 200){
                            //绘制线
                            if(this.type === "测量长度"){
                                let jsonLine = JSON.parse(JSON.parse(response.data.data.drawDetail).jsonLine)
                                for(let i=0;i<jsonLine.features.length;i++){
                                    let latlngs = [
                                        [jsonLine.features[i].geometry.coordinates[0][1],jsonLine.features[i].geometry.coordinates[0][0]],
                                        [jsonLine.features[i].geometry.coordinates[1][1],jsonLine.features[i].geometry.coordinates[1][0]]
                                    ];
                                    L.polyline(latlngs,{color:'#FF9933'}).addTo(this.$refs.toolboxSharePageMap.mapObject);
                                }
                            }
                            //绘制面
                            if(this.type === "测量面积"){
                                let jsonLine = JSON.parse(JSON.parse(response.data.data.drawDetail).jsonLine)
                                let areaLatLngs = [];
                                for(let i=0;i<jsonLine.features.length;i++){
                                    if(i === 0){
                                        areaLatLngs.push(
                                            [jsonLine.features[i].geometry.coordinates[0][1],jsonLine.features[i].geometry.coordinates[0][0]],
                                        )
                                    }
                                    areaLatLngs.push(
                                        [jsonLine.features[i].geometry.coordinates[1][1],jsonLine.features[i].geometry.coordinates[1][0]]
                                    )
                                }
                                L.polygon(areaLatLngs, {color: '#FFE033',fillColor:'#EE2B23',fillOpacity:0.4}).addTo(this.$refs.toolboxSharePageMap.mapObject);
                            }
                            //绘制点
                            let jsonPoint = JSON.parse(JSON.parse(response.data.data.drawDetail).jsonPoint)
                            for(let i=0;i<jsonPoint.features.length;i++){
                                let latlngs = [jsonPoint.features[i].geometry.coordinates[1],jsonPoint.features[i].geometry.coordinates[0]];
                                L.circle(latlngs, {color:'#FFffff',fillColor:'#ffffff',fillOpacity:1,radius: 8}).addTo(this.$refs.toolboxSharePageMap.mapObject);
                            }

                            //信息栏
                            if(this.type === "测量长度"){
                                this.markInfo = {
                                    title:response.data.data.drawName,
                                    poi:response.data.data.geoName,
                                    lenth:response.data.data.lenth,
                                    angle:response.data.data.angle,
                                }
                            }
                            else if(this.type === "测量面积"){
                                this.markInfo = {
                                    title:response.data.data.drawName,
                                    poi:response.data.data.geoName,
                                    circumference:response.data.data.circumference,
                                    area:response.data.data.area,
                                }
                            }

                            //地图飞到地图中心点位置
                            this.$refs.toolboxSharePageMap.mapObject.flyTo(
                                L.latLng(response.data.data.lat, response.data.data.lon),response.data.data.zoom,
                                {duration:2}
                            )
                        }
                        else{
                            console.log(response.data.status + response.data.message)
                        }
                    })
                }
                else if(this.type === "路线规划"){
                    this.$http.get("/earthApi/draw/getRoutePlanDetail?planId=" + this.toolboxInfoId).then(response => {
                        if(response.data.status === 200){
                            //绘制线
                            let jsonLineManual = JSON.parse(JSON.parse(response.data.data.routePlan.route).jsonLineManual)
                            for(let i=0;i<jsonLineManual.features.length;i++){
                                let latlngs = [
                                    [jsonLineManual.features[i].geometry.coordinates[0][1],jsonLineManual.features[i].geometry.coordinates[0][0]],
                                    [jsonLineManual.features[i].geometry.coordinates[1][1],jsonLineManual.features[i].geometry.coordinates[1][0]]
                                ];
                                L.polyline(latlngs,{color:'#60C2C8'}).addTo(this.$refs.toolboxSharePageMap.mapObject);
                            }
                            let jsonLineAuto = JSON.parse(JSON.parse(response.data.data.routePlan.route).jsonLineAuto)
                            for(let i=0;i<jsonLineAuto.features.length;i++){
                                let latlngs = [
                                    [jsonLineAuto.features[i].geometry.coordinates[0][1],jsonLineAuto.features[i].geometry.coordinates[0][0]],
                                    [jsonLineAuto.features[i].geometry.coordinates[1][1],jsonLineAuto.features[i].geometry.coordinates[1][0]]
                                ];
                                L.polyline(latlngs,{color:'#F2D417'}).addTo(this.$refs.toolboxSharePageMap.mapObject);
                            }
                            //绘制点
                            let jsonPoint = JSON.parse(JSON.parse(response.data.data.routePlan.route).jsonPoint)
                            for(let i=0;i<jsonPoint.features.length;i++){
                                let latlngs = [jsonPoint.features[i].geometry.coordinates[1],jsonPoint.features[i].geometry.coordinates[0]];
                                L.circle(latlngs, {color:'#FFffff',fillColor:'#ffffff',fillOpacity:1,radius: 8}).addTo(this.$refs.toolboxSharePageMap.mapObject);
                            }
                            //绘制标记点
                            let markerList = response.data.data.markerList
                            for(let i=0;i<markerList.length;i++){
                                let latlngs = [JSON.parse(markerList[i].geometry).coordinates[1],JSON.parse(markerList[i].geometry).coordinates[0]];
                                let markerColor = markerList[i].properties.color;
                                L.marker(latlngs,{
                                    icon:L.divIcon({
                                        className: 'custom-div-icon',
                                        html: '<div style="width:20px;height:20px;border-radius:50%;text-align:center;background:#' + markerColor.substr(2).slice(2) + markerColor.substr(2).slice(0,2) + ';">' +
                                        '<p style="margin:0;line-height:20px;font-size:12px;color:#ffffff;" class="iconfontFamily">&#xe' + markerList[i].properties.code.substr(3) + ';</p>' +
                                        '</div>',
                                        iconAnchor: [10,10]
                                    })
                                }).addTo(this.$refs.toolboxSharePageMap.mapObject);
                            }

                            //信息栏
                            this.markInfo = {
                                title:response.data.data.routePlan.drawName,
                                poi:response.data.data.routePlan.startName + "——" + response.data.data.routePlan.endName,
                                mileage:response.data.data.routePlan.mileage
                            }

                            //地图飞到地图中心点位置
                            this.$refs.toolboxSharePageMap.mapObject.flyTo(
                                L.latLng(response.data.data.routePlan.lat, response.data.data.routePlan.lon),response.data.data.routePlan.zoom,
                                {duration:2}
                            )
                        }
                        else{
                            console.log(response.data.status + response.data.message)
                        }
                    })
                }
                else if(this.type === "实时轨迹"){
                    this.$http.get("/earthApi/draw/getDrawLocusDetail?locusId=" + this.toolboxInfoId).then(response => {
                        if(response.data.status === 200){
                            //绘制线
                            let jsonLine = JSON.parse(JSON.parse(response.data.data.route).jsonLine)
                            for(let i=0;i<jsonLine.features.length;i++){
                                let latlngs = [
                                    [jsonLine.features[i].geometry.coordinates[0][1],jsonLine.features[i].geometry.coordinates[0][0]],
                                    [jsonLine.features[i].geometry.coordinates[1][1],jsonLine.features[i].geometry.coordinates[1][0]]
                                ];
                                L.polyline(latlngs,{color:'#30E938'}).addTo(this.$refs.toolboxSharePageMap.mapObject);
                            }

                            //信息栏
                            this.markInfo = {
                                title:response.data.data.startTime,
                                poi:response.data.data.startName + "——" + response.data.data.endName,
                                mileage:response.data.data.mileage,
                                consume:response.data.data.consume,
                            }

                            //地图飞到地图中心点位置
                            this.$refs.toolboxSharePageMap.mapObject.flyTo(
                                L.latLng(response.data.data.lat, response.data.data.lon),response.data.data.zoom,
                                {duration:2}
                            )
                        }
                        else{
                            console.log(response.data.status + response.data.message)
                        }
                    })
                }
            }

        },


        mounted(){
            this.toolboxInfoId = this.$route.query.toolboxInfoId;
            this.type = this.$route.query.type
            document.title = this.type;
            this.getDetail();
        }


    }

</script>

<style scoped>
    .toolboxSharePage{
        font-family: 微软雅黑;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        /*text-align: center;*/
        color: #2c3e50;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        overflow: hidden;
    }
    .toolboxSharePage p{
        margin:0;
    }


    /*信息栏*/
    .markInfoBar{
        position:absolute;
        bottom:0;
        width:100%;
        height:180px;
        background:#ffffff;
        border-radius:12px 12px 0 0;
        z-index:999;
        overflow-x: hidden;
    }
    .markInfoBar-item{
        width:calc(100% - 64px);
        height:105px;
        border-radius:8px;
        box-shadow:0 0 6px 0 rgba(164,164,164,0.25);
        margin-right:15px;
        display:inline-block;
        white-space: normal;
    }
    .markInfoBar-item-image{
        width:100px;
        height:75px;
        border-radius:12px;
        margin:15px 5px 0 0;
        float:left;
    }
    .markInfoBar-item-title{
        color:#4A4A4A;
        font-size:14px;
    }
    .markInfoBar-item-poi{
        margin-top:3px !important;
        color:#4A4A4A;
        font-size:10px;
    }
    .markInfoBar-item-describe{
        margin-top:3px !important;
        color:#4A4A4A;
        font-size:12px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
    }

</style>
