<template>
    <div class="content">
        <div style="height: 100%;width: 100%;overflow-y: scroll" >
            <div style="margin-bottom: 20px;width: 100%;text-align: center;height: 40px;line-height: 40px;font-size: 20px;color: black;font-weight: 700;margin-top: 40px">隐私政策</div>
            <div class="paragraph2">
                发布日期：2022年5月19日
            </div>
            <div class="paragraph2">
                生效日期：2022年5月19日
            </div>
            <div style="clear: both"></div>
            <div class="subTitle">一、特别提示</div>
            <div class="paragraph">
              共生地球是由长光卫星技术股份有限公司（以下简称长光卫星）开发。长光卫星技术股份有限公司（以下简称“我们”）非常重视用户（“您”）的个人信息的保护，您在使用我们的产品或服务时，我们可能会收集和使用您的相关信息。我们希望通过《共生地球隐私政策》（以下简称“本政策”）向您说明我们在收集和使用您相关个人信息时对应的处理规则相关事宜，以便更好地保障您的权益。请在使用共生地球服务前仔细阅读本政策，如果您进一步访问并使用共生地球，则表示您了解并接受了本政策中所述的全部内容，并同意受其约束，如果您不接受前述任何内容，请停止访问或使用共生地球。
            </div>
            <div class="subTitle">二、我们收集哪些用户信息？</div>
            <div class="paragraph">
                我们将通过以下途径收集和获得您的个人信息：
            </div>
          <div class="paragraph">
            1、有关您注册的个人识别信息(以下简称“用户个人信息”，如<span style="font-weight: bold;font-style: italic;color: black">微信账号其他第三方账号</span>)。
          </div>
            <div class="paragraph">
                2、有关您使用共生地球的信息(使用共生地球过程中，系统自动记录包括但不限于您的使用情况、使用时长、浏览记录、您对共生地球呈现的推送信息的反馈、您下载和使用应用程序、您使用第三方平台的情况方面的信息)；
            </div>
            <div class="paragraph">
                3、您通过共生地球服务向其他方提供的共享信息，以及您使用共生地球服务时所储存的信息。
            </div>
            <div class="paragraph">
                4、您在使用共生地球服务过程中我们获取的您的信息（例如您在使用共生地球服务时，我们收集、汇总、记录的日志信息、位置信息、移动设备所用的版本和设备识别码设备信息、IP地址、地理位置、访问日期和时间、软硬件特征信息数据，用于鉴别用户身份、记录系统故障和分析系统故障时产生的原因及向您推荐您可能感兴趣的广告/资讯、用户行为分析、广告投放监测、开展内部审计、数据分析和研究，以促进改善、优化我们的产品、服务）。
            </div>
          <div class="paragraph">
            在您浏览地图时，共生地球会向系统申请<span style="font-weight: bold;font-style: italic;color: black">定位权限（位置权限）</span>以显示您当前位置，共生地球也会获取设备的MAC地址和基站信息作为辅助定位。
          </div>
          <div class="paragraph">
            在您使用WIFI网络时，共生地球会向系统获取<span style="font-weight: bold;font-style: italic;color: black">WiFi_SSID、WI-FI BSSID、设备Wi-Fi信息</span>。
          </div>

          <div class="paragraph">
            在您使用共生地球极光认证、极光SDK、微信QQ微博分享时，SDK收集极光SDK,io.flutter.embedding,dev.fluttercommunity.plus获取Android ID、io.github.v7lin,io.flutter.embedding<span style="font-weight: bold;font-style: italic;color: black">获取安装列表。</span>
          </div>
          <div class="paragraph">
            在您使用共生地球3D应用、全景应用陀螺仪功能时，共生地球需要用到陀螺仪传感器信息，用于保障您正常使用我们的服务，维护基础功能的正常运行。          </div>

            <div class="paragraph">
              在您使用共生地球的后台轨迹记录功能时，共生地球需要用到后台定位功能。
            </div>
            <div class="paragraph">
              共生地球支持您在地图上插入拍照照片，因此共生地球需要用到照相机权限。
            </div>
            <div class="paragraph">
              共生地球支持您保存自己的数据到SD卡上，因此需要获取您的SD读写权限。
            </div>
            <div class="paragraph">
              您在浏览地图的时候，需要从互联网地图服务器上获取地图数据，因此共生地球也需要Internet访问权限。
            </div>
            <div class="paragraph">
                5、我们通过间接方式收集到的您的个人信息（共生地球可能从关联方、第三方合作伙伴获取您授权共享的相关信息，例如您授权共享的账户信息，包括用户名、头像、昵称）。
            </div>
            <div class="paragraph">
              6、第三方SDK
            </div>
          <div class="paragraph">
            （1）共生地球默认使用极光认证一键登录SDK模块。极光认证一键登录SDK模块会获取您的个人信息，包括设备信息、网络信息、位置信息（仅用于极光认证）。具体如下：
          </div>

          <div class="paragraph">
            第三方SDK名称：极光认证、极光SDK、cn.com.chinatelecom(电信;中国电信;天翼)、com.geetest(极验;无感本机认证)
          </div>
          <div class="paragraph">
            收集个人信息目的：快速验证用户的手机号码和本机SIM卡号码一致性的功能，完成一键登录
          </div>
          <div class="paragraph">
            收集个人信息方式：嵌入式SDK
          </div>
          <div class="paragraph">
            收集个人信息范围：<span style="font-weight: bold;font-style: italic;color: black;color: black">设备标识信息</span>（IMEI、IDFA、<span style="font-weight: bold;font-style: italic;color: black;color: black">Android ID</span>、GID、 MAC、OAID、VAID、AAID、IMSI、MEID、UAID、SN、ICCID、SIM信息、<span style="font-weight: bold;font-style: italic;color: black;color: black">获取安装列表</span>）。
          </div>
          <div class="paragraph">
            第三方机构名称：深圳市和讯华谷信息技术有限公司
          </div>
          <div class="paragraph">
            官网链接：https://www.jiguang.cn/
          </div>
          <div class="paragraph">
            （2）共生地球默认使用微信SDK、腾讯QQSDK、新浪微博SDK用于第三方账号登录和分享。具体如下：
          </div>
          <div class="paragraph">
            第三方SDK名称：微信SDK、腾讯QQSDK、新浪微博SDK
          </div>
          <div class="paragraph">
            收集个人信息目的：帮助用户登录至第三方（qq、微信、微博）应用，帮助用户分享内容至第三方应用
          </div>
          <div class="paragraph">
            收集个人信息方式：嵌入式SDK
          </div>
          <div class="paragraph">
            收集个人信息范围：<span style="font-weight: bold;font-style: italic;color: black;color: black">设备标识信息、第三方账号信息</span>（IMEI、MAC 地址、<span style="font-weight: bold;font-style: italic;color: black;color: black">Android ID</span>、IDFA、OPENUDID、GUID、SIM卡IMSI信息、第三方账号用户ID、昵称、头像、<span style="font-weight: bold;font-style: italic;color: black;color: black">获取安装列表</span>）
          </div>
          <div class="paragraph">
            第三方机构名称：深圳市腾讯计算机系统有限公司、北京微梦创科网络技术有限公司
          </div>
          <div class="paragraph">
            隐私政策链接：https://www.wechat.com/zh_CN/privacy_policy.html、https://privacy.qq.com/policy/tencent-privacypolicy、https://www.weibo.com/signup/v5/privacy.com
          </div>
          <div class="paragraph">
            （3）共生地球默认使用百度地图SDK用于实现定位、地点检索、路径规划。具体如下：
          </div>
          <div class="paragraph">
            第三方SDK名称：百度地图SDK
          </div>
          <div class="paragraph">
            收集个人信息目的：帮助用户实现定位、按照地点搜索和路径规划
          </div>
          <div class="paragraph">
            收集个人信息范围：<span style="font-weight: bold;font-style: italic;color: black">设备标识信息</span>（Android ID）、地理位置信息、存储
          </div>
          <div class="paragraph">
            第三方机构名称：北京百度网讯科技有限公司
          </div>
          <div class="paragraph">
            隐私政策链接：https://lbsyun.baidu.com/index.php?title=open/privacy
          </div>
        <div class="paragraph">
          （4）共生地球默认使用io.github.v7lin SDK用于判断微信、微博、QQ第三方平台应用的安装、跳转、分享。具体如下：
        </div>
        <div class="paragraph">
          第三方SDK名称：io.github.v7lin SDK
        </div>
        <div class="paragraph">
          收集个人信息目的：判断用户是否已安装微信、微博、QQ第三方平台应用；通过分享链接打开共生地球时，判断用户是否已安装共生地球应用
        </div>
        <div class="paragraph">
          收集个人信息范围：<span style="font-weight: bold;font-style: italic;color: black">获取安装列表</span>
        </div>
        <div class="paragraph">
          相关网站：https://github.com/RxReader/link_kit
        </div>
        <div class="paragraph">
          （5）共生地球默认使用io.flutter.embedding SDK ，Flutter配套sdk，用于将项目移植在相应的支持平台。具体如下：</div>
        <div class="paragraph">
          第三方SDK名称：io.flutter.embedding SDK
        </div>
        <div class="paragraph">
          收集个人信息目的：用于支持将Flutter添加到现有项目的执行环境，使Flutter架构的项目可以在Android端运行
        </div>
        <div class="paragraph">
          收集个人信息范围：<span style="font-weight: bold;font-style: italic;color: black">获取安装列表</span>
        </div>
        <div class="paragraph">
          相关网站：https://github.com/flutter/engine/tree/main/shell/platform/android/io/flutter/embedding
        </div>
          <div class="paragraph">
            （6）共生地球默认使用cn.jpush.android SDK ，Flutter配套sdk，为App赋予推送能力，用于向用户发送推送消息，需要关联启动共生地球应用。具体如下：</div>
          <div class="paragraph">
            第三方SDK名称：cn.jpush.android SDK
          </div>
          <div class="paragraph">
            收集个人信息目的：用于向用户发送推送消息
          </div>
          <div class="paragraph">
            收集个人信息范围：<span style="font-weight: bold;font-style: italic;color: black">个人常用设备信息、位置信息、网络信息</span>
          </div>
          <div class="paragraph">
            相关网站：https://www.jiguang.cn/license/privacy
          </div>

          <div class="paragraph">
            （7）共生地球使用Flutter扩展插件库dev.fluttercommunity.plus，为App提供网络请求、设备感应交互等功能支持，需要关联启动共生地球应用。具体如下:</div>
          <div class="paragraph">
            第三方SDK名称：dev.fluttercommunity.plus
          </div>
          <div class="paragraph">
            收集个人信息目的：为App提供网络请求、设备感应交互等功能
          </div>
          <div class="paragraph">
            收集个人信息范围：<span style="font-weight: bold;font-style: italic;color: black">设备信息（Android ID）、网络信息</span>
          </div>
          <div class="paragraph">
            相关网站：https://gitcode.com/fluttercommunity/plus_plugins
          </div>


          <div class="paragraph">
            （8）自启动说明
          </div>
          <div class="paragraph">
            1）为确保本应用处于关闭或后台运行状态下可正常接收到客户端推送的信息，本应用须使用(自启动)能力，将存在一定频率通过系统发送广播唤醒本应用自启动或关联启动行为,是因实现功能及服务所必要的。
          </div>
          <div class="paragraph">
            2）当您打开内容类推送消息，在征得您的明确同意后，会跳转打开相关内容。在未征得您同意的情况下，则不会有自启动或关联启动。
          </div>
<!--          <div class="paragraph">-->
<!--            3、当您打开本App内部下载的文件后，会关联启动第三方App。-->
<!--        </div>-->






            <div class="paragraph">
                7、其他信息（如您在互动平台的互动信息、您参与线下活动的情况、您就网站使用或用户权益相关问题联系我们或提供反馈及投诉的情况)。
            </div>
            <div class="subTitle">三、我们如何使用这些信息</div>
            <div class="paragraph">
                对于我们收集或接收的有关您的信息，我们可能会用于多种目的，包括但不限于：
            </div>
            <div class="paragraph">
                1、将您的公开信息进行汇总、分析以形成报告；
            </div>
            <div class="paragraph">
                2、处理您提交的咨询、订阅或定制服务或让您参与有关我们产品和服务的调查；
            </div>
            <div class="paragraph">
                3、管理会员资格及会员权益(如认证身份、管理会员账户、组织会员活动、发放会员福利)；
            </div>
            <div class="paragraph">
                4、运营和改善共生地球的服务(如设计并推广定制化的服务和产品、推送有针对性的资讯、提升用户体验、及时发现并解决共生地球的技术问题)；
            </div>
            <div class="paragraph">
                5、用于网络安全防范（在我们提供服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性）；
            </div>
            <div class="paragraph">
                6、应用户特殊要求而提供特定服务时，需要将信息提供给我们的关联公司、第三方或其他用户；
            </div>
            <div class="paragraph">
                7、其他有利于用户和共生地球运营者利益且不违反任何强制性法律法规的情况。
            </div>
            <div class="subTitle">四、我们如何分享和披露用户信息</div>
            <div class="paragraph">
                1、除非事先征得您的同意或符合本政策的相关规定，我们不会向任何第三方提供、出售、出借、出租或分享用户信息，也不允许任何第三方以任何手段查询、收集、编辑或传播我们收集或接收的用户信息。"
            </div>
            <div class="paragraph">
                2、我们会根据法律、行政或司法机关的决定、命令或要求来保留或分享用户信息。在我们认为分享用户信息对遵守相关的法律是必要的时候，我们也会分享该些信息。此外，若分享用户信息对于保护共生地球、用户或其他方的权益是必要或适当的，我们也会分享用户信息。
            </div>
            <div class="paragraph">
                3、在如下情况下，共生地球可能会披露您的信息：
            </div>
            <div class="paragraph">
                （1）事先获得您的授权；
            </div>
            <div class="paragraph">
                （2）您使用共享功能；
            </div>
            <div class="paragraph">
                （3）根据法律、法规、法律程序的要求或政府主管部门的强制性要求；
            </div>
            <div class="paragraph">
                （4）以学术研究或公共利益为目的；
            </div>
            <div class="paragraph">
                （5）为维护共生地球的合法权益，例如查找、预防、处理欺诈或安全方面的问题；
            </div>
            <div class="paragraph">
                （6）符合相关服务条款或使用协议的规定。
            </div>
            <div class="subTitle">五、我们如何保护用户信息</div>
            <div class="paragraph">
                1、我们将采取合理的技术措施和其它必要措施，以确保用户信息的安全，并努力防止用户信息泄露、丢失或遭受破坏。其中包括：
            </div>
            <div class="paragraph">
                （1）使用加密技术来确保您的数据在传输过程中保持私密性；
            </div>
            <div class="paragraph">
                （2）审查我们在收集、存储和处理信息方面的做法（包括实体安全措施），以防未经授权的人员访问我们的系统。
            </div>
            <div class="paragraph">
                2、尽管严格遵守法律法规要求的标准并已经采取了上述合理有效措施，但由于技术的限制以及可能存在的各种恶意手段我们无法控制的原因，共生地球无法始终保证信息绝对安全，我们将尽力确保您提供给我们的个人信息的安全性。因此，我们强烈建议您采取积极措施保护个人信息的安全，包括但不限于使用复杂密码、定期修改密码、不将自己的账号密码个人信息透露给他人。
            </div>
            <div class="subTitle">六、未成年人的个人信息保护</div>
            <div class="paragraph">
                我们的网站和服务主要面向成人。如您为未成年人，建议您请您的父母或监护人仔细阅读本隐私政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。
            </div>
            <div class="subTitle">七、本政策的适用范围</div>
            <div class="paragraph">
                本隐私政策适用于共生地球提供的所有服务。但是不包括附有独立隐私政策的服务（如第三方提供的产品和服务）。请您注意，本隐私政策不适用于以下情况：
            </div>
            <div class="paragraph">
                1、通过我们的服务而接入的第三方服务（包括任何第三方网站）收集的信息（本隐私权政策不适用于第三方提供的产品和服务，例如在共生地球上由第三方提供的产品和服务，以及在我们的服务中链接到的其他网站，这些产品、服务或网站会有独立的隐私政策予以规范，请另行查阅相应的政策规定）；
            </div>
            <div class="paragraph">
                2、通过在我们服务中进行广告服务的其他公司或机构所收集的信息。
            </div>
            <div class="subTitle">八、用户权利的保障与响应</div>
            <div class="paragraph">按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：</div>
            <div class="paragraph">1.访问权</div>
            <div class="paragraph">您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：</div>
            <div class="paragraph">账户信息——如果您希望访问或编辑您的账户中的个人资料信息、更改您的密码或关闭您的账户，您可以通过访问APP-->我的-->编辑资料、APP-->我的-->设置-->账号与安全板块执行此类操作。</div>
            <div class="paragraph">2.更正权</div>
            <div class="paragraph">当您发现我们处理的关于您的个人信息有错误时，您可以通过登录APP个人中心编辑资料栏进行修改。</div>
            <div class="paragraph">3.删除权</div>
            <div class="paragraph">在以下情形中，您可以向我们提出删除个人信息的要求：</div>
            <div class="paragraph">（1）如果我们处理个人信息的行为违反法律法规；</div>
            <div class="paragraph">（2）如果我们收集、使用您的个人信息，却未征得您的同意；</div>
            <div class="paragraph">（3）如果我们处理个人信息的行为违反了与您的约定；</div>
            <div class="paragraph">（4）如果您不再使用我们的产品或服务，或您注销了账号；</div>
            <div class="paragraph">（5）如果我们不再为您提供产品或服务；</div>
            <div class="paragraph">（6）若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。</div>
            <div class="paragraph">当您被从我们的服务中删除信息后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。</div>
            <div class="paragraph">4.撤回同意权</div>
            <div class="paragraph">您可以电话联系客服向我们提出撤回收集、使用个人信息的同意授权。撤回同意后，我们后续不会再处理您的个人信息。同时，我们会保障您拒绝接收基于您个人信息推送的商业广告的权利。在我们对外共享、转让、公开披露您的个人信息时，我们会像您提供撤回同意的通知。撤回同意不影响撤回前基于同意的您的个人信息处理。</div>
            <div class="paragraph">5.注销权</div>
            <div class="paragraph">您可以在APP-->我的-->设置-->账号与安全-->注销账户板块注销。在注销账户之后，我们将停止为您提供登录状态下相关产品或服务，并删除您的个人信息，法律法规另有规定的除外。</div>
            <div class="paragraph">6.约束信息系统自动决策</div>
            <div class="paragraph">在某些业务功能中，我们可能仅依据信息系统、算法在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将提供适当的救济方式。</div>
            <div class="paragraph">7.响应您的上述请求</div>
            <div class="paragraph">为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。我们将在15个工作日内做出答复。</div>
            <div class="subTitle">九、如何联系我们</div>
            <div class="paragraph">
              您可以通过以下方式与我们联系，我们将在 15 个工作日内答复您的请求；
            </div>
            <div class="paragraph">
              1、您可以通过APP上提供的“吉林一号”微信公众号、“长光卫星”新浪微博与我们私信联系。
            </div>
            <div class="paragraph">
              2、您可以联系共生地球的客服电话进行反馈（15948788451）。
            </div>
            <div class="paragraph">
              3、我们还设立了专门的个人信息保护团队，您可以联系我们的个人信息保护负责人邮箱 gongshengdiqiu@163.com。
            </div>
            <div class="subTitle">十、开发者信息</div>
            <div class="paragraph">
              开发团队：长光卫星技术股份有限公司共生地球开发团队，负责人王亚洲（联系方式：15948788451）。
            </div>
            <div class="subTitle">十一、本政策的更新</div>
            <div class="paragraph">
                我们可能会不定期修改、更新本隐私政策，有关隐私政策的更新，您可以访问共生地球App 查询最新版本的隐私政策。
            </div>
            <div style="width: 100%;height: 80px;"></div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                phoneNum:"",
                currentPage:"activity",
            }
        },
        methods: {
        },
        computed:{
        }
    }
</script>

<style>
    .content {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background:white;
        z-index: -1;
    }

    .subTitle{
        width: 82%;
        margin: 0 auto;
        font-size: 16px;
        color: black;
        text-align: left;
        line-height: 20px;
        margin-top: 20px;
        font-weight: 700;
        word-wrap:break-word;
    }

    .paragraph{
        width: 82%;
        font-size: 14px;
        color: #7C7B7B;
        text-align: left;
        line-height: 18px;
        text-indent: 30px;
        word-wrap:break-word;
        margin:10px auto 0 auto;
    }

    .paragraph2{
        width: 82%;
        float: right;
        margin-right: 9%;
        font-size: 14px;
        color: #7C7B7B;
        text-align: right;
        line-height: 18px;
        text-indent: 30px;
        margin-top: 5px;
        clear: both;
      word-wrap:break-word;
    }
</style>
