import {LWMSTileLayer} from "vue2-leaflet";
<template>
  <div id = "fireAlarm">
    <div id="map">
    </div>
    <div id="scanner" :style="scannerShow? 'transform: translateY(2000px);transition: transform 4s;':''"></div>
    <TopShareBar v-if="share" />
  </div>
</template>

<script>
import L from "leaflet";
import 'leaflet'
// import Custom from "@/lib/leaflet.wms.simplify.js";
import Custom from "./leaflet.wms.simplify";
import TopShareBar from '@/components/TopShareBar';
import {getWxSignature} from "@/utils/shareConfig";

export default {

  components :{
    TopShareBar
  },
  name: "FireAlarm",
  data(){
    return {
      scannerShow: false,
      map: "",
      infoId:'',
      maxgeo:{},
      url:"http://121.36.101.27:8000/geoserver/linhuo_beijing/wms",
      lat:39.8,
      lng:116.20,
      avatarUrl:"",
      htmlString:"",
      share: false,
      wmsUrl: "linhuo_beijing:20220514_FRID_Beijing_24h",
      maxZoom:0,
      wmsLayer:null,
      clickedItem:"",
    }
  },
  mounted() {
    window.fireAlarmMap = this;
    this.infoId = this.$route.query.infoId
    this.wmsUrl = this.$route.query.wmsUrl
    if(this.$route.query.share === "1"){
      this.share = true
    }else{
      this.share = false
    }
    getWxSignature(this.infoId)
    this.init();
  },
  methods:{
    clickListener(){
      this.map.off("click")
      //必须先转化成const
      const map = this.map;
      //本地测试
      // let url = "/fireAlarmGeoServer/linhuo_beijing/wms";
      //线上
      let url = "/fireAlarmGeoServer/geoserver/linhuo_beijing/wms";
      let layer = this.wmsUrl.split(":")[1];
      this.map.on("click", function (event) {
        Custom.GetInfo(map, event, url, layer, (info) => {
          // console.log(info);
          var list = info.toString().split("\n");
          if(list[0] === "no features were found" ){
            return
          }
          // console.log("周杰伦");
          // console.log(list);
          var properties = {};
          var level = ''
          if(list[5].split("=")[1].trim() == 2){
            level = '较高'
          }else if(list[5].split("=")[1].trim() == 3){
            level = '高度'
          }else if(list[5].split("=")[1].trim() == 4){
            level = '极度'
          }

          properties["humidity"] = list[7].split("=")[1].trim();
          properties["temperature"] = list[6].split("=")[1].trim();
          properties["coverage"] = list[10].split("=")[1].trim();
          properties["alertLevel"] = level;
          properties["location"] = list[3].split("=")[1].trim();
          if(window["OpenFireAlarmPanel"]){
            window["OpenFireAlarmPanel"].postMessage(JSON.stringify(properties));
          }
        });
      });
    },
    init() {

      this.initMap();
      this.fireAlarmFlyTo(this.lat, this.lng);
      // this.initMarker();
      this.initFireMapLayer();
      this.getGeoRange()

      //用html的div的样式来创建marker的icon，注意需要加className属性，否则会出现marker1的问题
    },
    initMap(){
      const baseLayer = L.tileLayer(
          "https://t0.tianditu.gov.cn/DataServer?T=img_w&X={x}&Y={y}&L={z}&tk=05861044e5fe4135647facbd39abc543",
          {maxZoom: 18, minZoom: 5,maxBounds:this.maxBounds}
      );
      this.map = L.map("map", {
        center: [this.lat , this.lng],
        minZoom:5,
        maxZoom: 18,// 地图中心
        zoom: this.maxZoom, //缩放比列
        zoomControl: false, //禁用 + - 按钮
        doubleClickZoom: false, // 禁用双击放大
        attributionControl: false,// 移除右下角leaflet标识
        crs: L.CRS.EPSG3857,
        layers:[baseLayer],
      });
    },
    resetSight(){
      this.fireAlarmFlyTo(this.lat, this.lng);
    },
    initFireMapLayer(){
      // this.wmsUrl = 'linhuo_beijing:20220514_FRID_Beijing_24h'
      if(this.share){
        this.upDateWmsLayer(this.wmsUrl)
      }else {
        if(window["InitFireAlarmLayer"]){
          window["InitFireAlarmLayer"].postMessage("");
        }
      }

    },
    addFireAlarmLayer(){
      this.scannerShow = true
      setTimeout(()=>{ this.scannerShow = false; this.wmsLayer.addTo(this.map); },1200)
    },
    upDateWmsLayer(url){
      this.wmsUrl = url;
      // console.log(this.wmsUrl)
      if(this.wmsLayer!= null){
        this.wmsLayer.remove();
      }
      //本地测试
      // this.wmsLayer = L.tileLayer.wms("/fireAlarmGeoServer/linhuo_beijing/wms"
          //线上
          this.wmsLayer = L.tileLayer.wms("/fireAlarmGeoServer/geoserver/linhuo_beijing/wms"
          , {
            layers: this.wmsUrl,
            format: 'image/png',//返回的数据格式
            transparent: true,
          }
      );
      console.log(this.wmsLayer)
      this.addFireAlarmLayer()
      if(!this.share){
        this. clickListener()
      }
    },
    removeFireAlarmLayer(){
      //删除图层
      this.wmsLayer.remove();
    },
    fireAlarmFlyTo(lat, lng){
      this.map.flyTo({lat: lat+0.5, lng:lng+0.2}, 8);
    },
    getGeoRange(){
      this.$http.get('/earthApi/yijing/getGeoRange?infoId='+this.infoId).then(res=> {
        if(res["data"].status === 200){
          console.log(res["data"].data)
          this.maxgeo = res["data"].data.maxgeo
          this.maxZoom = res["data"].data.maxZoom
          this.map.fitBounds(this.maxgeo)
          // this.map.setZoom(this.maxZoom)
        }
      })
    }
  },
}
</script>

<style scoped lang="scss">

.opacitySlide {
  position: absolute;
  z-index: 2;
  left: 24px;
  right: 24px;
  bottom: 160px;
  input[type=range] {
    width: 100%;
    background: white;
    height: 5px;
    border-radius: 3px;
    outline: none;
  }

}

#scanner{
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: -152px;
  height: 150px;
  background: linear-gradient(180deg,rgba(0,0,0,0.00), #99c0e0);
  border-bottom: #5aa6e4 solid 2px;
}


#fireAlarm{
  font-family: 微软雅黑;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}
#map {
  width: 100%;
  height: calc(100vh);
  z-index: 1;
}
</style>
