<template>
    <div class="myWish alibabaPuHuiTiFontFamily">
        <div v-if="myWish.length !==0" style="height: 100%;width: 100%;position: absolute;display: flex;flex-direction: column;justify-content: space-between">
            <div style=" margin-top:89px;font-size: 24px;font-weight: 900;color: white;text-align: center;width: 100%">
                <span class="iconfontFamily" style="position:absolute;font-size:25px;color: #FED150;margin-left: -27px;margin-top: -10px">&#xe782;</span>
                我的愿望
            </div>
<!--            height: calc(100% - 200px);width: calc(100% - 128px)-->
            <!--轮播部分-->
            <div style="max-height: 500px;width: 100%;margin-top: 30px;position: relative;flex: 1">
              <swiper ref="wishSwiper" @slideChange="onPageChange()" :options="swiperOption" style="height: 100%;width: 100%;">
                        <swiper-slide v-for="(item,index) in myWish" :key="index">
                            <div :key="index" style="position:relative;height: 100%;width: 100%;display: flex;align-items: center;justify-items: center">
                              <div v-show="!wishOpen" style="height: calc(100% - 154px); max-height: 115px;width: 100%;margin: 0 auto" :style="{'animation':wishOpenIng ? 'wishCloseHide 0.6s' : 'wishCloseShow 0.6s','animation-fill-mode':'forwards'}">
                                    <div style="height: 100%;width: 100%;" @click="openLetter()" >
                                        <img v-if="item.wishStatus === 3 || item.wishStatus === 4" style="height:100%" src="../../../../assets/Satellite/ActivityFour/letter_complete.png"  alt="图像无法加载">
                                        <img v-else style="height:100%" src="../../../../assets/Satellite/ActivityFour/letter.png" alt="图像无法加载">
                                    </div>
                                </div>
                                <div v-show="wishOpen" style="height: 100%;width:100%;display: flex;justify-content: center;align-items: center;position: relative;" @click="closeLetter()" :style="{'animation':wishCloseIng ?  'wishOpenHide 0.4s':!wishOpenIng ? 'wishOpenShow 0.4s' : 'wishOpenHide 0.4s','animation-fill-mode':'forwards'}">
                                        <div style="position:relative;height: calc(100% - 30px);color: white;line-height: 23px;text-align: justify;z-index: 99999;word-break: break-all;padding: 30px 20px 0 20px;font-family: HongLeiBanShuJianTi;"
                                            :style="{'width':screenSmallSize ? 'calc(100% - 120px)':'calc(100% - 80px)','font-size':screenSmallSize ? '16px':'18px'}"
                                        >{{item.wishContent}}</div>
                                    <div v-if="item.wishStatus === 1" style="position: absolute;bottom: 18px;left: 60px;color: #FFBF89;line-height: 23px;text-align: left;z-index: 9999"
                                         :style="{'left':screenSmallSize ? '60px':'40px','font-size':screenSmallSize ? '14px':'16px'}">心愿池</div>
                                    <div v-else style="position: absolute;bottom: 18px;left: 60px;color: white;line-height: 23px;text-align: left;z-index: 9999"
                                         :style="{'left':screenSmallSize ? '60px':'40px','font-size':screenSmallSize ? '14px':'16px'}">环绕地球 <span style="color: #FF8D1A">{{parseToString(item.orbitNumber)}}</span>圈</div>
                                        <div v-show="isShowCloseLetterTips" style="position: absolute; top: 50%;left: 50%;height: 22px;width: 84px;transform: translate(-50%);font-size: 14px;color: white;z-index: 9999" :style="{'animation':isShowCloseLetterTipsPro ? 'textShow 1s':'textHide 1s','animation-fill-mode':'forwards'}">点击折叠愿望</div>
                                        <img style=" position:absolute;height:100%;top: 0;left: 50%;transform: translate(-50%);"  src="../../../../assets/Satellite/ActivityFour/wishCardBg.png" alt="图像无法加载">
                                </div>
                            </div>
                        </swiper-slide>
                    </swiper>
<!--                :style="{'animation':!wishOpenIng ? 'wishOpenShow 0.4s' : '','animation-fill-mode':!wishOpenIng ? 'forwards' : ''}"-->
<!--                :style="{'animation':checkWishWaitIng ? 'wishCloseShake 2.3s infinite' : wishOpenIng ? 'wishCloseHide 0.6s' : 'wishCloseShow 0.6s','animation-fill-mode':'forwards'}"-->
                <div v-if="!wishOpen" style="position: absolute;top:0;font-size: 24px;text-align: center;width: 100%;height: 32px;line-height: 32px;z-index: 9999;background-image: linear-gradient(to right, #F6D365, #FDA085);-webkit-background-clip: text;color: transparent;font-family:YouSheBiaoTiHei">{{letterStatus}}</div>
                <div  v-if="isShow0penLetterTips" style="position: absolute;top: 40px;left:0;font-size: 14px;color: white;;text-align: center;width: 100%;height: 20px;line-height: 20px;z-index: 9999;" :style="{'animation':isShow0penLetterTipsPro ? 'textShow 1s':'textHide 1s'}">点击信封查看愿望详情</div>
                <!--                        :style="{'height':is0penLetter ? '100%':'calc(100% - 224px)'}"-->
                <div  v-if="!wishOpen && myWish[swiperIndex].wishStatus === 2" style="position: absolute;bottom:40px;font-size: 16px;color: white;text-align: center;width: 100%;height: 22px;">已环绕地球：<span style="color: #FF8F1F">{{parseToString(letterOrbitNumber)}}</span> 圈</div>
                <div  v-if="!wishOpen && (myWish[swiperIndex].wishStatus === 3 || myWish[swiperIndex].wishStatus === 4)" style="position: absolute;bottom:40px;font-size: 16px;color: white;text-align: center;width: 100%;height: 22px;">共环绕地球：<span style="color: #FF8F1F">{{parseToString(letterOrbitNumber)}}</span> 圈</div>
                <div  v-if="!wishOpen && myWish[swiperIndex].wishStatus === 1" style="position: absolute;bottom:40px;font-size: 16px;color: rgba(255, 141, 26, 1);text-align: center;width: 100%;height: 22px;">等待前往太空</div>
                <div  v-if="!wishOpen && myWish[swiperIndex].wishStatus === 2" style="position: absolute;bottom:0;height:22px;font-size: 16px;color: white;text-align: center;width: 100%;line-height: 22px;display: flex;align-items: center;justify-content: center;z-index: 9999">
                    <span style="font-size:22px;color: #FF8F1F;margin-right: 6px;line-height: 22px;height: 22px;" class="iconfontFamily " @click="goToSatellite()">&#xe76d;</span>
                    <div @click="goToSatellite()">查看愿望实时位置</div>
                </div>
                <div  v-if="!wishOpen && myWish[swiperIndex].wishStatus === 4" style="position: absolute;bottom:0;height:22px;font-size: 16px;color: #808080;text-align: center;width: 100%;line-height: 22px;display: flex;align-items: center;justify-content: center;z-index: 9999">
                    吉林一号星座协力支持
                </div>
                <div  v-if="!wishOpen && myWish[swiperIndex].wishStatus === 1 " style="position: absolute;bottom:0;height:22px;font-size: 16px;color: white;text-align: center;width: 100%;line-height: 22px;display: flex;align-items: center;justify-content: center;z-index: 9999">
                    愿望不定期抽取，请耐心等待
                </div>
            </div>
            <!--底部分享-->
            <div style="margin-top:30px;width: 100%;height:52px;display: flex;justify-content: center;">
                <div style="width: 140px;height: 52px;line-height:52px;text-align: center;color: #2A82E4;font-size: 16px;font-weight: 500;border-radius: 28px;background: linear-gradient(90deg, #A1C4FD 0%, #C2E9FB 100%),linear-gradient(180deg, #A1C4FD 0%, #C2E9FB 100%);" @click="shareWish()">分享愿望</div>
                <div style="margin-left: 16px;width: 140px;height: 52px;line-height:52px;text-align: center;color: #DB6E00;font-size: 16px;font-weight: 500;border-radius: 28px;background: linear-gradient(90deg, #FFC97D 0%, #FED150 100%),linear-gradient(180deg, #A1C4FD 0%, #C2E9FB 100%);" @click="pushNewWish()">发送新愿望</div>
            </div>
            <div style="height: 3%;width: 100%"></div>
            <div style="position:absolute;top: 139px;right: 0; width: 50px;height: 40px;z-index: 99999;">
                <!--按钮-->
                <div class="iconfontFamily " style="float:right;width: 50px;height: 40px;line-height:40px;border-radius: 20px 0 0 20px;background: linear-gradient(90deg, #29323C 0%, #6E8399 100%),#FFFFFF;text-align: center;font-size: 24px;" @click="goToActivation">
                    <div class="textColor"> &#xe784;</div>
                </div>
            </div>
            <!--提示是否激活-->
<!--            isMemberStatus === 0 && !isShowActivationTips-->
<!--            :style="{'animation': 'tipScroll 2s forwards'}"-->
            <div v-if="isMemberStatus === 0 && !isShowActivationTips" style="position:absolute;top: 139px;right: 58px;height: 40px;display: flex;justify-content: right;align-items: center;width: 205px;z-index: 99999;animation: tipScroll 2s forwards;overflow: hidden" >
                <div style="width: 196px;height: 26px;line-height: 26px;text-align: center;font-size: 12px;border-radius: 4px;background: #CCCCCC">激活共生地球会员，享专属权益</div>
                <div style="width: 0;height: 0;border-top: 5px solid transparent;border-left: 5px solid #CCCCCC;border-bottom: 5px solid transparent"></div>
            </div>
        </div>

        <div v-else style="height: 100%;width: 100% ;position: absolute">
            <div style=" margin-top:89px;font-size: 24px;font-weight: 900;color: white;text-align: center;width: 100%">
                <span class="iconfontFamily" style="position:absolute;font-size:25px;color: #FED150;margin-left: -27px;margin-top: -10px">&#xe782;</span>
                我的愿望
            </div>
            <div style="height: calc(100% - 242px);width: 100%;display: flex;flex-direction:column;justify-items: center;align-items: center;justify-content: center;">
                <img style="width: 147px;margin-left: 5px" src="../../../../assets/Satellite/ActivityFour/noWish.png" alt="图片无法加载" />
                <div style="font-size: 16px; height: 22px;line-height: 22px;color: white;width: 198px;text-align: center;">当前暂无心愿，快来发送吧~</div>
            </div>
            <div style="width: 100%;height:52px;display: flex;justify-content: center;">
                <div style="width: 140px;height: 52px;line-height:52px;text-align: center;color: #DB6E00;font-size: 16px;font-weight: 500;border-radius: 28px;background: linear-gradient(90deg, #FFC97D 0%, #FED150 100%),linear-gradient(180deg, #A1C4FD 0%, #C2E9FB 100%);" @click="pushNewWish()">发送新愿望</div>
            </div>
        </div>

        <TopShareBarWithBack  v-if="share === '1'" backPage="/SatelliteActivityPageFour" :backPageParam="JSON.stringify({infoId: this.$route.query.infoId,share: 1})"/>
    </div>
</template>

<script>
    import TopShareBarWithBack from "../../../../components/TopShareBarWithBack";
    import 'swiper/dist/css/swiper.css'
    import { swiper, swiperSlide } from 'vue-awesome-swiper'
    import {getWxSignature} from "@/utils/shareConfig";
    export default {
        name: "MyWish",
        components: {
            TopShareBarWithBack,
            swiper,
            swiperSlide,
        },
        data(){
            return {
                share:'',//分享
                is0penLetter:false,//是否打开信封
                aaa:'',
                isShow0penLetterTips:false,
                isShow0penLetterTipsPro:false,//未打开时的动画过程
                isShowCloseLetterTips:true,
                isShowCloseLetterTipsPro:false,
                myWish:[
                    {
                    "avatar": "https://cgwxforum.obs.cn-north-4.myhuaweicloud.com:443/202108301117000116048_thumb.png",
                    "geo": "{\"coordinates\": [-90.39,-43.72],\"type\": \"Point\"}",
                    "geoName": "啥啥啥市",
                    "id": 5,
                    "memberStatus": 0,
                    "nickName": "探索者987654321",
                    "orbitNumber": 16,
                    "userId": "1655713735396487",
                    "wishContent": "希望。。。。。。。。1",
                    "wishStatus": 2
                    },
                ],
                swiperOption:{
                    effect : 'coverflow',
                    slidesPerView: 'auto',
                    centeredSlides: true,
                    coverflowEffect: {
                        rotate: 0,
                        stretch: -100,
                        depth: 700,
                        modifier: 0.5,
                        slideShadows: false
                    },
                    touchRatio: 2
                },
                swiperIndex:0,
                isMemberStatus:5,//当前用户的会员状态ID
                letterStatus:'',
                letterOrbitNumber:0,
                wishOpenIng:false,   //开信封过程中
                wishOpen:false,   //已拆信封，进入愿望卡片
                wishCloseIng:false,
                screenSmallSize:false,
                infoId:'',//活动ID
                isShowActivationTips:false
            }
        },
        methods:{
            //分享愿望
            shareWish(){
                if(this.share === '1'){
                    window.location.href='/OpenApp?infoId=' + this.infoId + '&type=1500'
                }else{
                    // eslint-disable-next-line no-undef
                    ShareWish.postMessage(this.myWish[this.swiperIndex].id)
                }
            },
            //发送新愿望
            pushNewWish(){
                if(this.share === '1'){
                    this.$router.push(
                        {
                            path: '/EditWishContent',
                            query: {
                                infoId: this.infoId,
                                share: '1',
                            }
                        }
                    )
                }else{
                    // eslint-disable-next-line no-undef
                    PushNewWish.postMessage("")
                }
            },
            //swiper翻页
            onPageChange(){
                this.swiperIndex = this.$refs.wishSwiper.swiper.activeIndex
                this.letterStatus = this.wishStatusToString(this.myWish[this.swiperIndex].wishStatus)
                this.letterOrbitNumber = this.myWish[this.swiperIndex].orbitNumber
            },
            openLetter(){
                this.wishOpenIng = true;
                setTimeout(()=>{
                    this.wishOpenIng = false;
                    this.wishOpen = true;
                    this.wishCloseIng = false
                },300)
               // this.is0penLetter = true
                this.isShowCloseLetterTipsPro = true
                setTimeout(()=>{this.isShowCloseLetterTipsPro = false},2000)
                setTimeout(()=>{this.isShowCloseLetterTips = false},3000)

            },
            closeLetter(){
                //大动画
                this.wishCloseIng = true
                setTimeout(()=>{
                    this.wishCloseIng = false;
                    this.wishOpenIng = false;
                    this.wishOpen = false;
                },300)
                //小动画

            },
            getMyWishes(){
                this.myWish = []
                this.$http.get('/earthApi/sa-user-wish/getMyWishes',{headers:{'Authorization': 'Bearer ' + this.aaa}}).then((res)=>{
                    if(res.data.status === 200){
                        this.myWish = res.data.data.wishList
                        if(res.data.data.wishList.length >0){
                            this.letterStatus = this.wishStatusToString(res.data.data.wishList[0].wishStatus)
                            this.letterOrbitNumber = res.data.data.wishList[0].orbitNumber
                            this.isShow0penLetterTipsPro = true
                            this.isShow0penLetterTips = true
                            setTimeout(()=>{this.isShow0penLetterTipsPro = false},2000)
                            setTimeout(()=>{this.isShow0penLetterTips = false},3000)
                            setTimeout(()=>{this.isShowActivationTips = true},4000)
                        }
                        this.isMemberStatus = res.data.data.memberStatus
                    }
                })
            },
            wishStatusToString(val){
                const map={
                    1:'已进入心愿池',
                    2:'太空探索中 ',
                    3:'抵达目标地点',
                    4:'抵达目标地点',
                }
                return map[val]
            },
            //去卫星页
            goToSatellite(){
                if(this.share === '1'){
                    window.location.href='/OpenApp?infoId=' + this.infoId + '&type=1500'
                }else{
                    // eslint-disable-next-line no-undef
                    ToSatellitePage.postMessage('')
                }
            },
            //去激活页面
            goToActivation(){
                if(this.share === '1'){
                    this.$router.push(
                        {
                            path: '/MemberActivation',
                            query: {
                                infoId: this.infoId,
                                share: '1',
                                from:'myWish'
                            }
                        }
                    )
                }else{
                    // eslint-disable-next-line no-undef
                    ToActivationPage.postMessage(this.swiperIndex)
                }
            },
            //数字转字符串
            parseToString(val){
                let str = val.toFixed(1)
                return str
            },
            //改变状态回调方法
            changeMyWishStatus(){
                if(this.letterStatus ==='已进入心愿池'){
                    this.letterStatus = '太空探索中'
                }
                if(this.myWish.length>0){
                    for(let i = 0;i<this.myWish.length;i++){
                        if(this.myWish[i].wishStatus === 1){
                            this.myWish[i].wishStatus = 2
                        }
                    }
                }
            }
        },
        mounted() {
            window.myWishPage = this
            if(this.$route.query.share === '1'){
                this.aaa = this.$store.state.token
            }else{
                this.aaa = this.$route.query.token
            }
            this.share = this.$route.query.share
            this.infoId = this.$route.query.infoId
            //获取屏幕高度，确定他的愿望内容的字号
            this.screenSmallSize = document.getElementsByClassName("myWish")[0].clientHeight < 720;
            this.getMyWishes()
          getWxSignature(this.$route.query.infoId)


        }
    }
</script>

<style scoped>
    .myWish{
        width: 100%;
        height: 100%;
        position:absolute;
        background-image:url("../../../../assets/Satellite/ActivityFour/myWish_bg.jpg");
        background-position: center;
        background-size: cover;
        /*overflow: hidden;*/
    }
    .textColor{
        background-image:-webkit-linear-gradient(90deg, #F6D365 0%, #FFFFFF 100%);
        -webkit-background-clip:text;
        -webkit-text-fill-color:transparent;
    }
    .swiper-slide {
        /*width: calc(100% - 94px) !important;*/
        width: 280px !important;
        /*margin-left: 9px;*/
        height: 100%;
        /*background: #FFFFFF;*/
        /*box-shadow: 0px 0px 15px 0px rgba(197, 197, 197, 0.2);*/
        /*border-radius: 7px;*/
        text-align: center;
    }



</style>
<style>
    @keyframes wishCloseShow {
        from {height:0;opacity:0;}
        to {height:100%;opacity:1;}
    }
    @keyframes wishCloseHide {
        from {height:100%;opacity:1;}
        to {height:0;opacity:0;}
    }
    @keyframes wishOpenShow {
        from {
            transform: scale(0);
        }
        to {
            transform: scale(1);
        }
    }

    @keyframes wishOpenHide {
        from {
            transform: scale(1);
        }
        to {
            transform: scale(0);
        }
    }
    @keyframes textHide {
        from {
            opacity:1;
        }
        to {
            opacity:0;
        }
    }
    @keyframes textShow {
        from {
            opacity:0;
        }
        to {
            opacity:1;
        }
    }
    @keyframes tipScroll{
        from{
            width: 0;
        }
        to{
            width: 205px
        }
    }
</style>
