<template>

  <div ref="productPageImageLib">

    <div v-if="device==='web'" class="productPageImageLib">
      <!--顶栏-->
      <div style="position:fixed;top:0;left:0;width:calc(100% - 90px);height:80px;background:rgba(21, 21, 40, 0.45);padding:0 45px;display:flex;align-items:center;z-index:99;">
        <div style="flex:1;">
          <img @click="reloadPage" style="width:166px;cursor:pointer;" src="../../assets/App/productPage/imageLib/logo.png" />
        </div>

        <p @click="gotoApp" style="font-size:16px;cursor:pointer;">首页</p>
<!--        <p @click="gotoImageLib" style="margin-left:49px;font-size:16px;cursor:pointer;">在线体验</p>-->
        <p @click="gotoAboutUsDetailPage" style="margin-left:63px;font-size:16px;cursor:pointer;">关于我们</p>

<!--        <div style="flex:1;">-->
<!--          <div @click="gotoImageLib" style="width:128px;height:38px;border-radius:20px;background:linear-gradient(175deg,rgba(255, 255, 255, 0.5) 0%,rgba(255, 255, 255, 0) 100%);padding:1px;float:right;cursor:pointer;">-->
<!--            <div style="width:128px;height:38px;border-radius:20px;background:rgba(42, 130, 228, 1);text-align:center;">-->
<!--              <p style="line-height:38px;font-size:14px;">注册/登录</p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <div style="flex:1;"></div>

      </div>


      <!--第一屏-->
      <div id="productPageImageLibScreen1" style="display:flex;align-items:center;" class="screen">
        <div :style="{'min-width':webScreenSize === 'big' ? '540px' : '460px'}" style="width:45%;">
          <div :style="{'width':webScreenSize === 'big' ? '480px' : '400px'}" style="position:relative;left:50%;transform:translate(-50%);height:100%;">
            <p style="text-align:left;font-weight:bold;" class="productPageImageLibColorTitle">吉林一号·遥感影像库</p>
            <div>
              <p style="margin-top:39px;line-height:35px;" class="productPageImageLibPs">为创作者而生的地图服务平台<br/>丰富工具帮助创作者创作出更有价值的信息</p>
<!--              <div @click="gotoImageLib" style="width:128px;height:38px;margin-top:96px;border-radius:20px;background:linear-gradient(175deg,rgba(255, 255, 255, 0.5) 0%,rgba(255, 255, 255, 0) 100%);padding:1px;cursor:pointer;">-->
<!--                <div style="width:128px;height:38px;border-radius:20px;background:rgba(42, 130, 228, 1);text-align:center;">-->
<!--                  <p style="line-height:38px;font-size:14px;">-->
<!--                    立即体验-->
<!--                    <span style="margin-left:9px;" class="iconfontFamily">&#xe7a0;</span>-->
<!--                  </p>-->
<!--                </div>-->
<!--              </div>-->
              <p style="margin-top:96px;line-height:35px;" class="productPageImageLibPs">敬请期待</p>

            </div>

          </div>
        </div>
        <div style="flex:1;height:100%;">
          <div :style="{'max-width':webScreenSize === 'big' ? '780px' : '600px'}" style="position:relative;left:0;top:50%;transform:translate(0,-50%);width:95%;">
            <img style="width:95%;border-radius:10px;"
                 src="../../assets/App/productPage/imageLib/screen1/mainPicture.jpg" />
            <!--小图放大-->
            <img id="screen1BigImg1" style="position:absolute;bottom:45%;left:66%;width:0;animation-duration:1.5s;animation-fill-mode: forwards;"
                 src="../../assets/App/productPage/imageLib/screen1/bigImg1.png" />
            <img id="screen1BigImg2" style="position:absolute;top:65%;left:52%;width:0;animation-duration:1.5s;animation-fill-mode: forwards;"
                 src="../../assets/App/productPage/imageLib/screen1/bigImg2.png" />
          </div>
        </div>
      </div>

      <!--第二屏-->
      <div id="productPageImageLibScreen2" class="screen">
        <div :style="{'width':webScreenSize === 'big' ? '1260px' : '1000px'}"
             style="position:relative;top:50%;left:0;transform:translate(0,-50%);width:1260px;margin:0 auto;display:flex;flex-direction:column;align-items:center;">
          <p id="productPageImageLibScreen2_title" class="productPageImageLibColorTitle floatAnimationDom">智能检索 直达影像</p>
          <p id="productPageImageLibScreen2_ps" class="floatAnimationDom productPageImageLibPs" style="width:100%;margin-top:14px;text-align:center;">海量数据 智能管理</p>

          <div id="productPageImageLibScreen2_img" class="floatAnimationDom" style="width:100%;margin-top:72px;display:flex;justify-content:center;align-items:center;">
            <div :style="{'width':webScreenSize === 'big' ? '700px' : '540px'}">
              <img style="width:100%;" src="../../assets/App/productPage/imageLib/screen2/mainPicture.png" />
            </div>
            <div :style="{'width':webScreenSize === 'big' ? '510px' : '410px'}" style="position:relative;margin-left:50px;">
              <p :style="{'font-size':webScreenSize === 'big' ? '24px' : '20px'}" style="line-height:36px;">庞大的影像数据管理平台，涵盖丰富遥感资源<br/>通过智能检索，可轻松找到所需影像</p>
            </div>
          </div>
        </div>

      </div>

      <!--第三屏-->
      <div id="productPageImageLibScreen3" class="screen">
        <div :style="{'width':webScreenSize === 'big' ? '1260px' : '1000px'}"
             style="position:relative;top:50%;left:0;transform:translate(0,-50%);margin:0 auto;display:flex;flex-direction:column;align-items:center;">
          <p id="productPageImageLibScreen3_title" class="productPageImageLibColorTitle floatAnimationDom">官方授权 版权无忧</p>
          <p id="productPageImageLibScreen3_ps" class="floatAnimationDom productPageImageLibPs" style="width:100%;margin-top:14px;text-align:center;">一键购买 直达创作</p>

          <div id="productPageImageLibScreen3_img" class="floatAnimationDom" style="width:100%;margin-top:72px;display:flex;justify-content:center;align-items:center;">
            <div :style="{'width':webScreenSize === 'big' ? '700px' : '540px'}">
              <img style="width:100%;border-radius:10px;" src="../../assets/App/productPage/imageLib/screen3/mainPicture.jpg" />
            </div>
            <div :style="{'width':webScreenSize === 'big' ? '510px' : '410px'}" style="position:relative;margin-left:50px;">
              <p :style="{'font-size':webScreenSize === 'big' ? '24px' : '20px'}" style="line-height:36px;">致力于为用户提供合法、可靠的服务<br/>让创作和分享过程更加安心、畅快</p>
            </div>
          </div>
        </div>

      </div>

      <!--第四屏-->
      <div id="productPageImageLibScreen4" class="screen">
        <div style="position:relative;top:50%;left:0;transform:translate(0,-50%);width:100%;display:flex;flex-direction:column;align-items:center;">
          <p id="productPageImageLibScreen4_title" class="productPageImageLibColorTitle floatAnimationDom">海量影像  超清查看</p>
          <p id="productPageImageLibScreen4_ps" class="floatAnimationDom productPageImageLibPs" style="width:100%;margin-top:14px;text-align:center;">还原地球 极致瞰世界</p>

          <!--横向图片区域-->
          <div id="productPageImageLibScreen4_img" class="floatAnimationDom" style="width:100%;margin-top:65px;">

            <div :style="{'animation-play-state':screen4ImageScrollPause ? 'paused' : 'running'}" style="position:relative;width:100%;display:flex;animation:screen4ImageScrollFront 20s linear infinite;">
              <div v-for="(item,index) in screenImgList1" :key="'screenImgList1'+index"
                   style="width:12.5%;flex-shrink:0;cursor:pointer;">
                <img @click="gotoScreen4ImageItem(item)" @mouseover="screen4ImageScrollPause = true;" @mouseout="screen4ImageScrollPause = false;"
                     :src="item.thumb" style="width:calc(100% - 12px);aspect-ratio:1.7/1;border-radius:8px;object-fit:cover;"/>
              </div>
            </div>
            <div :style="{'animation-play-state':screen4ImageScrollPause ? 'paused' : 'running'}" style="position:relative;width:100%;margin-top:9px;display:flex;animation:screen4ImageScrollBehind 20s linear infinite;">
              <div v-for="(item,index) in screenImgList2" :key="'screenImgList2'+index"
                   style="width:12.5%;flex-shrink:0;cursor:pointer;">
                <img @click="gotoScreen4ImageItem(item)" @mouseover="screen4ImageScrollPause = true;" @mouseout="screen4ImageScrollPause = false;"
                     :src="item.thumb" style="width:calc(100% - 12px);aspect-ratio:1.7/1;border-radius:8px;object-fit:cover;" />
              </div>
            </div>
            <div :style="{'animation-play-state':screen4ImageScrollPause ? 'paused' : 'running'}" style="position:relative;width:100%;margin-top:9px;display:flex;animation:screen4ImageScrollFront 20s linear infinite;">
              <div v-for="(item,index) in screenImgList3" :key="'screenImgList3'+index"
                   style=";width:12.5%;flex-shrink:0;cursor:pointer;">
                <img @click="gotoScreen4ImageItem(item)" @mouseover="screen4ImageScrollPause = true;" @mouseout="screen4ImageScrollPause = false;"
                     :src="item.thumb" style="width:calc(100% - 12px);aspect-ratio:1.7/1;border-radius:8px;object-fit:cover;" />
              </div>
            </div>

          </div>
        </div>

        <!--底部渐变-->
        <div style="position:absolute;bottom:0;left:0;width:100%;height:113px;background:linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);"></div>

      </div>

      <!--第五屏-->
      <div id="productPageImageLibScreen5" style="display:flex;flex-direction:column;" class="screen">

        <div style="flex:1;">
          <div style="position:relative;top:60%;left:0;transform:translate(0,-50%);width:100%;display:flex;flex-direction:column;align-items:center;">
            <!--标题-->
            <p :style="{'font-size':webScreenSize === 'big' ? '60px' : '38px'}" style="line-height:80px;" class="productPageImageLibColorTitle">立即开启遥感影像探索之旅</p>
<!--            &lt;!&ndash;按钮&ndash;&gt;-->
<!--            <div @click="gotoImageLib" style="width:128px;height:38px;margin-top:96px;border-radius:20px;background:linear-gradient(175deg,rgba(255, 255, 255, 0.5) 0%,rgba(255, 255, 255, 0) 100%);padding:1px;cursor:pointer;">-->
<!--              <div style="width:128px;height:38px;border-radius:20px;background:rgba(42, 130, 228, 1);text-align:center;">-->
<!--                <p style="line-height:38px;font-size:14px;">-->
<!--                  立即体验-->
<!--                  <span style="margin-left:9px;" class="iconfontFamily">&#xe7a0;</span>-->
<!--                </p>-->
<!--              </div>-->
<!--            </div>-->

          </div>
        </div>

        <!--底栏-->
        <div style="width:100%;height:364px;">
          <app-page-foot-bar :produceList="footBarProductList"></app-page-foot-bar>

        </div>

        <!--顶部渐变-->
        <div style="position:absolute;top:0;left:0;width:100%;height:113px;background:linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);"></div>


      </div>



      <!--图像详情蒙层-->
      <div v-if="imageDetailShow" style="position:fixed;top:0;left:0;width:100vw;height:100vh;background:#000000;padding-top:80px;z-index:10;display:flex;">

        <div style="flex:1;height:100%">

          <img :src="currImageDetail.picture" style="width:100%;height:100%;object-fit:contain;">

        </div>

        <div style="position:relative;width:340px;height:100%;background:rgba(32, 31, 28, 1);">
          <!--关闭按钮-->
          <div @click="imageDetailShow = false;" style="position:absolute;top:12px;right:30px;width:20px;height:20px;border-radius:50%;border:1px solid rgba(128, 128, 128, 1);text-align:center;cursor:pointer;">
            <p style="line-height:20px;font-size:12px;color:rgba(128, 128, 128, 1);" class="iconfontFamily">&#xe72c;</p>
          </div>

          <div style="position:relative;top:50%;left:0;transform:translate(0,-50%);width:calc(100% - 66px);padding:0 40px 0 26px;">
            <p style="font-size:16px;color:rgba(151, 151, 151, 1);">影像名称</p>
            <p style="margin-top:13px;font-size:16px;">{{currImageDetail.title}}</p>
            <p style="margin-top:15px;font-size:16px;color:rgba(151, 151, 151, 1);">影像描述</p>
            <p style="margin-top:13px;font-size:16px;">{{currImageDetail.introduce}}</p>
<!--            <div @click="gotoImageLibProject" style="width:108px;height:42px;margin-top:94px;border:2px solid rgba(56, 56, 56, 1);box-shadow: 0px 2px 8px rgba(56, 56, 56, 0.1);text-align:center;cursor:pointer;">-->
<!--              <p style="line-height:42px;font-size:14px;">下载原图</p>-->
<!--            </div>-->
          </div>

        </div>

      </div>


    </div>


    <div v-if="device==='mobile'" style="padding-top:80px;" class="productPageImageLib">
      <!--顶栏-->
      <div style="position:fixed;top:0;left:0;width:calc(100% - 30px);height:80px;background:rgba(21, 21, 40, 0.45);padding:0 15px;display:flex;align-items:center;z-index:99;">
        <img @click="reloadPage" style="width:146px;" src="../../assets/App/productPage/imageLib/logo.png" />
        <div style="flex:1;"></div>

        <p @click="gotoApp" style="font-size:14px;">首页</p>
<!--        <p @click="gotoImageLib" style="margin-left:14px;font-size:14px;">在线体验</p>-->
        <p @click="gotoAboutUsDetailPage" style="margin-left:14px;margin-right:5px;font-size:14px;">关于我们</p>

      </div>

      <!--第一屏-->
      <div id="productPageImageLibScreen1_mobile">
        <div style="position:relative;">
          <div style="width:calc(100% - 40px);margin:0 auto;padding-top:30px;">
            <p :style="{'font-size':mobileScreenSize === 'small' ? '28px' : '46px'}" class="productPageImageLibColorTitle_mobile">吉林一号·遥感影像库</p>
            <p style="margin-top:20px;" class="productPageImageLibPs_mobile">为创作者而生的地图服务平台</p>
            <p class="productPageImageLibPs_mobile">丰富工具帮助创作者创作出更有价值的信息</p>

<!--            <div @click="gotoImageLib" style="width:116px;height:38px;margin-top:34px;border-radius:20px;background:linear-gradient(175deg,rgba(255, 255, 255, 0.5) 0%,rgba(255, 255, 255, 0) 100%);padding:1px;">-->
<!--              <div style="width:115px;height:37px;border-radius:20px;background:rgba(42, 130, 228, 1);text-align:center;">-->
<!--                <p style="line-height:36px;font-size:14px;">-->
<!--                  立即体验-->
<!--                  <span style="margin-left:9px;" class="iconfontFamily">&#xe7a0;</span>-->
<!--                </p>-->
<!--              </div>-->
<!--            </div>-->

            <p style="margin-top:34px;line-height:35px;" class="productPageImageLibPs_mobile">敬请期待</p>

          </div>

          <div :style="{'max-width':mobileScreenSize === 'small' ? '500px' : '600px'}" style="position:relative;width:calc(100% - 56px);margin:40px auto 0 auto;">
            <img style="width:100%;border-radius:10px;"
                 src="../../assets/App/productPage/imageLib/screen1/mainPicture.jpg" />
            <!--小图放大-->
            <img id="screen1BigImg1_mobile" style="position:absolute;bottom:45%;left:69.5%;width:0;animation-duration:1.5s;animation-fill-mode: forwards;"
                 src="../../assets/App/productPage/imageLib/screen1/bigImg1.png" />
            <img id="screen1BigImg2_mobile" style="position:absolute;top:65%;left:55%;width:0;animation-duration:1.5s;animation-fill-mode: forwards;"
                 src="../../assets/App/productPage/imageLib/screen1/bigImg2.png" />
          </div>

        </div>
      </div>

      <!--第二屏-->
      <div id="productPageImageLibScreen2_mobile">
        <div style="width:calc(100% - 40px);margin:0 auto;padding-top:190px;text-align:center;">
          <p id="productPageImageLibScreen2_title" class="productPageImageLibColorTitle_mobile floatAnimationDom">智能检索 直达影像</p>
          <p id="productPageImageLibScreen2_ps" style="margin-top:20px;" class="productPageImageLibPs_mobile floatAnimationDom">海量数据 智能管理</p>
          <img id="productPageImageLibScreen2_img" class="floatAnimationDom"
               :style="{'max-width':mobileScreenSize === 'small' ? '550px' : '600px'}"
               style="width:100%;max-width:550px;margin-top:30px;" src="../../assets/App/productPage/imageLib/screen2/mainPicture.png" />
        </div>
      </div>

      <!--第三屏-->
      <div id="productPageImageLibScreen3_mobile">
        <div style="width:calc(100% - 40px);margin:0 auto;padding-top:160px;text-align:center;">
          <p id="productPageImageLibScreen3_title" class="productPageImageLibColorTitle_mobile floatAnimationDom">官方授权 版权无忧</p>
          <p id="productPageImageLibScreen3_ps" style="margin-top:20px;" class="productPageImageLibPs_mobile floatAnimationDom">一键购买 直达创作</p>
          <img id="productPageImageLibScreen3_img" class="floatAnimationDom"
               :style="{'max-width':mobileScreenSize === 'small' ? '550px' : '600px'}"
               style="width:100%;max-width:550px;margin-top:30px;border-radius:12px;z-index:1;" src="../../assets/App/productPage/imageLib/screen3/mainPicture.jpg" />
        </div>
      </div>

      <!--第四屏-->
      <div id="productPageImageLibScreen4_mobile">
        <div style="width:100%;padding-top:160px;text-align:center;">
          <p class="productPageImageLibColorTitle_mobile">海量影像 超清查看</p>
          <p style="margin-top:20px;" class="productPageImageLibPs_mobile">还原地球 极致瞰世界</p>

          <!--横向图片区域-->
          <div style="width:100%;margin-top:30px;">

            <div style="position:relative;width:100%;display:flex;animation:screen4ImageScrollFront 20s linear infinite;">
              <div v-for="(item,index) in screenImgList1_mobile" :key="'screenImgList1'+index"
                   style="width:25%;flex-shrink:0;cursor:pointer;">
                <img @click="gotoScreen4ImageItem_mobile(item)" @mouseover="screen4ImageScrollPause = true;" @mouseout="screen4ImageScrollPause = false;"
                     :src="item.thumb" style="width:calc(100% - 6px);aspect-ratio:1.7/1;border-radius:8px;object-fit:cover;"/>
              </div>
            </div>
            <div style="position:relative;width:100%;margin-top:4px;display:flex;animation:screen4ImageScrollBehind 20s linear infinite;">
              <div v-for="(item,index) in screenImgList2_mobile" :key="'screenImgList2'+index"
                   style="width:25%;flex-shrink:0;cursor:pointer;">
                <img @click="gotoScreen4ImageItem_mobile(item)" @mouseover="screen4ImageScrollPause = true;" @mouseout="screen4ImageScrollPause = false;"
                     :src="item.thumb" style="width:calc(100% - 6px);aspect-ratio:1.7/1;border-radius:8px;object-fit:cover;" />
              </div>
            </div>
            <div style="position:relative;width:100%;margin-top:4px;display:flex;animation:screen4ImageScrollFront 20s linear infinite;">
              <div v-for="(item,index) in screenImgList3_mobile" :key="'screenImgList3'+index"
                   style=";width:25%;flex-shrink:0;cursor:pointer;">
                <img @click="gotoScreen4ImageItem_mobile(item)" @mouseover="screen4ImageScrollPause = true;" @mouseout="screen4ImageScrollPause = false;"
                     :src="item.thumb" style="width:calc(100% - 6px);aspect-ratio:1.7/1;border-radius:8px;object-fit:cover;" />
              </div>
            </div>

          </div>


        </div>

      </div>


      <!--第五屏-->
      <div id="productPageImageLibScreen5_mobile">

        <div style="width:100%;margin:0 auto;padding-top:125px;padding-bottom:200px;text-align:center;">
          <p :style="{'font-size':mobileScreenSize === 'small' ? '20px' : '36px'}" class="productPageImageLibColorTitle_mobile">开启吉林一号·遥感影像库探索之旅</p>

<!--          <div @click="gotoImageLib" style="width:116px;height:38px;margin:43px auto 0 auto;border-radius:20px;background:linear-gradient(175deg,rgba(255, 255, 255, 0.5) 0%,rgba(255, 255, 255, 0) 100%);padding:1px;">-->
<!--            <div style="width:115px;height:37px;border-radius:20px;background:rgba(42, 130, 228, 1);text-align:center;">-->
<!--              <p style="line-height:36px;font-size:14px;">-->
<!--                立即体验-->
<!--                <span style="margin-left:9px;" class="iconfontFamily">&#xe7a0;</span>-->
<!--              </p>-->
<!--            </div>-->
<!--          </div>-->
        </div>


        <!--顶部渐变-->
        <div style="position:absolute;top:0;left:0;width:100%;height:63px;background:linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);"></div>

        <!--底部渐变-->
        <div style="position:absolute;bottom:0;left:0;width:100%;height:120px;background:linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);"></div>

      </div>

      <!--底栏-->
      <div style="position:relative;width:100%;margin-top:-100px;">
        <div style="position:absolute;bottom:0;left:0;width:100%;height:calc(100% - 100px);background:#000000;"></div>
        <app-page-foot-bar-mobile :produceList="footBarProductList" style="position:relative;"></app-page-foot-bar-mobile>
      </div>


      <!--图像详情蒙层-->
      <div v-if="imageDetailShow_mobile" style="position:fixed;top:0;left:0;width:100vw;height:calc(100vh - 80px);background:#000000;padding-top:80px;z-index:10;">
        <!--关闭按钮-->
        <div @click="imageDetailShow_mobile = false;" style="position:absolute;top:92px;right:18px;width:20px;height:20px;border-radius:50%;border:1px solid rgba(255, 255, 255, 1);text-align:center;cursor:pointer;">
          <p style="line-height:20px;font-size:12px;color:rgba(255, 255, 255, 1);" class="iconfontFamily">&#xe72c;</p>
        </div>

        <div style="width:100%;height:calc(100% - 330px);">
          <img :src="currImageDetail.picture" style="width:100%;height:100%;object-fit:contain;">
        </div>

        <div style="position:relative;width:calc(100% - 40px);height:292px;background:rgba(32, 31, 28, 1);padding:19px 20px;display:flex;flex-direction:column;">
          <p style="font-size:16px;color:rgba(151, 151, 151, 1);">影像名称</p>
          <p style="margin-top:13px;font-size:16px;">{{currImageDetail.title}}</p>
          <p style="margin-top:15px;font-size:16px;color:rgba(151, 151, 151, 1);">影像描述</p>
          <p style="flex:1;margin-top:13px;font-size:16px;overflow:auto;">{{currImageDetail.introduce}}</p>
<!--          <div @click="gotoImageLibProject" style="width:120px;margin-top:18px;border:2px solid rgba(56, 56, 56, 1);box-shadow: 0px 2px 8px rgba(56, 56, 56, 0.1);border-radius:4px;text-align:center;">-->
<!--            <p style="line-height:42px;font-size:14px;">下载原图</p>-->
<!--          </div>-->

        </div>

      </div>



    </div>


  </div>


</template>

<script>

import AppPageFootBar from "@/views/AppPage/AppPageFootBar"
import AppPageFootBarMobile from "@/views/AppPage/AppPageFootBar_mobile"

export default {
  name: "ProductPageImageLib",
  components: {
    AppPageFootBar,AppPageFootBarMobile
  },
  data(){
    return{

      device:"web",
      webScreenSize:"big",
      mobileScreenSize:"small",

      //第四屏图片数据(8项（100能有限除） 复制两遍)
      screenImgList1:[
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com:443/202311221646000136322.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com:443/202311221644000216647.jpg",
          title:"新疆昭苏油菜花海",
          introduce:"新疆维吾尔自治区昭苏油菜花盛开的景象，通过遥感影像的形式展示了万顷田地满地金黄的极致景色。",
          imageLibMainId:"14d87b17ee5c4742a3467f9a4f86c977"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221656000038326.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221653000596840.jpg",
          title:"查干湖四季变装",
          introduce:"吉林省查干湖不同季节的自然景观，通过卷帘对比的形式展示了查干湖清澈见底,一望无际的绝美景色。",
          imageLibMainId:"f84c939476ab4b7fb4f968b19cb4c7fd"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221659000067932.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221658000355637.jpg",
          title:"天津长芦“变色龙”盐场",
          introduce:"天津塘沽长芦盐场一年不同时期颜色变化情况，通过遥感影像的形式展示了色彩斑斓、绚丽多彩的盐场独特风景。",
          imageLibMainId:"b59e1e3e976d4e2bb3586aa540e81567"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221708000577156.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221708000504757.png",
          title:"北京大兴国际机场",
          introduce:"北京大兴国际机场为4F级国际机场、世界级航空枢纽、国家发展新动力源。",
          imageLibMainId:"6994bccfea83408197e264b6e8ac5ddc"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221715000105884.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221713000596738.jpg",
          title:"青海戈壁滩光伏“蓝海”",
          introduce:"青海省各地光伏产业项目，通过遥感影像的形式展示了大面积光伏太阳能板在青海戈壁上的延伸发散。",
          imageLibMainId:"9700be27f0434c3c92c92ff64b3a7a61"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221721000103801.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221721000071006.jpg",
          title:"内蒙古万里无垠大草原",
          introduce:"内蒙古各个地区大草原的风貌，展现了各个地区的独特性与相互的差异性。",
          imageLibMainId:"9a2838a3dd7e4d8eb9f4f214a3394cd4"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221729000272680.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221729000135725.jpg",
          title:"喀纳斯湖",
          introduce:"新疆喀纳斯湖不同季节的景观特点，通过卷帘对比的方式展示了湖面结冰与融化后的不同景色。",
          imageLibMainId:"05799cf3e3b54b59b8b335c358a21f24"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221733000105570.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221733000045454.png",
          title:"三江源：中国水塔",
          introduce:"青海省三江源国家自然保护区的自然环境，通过遥感影像的形式展示了三江源水草丰美、湖泊星罗棋布的极致美景。",
          imageLibMainId:"5ff430d0cfa34906a7b2a88d9631a4d0"
        },

          //复制
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com:443/202311221646000136322.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com:443/202311221644000216647.jpg",
          title:"新疆昭苏油菜花海",
          introduce:"新疆维吾尔自治区昭苏油菜花盛开的景象，通过遥感影像的形式展示了万顷田地满地金黄的极致景色。",
          imageLibMainId:"14d87b17ee5c4742a3467f9a4f86c977"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221656000038326.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221653000596840.jpg",
          title:"查干湖四季变装",
          introduce:"吉林省查干湖不同季节的自然景观，通过卷帘对比的形式展示了查干湖清澈见底,一望无际的绝美景色。",
          imageLibMainId:"f84c939476ab4b7fb4f968b19cb4c7fd"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221659000067932.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221658000355637.jpg",
          title:"天津长芦“变色龙”盐场",
          introduce:"天津塘沽长芦盐场一年不同时期颜色变化情况，通过遥感影像的形式展示了色彩斑斓、绚丽多彩的盐场独特风景。",
          imageLibMainId:"b59e1e3e976d4e2bb3586aa540e81567"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221708000577156.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221708000504757.png",
          title:"北京大兴国际机场",
          introduce:"北京大兴国际机场为4F级国际机场、世界级航空枢纽、国家发展新动力源。",
          imageLibMainId:"6994bccfea83408197e264b6e8ac5ddc"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221715000105884.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221713000596738.jpg",
          title:"青海戈壁滩光伏“蓝海”",
          introduce:"青海省各地光伏产业项目，通过遥感影像的形式展示了大面积光伏太阳能板在青海戈壁上的延伸发散。",
          imageLibMainId:"9700be27f0434c3c92c92ff64b3a7a61"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221721000103801.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221721000071006.jpg",
          title:"内蒙古万里无垠大草原",
          introduce:"内蒙古各个地区大草原的风貌，展现了各个地区的独特性与相互的差异性。",
          imageLibMainId:"9a2838a3dd7e4d8eb9f4f214a3394cd4"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221729000272680.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221729000135725.jpg",
          title:"喀纳斯湖",
          introduce:"新疆喀纳斯湖不同季节的景观特点，通过卷帘对比的方式展示了湖面结冰与融化后的不同景色。",
          imageLibMainId:"05799cf3e3b54b59b8b335c358a21f24"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221733000105570.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221733000045454.png",
          title:"三江源：中国水塔",
          introduce:"青海省三江源国家自然保护区的自然环境，通过遥感影像的形式展示了三江源水草丰美、湖泊星罗棋布的极致美景。",
          imageLibMainId:"5ff430d0cfa34906a7b2a88d9631a4d0"
        },
      ],
      screenImgList2:[
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231052000284041.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231052000062920.jpg",
          title:"珠穆朗玛峰：山界之王",
          introduce:"西藏自治区喜马拉雅山脉珠穆朗玛峰的山脉结构，通过3D影像的形式展示了山界之王的恢宏雄伟。",
          imageLibMainId:"7f0e484f0c364e1ba0597954f5111b2a"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231109000538917.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231109000387960.jpg",
          title:"黄河入海口",
          introduce:"黄河入海口，位于山东省东营市垦利区黄河口镇境内，地处渤海与莱州湾的交汇处。",
          imageLibMainId:"4873a2e071dc4d65951a9be940b35f8e"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231143000095792.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231142000517311.jpg",
          title:'美国"棋盘"农田',
          introduce:"美国农业是美国的主要产业之一，同时美国也是一个粮食净出口国和全球最大粮食出口国。美国农业高度机械化，平均每平方公里的农地，只需要一人农民或农业人员参与农业生产。",
          imageLibMainId:"f5908db419d64298be615c1f0d7ac6bc"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231144000494596.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231144000348956.jpg",
          title:"美国黄石公园",
          introduce:"美国黄石公园主要位于怀俄明州，部分位于蒙大拿州和爱达荷州，于1872年3月1日美国总统尤利西斯·辛普森·格兰特签署国会通过的法案后建立，是世界上第一座国家公园。",
          imageLibMainId:"17a4dac4cbbc49b1b8397864e549fea8"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231147000429446.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231147000244846.jpg",
          title:"日本农田",
          introduce:"影像描述了日本新潟、北海道两地的自然农田形状。",
          imageLibMainId:"39d74661834644989ecb142d345d244a"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/20231123115000036871.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231150000211436.jpg",
          title:"日本樱岛火山爆发",
          introduce:"樱岛是一座复式火山，其中北岳是樱岛的最高峰，海拔高达1,117米，至今岛上的火山活动仍在继续，由于其火山口距离有60万人居住的鹿儿岛市市区仅8千米距离，被国际火山学与地球内部化学协会列为“十年火山”。",
          imageLibMainId:"e700e0b871614ecbac346873342c2e20"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231338000251333.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231338000115340.jpg",
          title:"福岛第一核电站",
          introduce:"福岛县第一核电站是世界最大的核电站福岛核电站的第一站，2011年3月12日，受东日本大地震影响，福岛县第一次发生核泄漏。第一核电站的1至6号机组将全部永久废弃。",
          imageLibMainId:"d709fde9e1aa4696bd59b6768ceae82b"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231340000427451.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231340000409304.jpg",
          title:"海参崴",
          introduce:"海参崴，清朝时为中国领土隶属于吉林将军，俄罗斯帝国将其命名为符拉迪沃斯托克，俄语意为“统治东方”。",
          imageLibMainId:"e98373e7e4304ba5bd598d86dd275e17"
        },


        //复制
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231052000284041.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231052000062920.jpg",
          title:"珠穆朗玛峰：山界之王",
          introduce:"西藏自治区喜马拉雅山脉珠穆朗玛峰的山脉结构，通过3D影像的形式展示了山界之王的恢宏雄伟。",
          imageLibMainId:"7f0e484f0c364e1ba0597954f5111b2a"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231109000538917.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231109000387960.jpg",
          title:"黄河入海口",
          introduce:"黄河入海口，位于山东省东营市垦利区黄河口镇境内，地处渤海与莱州湾的交汇处。",
          imageLibMainId:"4873a2e071dc4d65951a9be940b35f8e"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231143000095792.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231142000517311.jpg",
          title:'美国"棋盘"农田',
          introduce:"美国农业是美国的主要产业之一，同时美国也是一个粮食净出口国和全球最大粮食出口国。美国农业高度机械化，平均每平方公里的农地，只需要一人农民或农业人员参与农业生产。",
          imageLibMainId:"f5908db419d64298be615c1f0d7ac6bc"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231144000494596.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231144000348956.jpg",
          title:"美国黄石公园",
          introduce:"美国黄石公园主要位于怀俄明州，部分位于蒙大拿州和爱达荷州，于1872年3月1日美国总统尤利西斯·辛普森·格兰特签署国会通过的法案后建立，是世界上第一座国家公园。",
          imageLibMainId:"17a4dac4cbbc49b1b8397864e549fea8"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231147000429446.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231147000244846.jpg",
          title:"日本农田",
          introduce:"影像描述了日本新潟、北海道两地的自然农田形状。",
          imageLibMainId:"39d74661834644989ecb142d345d244a"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/20231123115000036871.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231150000211436.jpg",
          title:"日本樱岛火山爆发",
          introduce:"樱岛是一座复式火山，其中北岳是樱岛的最高峰，海拔高达1,117米，至今岛上的火山活动仍在继续，由于其火山口距离有60万人居住的鹿儿岛市市区仅8千米距离，被国际火山学与地球内部化学协会列为“十年火山”。",
          imageLibMainId:"e700e0b871614ecbac346873342c2e20"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231338000251333.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231338000115340.jpg",
          title:"福岛第一核电站",
          introduce:"福岛县第一核电站是世界最大的核电站福岛核电站的第一站，2011年3月12日，受东日本大地震影响，福岛县第一次发生核泄漏。第一核电站的1至6号机组将全部永久废弃。",
          imageLibMainId:"d709fde9e1aa4696bd59b6768ceae82b"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231340000427451.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231340000409304.jpg",
          title:"海参崴",
          introduce:"海参崴，清朝时为中国领土隶属于吉林将军，俄罗斯帝国将其命名为符拉迪沃斯托克，俄语意为“统治东方”。",
          imageLibMainId:"e98373e7e4304ba5bd598d86dd275e17"
        },
      ],
      screenImgList3:[
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231342000367943.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231342000213760.jpg",
          title:"朝鲜平壤",
          introduce:"平壤位于朝鲜半岛西北部，大同江横跨其中，因其地势平坦而得名。大同江与其支流流经市中心。是朝鲜半岛历史最悠久的城市，相传早在檀君时代就被定为都城。",
          imageLibMainId:"82671b439fd043a998f28f3f557f172e"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231345000491501.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231345000381604.jpg",
          title:"马尔代夫群岛",
          introduce:"马尔代夫是印度洋上的群岛国家。距离印度南部约600公里，距离斯里兰卡西南部约750公里。",
          imageLibMainId:"55cf199b3faf4e09a2c372654d0dba06"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/20231123134800019941.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231348000064312.jpg",
          title:"沙特线性城市",
          introduce:"The Line 线性城市西部施工进展，在沙特王子的计划中，THE LINE这座170公里长，200米宽，高度超过500米的线性城市未来能够供900万人生活工作。",
          imageLibMainId:"2798662b8aca4d4fbee788f4d1792ba1"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231350000454504.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231350000392069.jpg",
          title:"欧洲干旱危机",
          introduce:"欧洲西班牙水库的水位情况，通过遥感影像分时段记录的形式展示了水位的变化。",
          imageLibMainId:"f125e0ea24634d47870f59df693634e8"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231353000057541.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231352000575271.jpg",
          title:"澳大利亚福赛斯群岛",
          introduce:"澳大利亚占有大洋洲绝大部分陆地，总面积769.2万平方千米。沙漠和半沙漠面积达340万平方千米，约占总面积的44%。",
          imageLibMainId:"2604aa67212b4318ab0bcd6496bb7aaa"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231355000381590.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231355000197171.jpg",
          title:"埃及金字塔",
          introduce:"胡夫金字塔，是古埃及金字塔中最大的金字塔。塔高146.59米，因年久风化，顶端剥落10米，现高136.5米，相当于40层大厦高。",
          imageLibMainId:"f92d18e6f42a4ed9b59f613194604758"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231358000335915.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231358000188713.jpg",
          title:"利比亚特大洪灾",
          introduce:"地中海飓风“丹尼尔”9月10日袭击利比亚东部地中海沿岸，引发强降雨、洪水和泥石流。利比亚东北部港口城市德尔纳（Derna）受灾尤为严重，该市发生了大坝垮塌。毫不夸张地说，这个城市的25%已经消失。",
          imageLibMainId:"6ce5a85c4db544399c0ef436dacd4ef0"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231403000209507.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231403000019066.jpg",
          title:"摩洛哥地震",
          introduce:"当地时间2023年9月8日21时，在摩洛哥发生6.9级地震，震源深度10公里；摩洛哥国王穆罕默德六世主持会议部署震后救援工作。会议同时决定摩洛哥全国哀悼三天，所有公共建筑均下半旗志哀。",
          imageLibMainId:"0ee29370a2924747b3dc43e48e7d20da"
        },

        //复制
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231342000367943.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231342000213760.jpg",
          title:"朝鲜平壤",
          introduce:"平壤位于朝鲜半岛西北部，大同江横跨其中，因其地势平坦而得名。大同江与其支流流经市中心。是朝鲜半岛历史最悠久的城市，相传早在檀君时代就被定为都城。",
          imageLibMainId:"82671b439fd043a998f28f3f557f172e"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231345000491501.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231345000381604.jpg",
          title:"马尔代夫群岛",
          introduce:"马尔代夫是印度洋上的群岛国家。距离印度南部约600公里，距离斯里兰卡西南部约750公里。",
          imageLibMainId:"55cf199b3faf4e09a2c372654d0dba06"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/20231123134800019941.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231348000064312.jpg",
          title:"沙特线性城市",
          introduce:"The Line 线性城市西部施工进展，在沙特王子的计划中，THE LINE这座170公里长，200米宽，高度超过500米的线性城市未来能够供900万人生活工作。",
          imageLibMainId:"2798662b8aca4d4fbee788f4d1792ba1"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231350000454504.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231350000392069.jpg",
          title:"欧洲干旱危机",
          introduce:"欧洲西班牙水库的水位情况，通过遥感影像分时段记录的形式展示了水位的变化。",
          imageLibMainId:"f125e0ea24634d47870f59df693634e8"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231353000057541.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231352000575271.jpg",
          title:"澳大利亚福赛斯群岛",
          introduce:"澳大利亚占有大洋洲绝大部分陆地，总面积769.2万平方千米。沙漠和半沙漠面积达340万平方千米，约占总面积的44%。",
          imageLibMainId:"2604aa67212b4318ab0bcd6496bb7aaa"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231355000381590.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231355000197171.jpg",
          title:"埃及金字塔",
          introduce:"胡夫金字塔，是古埃及金字塔中最大的金字塔。塔高146.59米，因年久风化，顶端剥落10米，现高136.5米，相当于40层大厦高。",
          imageLibMainId:"f92d18e6f42a4ed9b59f613194604758"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231358000335915.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231358000188713.jpg",
          title:"利比亚特大洪灾",
          introduce:"地中海飓风“丹尼尔”9月10日袭击利比亚东部地中海沿岸，引发强降雨、洪水和泥石流。利比亚东北部港口城市德尔纳（Derna）受灾尤为严重，该市发生了大坝垮塌。毫不夸张地说，这个城市的25%已经消失。",
          imageLibMainId:"6ce5a85c4db544399c0ef436dacd4ef0"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231403000209507.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231403000019066.jpg",
          title:"摩洛哥地震",
          introduce:"当地时间2023年9月8日21时，在摩洛哥发生6.9级地震，震源深度10公里；摩洛哥国王穆罕默德六世主持会议部署震后救援工作。会议同时决定摩洛哥全国哀悼三天，所有公共建筑均下半旗志哀。",
          imageLibMainId:"0ee29370a2924747b3dc43e48e7d20da"
        },
      ],
      screen4ScrollTemp:"",
      screen4ImageScrollPause:false,



      //图像详情蒙层
      imageDetailShow:false,
      currImageDetail:"",


      //底栏项目列表
      footBarProductList:[
        {
          title:"吉林一号·共生地球APP",
          href:"/ProductPageApp"
        },
        {
          title:"吉林一号·共生地球web端",
          href:"/ProductPageWebMarker"
        },

      ],




      /**移动端*********************************************************************************************/

      screenImgList1_mobile:[
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com:443/202311221646000136322.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com:443/202311221644000216647.jpg",
          title:"新疆昭苏油菜花海",
          introduce:"新疆维吾尔自治区昭苏油菜花盛开的景象，通过遥感影像的形式展示了万顷田地满地金黄的极致景色。",
          imageLibMainId:"14d87b17ee5c4742a3467f9a4f86c977"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221656000038326.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221653000596840.jpg",
          title:"查干湖四季变装",
          introduce:"吉林省查干湖不同季节的自然景观，通过卷帘对比的形式展示了查干湖清澈见底,一望无际的绝美景色。",
          imageLibMainId:"f84c939476ab4b7fb4f968b19cb4c7fd"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221659000067932.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221658000355637.jpg",
          title:"天津长芦“变色龙”盐场",
          introduce:"天津塘沽长芦盐场一年不同时期颜色变化情况，通过遥感影像的形式展示了色彩斑斓、绚丽多彩的盐场独特风景。",
          imageLibMainId:"b59e1e3e976d4e2bb3586aa540e81567"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221708000577156.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221708000504757.png",
          title:"北京大兴国际机场",
          introduce:"北京大兴国际机场为4F级国际机场、世界级航空枢纽、国家发展新动力源。",
          imageLibMainId:"6994bccfea83408197e264b6e8ac5ddc"
        },

        //复制
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com:443/202311221646000136322.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com:443/202311221644000216647.jpg",
          title:"新疆昭苏油菜花海",
          introduce:"新疆维吾尔自治区昭苏油菜花盛开的景象，通过遥感影像的形式展示了万顷田地满地金黄的极致景色。",
          imageLibMainId:"14d87b17ee5c4742a3467f9a4f86c977"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221656000038326.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221653000596840.jpg",
          title:"查干湖四季变装",
          introduce:"吉林省查干湖不同季节的自然景观，通过卷帘对比的形式展示了查干湖清澈见底,一望无际的绝美景色。",
          imageLibMainId:"f84c939476ab4b7fb4f968b19cb4c7fd"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221659000067932.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221658000355637.jpg",
          title:"天津长芦“变色龙”盐场",
          introduce:"天津塘沽长芦盐场一年不同时期颜色变化情况，通过遥感影像的形式展示了色彩斑斓、绚丽多彩的盐场独特风景。",
          imageLibMainId:"b59e1e3e976d4e2bb3586aa540e81567"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221708000577156.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221708000504757.png",
          title:"北京大兴国际机场",
          introduce:"北京大兴国际机场为4F级国际机场、世界级航空枢纽、国家发展新动力源。",
          imageLibMainId:"6994bccfea83408197e264b6e8ac5ddc"
        },

      ],
      screenImgList2_mobile:[
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231052000284041.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231052000062920.jpg",
          title:"珠穆朗玛峰：山界之王",
          introduce:"西藏自治区喜马拉雅山脉珠穆朗玛峰的山脉结构，通过3D影像的形式展示了山界之王的恢宏雄伟。",
          imageLibMainId:"7f0e484f0c364e1ba0597954f5111b2a"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231109000538917.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231109000387960.jpg",
          title:"黄河入海口",
          introduce:"黄河入海口，位于山东省东营市垦利区黄河口镇境内，地处渤海与莱州湾的交汇处。",
          imageLibMainId:"4873a2e071dc4d65951a9be940b35f8e"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231143000095792.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231142000517311.jpg",
          title:'美国"棋盘"农田',
          introduce:"美国农业是美国的主要产业之一，同时美国也是一个粮食净出口国和全球最大粮食出口国。美国农业高度机械化，平均每平方公里的农地，只需要一人农民或农业人员参与农业生产。",
          imageLibMainId:"f5908db419d64298be615c1f0d7ac6bc"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231144000494596.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231144000348956.jpg",
          title:"美国黄石公园",
          introduce:"美国黄石公园主要位于怀俄明州，部分位于蒙大拿州和爱达荷州，于1872年3月1日美国总统尤利西斯·辛普森·格兰特签署国会通过的法案后建立，是世界上第一座国家公园。",
          imageLibMainId:"17a4dac4cbbc49b1b8397864e549fea8"
        },

        //复制
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231052000284041.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231052000062920.jpg",
          title:"珠穆朗玛峰：山界之王",
          introduce:"西藏自治区喜马拉雅山脉珠穆朗玛峰的山脉结构，通过3D影像的形式展示了山界之王的恢宏雄伟。",
          imageLibMainId:"7f0e484f0c364e1ba0597954f5111b2a"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231109000538917.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231109000387960.jpg",
          title:"黄河入海口",
          introduce:"黄河入海口，位于山东省东营市垦利区黄河口镇境内，地处渤海与莱州湾的交汇处。",
          imageLibMainId:"4873a2e071dc4d65951a9be940b35f8e"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231143000095792.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231142000517311.jpg",
          title:'美国"棋盘"农田',
          introduce:"美国农业是美国的主要产业之一，同时美国也是一个粮食净出口国和全球最大粮食出口国。美国农业高度机械化，平均每平方公里的农地，只需要一人农民或农业人员参与农业生产。",
          imageLibMainId:"f5908db419d64298be615c1f0d7ac6bc"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231144000494596.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231144000348956.jpg",
          title:"美国黄石公园",
          introduce:"美国黄石公园主要位于怀俄明州，部分位于蒙大拿州和爱达荷州，于1872年3月1日美国总统尤利西斯·辛普森·格兰特签署国会通过的法案后建立，是世界上第一座国家公园。",
          imageLibMainId:"17a4dac4cbbc49b1b8397864e549fea8"
        },

      ],
      screenImgList3_mobile:[
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231342000367943.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231342000213760.jpg",
          title:"朝鲜平壤",
          introduce:"平壤位于朝鲜半岛西北部，大同江横跨其中，因其地势平坦而得名。大同江与其支流流经市中心。是朝鲜半岛历史最悠久的城市，相传早在檀君时代就被定为都城。",
          imageLibMainId:"82671b439fd043a998f28f3f557f172e"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231345000491501.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231345000381604.jpg",
          title:"马尔代夫群岛",
          introduce:"马尔代夫是印度洋上的群岛国家。距离印度南部约600公里，距离斯里兰卡西南部约750公里。",
          imageLibMainId:"55cf199b3faf4e09a2c372654d0dba06"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/20231123134800019941.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231348000064312.jpg",
          title:"沙特线性城市",
          introduce:"The Line 线性城市西部施工进展，在沙特王子的计划中，THE LINE这座170公里长，200米宽，高度超过500米的线性城市未来能够供900万人生活工作。",
          imageLibMainId:"2798662b8aca4d4fbee788f4d1792ba1"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231350000454504.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231350000392069.jpg",
          title:"欧洲干旱危机",
          introduce:"欧洲西班牙水库的水位情况，通过遥感影像分时段记录的形式展示了水位的变化。",
          imageLibMainId:"f125e0ea24634d47870f59df693634e8"
        },

        //复制
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231342000367943.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231342000213760.jpg",
          title:"朝鲜平壤",
          introduce:"平壤位于朝鲜半岛西北部，大同江横跨其中，因其地势平坦而得名。大同江与其支流流经市中心。是朝鲜半岛历史最悠久的城市，相传早在檀君时代就被定为都城。",
          imageLibMainId:"82671b439fd043a998f28f3f557f172e"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231345000491501.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231345000381604.jpg",
          title:"马尔代夫群岛",
          introduce:"马尔代夫是印度洋上的群岛国家。距离印度南部约600公里，距离斯里兰卡西南部约750公里。",
          imageLibMainId:"55cf199b3faf4e09a2c372654d0dba06"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/20231123134800019941.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231348000064312.jpg",
          title:"沙特线性城市",
          introduce:"The Line 线性城市西部施工进展，在沙特王子的计划中，THE LINE这座170公里长，200米宽，高度超过500米的线性城市未来能够供900万人生活工作。",
          imageLibMainId:"2798662b8aca4d4fbee788f4d1792ba1"
        },
        {
          thumb:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231350000454504.png",
          picture:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311231350000392069.jpg",
          title:"欧洲干旱危机",
          introduce:"欧洲西班牙水库的水位情况，通过遥感影像分时段记录的形式展示了水位的变化。",
          imageLibMainId:"f125e0ea24634d47870f59df693634e8"
        },

      ],

      imageDetailShow_mobile:false,


    }

  },

  methods:{
    //顶bar 刷新页面
    reloadPage(){
      window.location.reload();
    },

    //跳转首页
    gotoApp(){
      this.$router.push(
          {
            path: '/',
          }
      );
    },
    //跳转影像库项目
    gotoImageLib(){
      // window.open("http://10.200.11.3:8081/author/", '_blank');

      // window.open("https://earth.jl1.cn/creator/", '_blank');
    },
    //关于我们 进入详情页
    gotoAboutUsDetailPage(){
      this.$router.push(
          {
            path: '/AboutUs',
          }
      );
    },



    //首屏动画
    screen1Animation(){
      document.getElementById("screen1BigImg1").style.animationName = "screen1BigImgAnimation";
      document.getElementById("screen1BigImg2").style.animationName = "screen1BigImgAnimation";
    },

    //第四屏 点击一项影像
    gotoScreen4ImageItem(item){
      this.currImageDetail = item;
      this.imageDetailShow = true;
    },
    //跳转影像库项目影像集详情页
    gotoImageLibProject(){
      // window.open("https://earth.jl1.cn/creator/productDetails?mainId="+this.currImageDetail.imageLibMainId, '_blank');
    },



    //标题增加渐变颜色效果(直接写在css里不生效)
    productPageImageLibColorTitle(){
      let titleList = document.querySelectorAll(".productPageImageLibColorTitle");
      for(let i = 0;i<titleList.length;i++) {
        titleList[i].style["-webkit-background-clip"] = 'text';
      }
    },


    //鼠标滚动位置相关的动效
    listenerScrollFun(){
      const scrollTop = window.scrollY || window.pageYOffset;

      //第二屏
      if(scrollTop >= 400){
        this.titleAndPsAndImgFloatAnimation("productPageImageLibScreen2_title","productPageImageLibScreen2_ps","productPageImageLibScreen2_img");
      }
      //第三屏
      if(scrollTop >= 1250){
        this.titleAndPsAndImgFloatAnimation("productPageImageLibScreen3_title","productPageImageLibScreen3_ps","productPageImageLibScreen3_img");
      }
      //第四屏
      if(scrollTop >= 2100){
        this.titleAndPsAndImgFloatAnimation("productPageImageLibScreen4_title","productPageImageLibScreen4_ps","productPageImageLibScreen4_img");
      }


    },




    //标题+详情+图片 三者上浮动画
    titleAndPsAndImgFloatAnimation(titleDomId,psDomId,imgDomId){
      document.getElementById(titleDomId).style.animationName = "floatAnimation";
      document.getElementById(titleDomId).style.animationDuration = "1.5s";

      document.getElementById(psDomId).style.animationName = "floatAnimation";
      document.getElementById(psDomId).style.animationDuration = "2s";

      document.getElementById(imgDomId).style.animationName = "floatAnimation";
      document.getElementById(imgDomId).style.animationDuration = "2s";
    },






    /**移动端*******************************************************************************************/

    //首屏动画
    screen1Animation_mobile(){
      document.getElementById("screen1BigImg1_mobile").style.animationName = "screen1BigImgAnimation";
      document.getElementById("screen1BigImg2_mobile").style.animationName = "screen1BigImgAnimation";
    },

    //第四屏 点击一项影像
    gotoScreen4ImageItem_mobile(item){
      this.currImageDetail = item;
      this.imageDetailShow_mobile = true;
    },


    //标题增加渐变颜色效果(直接写在css里不生效)
    productPageImageLibColorTitle_mobile(){
      let titleList = document.querySelectorAll(".productPageImageLibColorTitle_mobile");
      for(let i = 0;i<titleList.length;i++) {
        titleList[i].style["-webkit-background-clip"] = 'text';
      }
    },


    //鼠标滚动位置相关的动效
    listenerScrollFun_mobile(){
      let screen1Height = document.getElementById("productPageImageLibScreen1_mobile").offsetHeight;
      let screen2Height = document.getElementById("productPageImageLibScreen2_mobile").offsetHeight;

      const scrollTop = window.scrollY || window.pageYOffset;

      //第二屏
      if(scrollTop >= screen1Height * 0.5){
        this.titleAndPsAndImgFloatAnimation("productPageImageLibScreen2_title","productPageImageLibScreen2_ps","productPageImageLibScreen2_img");
      }
      //第三屏
      if(scrollTop >= screen1Height + screen2Height * 0.5){
        this.titleAndPsAndImgFloatAnimation("productPageImageLibScreen3_title","productPageImageLibScreen3_ps","productPageImageLibScreen3_img");
      }

    },


  },
  mounted(){
    setTimeout(()=>{
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    },200)


    if(window.innerWidth >= 1000) {
      //web端
      this.device = "web";
      //适配web尺寸
      if(window.innerWidth < 1330){
        this.webScreenSize = "small";
        this.$refs.productPageImageLib.style.setProperty('--web-title-font-size', "40px");
        this.$refs.productPageImageLib.style.setProperty('--web-ps-font-size', "20px");
        this.$refs.productPageImageLib.style.setProperty('--web-ps-line-height', "30px");
      }
      else{
        this.$refs.productPageImageLib.style.setProperty('--web-title-font-size', "48px");
        this.$refs.productPageImageLib.style.setProperty('--web-ps-font-size', "24px");
        this.$refs.productPageImageLib.style.setProperty('--web-ps-line-height', "46px");
      }

      setTimeout(()=>{
        window.addEventListener('scroll', this.listenerScrollFun);
        this.screen1Animation();
      },200)

      this.productPageImageLibColorTitle();

    }
    else{
      //手机
      this.device = "mobile";
      //适配手机尺寸
      if(window.innerWidth > 650){
        this.mobileScreenSize = "big";
        console.log(this.mobileScreenSize);
        this.$refs.productPageImageLib.style.setProperty('--mobile-title-font-size', "40px");
        this.$refs.productPageImageLib.style.setProperty('--mobile-ps-font-size', "20px");
        this.$refs.productPageImageLib.style.setProperty('--mobile-ps-line-height', "30px");
      }
      else{
        this.$refs.productPageImageLib.style.setProperty('--mobile-title-font-size', "24px");
        this.$refs.productPageImageLib.style.setProperty('--mobile-ps-font-size', "16px");
        this.$refs.productPageImageLib.style.setProperty('--mobile-ps-line-height', "24px");
      }

      //device默认是web，手机端需要延时
      setTimeout(()=>{
        this.productPageImageLibColorTitle_mobile();
      },0)


      setTimeout(()=>{
        this.screen1Animation_mobile();
      },200)

      setTimeout(()=>{
        window.addEventListener('scroll', this.listenerScrollFun_mobile);
      },100)

    }




  },
  destroyed() {
    clearInterval(this.screen4ScrollTemp);
    if(window.innerWidth >= 1000){
      window.removeEventListener('scroll', this.listenerScrollFun);
    }
    else{
      window.removeEventListener('scroll', this.listenerScrollFun_mobile);
    }
  }
}
</script>

<style scoped>


.productPageImageLib{
  background:#000000;
  color:#ffffff;
  overflow:hidden;
  font-family:HarmonyOSSansSCRegular;
}
.productPageImageLib p{
  margin:0;
}


.screen{
  width: 100%;
}
#productPageImageLibScreen1{
  background-image:url('../../assets/App/productPage/imageLib/screen1/background.jpg');
  background-size:100% 100%;
  height: 100vh;
}
#productPageImageLibScreen2{
  background-image:url('../../assets/App/productPage/imageLib/screen2/background.jpg');
  background-size:100% 100%;
  height: 800px;
}
#productPageImageLibScreen3{
  background-image:url('../../assets/App/productPage/imageLib/screen3/background.jpg');
  background-size:100% 100%;
  height: 900px;
}
#productPageImageLibScreen4{
  background-image:url('../../assets/App/productPage/imageLib/screen4/background.jpg');
  background-size:100% 100%;
  height: 800px;
  position: relative;
}
#productPageImageLibScreen5{
  background-image:url('../../assets/App/productPage/imageLib/screen5/background.jpg');
  background-size:100% 100%;
  height: 100vh;
  position:relative;
}




.productPageImageLibColorTitle{
  width:100%;
  line-height:48px;
  font-size:var(--web-title-font-size);
  font-weight:bold;
  text-align:center;
  color:#ffffff;
  -webkit-text-fill-color: transparent;
  background:linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(159, 160, 167, 1) 100%);
}
.productPageImageLibPs{
  font-size:var(--web-ps-font-size);
  line-height:var(--web-ps-line-height);
}


/*上浮动画的元素*/
.floatAnimationDom{
  position:relative;
  top:100px;
  opacity:0;
  animation-fill-mode: forwards;
}



/**移动*****************************************************************************************************/

#productPageImageLibScreen1_mobile{
  background-image:url('../../assets/App/productPage/imageLib/screen1/background.jpg');
  background-size:100% 100%;
}
#productPageImageLibScreen2_mobile{
  background-image:url('../../assets/App/productPage/imageLib/screen2/background.jpg');
  background-size:100% 100%;
}
#productPageImageLibScreen3_mobile{
  background-image:url('../../assets/App/productPage/imageLib/screen3/background.jpg');
  background-size:100% 100%;
}
#productPageImageLibScreen4_mobile{
  background-image:url('../../assets/App/productPage/imageLib/screen4/background.jpg');
  background-size:100% 100%;
  position: relative;
}
#productPageImageLibScreen5_mobile{
  background-image:url('../../assets/App/productPage/imageLib/screen5/background.jpg');
  background-size:420% 340%;
  background-position:center -200px;
  position:relative;
  width:100%;
}



.productPageImageLibColorTitle_mobile{
  font-size:var(--mobile-title-font-size);
  font-weight:bold;
  color:#ffffff;
  -webkit-text-fill-color: transparent;
  background:linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(159, 160, 167, 1) 100%);
}
.productPageImageLibPs_mobile{
  font-size:var(--mobile-ps-font-size);
  line-height:var(--mobile-ps-line-height);
}







</style>

<style>

/*上浮动画*/
@keyframes floatAnimation {
  from {top:100px;opacity:0;}
  to {top:0px;opacity:1;}
}

/*首屏图片放大动画*/
@keyframes screen1BigImgAnimation {
  from {width:0}
  to {width:38%}
}

/*横向图片滚动*/
@keyframes screen4ImageScrollFront {
  0% {
    transform:translateX(0);
  }
  100% {
    transform:translateX(-100%);
  }
}
@keyframes screen4ImageScrollBehind {
  0% {
    transform:translateX(-100%);
  }
  100% {
    transform:translateX(0);
  }
}


</style>