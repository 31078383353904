<template>
  <div class="appPageFootBar" style="width:100%;height:100%;background:rgba(0,0,0, 1);color:rgba(161, 161, 167, 1);font-size:14px;">
    <div :style="{'width':webScreenSize === 'big' ? '1000px' : '960px'}"
         style="height:calc(100% - 47px);margin:0 auto;padding-top:47px;display:flex;flex-direction:column;">

      <div style="width:100%;flex:1;display:flex;">
        <div>
          <p style="font-size:16px;color:#ffffff;">联系我们</p>
          <div style="margin-top:27px;display:flex;">
            <p style="width:113px;">官方QQ交流群</p>
            <p>712093290</p>
          </div>
          <div style="margin-top:27px;display:flex;">
            <p style="width:113px;">商务合作</p>
            <p>18813148445&nbsp;&nbsp;&nbsp;&nbsp;王经理</p>
          </div>
        </div>
        <div style="margin-left:69px;">
          <p style="font-size:16px;color:#ffffff;">更多产品服务</p>
          <p @click="gotoProductPage(item.href)" v-for="(item,index) in produceList" :key="'produceItem'+index"
             style="margin-top:27px;cursor:pointer;">{{item.title}}</p>
        </div>

        <div style="flex:1;"></div>

        <div style="margin-top:26px;text-align:center;">
          <img style="width:100px;height:100px;" src="../../assets/App/footBar/weixin.jpg" />
          <p style="margin-top:6px;">官方微信公众号</p>
        </div>
        <div style="margin-top:26px;margin-left:67px;text-align:center;">
          <img style="width:100px;height:100px;" src="../../assets/App/footBar/weibo.jpg" />
          <p style="margin-top:6px;">官方微博账号</p>
        </div>
      </div>

      <div style="width:100%;height:65px;">
        <p style="line-height:65px;text-align:center;">
          <span @click="gotoPsPage('/TermsOfUsePage')" style="cursor:pointer;">使用条款</span>
          ｜
          <span @click="gotoPsPage('/PrivacyPolicyPage')" style="cursor:pointer;">隐私协议</span>
          ｜© 长光卫星 版权所有归长光卫星技术股份有限公司 | 吉ICP备15001825号-7 | 吉公网安备22017302000265号 | 甲测资字22100079
        </p>
      </div>

    </div>

  </div>

</template>

<script>
export default {
  name: "AppPageFootBar",
  props:["produceList"],
  data(){
    return{
      webScreenSize:"big",
    }
  },
  methods:{

    //跳转产品页
    gotoProductPage(pageUrl){
      this.$router.push(
          {
            path: pageUrl,
          }
      );
    },

    //跳转附件页面
    gotoPsPage(pageUrl){
      this.$router.push(
          {
            path: pageUrl,
          }
      );
    }

  },
  mounted(){
    //适配web尺寸
    if(window.innerWidth < 1330){
      this.webScreenSize = "small";
    }

  }
}
</script>

<style scoped>

.appPageFootBar{
  font-family:HarmonyOSSansSCRegular;
}
.appPageFootBar p{
  margin:0;
}

</style>