import  {Http} from 'vue-resource'
import wx from 'weixin-js-sdk'

export function getWxSignature(infoId,type = "standard"){
    let shareUrl = window.location.href
    if(type === "standard"){
        let data = new FormData()
        data.append('url', shareUrl)
        data.append('infoId', infoId)
        Http.post('/earthApi/main-info/getWxJsSdkSignature', data).then(response => {
            if (response.data.status === 200) {
                initWx(response.data.data,shareUrl);
            }
        })
    }else{
        if(type === "2"){
            //Event 活动专属
            let data = new FormData()
            data.append('url', shareUrl)
            data.append('infoId', infoId)
            data.append('type', type)
            Http.post('/earthApi/main-info/getWxJsSdkSignature', data).then(response => {
                if (response.data.status === 200) {
                    initWx(response.data.data,shareUrl);
                }
            })
        }else{
            if(infoId === undefined){
                infoId = ""
            }
            let data = new FormData()
            data.append('url', shareUrl)
            data.append('infoId', infoId)
            data.append('shareType', type)
            Http.post('/earthApi/main-info/getWxJsSdkSignature', data).then(response => {
                if (response.data.status === 200) {
                    initWx(response.data.data,shareUrl);
                }
            })
        }

    }

}

// eslint-disable-next-line no-unused-vars
function initWx(info,shareUrl){
    wx.checkJsApi({
        jsApiList: ['updateAppMessageShareData','updateTimelineShareData'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
        success: function(res) {
            console.log(res)
            // 以键值对的形式返回，可用的api值true，不可用为false
            // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
        }
    });

    wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        // appId: 'wx60d2ee726065af4e', // 测试
        appId: 'wx345ada45ddfcfcda', // 正式
        timestamp: info.timestamp, // 必填，生成签名的时间戳
        nonceStr: info.noncestr, // 必填，生成签名的随机串
        signature: info.signature,// 必填，签名
        jsApiList: [
            'updateAppMessageShareData','updateTimelineShareData'
        ] // 必填，需要使用的JS接口列表
    });

    wx.error(function(res){
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        console.log(res)
    });

    wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
        wx.updateAppMessageShareData({
            title: info.title, // 分享标题
            desc: info.desc, // 分享描述
            link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: info.imgUrl, // 分享图标
            success: function () {
                // 设置成功
            }
        })
    });

    wx.ready(function () {      //需在用户可能点击分享按钮前就先调用
        wx.updateTimelineShareData({
            title: info.title, // 分享标题
            link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: info.imgUrl, // 分享图标
            success: function () {
                // 设置成功
            }
        })
    });
}

