import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vueResource, {Http} from 'vue-resource'
import './lib/leaflet.css'
import './lib/side-by-side/layout.css'
import './lib/side-by-side/range.css'
import './fonts/SpecialFont.css'
import {Slider} from "element-ui";

Vue.use(Slider)

Vue.use(vueResource)
import globalJS from "./lib/global";
Vue.prototype.globalJS = globalJS;
Vue.config.productionTip = false
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = '共生地球App'
  }
  if(to.query.share == 1){
    let data = new FormData()
    data.append('link',to.fullPath)
    Http.post("/earthApi/shareRead", data)
  }
  track(to)
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

function track(to) {
  // console.log('111111')
  // console.log(to.path)
  // console.log(to.fullPath)
  // console.log(to.name)
  // console.log(to.query.sharePlatform)
  // console.log(to.query.infoId)
  // console.log(to.query.activityId)
  if(to.query.sharePlatform === undefined){
    return;
  }
  let param = "";
  let platform = "";
  let infoId = ""
if(to.query.sharePlatform === 'wechat'){
    platform = '微信分享'
  }else if(to.query.sharePlatform === 'timeLine'){
    platform = '朋友圈分享'
  }else if(to.query.sharePlatform === 'qq'){
    platform = 'QQ分享'
  }else if(to.query.sharePlatform === 'qzone'){
    platform = 'QQ空间分享'
  }else if(to.query.sharePlatform === 'weibo'){
    platform = '微博分享'
  }else if(to.query.sharePlatform === 'psletter'){
    platform = '私信好友分享'
  }

  if(to.path !== '/Event' && to.query.infoId === undefined && to.query.activityId === undefined){
    //没有infoId与activityId时，无需埋点
    return
  }

  if(to.path === '/Event'){
    param = '首页活动访问入口_分享_' + platform
  }else if(to.path ==='/SatelliteActivityPageOne' || to.path ==='/SatelliteActivityPageTwo' ||to.path ==='/SatelliteActivityPageThree'){
    param = param + '卫星活动访问入口_分享_' + platform
  }else if(to.path ==='/CampusActivity'){
    param = '校园活动访问入口_分享_' + platform
  }else {
    param ='应用访问入口_分享_' + platform
  }

  if(to.query.infoId !== undefined){
    infoId = to.query.infoId
  }
  if(to.query.activityId !== undefined){
    infoId = to.query.activityId
  }
  let data = new FormData()
  data.append('clickType',param)
  data.append('infoId',infoId)
  Http.post("/earthApi/click-button-log/clickButtonLog", data)
  //     .then((res)=>{
  //   console.log(res)
  // })
  console.log(param)
}


