<template>
  <div class="OPPOSans" style="width: 100vw;height: 100vh;position: relative;">
    <div v-show="loading" style="position: absolute;left: 50%;top: 50%;transform: translateY(-50%) translateX(-50%);z-index: 1">
      <div style="margin-bottom:5px;overflow:hidden;">
        <div class="loadingArea" style="width:200px;height:90px;margin-top:4px;margin-left:6px;float:left;">
          <p style="color: white;display: flex;justify-content: center;font-weight: 500">加载中</p>
          <div class="loadingArea_white">
          </div>
        </div>
      </div>
    </div>
    <div id="map"
         style="width: 100%;height: 100%;position: absolute;background-color: grey;top: 0;left: 0; overflow: hidden;">
    </div>
    <footprint-map v-if="showFootprint" :layer-opacity='1' :color-list="colorList"
                   :data-max='maxPvalue' :data-min='minPvalue' :hot-area-pos="hotAreaPos"
                   data-property-name="pValue" :map="map"
                   :point-data="userFootprint"></footprint-map>
    <top-share-bar v-if="shareType === 1"></top-share-bar>
    <div v-if="shareType === 1" style="position: fixed;display: flex;top: 80px;left: 20px">
      <div class="avatar" :style="{'background-image': 'url(' + userInfo['picture'] + ')'}"></div>
      <div style="margin-left: 20px;display: flex;align-items: center">
        <div>
          <p style="line-height: 16px;height: 16px;margin-top: 0;margin-bottom: 0;color: white;font-weight: 600">
            {{ userInfo['nickName'] }}</p>
          <p style="font-size: 10px;line-height: 20px;height: 13px;margin-top: 0;margin-bottom: 0;color: rgba(209,209,209,1);font-weight: 600">
            用户ID：{{ userInfo['id'] }}</p>
        </div>
      </div>
    </div>
    <div style="height: 210px;width: 100%;position: absolute;bottom: 10px">
      <swiper style="height: 100%;width: 100%" :options="swiperOption">
        <swiper-slide style="height: 220px">
          <div style="position: relative">
            <img src="../../../public/app-assets/image/footprint/footprint_world/1.png"
                 style="width: calc(100% - 20px);margin-left: 10px" alt="">
            <div style="position: absolute;height: 80%;width: 100%;top: 15%">
              <div class="text-area">
                <p class="text-line" style="">
                  <span style="font-family: YouSheBiaoTiHei;color: rgba(42,130,228,1)">{{
                      userInfo['firstDate']
                    }}</span>
                  <span style="margin-left: 8px;font-weight: 700">是你我第一次相遇</span>
                </p>
                <p class="text-line">
                  <span style="font-weight: 700">那一天，你一定遇见了很多风景吧。</span>
                </p>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide v-if="userInfo['userThroughAreaVisible']"  style="height: 220px">
          <div style="position: relative">
            <img src="../../../public/app-assets/image/footprint/footprint_world/2.png"
                 style="width: calc(100% - 20px);margin-left: 10px" alt="">
            <div style="position: absolute;height: 80%;width: 100%;top: 15%">
              <div v-if="throughAreaList.length > 0" class="text-area">
                <p class="text-line">
                  <span style="font-weight: 700">您已在共生地球探索</span>
                  <span
                      style="margin-left: 10px;font-family: YouSheBiaoTiHei;color: rgba(42,130,228,1)">{{
                      userInfo['areas']
                    }}km<sup>2</sup></span>
                </p>
                <p class="text-line">
                  <span style="font-weight: 700">超过共生地球上</span>
                  <span
                      style="margin-left: 10px;font-family: YouSheBiaoTiHei;color: rgba(42,130,228,1)">{{
                      userInfo['percent']
                    }}%</span>
                  <span style="font-weight: 700;margin-left: 8px">用户</span>
                </p>
              </div>
              <div v-if="throughAreaList.length === 0" class="text-area">
                <p class="text-line">
                  <span style="font-weight: 700">您暂未在共生地球上探索区域</span>
                </p>
                <p class="text-line">
                  <span style="font-weight: 700">快来与大家一起开启探索之路！</span>
                </p>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide v-if="userInfo['userThroughAreaVisible']" style="height: 220px">
          <div style="position: relative">
            <img src="../../../public/app-assets/image/footprint/footprint_world/3.png"
                 style="width: calc(100% - 20px);margin-left: 10px" alt="">
            <div style="position: absolute;height: 80%;width: 100%;top: 15%">
              <div v-if="throughAreaList.length > 0" class="text-area">
                <p class="text-line">
                  <span style="font-weight: 700">过去一年你最关注的地方是</span>
                  <span
                      style="font-family: YouSheBiaoTiHei;color: rgba(42,130,228,1);margin-left: 8px">{{
                      this.throughAreaList[0]['areaName']
                    }}</span>
                </p>
                <p class="text-line">
                  <span style="font-weight: 700">相信这里对你来说有特别的意义~</span>
                </p>
              </div>
              <div v-if="throughAreaList.length === 0" class="text-area">
                <p class="text-line">
                  <span style="font-weight: 700">过去一年你暂无最关注的地方</span>
                </p>
                <p class="text-line">
                  <span style="font-weight: 700">快来看看家乡的最新变化吧~</span>
                </p>
              </div>
            </div>
          </div>

        </swiper-slide>
        <swiper-slide style="height: 220px">
          <div style="position:relative;">
            <img src="../../../public/app-assets/image/footprint/footprint_world/4.png"
                 style="width: calc(100% - 20px);margin-left: 10px" alt="">
            <div style="position: absolute;height: 80%;width: 100%;top: 15%">
              <div class="text-area">
                <p class="text-line">
                  <span style="font-weight: 700">今日共有</span>
                  <span
                      style="font-family: YouSheBiaoTiHei;color: rgba(42,130,228,1);margin-left: 5px">{{
                      userInfo['users']
                    }}</span>
                  <span style="margin-left: 5px;font-weight: 700">位探索者与你一起</span>
                </p>
                <p class="text-line">
                  <span style="font-weight: 700">在共生地球相伴！</span>
                </p>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide style="height: 220px">
          <div style="position:relative;">
            <img src="../../../public/app-assets/image/footprint/footprint_world/5.png"
                 style="width: calc(100% - 20px);margin-left: 10px" alt="">
            <div style="position: absolute;height: 80%;width: 100%;top: 15%">
              <div class="text-area">
                <p class="text-line">
                  <span style="font-weight: 700">共生地球已陪伴您</span>
                  <span
                      style="margin-left: 10px;font-family: YouSheBiaoTiHei;color: rgba(42,130,228,1)">{{
                      userInfo['days']
                    }}</span>
                  <span style="margin-left: 10px;font-weight: 700">天</span>
                </p>
                <p class="text-line">
                  <span style="font-weight: 700">未来的日子里，让我们一起探索冒险吧！</span>
                </p>
              </div>
            </div>
          </div>
        </swiper-slide>
        <div id="mypage" class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import TopShareBar from "@/components/TopShareBar.vue";
import {swiper, swiperSlide} from "vue-awesome-swiper";
import {getMapboxToken} from "@/utils/utils";
import mapboxgl from "mapbox-gl";
import FootprintMap from "@/views/FootprintWorld/FootprintMap.vue";

export default {
  name: 'FootprintDetail',
  components: {FootprintMap, TopShareBar, swiper, swiperSlide,},
  data() {
    return {
      map: '',
      colorList: [
        {
          "hexColor": "#BE1111",
        },
        {
          "hexColor": "#F39D00",
        },
        {
          "hexColor": "#1BB32A",
        },
        {
          "hexColor": "#176AD5",
        },
        {
          "hexColor": "rgba(25,70,150, .7)",
        }
      ],
      shareType: 0,
      userId: '',
      userFootprint: '',
      showFootprint: false,
      maxPvalue: 0,
      minPvalue: 100,
      loading: false,
      hotAreaPos: [101.818, 29.646], // 若无浏览历史记录则跳转到噶贡山
      userInfo: '',
      throughAreaList: [],
      swiperOption: {
        direction: 'horizontal',
        // loop: true,		// 循环回路
        observer: true,
        observeParents: true,
        slidesPerView: "auto",	// 设置slider容器能够同时显示的slides数量; 设置为auto则自动根据slides的宽度来设定数量
        centeredSlides: true,	// 设定为true时，active slide会居中，而不是默认状态下的居左
        spaceBetween: 20,	// 每个swiper-slide之间的间隔
        pagination: {
          el: '.swiper-pagination'
        }
      },
    }
  },
  methods: {

    async initMapbox() {
      this.loading = true
      await getMapboxToken()
      mapboxgl.accessToken = this.globalJS.mapboxToken;
      this.map = new mapboxgl.Map({
        container: 'map',
        center: [0, 0],
        zoom: 3,
        style: 'mapbox://styles/wyzwangyazhou/clpusikxv01ed01pk5zry9524',
        preserveDrawingBuffer: true,
        localIdeographFontFamily: '',
        projection: {name: 'globe'},
        transformRequest: (url) => {
          if (url.indexOf('https://api.mapbox.com') > -1) {
            return {
              url: url.replace('https://api.mapbox.com', 'https://earth.jl1.cn/mapbox'),
            };
          }
        }
      });
      this.map.on('load', () => {
        this.map.addSource('mapbox-dem', {
          type: 'raster-dem',
          url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
          tileSize: 512,
          maxZoom: 14,
        });
        this.map.setFog({
          "horizon-blend": 0.1,
          "space-color": 'rgba(0, 0, 0, 1)',
          "star-intensity": 0.15
        });
        this.map.setTerrain({'source': 'mapbox-dem', 'exaggeration': 1});
        this.map.addLayer({
          id: 'sky',
          type: 'sky',
          paint: {
            'sky-type': 'atmosphere',
            'sky-atmosphere-sun': [0.0, 0.0],
            'sky-atmosphere-sun-intensity': 15,
          }
        });
        this.map.addSource('tianditu', {
          type: 'raster',
          tiles: [
            'https://t0.tianditu.gov.cn/DataServer?T=cva_w&X={x}&Y={y}&L={z}&tk=05861044e5fe4135647facbd39abc543'
          ],
          tileSize: 256,
        });
        this.map.addLayer({
          id: 'layer',
          type: 'raster',
          source: 'tianditu'
        })
        this.getUserFootprint()
      });
    },
    getUserInfo() {
      this.$http.get('/earthApi/data/getUserThroughInfo?userId=' + this.userId).then((resp) => {
        resp = resp.data
        if (resp.status === 200) {
          this.userInfo = resp.data
          console.log(this.userInfo)
          this.userInfo['percent'] = this.userInfo['percent'].toFixed(2)
          this.throughAreaList = this.userInfo['userThroughAreaList']
        } else {
          console.log('获取用户信息失败')
        }
      })
    },
    movingAverage(features, windowSize) {
      let sum = 0
      for (let i = 0; i < features.length - windowSize + 1; i++) {
        // sum = 0 这句加上，地图点亮的会少不好看，正常的数据平滑是需要加上的。。。
        for (let j = 0; j < windowSize; j++) {
          sum += features[i + j]['properties']['pValue']
        }
        features[i]['properties']['pValue'] = sum / windowSize
      }
      for (let i = features.length - 1; i >= windowSize - 1; i--) {
        // sum = 0 这句加上，地图点亮的会少不好看，正常的数据平滑是需要加上的。。。
        for (let j = 0; j < windowSize; j++) {
          sum += features[i - j]['properties']['pValue']
        }
        features[i]['properties']['pValue'] = sum / windowSize
      }
    },
    async getUserFootprint() {
      await this.$http.get('/earthApi/data/getUserFootInfo?userId=' + this.userId).then((resp) => {
        resp = resp.data
        if (resp.status === 200) {
          this.userFootprint = resp.data
          this.userFootprint['features'].forEach((f) => {
            if (f['properties']['pValue'] > this.maxPvalue) {
              this.hotAreaPos = f['geometry']['coordinates']
            }
          })
          this.movingAverage(this.userFootprint['features'], 5)
          this.userFootprint['features'].forEach((f) => {
            if (f['properties']['pValue'] > this.maxPvalue) {
              this.maxPvalue = f['properties']['pValue']
            }
            this.minPvalue = Math.min(this.minPvalue, f['properties']['pValue'])
          })
          this.userFootprint['features'].forEach((f) => {
            if (this.hotAreaPos === f['geometry']['coordinates']) {
              f['properties']['pValue'] = this.maxPvalue
            }
          })
          this.loading = false
          this.showFootprint = true
        } else {
          console.log('获取用户足迹失败')
        }
      })
      this.loading = false
    }
  },
  mounted() {
    if (this.$route.query.share) {
      this.shareType = parseInt(this.$route.query.share)
    }
    if (this.$route.query.userId) {
      this.userId = this.$route.query.userId
    }
    this.getUserInfo()
    this.initMapbox()
    document.title = '足迹世界'
    console.log(this.$route.path)
  }
}
</script>

<style scoped>

/*loading动画效果*/
@-webkit-keyframes loading {
  0%{left:20%;}
  100%{left:80%;}
}
@keyframes loading{
  0%{left:20%;}
  100%{left:80%;}
}

.loadingArea{
  position:relative;
  background:rgba(0,0,0,0.6);
  border-radius:4px;
  overflow:hidden;
}

.loadingArea_white{
  position:absolute;
  left:50%;
  width:6px;
  top: 60%;
  height:5%;
  border-radius:4px;
  background: -webkit-linear-gradient(left, #dfdfdf 0%, #f8f8f8 48%, #ffffff 50%, #f8f8f8 52%, #dfdfdf 100% ); /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(right, #dfdfdf 0%, #f8f8f8 48%, #ffffff 50%, #f8f8f8 52%, #dfdfdf 100% ); /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(right, #dfdfdf 0%, #f8f8f8 48%, #ffffff 50%, #f8f8f8 52%, #dfdfdf 100% ); /* Firefox 3.6 - 15 */
  background: linear-gradient(to right, #dfdfdf 0%, #f8f8f8 48%, #ffffff 50%, #f8f8f8 52%, #dfdfdf 100% ); /* 标准的语法 */
  box-shadow: 0 0 20px #ffffff;
  animation:loading 1s infinite;
  -webkit-animation:loading 1s infinite;
}

.avatar {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 3px solid white;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.swiper-container /deep/ .swiper-pagination {
  margin-bottom: -10px;
  z-index: 2;
}

.swiper-container /deep/ .swiper-pagination-bullet {
  background: rgba(255, 255, 255, 1);
}

.swiper-container /deep/ .swiper-pagination-bullet-active {
  background-color: rgba(42, 130, 228, 1);
  color: rgba(42, 130, 228, 1);
}

.text-area {
  color: black;
  line-height: 16px;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  width: calc(100% - 90px);
  margin-left: 40px
}

.text-line {
  border-bottom: 1px solid rgba(42, 130, 228, 1);
  padding-left: 5px;
  width: fit-content;
  line-height: 16px;
  min-width: calc(100% - 10px);
  white-space: nowrap
}
</style>