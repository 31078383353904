<template>
  <div class="pageBackground">

    <!--检查更新-->
    <div v-if="checkUpdateIng" style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);text-align:center;">
      <img style="width:197px;" src="../../../assets/Event/GraduationPhoto/logo.png">
      <p style="font-family:YouSheBiaoTiYuan;color:#695EC2;font-size:20px;margin:0;">正在进入校园列表...</p>
    </div>


    <!--未更新-->
    <div v-if="notUpdate" style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);width:290px;text-align:center;">
      <img style="width:75px;" src="../../../assets/Event/GraduationPhoto/notUpdate.png">
      <p style="font-family:YouSheBiaoTiYuan;color:#695EC2;font-size:20px;margin-top:48px;">当前版本暂不支持校园功能</p>
      <p style="font-family:YouSheBiaoTiYuan;color:#695EC2;font-size:20px;margin-top:10px;">请更新至最新版本后重新尝试</p>


<!--      <p @click="updateApp">展示更新弹窗</p>-->

    </div>






  </div>

</template>

<script>
export default {
  name: "GraduationPhoto",
  data(){
    return{
      currAppVersion:"",

      checkUpdateIng:true,
      notUpdate:false,

    }

  },
  methods:{
    checkUpdate(){
      //太空毕业照版本
      if(window["flutter_gotoSchoolList"]){
        window["flutter_gotoSchoolList"].postMessage("");
      }
      else{
        this.checkUpdateIng = false;
        this.notUpdate = true
      }
    },
    setBackBtnColor(){
      if(window["flutter_backBtnColor"]){
        window["flutter_backBtnColor"].postMessage("0xff000000");
      }
    },




    // getAppVersion(param){
    //   this.currAppVersion = param
    //
    // },
    // updateApp(){
    //   if(window["flutter_updateShow"]){
    //     window["flutter_updateShow"].postMessage("");
    //   }
    //
    // }


  },
  mounted(){
    window.checkAppUpdatePage = this;
    this.setBackBtnColor();
    this.checkUpdate();
  }
}
</script>

<style scoped>

.pageBackground{
  position:fixed;
  top:0;
  left:0;
  bottom:0;
  right:0;
  width:100vw;
  height:100vh;
  background-image: url('../../../assets/Event/GraduationPhoto/background.png');
  background-position: center;
  background-size: cover;
}

</style>