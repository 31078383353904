<template>
  <div>
    <div style="width: 100%;height: 55px;background-color: rgba(255,255,255,0.60);position: fixed;top: 0px;text-align: center;line-height: 55px;z-index: 999">

      <div style="position:absolute;top:0;left:50%;transform:translate(-50%);height:100%;width:145px;text-align:center;">
        <p style="margin:0;line-height:55px;font-size: 14px;color: #ffffff;">打开APP体验完整功能</p>
      </div>

      <div @click="backFun" v-show="backBtnShow" style="float: left;margin-left: 28px;">
        <span class="iconfontFamily" style="margin:0;line-height:55px;font-size: 16px;color: #ffffff;" >&#xe786;</span>
      </div>
      <div class="shareButton"><a id="openApp" :href="shareUrl">打开APP</a></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopShareBarWithBack",
  props:{
    backBtnShow: {
      default:true
    },
    //返回按钮将要进入的页面路由，默认即返回上一页
    backPage: {
      default:""
    },
    //返回按钮将要进入的页面传参（backPage为默认时不可用）
    backPageParam: {
      default:""
    },
  },
  data(){
    return {
      typeList:{
        "/SatelliteActivityPageFour": "1500",
        "/MemberActivation":"1500"

        //其他页面路由 类型均为1500




      }
    }
  },
  computed:{
    shareUrl(){
      return '/OpenApp?infoId=' + this.$route.query.infoId + '&type=' + this.typeList[this.$route.path]
    },
  },
  methods:{
    backFun(){
      console.log(this.backPage)
      if(this.backPage === ''){
        this.$router.go(-1);
      }
      else{
        console.log(JSON.parse(this.backPageParam))
        this.$router.push(
            {
              path: this.backPage,
              query: JSON.parse(this.backPageParam)
            }
        )

      }

    }


  }
}
</script>

<style lang="scss" scoped>
.shareButton{
  width: 75px;
  height: 28px;
  background: #695EC1;
  border-radius: 14px;
  float: right;
  margin-right: 15px;
  margin-top: 13px;
  #openApp{
    text-decoration: none;
    display: block;
    color: white;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    line-height: 28px;
    height: 28px;
    background: #695EC1;
    border-radius: 27px;
    box-shadow: 0px 2px 10px 0px rgba(95,95,95,0.50);
  }
}

</style>
