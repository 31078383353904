<template>
  <div class="appPageTopBar">
    <!--顶栏-->
    <div style="position:fixed;top:0;left:0;width:calc(100% - 74px);height:80px;background:rgba(21, 21, 40, 0.45);padding:0 37px;
    font-size:16px;display:flex;align-items:center;z-index:99;">
      <div style="flex:1;">
        <p @click="openProductMantle" style="float:left;cursor:pointer;">产品与服务</p>
        <p @click="openAboutUsMantle" style="margin-left:37px;float:left;cursor:pointer;">关于我们</p>
      </div>
      <p @click="gotoAppPage" style="font-size:26px;cursor:pointer;" class="iconfontFamily">&#xe78d;</p>
      <div style="flex:1;"></div>
    </div>


    <!--产品与服务-->
    <div v-if="productMantleShow" style="position:fixed;top:0;left:0;width:100vw;height:100vh;background:#000000;padding-top:80px;z-index:10;display:flex;">
      <div style="position:relative;width:calc(30% - 74px);min-width:226px;max-width:370px;height:100%;background:rgba(9, 9, 18, 0.8);padding:9px 37px;">
        <div @click="webScreenSize === 'small' ? chooseProductMantleItem(index) : gotoProductPage(item.href)"
             @mouseover="webScreenSize === 'small' ? '' : chooseProductMantleItem(index)"
             v-for="(item,index) in productMantleProductList" :key="'productMantleItem'+index"
             :style="{'border-bottom':productMantleCurrIndex===index ? '1px solid rgba(42, 130, 228, 1)' : '1px solid transparent'}"
             style="height:23px;margin-top:23px;cursor:pointer;">
          <p style="font-size:16px;">{{item.title}}</p>
        </div>

        <!--关闭按钮-->
        <div @click="productMantleShow = false;" style="position:absolute;top:13px;right:12px;cursor:pointer;">
          <p style="font-size:12px;color:rgba(128, 128, 128, 1);" class="iconfontFamily">&#xe72c;</p>
        </div>

      </div>

      <div :style="{'background-image':'url('+productMantleProductList[productMantleCurrIndex].background+')'}"
           style="flex:1;height:100%;background-size:100% 100%;">

        <div style="position:relative;top:8%;left:0;width:100%;display:flex;flex-direction:column;align-items:center;">
          <p v-show="productMantleContentShow" class="floatAnimationDom_always"
             style="line-height:48px;font-size:40px;font-weight:bold;animation-duration:1.5s;">{{productMantleProductList[productMantleCurrIndex].title}}</p>
          <p v-show="productMantleContentShow" class="floatAnimationDom_always"
             style="margin-top:14px;font-size:18px;">{{productMantleProductList[productMantleCurrIndex].ps}}</p>
          <!--特色服务页面主要内容-->
          <div v-show="productMantleProductList[productMantleCurrIndex].title === '吉林一号·特色服务'"
               style="width:100%;height:60vh;margin-top:30px;display:flex;">

            <div v-show="productMantleContentShow" class="floatAnimationDom_always" style="position:relative;flex:1;height:100%;">
              <img v-for="(item,index) in productMantleSpecialProductList" :key="'productMantleSpecialProductItem'+index"
                   :id="item.id"
                   :style="{'height':(55-5*index)+'vh',
                   'right':(50*(productMantleSpecialProductList.length-1)-50*index)+'px',
                   'z-index':productMantleSpecialProductList.length-index}"

                   style="position:absolute;top:50%;transform:translate(0,-50%);animation-duration:200ms;"
                   :src="item.mainPicture" />
            </div>

            <div v-show="productMantleContentShow" class="floatAnimationDom_always" style="flex:1;height:100%;display:flex;flex-direction:column;justify-content: space-evenly">

              <div @mouseenter="chooseSpecialProductItem(index)" v-for="(item,index) in productMantleSpecialProductList" :key="'productMantleSpecialProductIntroduce'+index"
                   :style="{'background':productMantleSpecialProductCurrIndex === index ? 'rgba(42, 130, 228, 0.22)' : ''}"
                   style="position:relative;left:10%;width:calc(78% - 60px);min-width:270px;max-width:330px;height:123px;border-radius:10px;
                   padding:0 30px;cursor:pointer;display:flex;align-items:center;">

                <div style="flex:1;">
                  <p class="productMantleSpecialProductIntroduceTitle" style="font-size:20px;font-weight:700;color:#ffffff;
                  -webkit-text-fill-color: transparent;background:linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(159, 160, 167, 1) 100%);">
                    {{item.title}}
                  </p>
                  <p style="margin-top:14px;font-size:16px;">{{item.ps}}</p>
                </div>

<!--                &lt;!&ndash;二维码&ndash;&gt;-->
<!--                <div v-show="productMantleSpecialProductCurrIndex === index" style="width:61px;height:61px;margin-left:15px;">-->
<!--                  <img style="width:100%;height:100%;" :src="item.code" />-->
<!--                </div>-->
              </div>

            </div>

          </div>
          <!--其他产品页面主要内容-->
          <img class="floatAnimationDom_always"
               v-show="productMantleContentShow && productMantleProductList[productMantleCurrIndex].title !== '吉林一号·特色服务'"
               :style="{'height':productMantleProductList[productMantleCurrIndex].title === '吉林一号·共生地球APP' ? '50vh' : '40vh',
          'margin-top':productMantleProductList[productMantleCurrIndex].title === '吉林一号·共生地球APP' ? '40px' : '100px'}"
               :src="productMantleProductList[productMantleCurrIndex].mainPicture" />
        </div>

        <div @click="gotoProductPage(productMantleProductList[productMantleCurrIndex].href)" v-if="productMantleProductList[productMantleCurrIndex].title !== '吉林一号·特色服务'"
             style="position:absolute;right:10%;bottom:15%;" class="btn_outline">
          <p>了解详情</p>
        </div>

      </div>

    </div>


    <!--关于我们-->
    <div v-if="aboutUsMantleShow" style="position:fixed;top:0;left:0;width:100vw;height:100vh;background:#000000;padding-top:80px;z-index:10;display:flex;">
      <div style="position:relative;width:calc(30% - 74px);min-width:226px;max-width:370px;height:100%;background:rgba(9, 9, 18, 0.8);padding:9px 37px;">
        <div @click="webScreenSize === 'small' ? chooseAboutUsMantleItem(index) : gotoAboutUsDetailPage"
             @mouseover="webScreenSize === 'small' ? '' : chooseAboutUsMantleItem(index)"
             v-for="(item,index) in aboutUsMantleList" :key="'aboutUsMantleItem'+index"
             :style="{'border-bottom':aboutUsMantleCurrIndex===index ? '1px solid rgba(42, 130, 228, 1)' : '1px solid transparent'}"
             style="height:23px;margin-top:23px;cursor:pointer;">
          <p style="font-size:16px;">{{item.title}}</p>
        </div>

        <!--关闭按钮-->
        <div @click="aboutUsMantleShow = false;" style="position:absolute;top:13px;right:12px;cursor:pointer;">
          <p style="font-size:12px;color:rgba(128, 128, 128, 1);" class="iconfontFamily">&#xe72c;</p>
        </div>

      </div>

      <div :style="{'background-image':'url('+aboutUsMantleList[aboutUsMantleCurrIndex].background+')',
      'background-size':aboutUsMantleCurrIndex === 0 ? '100%' : '100% 100%'}"
           style="position:relative;flex:1;height:100%;background-repeat:no-repeat">

        <!--项目介绍-->
        <div v-show="aboutUsMantleCurrIndex === 0" style="position:relative;top:35%;left:0;transform:translate(0,-50%);width:100%;display:flex;flex-direction:column;align-items:center;">
          <p v-show="aboutUsMantleContentShow" class="floatAnimationDom_always"
             style="line-height:48px;font-size:40px;font-weight:bold;letter-spacing:7px;animation-duration:1.5s;">吉林一号·共生地球</p>
          <p v-show="aboutUsMantleContentShow" class="floatAnimationDom_always"
             style="margin-top:18px;line-height:16px;font-size:14px;font-weight:bold;letter-spacing:7px;">JILIN-1 GONGSHENGDIQIU</p>
          <p v-show="aboutUsMantleContentShow" class="floatAnimationDom_always"
             style="width:80%;max-width:799px;margin-top:23px;line-height:26px;font-size:18px;text-align:center;animation-duration:2.5s;">
            吉林一号·共生地球项目是长光卫星技术股份有限公司下，面向大众用户开展的遥感大众化服务系列产品。产品与服务形式多样，包含APP、网页版、个性化服务等，项目自2021年至今以服务超过200万用户，未来将致力于让遥感走进千家万户，让遥感服务生活。
          </p>
        </div>


        <!--关于吉林一号-->
        <div v-show="aboutUsMantleCurrIndex === 1" style="position:relative;top:8%;left:0;width:100%;display:flex;flex-direction:column;align-items:center;">
          <p v-show="aboutUsMantleContentShow" class="floatAnimationDom_always"
             style="line-height:48px;font-size:40px;font-weight:bold;animation-duration:1.5s;">吉林一号星座</p>
          <p v-show="aboutUsMantleContentShow" class="floatAnimationDom_always"
             style="width:80%;margin-top:21px;font-size:18px;text-align:center;">“吉林一号”现已建成目前全球最大的亚米级商业遥感卫星星座。在遥感信息服务上占据优势地位，逐渐成为全球重要航天遥感信息来源。</p>

          <img v-show="aboutUsMantleContentShow" class="floatAnimationDom_always" style="width:68%;margin-top:50px;"
               src="../../assets/App/aboutUsMantle/aboutJL1/mainPicture.png" />

        </div>



        <div @click="gotoAboutUsDetailPage"
             style="position:absolute;right:10%;bottom:15%;" class="btn_outline">
          <p>了解详情</p>
        </div>

      </div>


    </div>


  </div>

</template>

<script>


export default {
  name: "AppPageTopBar",
  props:["fromPage"],
  data(){
    return{
      webScreenSize:"big",

      //产品与服务蒙层
      productMantleShow:false,
      productMantleContentShow:false,    //主要内容显示
      //产品服务列表
      productMantleProductList:[
        {
          title:"吉林一号·共生地球APP",
          ps:"太空新视角，卫星瞰世界！",
          mainPicture:require('../../assets/App/productMantle/app/mainPicture.png'),
          background:require('../../assets/App/productMantle/app/background.jpg'),
          href:"/ProductPageApp",
        },
        {
          title:"吉林一号·共生地球web端",
          ps:"为创作者而生的地图标记工具",
          mainPicture:require('../../assets/App/productMantle/webMarker/mainPicture.png'),
          background:require('../../assets/App/productMantle/webMarker/background.jpg'),
          href:"/ProductPageWebMarker",
        },
        {
          title:"吉林一号·遥感影像库",
          ps:"卫星视角下的星球，高清图源版权无忧！",
          mainPicture:require('../../assets/App/productMantle/imageLib/mainPicture.png'),
          background:require('../../assets/App/productMantle/imageLib/background.jpg'),
          href:"/ProductPageImageLib",
        },
        {
          title:"吉林一号·特色服务",
          ps:"即用即开 随时随地感受遥感魅力！",
          background:require('../../assets/App/productMantle/specialProduct/background.jpg'),
          href:"",
        },


      ],
      //特色服务列表
      productMantleSpecialProductList:[
        {
          id:"satelliteObservation",
          title:"卫星观察时刻",
          ps:"卫星追踪热点事件，跟踪报道事件进展！",
          mainPicture:require('../../assets/App/productMantle/specialProduct/satelliteObservation.png'),
          code:require('../../assets/logo.png')
        },
        {
          id:"earthStarCommentator",
          title:"最美地球评选",
          ps:"参与遥感图像评价，让地图变得更加美丽！",
          mainPicture:require('../../assets/App/productMantle/specialProduct/earthStarCommentator.png'),
          code:require('../../assets/logo.png')
        },
        {
          id:"satelliteFlash",
          title:"卫星快闪进行时",
          ps:"个性化拍摄，让卫星留住这时刻！",
          mainPicture:require('../../assets/App/productMantle/specialProduct/satelliteFlash.png'),
          code:require('../../assets/logo.png')
        }
      ],
      productMantleCurrIndex:0,
      productMantleSpecialProductCurrIndex:0,
      productMantleSpecialProductTemp:"",



      //关于我们蒙层
      aboutUsMantleShow:false,
      aboutUsMantleContentShow:false,
      aboutUsMantleList:[
        {
          title:"项目介绍",
          background:require('../../assets/App/aboutUsMantle/aboutProduct/background.jpg')
        },
        {
          title:"关于吉林一号",
          background:require('../../assets/App/aboutUsMantle/aboutJL1/background.jpg')
        },
      ],
      aboutUsMantleCurrIndex:0,





    }

  },
  methods:{

    //点击logo返回首页
    gotoAppPage(){
      if(this.$route.path === "/"){
        window.location.reload();
      }
      else{
        this.$router.push(
            {
              path: '/',
            }
        );
      }
    },


    /**产品与服务蒙层*********************************************************/
    //显示产品与服务蒙层
    openProductMantle(){
      if(this.aboutUsMantleShow){
        this.aboutUsMantleShow = false;
      }
      this.productMantleShow = true;
      this.productMantleContentShow = true;
      setTimeout(()=>{
        this.productMantleSpecialProductIntroduceTitleColor();
      },0)
      this.productMantleCurrIndex = 0;
      this.productMantleSpecialProductCurrIndex = 0;
    },

    //点击某项产品
    chooseProductMantleItem(index){
      //唤起动画
      this.productMantleContentShow = false;
      this.productMantleCurrIndex = index;
      setTimeout(()=>{
        this.productMantleContentShow = true;
      },0)
    },

    //点击某项特色产品
    chooseSpecialProductItem(index){
      this.productMantleSpecialProductCurrIndex = index;

      clearTimeout(this.productMantleSpecialProductTemp);

      //叠放图片动效
      for(let i=0;i<this.productMantleSpecialProductList.length;i++){

        document.getElementById(this.productMantleSpecialProductList[i].id).style.animationName = "";

        //每项产品图片的新叠放位置 0-n 可以根据 oldIndex,index,该项产品在数组中的index计算
        let newPoisition;
        if(i>=index){
          newPoisition = i - index;
        }
        else{
          newPoisition = this.productMantleSpecialProductList.length + (i - index);
        }
        //根据新叠放位置 计算css样式 写动画并加载
        //选中背景动画
        let tempStyle = `@keyframes ${'currItemAnimation'+i} {
                            from {
                            height:${document.getElementById(this.productMantleSpecialProductList[i].id).style.height};
                            right:${document.getElementById(this.productMantleSpecialProductList[i].id).style.right};
                            z-index:${this.productMantleSpecialProductList.length-newPoisition};
                            }
                            to {
                            height:${(55-5*newPoisition)+'vh'};
                            right:${(50*(this.productMantleSpecialProductList.length-1)-50*newPoisition)+'px'};
                            z-index:${this.productMantleSpecialProductList.length-newPoisition};
                            }
                        }`
        const style = document.createElement('style');
        style.setAttribute('type', 'text/css');
        document.head.appendChild(style);
        style.sheet.insertRule(tempStyle, 0);

        document.getElementById(this.productMantleSpecialProductList[i].id).style.animationName = "currItemAnimation"+i;

        this.productMantleSpecialProductTemp = setTimeout(()=>{
          document.getElementById(this.productMantleSpecialProductList[i].id).style.height = (55-5*newPoisition)+'vh';
          document.getElementById(this.productMantleSpecialProductList[i].id).style.right = (50*(this.productMantleSpecialProductList.length-1)-50*newPoisition)+'px';
          document.getElementById(this.productMantleSpecialProductList[i].id).style.zIndex = this.productMantleSpecialProductList.length-newPoisition;
          document.getElementById(this.productMantleSpecialProductList[i].id).style.animationName = "";
          style.sheet.deleteRule(0);
        },200)

      }

    },

    //点击某项产品了解详情
    gotoProductPage(pageUrl){
      this.$router.push(
          {
            path: pageUrl,
          }
      );
    },


    //特色服务标题增加渐变颜色效果(直接写在css里不生效)
    productMantleSpecialProductIntroduceTitleColor(){
      let numberList = document.querySelectorAll(".productMantleSpecialProductIntroduceTitle");
      for(let i = 0;i<numberList.length;i++) {
        numberList[i].style["-webkit-background-clip"] = 'text';
      }
    },



    /**关于我们蒙层*********************************************************/
    openAboutUsMantle(){
      if(this.productMantleShow){
        this.productMantleShow = false;
      }
      this.aboutUsMantleShow = true;
      this.aboutUsMantleContentShow = true;
      this.aboutUsMantleCurrIndex = 0;
    },
    //点击关于我们某项
    chooseAboutUsMantleItem(index){
      //唤起动画
      this.aboutUsMantleContentShow = false;
      this.aboutUsMantleCurrIndex = index;
      setTimeout(()=>{
        this.aboutUsMantleContentShow = true;
      },0)
    },



    //关于我们 进入详情页
    gotoAboutUsDetailPage(){
      switch(this.aboutUsMantleCurrIndex){
        case 0:{
          //项目介绍
          if(this.fromPage === "aboutUsPage"){
            //在关于我们页面
            this.aboutUsMantleShow = false;
          }
          else{
            this.$router.push(
                {
                  path: '/AboutUs',
                }
            )
          }

          break;
        }
        case 1:{
          //关于吉林一号
          window.open("http://www.jl1.cn/index.aspx", '_blank');

          break;
        }


      }


    }


  },
  mounted(){
    //适配web尺寸
    if(window.innerWidth < 1330){
      this.webScreenSize = "small";
    }


  }


}
</script>

<style scoped>

.appPageTopBar{
  font-family:HarmonyOSSansSCRegular;
}
.appPageTopBar p{
  margin:0;
}


.floatAnimationDom_always{
  position:relative;
  top:100px;
  opacity:0;
  animation-name: floatAnimation;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

/*上浮动画*/
@keyframes floatAnimation {
  from {top:100px;opacity:0;}
  to {top:0px;opacity:1;}
}


/*通用*/
.btn_outline{
  height:42px;
  padding:0 24px;
  border:2px solid rgba(56, 56, 56, 1);
  box-shadow: 0px 2px 8px  rgba(0, 60, 102, 0.4);
  font-size:16px;
  cursor:pointer;
}
.btn_outline p{
  line-height:42px;
}


</style>