<template>
  <div class="OPPOSans" style="font-weight: 700;position: relative">
    <div v-if="share === 0" style="position: absolute;height: 85px;width: 100vw;top: 0;background-color: rgba(244,244,244,1)"></div>
    <div style="padding-left: 20px;padding-right: 20px;line-height: 30px;" :style="{'padding-top' : share === 1 ? '20px' : '100px'}">
      <p>
        1.为生成您的足迹世界相关报告，共生地球将根据您的授权查询您在共生地球的地图访问数据、登录时间等，并据此进行汇总统计分析，以用于本活动页面向您进行信息展示。
      </p>
      <p>
        2.您的足迹报告为算法自动生成结果，可能会与实际有偏差，共生地球无法保证相关数据的绝对准确性和有效性。
      </p>
      <p>
        3.活动页面包含您的个人信息，当您选择向其他人转发活动页面的截图时，其他人将会看到上述信息，请谨慎选择。
      </p>
      <p>
        4.除上述声明目的外，共生地球不会对本次查询的信息作其他处理。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserAuthInfo',
  data() {
    return {
      share: false
    }
  },
  mounted() {
    if (this.$route.query.share) {
      this.share = parseInt(this.$route.query.share)
    }
    console.log(this.share)
  }
}
</script>

<style scoped>

</style>