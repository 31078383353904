<template>
    <div class="wallpaper" :style="{'background-image':'url(' + imageUrl + ')'}">

      <!--分享说明-->
      <share-reason :shareReason="shareReason"></share-reason>

      <!--顶部下载栏-->
      <TopShareBar />
        <!--分享按钮-->
<!--        <ShareButton></ShareButton>-->
    </div>
</template>

<script>
    import TopShareBar from '@/components/TopShareBar';
    import ShareReason from '@/components/ShareReason';
    import {getWxSignature} from "@/utils/shareConfig";
    export default {
        name:"WallpaperSharePage",
        components: {TopShareBar,ShareReason},
        data(){
            return{
              mainInfoId:"",
              imageUrl:"",
              title:"",
              shareReason:"",
            }
        },
        methods:{
          getDetail(){
            this.$http.get("/earthApi/main-info/getMainInfoDetail?infoId=" + this.mainInfoId).then(response => {
              if(response.data.status === 200){
                this.imageUrl = response.data.data.picture1
                this.title = response.data.data.mainTitle
                this.shareReason = response.data.data.shareReason
                document.title = this.title;
              }
              else if(response.data.status === 404){
                this.$router.push(
                    {
                      path: '/NotExist',
                    }
                )
              }
              else{
                console.log(response.data.status + response.data.message)
              }
            })

          }
        },
        mounted(){
          if(this.$route.query.infoId){
            this.mainInfoId = this.$route.query.infoId;
            this.getDetail();
            getWxSignature(this.$route.query.infoId)

          }
          else{
            window.location.href = this.$route.fullPath.replace("mainInfoId","infoId")
          }
        }

    }

</script>

<style scoped>
    .wallpaper{
        position:absolute;
        width:100%;
        height:100%;
        background-color: #222222;
        background-repeat:no-repeat;
        background-size:cover;
        background-position:center;
    }
    .wallpaper p{
        margin:0;
    }


</style>