import { render, staticRenderFns } from "./CampusImage.vue?vue&type=template&id=7f0ed014&scoped=true&"
import script from "./CampusImage.vue?vue&type=script&lang=js&"
export * from "./CampusImage.vue?vue&type=script&lang=js&"
import style0 from "./CampusImage.vue?vue&type=style&index=0&id=7f0ed014&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f0ed014",
  null
  
)

export default component.exports