<template>
  <div id="login">
    <div v-show="currentPage === 'login'">
      <div style="width: 100%;height: 100px;background: rgba(131, 124, 194, 1);overflow:hidden">
        <div style="height: 26px;width: 100%">
          <span @click="goBack" class="iconfontFamily" style="float: right;color: white;font-size:12px;line-height: 40px;margin-right: 10px" >&#xe72c;</span>
        </div>
        <div v-show="wayOfLogin === 'phone'" style="width: 260px;height: 50px;margin: 0 auto 0 auto">
          <div style="float: left"><img src="/app-assets/image/user_system/iconLogo.png" width="50" height="50"/></div>
          <div style="float: left;margin-left: 10px">
            <div style="font-size: 18px;color: white">登录共生地球</div>
            <div style="font-size: 14px;color: white;margin-top: 6px">解锁数字地球探索新体验</div>
          </div>
        </div>
        <div v-show="wayOfLogin === 'password'" style="width: fit-content;height: 50px;margin: 0 auto">
          <div style="font-size: 18px;color: white;width: fit-content;margin: 0 auto">登录共生地球</div>
          <div style="font-size: 14px;color: white;width: fit-content;margin: 6px auto 0 auto">解锁地球探索新体验</div>
        </div>
      </div>
      <!--手机号-->
      <div id="phoneCodeArea">
        <div style="height: 39px;width: 100%">
          <div @click="goCodeArea" style="width: fit-content;height: fit-content;">
            <div style="color: rgba(0, 0, 0, 1);font-size: 14px;line-height: 39px;margin-left: 25px;float: left">+{{areaCode}}</div>
            <span class="iconfontFamily" style="float: left;color: black;font-size:12px;line-height: 39px;margin-left: 9px;scale: 0.5" >&#xe68d;</span>
          </div>

          <div class="verticalDivider"></div>
          <input v-model="loginPhoneNum" placeholder="输入手机号" id="phoneNumInput" style="height: 36px;background-color: transparent">
        </div>
        <div style="width: 100%;height: 1px;background: rgba(151, 151, 151, 1)"></div>
      </div>
      <!--验证码-->
      <div v-show="wayOfLogin === 'phone'" style="width: calc(100% - 30px);height: 40px;margin-top: 20px;margin-left: 15px">
        <div style="width: calc(100% - 125px);float: left;">
          <input v-model="loginVerificationCode" placeholder="输入手机验证码" id="verificationCodeInput">
          <div style="width: 100%;height: 1px;background: rgba(151, 151, 151, 1)"></div>
        </div>
        <div @click="showPicSliderForPhone" style="font-size: 14px;float: left;margin-top: 15px;margin-left: 12px;text-align: center;width: 110px;" :style="{'color':sendingAvailable  ? 'rgba(0, 0, 0, 1)':'rgba(128, 128, 128, 1)'}">
          {{sendCodeContent}}
        </div>
      </div>
      <!--密码-->
      <div v-show="wayOfLogin === 'password'" style="width: calc(100% - 30px);height: 40px;margin-top: 20px;margin-left: 15px">
          <input v-model="password" placeholder="输入账号密码" id="verificationCodeInput" style="width: 100%">
          <div style="width: 100%;height: 1px;background: rgba(151, 151, 151, 1)"></div>
      </div>

      <div class="hintText" style="margin-top: 30px">未注册手机验证后自动登录</div>
      <div class="hintText" style="margin-top: 3px">注册即同意
        <span @click="goUserAgreement" style="color: rgba(131, 124, 194, 1);text-decoration:underline">《用户协议》</span>
        <span @click="goPrivacy" style="color: rgba(131, 124, 194, 1);text-decoration:underline;margin-left: 3px">《隐私政策》</span>
      </div>
      <div v-show="wayOfLogin === 'phone'" id="loginBtn" @click="checkVerifyCode" :style="{'background':phoneLoginClickable?'rgba(131, 124, 194, 1)':'rgba(130, 125, 195, 0.6)'}">登录</div>

      <div  v-show="wayOfLogin === 'password'"  id="loginBtn" @click="showPicSliderForPsd" :style="{'background':passwordLoginClickable?'rgba(131, 124, 194, 1)':'rgba(130, 125, 195, 0.6)'}">登录</div>

      <div v-show="wayOfLogin === 'phone'" @click="wayOfLogin = 'password'" class="hintText" style="margin-top: 16px;color: rgba(131, 124, 194, 1)">密码登录</div>
      <div v-show="wayOfLogin === 'password'" @click="wayOfLogin = 'phone'" class="hintText" style="margin-top: 16px;color: rgba(131, 124, 194, 1)">手机验证码登录</div>



      <div v-show="showSnackbar" class="snackBar" style="position: absolute;left: 15px;top: 80px">{{hintContent}}</div>
      <Vcode :show="showPicVerify" @success="onPicVerifySuccess" @close="closePicVerify" successText="验证成功！"
             failText="验证失败，请重试！"/>
    </div>



    <div v-show="currentPage === 'areaCode'">
      <div style="width: 100%;height: 100px;background: rgba(131, 124, 194, 1);overflow:hidden">
        <div style="height: 26px;width: 100%;">
          <span @click="goBack" class="iconfontFamily" style="float: right;color: white;font-size:12px;line-height: 40px;margin-right: 10px" >&#xe72c;</span>
        </div>
        <div style="width: fit-content;height: 50px;margin: 0 auto">
          <div style="font-size: 18px;color: white;width: fit-content;margin: 0 auto">登录共生地球</div>
          <div style="font-size: 14px;color: white;width: fit-content;margin: 6px auto 0 auto">解锁地球探索新体验</div>
        </div>
      </div>
      <div style="margin-left: 30px;margin-top: 25px;height: 20px">
        <span @click="goBackLogin" class="iconfontFamily" style="float: left;color: black;font-size:18px;line-height: 20px;" >&#xe72c;</span>
        <div style="font-size: 18px;color: black;float: left;line-height: 20px;margin-left: 14px">选择国家/地区</div>
      </div>
      <div style="margin-top: 38px;margin-left: 28px;margin-bottom: 15px;color: rgba(151, 151, 151, 1);font-size:14px;">热门国家/地区</div>
      <div v-for="(item,index) in hotCountries" :key='index'>
        <div @click="clickHotCountries(index)"  style="width: 100%;height: 36px">
          <div style="margin-left: 36px;float: left;line-height: 36px">{{item.name}}</div>
          <div style="margin-right: 36px;float: right;line-height: 36px;color: rgba(131, 124, 194, 1)">+{{item.dial_code}}</div>
        </div>
      </div>
      <div style="margin-top: 15px;margin-left: 28px;color: rgba(151, 151, 151, 1);font-size:14px;">国家/地区列表</div>
      <div v-for="(item,index) in countries" :key='index + 1000'>
        <div @click="clickCountries(index)"  style="width: 100%;height: 36px">
          <div style="margin-left: 36px;float: left;line-height: 36px">{{item.name}}</div>
          <div style="margin-right: 36px;float: right;line-height: 36px;color: rgba(131, 124, 194, 1)">+{{item.dial_code}}</div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import Vcode from "vue-puzzle-vcode";

export default {
  name: "Login",
  components: {
    Vcode,
  },
  data(){
    return{
      currentPage:'login',
      loginPhoneNum:'',
      areaCode:"86",
      loginVerificationCode:'',
      sendingAvailable:true,
      sendCodeContent:'发送验证码',
      countDownNum:60,
      clock:undefined,
      hotCountries : [
        {
          "name": "中国",
          "flag": "🇨🇳",
          "code": "CN",
          "dial_code": 86,
          "max_length": 12
        },
        {
          "name": "美国",
          "flag": "🇺🇸",
          "code": "US",
          "dial_code": 1,
          "max_length": 10
        },
        {
          "name": "英国",
          "flag": "🇬🇧",
          "code": "GB",
          "dial_code": 44,
          "max_length": 10
        },
        {
          "name": "法国",
          "flag": "🇫🇷",
          "code": "FR",
          "dial_code": 33,
          "max_length": 9
        },
        {
          "name": "德国",
          "flag": "🇩🇪",
          "code": "DE",
          "dial_code": 49,
          "max_length": 13
        },
        {
          "name": "日本",
          "flag": "🇯🇵",
          "code": "JP",
          "dial_code": 81,
          "max_length": 10
        },
        {
          "name": "加拿大",
          "flag": "🇨🇦",
          "code": "CA",
          "dial_code": 1,
          "max_length": 10
        },
        {
          "name": "澳大利亚",
          "flag": "🇦🇺",
          "code": "AU",
          "dial_code": 61,
          "max_length": 15
        },
        {
          "name": "印度",
          "flag": "🇮🇳",
          "code": "IN",
          "dial_code": 91,
          "max_length": 10
        },
      ],
      countries : [
        {
          "name": "中国",
          "flag": "🇨🇳",
          "code": "CN",
          "dial_code": 86,
          "max_length": 12
        },
        {
          "name": "中国",
          "flag": "🇨🇳",
          "code": "CN",
          "dial_code": 86,
          "max_length": 12
        },
        {
          "name": "美国",
          "flag": "🇺🇸",
          "code": "US",
          "dial_code": 1,
          "max_length": 10
        },
        {
          "name": "英国",
          "flag": "🇬🇧",
          "code": "GB",
          "dial_code": 44,
          "max_length": 10
        },
        {
          "name": "法国",
          "flag": "🇫🇷",
          "code": "FR",
          "dial_code": 33,
          "max_length": 9
        },
        {
          "name": "德国",
          "flag": "🇩🇪",
          "code": "DE",
          "dial_code": 49,
          "max_length": 13
        },
        {
          "name": "日本",
          "flag": "🇯🇵",
          "code": "JP",
          "dial_code": 81,
          "max_length": 10
        },
        {
          "name": "加拿大",
          "flag": "🇨🇦",
          "code": "CA",
          "dial_code": 1,
          "max_length": 10
        },
        {
          "name": "澳大利亚",
          "flag": "🇦🇺",
          "code": "AU",
          "dial_code": 61,
          "max_length": 15
        },
        {
          "name": "印度",
          "flag": "🇮🇳",
          "code": "IN",
          "dial_code": 91,
          "max_length": 10
        },
        {
          "name": "印度",
          "flag": "🇮🇳",
          "code": "IN",
          "dial_code": 91,
          "max_length": 10
        },
        {
          "name": "中国",
          "flag": "🇨🇳",
          "code": "CN",
          "dial_code": 86,
          "max_length": 12
        },
        {
          "name": "阿富汗",
          "flag": "🇦🇫",
          "code": "AF",
          "dial_code": 93,
          "max_length": 9
        },
        {
          "name": "阿尔巴尼亚",
          "flag": "🇦🇱",
          "code": "AL",
          "dial_code": 355,
          "max_length": 9
        },
        {
          "name": "阿尔及利亚",
          "flag": "🇩🇿",
          "code": "DZ",
          "dial_code": 213,
          "max_length": 9
        },
        {
          "name": "美属萨摩亚",
          "flag": "🇦🇸",
          "code": "AS",
          "dial_code": 1684,
          "max_length": 7
        },
        {
          "name": "安道尔",
          "flag": "🇦🇩",
          "code": "AD",
          "dial_code": 376,
          "max_length": 9
        },
        {
          "name": "安哥拉",
          "flag": "🇦🇴",
          "code": "AO",
          "dial_code": 244,
          "max_length": 9
        },
        {
          "name": "安圭拉",
          "flag": "🇦🇮",
          "code": "AI",
          "dial_code": 1264,
          "max_length": 7
        },
        {
          "name": "南极洲",
          "flag": "🇦🇶",
          "code": "AQ",
          "dial_code": 672,
          "max_length": 6
        },
        {
          "name": "安提瓜和巴布达",
          "flag": "🇦🇬",
          "code": "AG",
          "dial_code": 1268,
          "max_length": 7
        },
        {
          "name": "阿根廷",
          "flag": "🇦🇷",
          "code": "AR",
          "dial_code": 54,
          "max_length": 10
        },
        {
          "name": "亚美尼亚",
          "flag": "🇦🇲",
          "code": "AM",
          "dial_code": 374,
          "max_length": 8
        },
        {
          "name": "阿鲁巴",
          "flag": "🇦🇼",
          "code": "AW",
          "dial_code": 297,
          "max_length": 7
        },
        {
          "name": "澳大利亚",
          "flag": "🇦🇺",
          "code": "AU",
          "dial_code": 61,
          "max_length": 15
        },
        {
          "name": "奥地利",
          "flag": "🇦🇹",
          "code": "AT",
          "dial_code": 43,
          "max_length": 13
        },
        {
          "name": "阿塞拜疆",
          "flag": "🇦🇿",
          "code": "AZ",
          "dial_code": 994,
          "max_length": 9
        },
        {
          "name": "巴哈马",
          "flag": "🇧🇸",
          "code": "BS",
          "dial_code": 1242,
          "max_length": 7
        },
        {
          "name": "巴林",
          "flag": "🇧🇭",
          "code": "BH",
          "dial_code": 973,
          "max_length": 8
        },
        {
          "name": "孟加拉国",
          "flag": "🇧🇩",
          "code": "BD",
          "dial_code": 880,
          "max_length": 10
        },
        {
          "name": "巴巴多斯",
          "flag": "🇧🇧",
          "code": "BB",
          "dial_code": 1246,
          "max_length": 7
        },
        {
          "name": "白俄罗斯",
          "flag": "🇧🇾",
          "code": "BY",
          "dial_code": 375,
          "max_length": 10
        },
        {
          "name": "比利时",
          "flag": "🇧🇪",
          "code": "BE",
          "dial_code": 32,
          "max_length": 9
        },
        {
          "name": "伯利兹",
          "flag": "🇧🇿",
          "code": "BZ",
          "dial_code": 501,
          "max_length": 7
        },
        {
          "name": "贝宁",
          "flag": "🇧🇯",
          "code": "BJ",
          "dial_code": 229,
          "max_length": 8
        },
        {
          "name": "百慕大",
          "flag": "🇧🇲",
          "code": "BM",
          "dial_code": 1441,
          "max_length": 7
        },
        {
          "name": "不丹",
          "flag": "🇧🇹",
          "code": "BT",
          "dial_code": 975,
          "max_length": 8
        },
        {
          "name": "玻利维亚",
          "flag": "🇧🇴",
          "code": "BO",
          "dial_code": 591,
          "max_length": 8
        },
        {
          "name": "波黑",
          "flag": "🇧🇦",
          "code": "BA",
          "dial_code": 387,
          "max_length": 8
        },
        {
          "name": "博茨瓦纳",
          "flag": "🇧🇼",
          "code": "BW",
          "dial_code": 267,
          "max_length": 8
        },
        {
          "name": "布韦岛",
          "flag": "🇧🇻",
          "code": "BV",
          "dial_code": 47,
          "max_length": 15
        },
        {
          "name": "巴西",
          "flag": "🇧🇷",
          "code": "BR",
          "dial_code": 55,
          "max_length": 10
        },
        {
          "name": "文莱",
          "flag": "🇧🇳",
          "code": "BN",
          "dial_code": 673,
          "max_length": 7
        },
        {
          "name": "保加利亚",
          "flag": "🇧🇬",
          "code": "BG",
          "dial_code": 359,
          "max_length": 9
        },
        {
          "name": "布基纳法索",
          "flag": "🇧🇫",
          "code": "BF",
          "dial_code": 226,
          "max_length": 8
        },
        {
          "name": "布隆迪",
          "flag": "🇧🇮",
          "code": "BI",
          "dial_code": 257,
          "max_length": 8
        },
        {
          "name": "柬埔寨",
          "flag": "🇰🇭",
          "code": "KH",
          "dial_code": 855,
          "max_length": 8
        },
        {
          "name": "喀麦隆",
          "flag": "🇨🇲",
          "code": "CM",
          "dial_code": 237,
          "max_length": 8
        },
        {
          "name": "加拿大",
          "flag": "🇨🇦",
          "code": "CA",
          "dial_code": 1,
          "max_length": 10
        },
        {
          "name": "佛得角",
          "flag": "🇨🇻",
          "code": "CV",
          "dial_code": 238,
          "max_length": 7
        },
        {
          "name": "开曼群岛",
          "flag": "🇰🇾",
          "code": "KY",
          "dial_code": 345,
          "max_length": 7
        },
        {
          "name": "中非共和国",
          "flag": "🇨🇫",
          "code": "CF",
          "dial_code": 236,
          "max_length": 8
        },
        {
          "name": "乍得",
          "flag": "🇹🇩",
          "code": "TD",
          "dial_code": 235,
          "max_length": 7
        },
        {
          "name": "智利",
          "flag": "🇨🇱",
          "code": "CL",
          "dial_code": 56,
          "max_length": 9
        },
        {
          "name": "圣诞岛",
          "flag": "🇨🇽",
          "code": "CX",
          "dial_code": 61,
          "max_length": 15
        },
        {
          "name": "科科斯（基林）群岛",
          "flag": "🇨🇨",
          "code": "CC",
          "dial_code": 61,
          "max_length": 15
        },
        {
          "name": "哥伦比亚",
          "flag": "🇨🇴",
          "code": "CO",
          "dial_code": 57,
          "max_length": 0
        },
        {
          "name": "科摩罗",
          "flag": "🇰🇲",
          "code": "KM",
          "dial_code": 269,
          "max_length": 7
        },
        {
          "name": "刚果",
          "flag": "🇨🇬",
          "code": "CG",
          "dial_code": 242,
          "max_length": 7
        },
        {
          "name": "刚果民主共和国",
          "flag": "🇨🇩",
          "code": "CD",
          "dial_code": 243,
          "max_length": 9
        },
        {
          "name": "库克群岛",
          "flag": "🇨🇰",
          "code": "CK",
          "dial_code": 682,
          "max_length": 5
        },
        {
          "name": "哥斯达黎加",
          "flag": "🇨🇷",
          "code": "CR",
          "dial_code": 506,
          "max_length": 8
        },
        {
          "name": "科特迪瓦",
          "flag": "🇨🇮",
          "code": "CI",
          "dial_code": 225,
          "max_length": 8
        },
        {
          "name": "克罗地亚",
          "flag": "🇭🇷",
          "code": "HR",
          "dial_code": 385,
          "max_length": 12
        },
        {
          "name": "古巴",
          "flag": "🇨🇺",
          "code": "CU",
          "dial_code": 53,
          "max_length": 8
        },
        {
          "name": "塞浦路斯",
          "flag": "🇨🇾",
          "code": "CY",
          "dial_code": 357,
          "max_length": 1
        },
        {
          "name": "捷克共和国",
          "flag": "🇨🇿",
          "code": "CZ",
          "dial_code": 420,
          "max_length": 12
        },
        {
          "name": "丹麦",
          "flag": "🇩🇰",
          "code": "DK",
          "dial_code": 45,
          "max_length": 8
        },
        {
          "name": "吉布提",
          "flag": "🇩🇯",
          "code": "DJ",
          "dial_code": 253,
          "max_length": 6
        },
        {
          "name": "多米尼加",
          "flag": "🇩🇲",
          "code": "DM",
          "dial_code": 1767,
          "max_length": 7
        },
        {
          "name": "多米尼加共和国",
          "flag": "🇩🇴",
          "code": "DO",
          "dial_code": 1849,
          "max_length": 12
        },
        {
          "name": "厄瓜多尔",
          "flag": "🇪🇨",
          "code": "EC",
          "dial_code": 593,
          "max_length": 8
        },
        {
          "name": "埃及",
          "flag": "🇪🇬",
          "code": "EG",
          "dial_code": 20,
          "max_length": 9
        },
        {
          "name": "萨尔瓦多",
          "flag": "🇸🇻",
          "code": "SV",
          "dial_code": 503,
          "max_length": 11
        },
        {
          "name": "赤道几内亚",
          "flag": "🇬🇶",
          "code": "GQ",
          "dial_code": 240,
          "max_length": 6
        },
        {
          "name": "厄立特里亚",
          "flag": "🇪🇷",
          "code": "ER",
          "dial_code": 291,
          "max_length": 7
        },
        {
          "name": "爱沙尼亚",
          "flag": "🇪🇪",
          "code": "EE",
          "dial_code": 372,
          "max_length": 10
        },
        {
          "name": "埃塞俄比亚",
          "flag": "🇪🇹",
          "code": "ET",
          "dial_code": 251,
          "max_length": 9
        },
        {
          "name": "福克兰群岛",
          "flag": "🇫🇰",
          "code": "FK",
          "dial_code": 500,
          "max_length": 5
        },
        {
          "name": "法罗群岛",
          "flag": "🇫🇴",
          "code": "FO",
          "dial_code": 298,
          "max_length": 6
        },
        {
          "name": "斐济",
          "flag": "🇫🇯",
          "code": "FJ",
          "dial_code": 679,
          "max_length": 7
        },
        {
          "name": "芬兰",
          "flag": "🇫🇮",
          "code": "FI",
          "dial_code": 358,
          "max_length": 12
        },
        {
          "name": "法国",
          "flag": "🇫🇷",
          "code": "FR",
          "dial_code": 33,
          "max_length": 9
        },
        {
          "name": "法属圭亚那",
          "flag": "🇬🇫",
          "code": "GF",
          "dial_code": 594,
          "max_length": 15
        },
        {
          "name": "法属波利尼西亚",
          "flag": "🇵🇫",
          "code": "PF",
          "dial_code": 689,
          "max_length": 6
        },
        {
          "name": "法国南部领土",
          "flag": "🇹🇫",
          "code": "TF",
          "dial_code": 262,
          "max_length": 15
        },
        {
          "name": "加蓬",
          "flag": "🇬🇦",
          "code": "GA",
          "dial_code": 241,
          "max_length": 7
        },
        {
          "name": "冈比亚",
          "flag": "🇬🇲",
          "code": "GM",
          "dial_code": 220,
          "max_length": 7
        },
        {
          "name": "格鲁吉亚",
          "flag": "🇬🇪",
          "code": "GE",
          "dial_code": 995,
          "max_length": 8
        },
        {
          "name": "德国",
          "flag": "🇩🇪",
          "code": "DE",
          "dial_code": 49,
          "max_length": 13
        },
        {
          "name": "加纳",
          "flag": "🇬🇭",
          "code": "GH",
          "dial_code": 233,
          "max_length": 9
        },
        {
          "name": "直布罗陀",
          "flag": "🇬🇮",
          "code": "GI",
          "dial_code": 350,
          "max_length": 8
        },
        {
          "name": "希腊",
          "flag": "🇬🇷",
          "code": "GR",
          "dial_code": 30,
          "max_length": 10
        },
        {
          "name": "格陵兰",
          "flag": "🇬🇱",
          "code": "GL",
          "dial_code": 299,
          "max_length": 6
        },
        {
          "name": "格林纳达",
          "flag": "🇬🇩",
          "code": "GD",
          "dial_code": 1473,
          "max_length": 7
        },
        {
          "name": "瓜德罗普",
          "flag": "🇬🇵",
          "code": "GP",
          "dial_code": 590,
          "max_length": 15
        },
        {
          "name": "关岛",
          "flag": "🇬🇺",
          "code": "GU",
          "dial_code": 1671,
          "max_length": 7
        },
        {
          "name": "危地马拉",
          "flag": "🇬🇹",
          "code": "GT",
          "dial_code": 502,
          "max_length": 8
        },
        {
          "name": "根西岛",
          "flag": "🇬🇬",
          "code": "GG",
          "dial_code": 44,
          "max_length": 6
        },
        {
          "name": "几内亚",
          "flag": "🇬🇳",
          "code": "GN",
          "dial_code": 224,
          "max_length": 8
        },
        {
          "name": "几内亚比绍",
          "flag": "🇬🇼",
          "code": "GW",
          "dial_code": 245,
          "max_length": 7
        },
        {
          "name": "圭亚那",
          "flag": "🇬🇾",
          "code": "GY",
          "dial_code": 592,
          "max_length": 7
        },
        {
          "name": "海地",
          "flag": "🇭🇹",
          "code": "HT",
          "dial_code": 509,
          "max_length": 8
        },
        {
          "name": "赫德岛和麦克唐纳岛",
          "flag": "🇭🇲",
          "code": "HM",
          "dial_code": 672,
          "max_length": 15
        },
        {
          "name": "梵蒂冈",
          "flag": "🇻🇦",
          "code": "VA",
          "dial_code": 379,
          "max_length": 10
        },
        {
          "name": "洪都拉斯",
          "flag": "🇭🇳",
          "code": "HN",
          "dial_code": 504,
          "max_length": 8
        },
        {
          "name": "中国香港",
          "flag": "🇭🇰",
          "code": "HK",
          "dial_code": 852,
          "max_length": 9
        },
        {
          "name": "匈牙利",
          "flag": "🇭🇺",
          "code": "HU",
          "dial_code": 36,
          "max_length": 9
        },
        {
          "name": "冰岛",
          "flag": "🇮🇸",
          "code": "IS",
          "dial_code": 354,
          "max_length": 9
        },
        {
          "name": "印度",
          "flag": "🇮🇳",
          "code": "IN",
          "dial_code": 91,
          "max_length": 10
        },
        {
          "name": "印度尼西亚",
          "flag": "🇮🇩",
          "code": "ID",
          "dial_code": 62,
          "max_length": 10
        },
        {
          "name": "伊朗",
          "flag": "🇮🇷",
          "code": "IR",
          "dial_code": 98,
          "max_length": 10
        },
        {
          "name": "伊拉克",
          "flag": "🇮🇶",
          "code": "IQ",
          "dial_code": 964,
          "max_length": 10
        },
        {
          "name": "爱尔兰",
          "flag": "🇮🇪",
          "code": "IE",
          "dial_code": 353,
          "max_length": 11
        },
        {
          "name": "马恩岛",
          "flag": "🇮🇲",
          "code": "IM",
          "dial_code": 44,
          "max_length": 6
        },
        {
          "name": "以色列",
          "flag": "🇮🇱",
          "code": "IL",
          "dial_code": 972,
          "max_length": 9
        },
        {
          "name": "意大利",
          "flag": "🇮🇹",
          "code": "IT",
          "dial_code": 39,
          "max_length": 13
        },
        {
          "name": "牙买加",
          "flag": "🇯🇲",
          "code": "JM",
          "dial_code": 1876,
          "max_length": 7
        },
        {
          "name": "日本",
          "flag": "🇯🇵",
          "code": "JP",
          "dial_code": 81,
          "max_length": 10
        },
        {
          "name": "约旦",
          "flag": "🇯🇴",
          "code": "JO",
          "dial_code": 962,
          "max_length": 9
        },
        {
          "name": "哈萨克斯坦",
          "flag": "🇰🇿",
          "code": "KZ",
          "dial_code": 7,
          "max_length": 10
        },
        {
          "name": "肯尼亚",
          "flag": "🇰🇪",
          "code": "KE",
          "dial_code": 254,
          "max_length": 10
        },
        {
          "name": "基里巴斯",
          "flag": "🇰🇮",
          "code": "KI",
          "dial_code": 686,
          "max_length": 5
        },
        {
          "name": "朝鲜",
          "flag": "🇰🇵",
          "code": "KP",
          "dial_code": 850,
          "max_length": 10
        },
        {
          "name": "韩国",
          "flag": "🇰🇷",
          "code": "KR",
          "dial_code": 82,
          "max_length": 11
        },
        {
          "name": "科索沃",
          "flag": "🇽🇰",
          "code": "XK",
          "dial_code": 383,
          "max_length": 8
        },
        {
          "name": "科威特",
          "flag": "🇰🇼",
          "code": "KW",
          "dial_code": 965,
          "max_length": 8
        },
        {
          "name": "吉尔吉斯斯坦",
          "flag": "🇰🇬",
          "code": "KG",
          "dial_code": 996,
          "max_length": 9
        },
        {
          "name": "老挝",
          "flag": "🇱🇦",
          "code": "LA",
          "dial_code": 856,
          "max_length": 9
        },
        {
          "name": "拉脱维亚",
          "flag": "🇱🇻",
          "code": "LV",
          "dial_code": 371,
          "max_length": 8
        },
        {
          "name": "黎巴嫩",
          "flag": "🇱🇧",
          "code": "LB",
          "dial_code": 961,
          "max_length": 8
        },
        {
          "name": "莱索托",
          "flag": "🇱🇸",
          "code": "LS",
          "dial_code": 266,
          "max_length": 8
        },
        {
          "name": "利比里亚",
          "flag": "🇱🇷",
          "code": "LR",
          "dial_code": 231,
          "max_length": 8
        },
        {
          "name": "阿拉伯利比亚民众国",
          "flag": "🇱🇾",
          "code": "LY",
          "dial_code": 218,
          "max_length": 9
        },
        {
          "name": "列支敦士登",
          "flag": "🇱🇮",
          "code": "LI",
          "dial_code": 423,
          "max_length": 9
        },
        {
          "name": "立陶宛",
          "flag": "🇱🇹",
          "code": "LT",
          "dial_code": 370,
          "max_length": 8
        },
        {
          "name": "卢森堡",
          "flag": "🇱🇺",
          "code": "LU",
          "dial_code": 352,
          "max_length": 11
        },
        {
          "name": "中国澳门",
          "flag": "🇲🇴",
          "code": "MO",
          "dial_code": 853,
          "max_length": 8
        },
        {
          "name": "马其顿",
          "flag": "🇲🇰",
          "code": "MK",
          "dial_code": 389,
          "max_length": 8
        },
        {
          "name": "马达加斯加",
          "flag": "🇲🇬",
          "code": "MG",
          "dial_code": 261,
          "max_length": 10
        },
        {
          "name": "马拉维",
          "flag": "🇲🇼",
          "code": "MW",
          "dial_code": 265,
          "max_length": 8
        },
        {
          "name": "马来西亚",
          "flag": "🇲🇾",
          "code": "MY",
          "dial_code": 60,
          "max_length": 9
        },
        {
          "name": "马尔代夫",
          "flag": "🇲🇻",
          "code": "MV",
          "dial_code": 960,
          "max_length": 7
        },
        {
          "name": "马里",
          "flag": "🇲🇱",
          "code": "ML",
          "dial_code": 223,
          "max_length": 8
        },
        {
          "name": "马耳他",
          "flag": "🇲🇹",
          "code": "MT",
          "dial_code": 356,
          "max_length": 8
        },
        {
          "name": "马绍尔群岛",
          "flag": "🇲🇭",
          "code": "MH",
          "dial_code": 692,
          "max_length": 7
        },
        {
          "name": "马提尼克",
          "flag": "🇲🇶",
          "code": "MQ",
          "dial_code": 596,
          "max_length": 15
        },
        {
          "name": "毛里塔尼亚",
          "flag": "🇲🇷",
          "code": "MR",
          "dial_code": 222,
          "max_length": 7
        },
        {
          "name": "毛里求斯",
          "flag": "🇲🇺",
          "code": "MU",
          "dial_code": 230,
          "max_length": 7
        },
        {
          "name": "马约特",
          "flag": "🇾🇹",
          "code": "YT",
          "dial_code": 262,
          "max_length": 9
        },
        {
          "name": "墨西哥",
          "flag": "🇲🇽",
          "code": "MX",
          "dial_code": 52,
          "max_length": 10
        },
        {
          "name": "摩尔多瓦",
          "flag": "🇲🇩",
          "code": "MD",
          "dial_code": 373,
          "max_length": 8
        },
        {
          "name": "摩纳哥",
          "flag": "🇲🇨",
          "code": "MC",
          "dial_code": 377,
          "max_length": 9
        },
        {
          "name": "蒙古",
          "flag": "🇲🇳",
          "code": "MN",
          "dial_code": 976,
          "max_length": 8
        },
        {
          "name": "黑山",
          "flag": "🇲🇪",
          "code": "ME",
          "dial_code": 382,
          "max_length": 12
        },
        {
          "name": "蒙特塞拉特",
          "flag": "🇲🇸",
          "code": "MS",
          "dial_code": 1664,
          "max_length": 7
        },
        {
          "name": "摩洛哥",
          "flag": "🇲🇦",
          "code": "MA",
          "dial_code": 212,
          "max_length": 9
        },
        {
          "name": "莫桑比克",
          "flag": "🇲🇿",
          "code": "MZ",
          "dial_code": 258,
          "max_length": 9
        },
        {
          "name": "缅甸",
          "flag": "🇲🇲",
          "code": "MM",
          "dial_code": 95,
          "max_length": 9
        },
        {
          "name": "纳米比亚",
          "flag": "🇳🇦",
          "code": "NA",
          "dial_code": 264,
          "max_length": 10
        },
        {
          "name": "瑙鲁",
          "flag": "🇳🇷",
          "code": "NR",
          "dial_code": 674,
          "max_length": 7
        },
        {
          "name": "尼泊尔",
          "flag": "🇳🇵",
          "code": "NP",
          "dial_code": 977,
          "max_length": 9
        },
        {
          "name": "荷兰",
          "flag": "🇳🇱",
          "code": "NL",
          "dial_code": 31,
          "max_length": 9
        },
        {
          "name": "新喀里多尼亚",
          "flag": "🇳🇨",
          "code": "NC",
          "dial_code": 687,
          "max_length": 6
        },
        {
          "name": "新西兰",
          "flag": "🇳🇿",
          "code": "NZ",
          "dial_code": 64,
          "max_length": 10
        },
        {
          "name": "尼加拉瓜",
          "flag": "🇳🇮",
          "code": "NI",
          "dial_code": 505,
          "max_length": 8
        },
        {
          "name": "尼日尔",
          "flag": "🇳🇪",
          "code": "NE",
          "dial_code": 227,
          "max_length": 8
        },
        {
          "name": "尼日利亚",
          "flag": "🇳🇬",
          "code": "NG",
          "dial_code": 234,
          "max_length": 10
        },
        {
          "name": "纽埃",
          "flag": "🇳🇺",
          "code": "NU",
          "dial_code": 683,
          "max_length": 4
        },
        {
          "name": "诺福克岛",
          "flag": "🇳🇫",
          "code": "NF",
          "dial_code": 672,
          "max_length": 15
        },
        {
          "name": "北马里亚纳群岛",
          "flag": "🇲🇵",
          "code": "MP",
          "dial_code": 1670,
          "max_length": 7
        },
        {
          "name": "挪威",
          "flag": "🇳🇴",
          "code": "NO",
          "dial_code": 47,
          "max_length": 8
        },
        {
          "name": "阿曼",
          "flag": "🇴🇲",
          "code": "OM",
          "dial_code": 968,
          "max_length": 8
        },
        {
          "name": "巴基斯坦",
          "flag": "🇵🇰",
          "code": "PK",
          "dial_code": 92,
          "max_length": 11
        },
        {
          "name": "帕劳",
          "flag": "🇵🇼",
          "code": "PW",
          "dial_code": 680,
          "max_length": 7
        },
        {
          "name": "巴拿马",
          "flag": "🇵🇦",
          "code": "PA",
          "dial_code": 507,
          "max_length": 8
        },
        {
          "name": "巴布亚新几内亚",
          "flag": "🇵🇬",
          "code": "PG",
          "dial_code": 675,
          "max_length": 11
        },
        {
          "name": "巴拉圭",
          "flag": "🇵🇾",
          "code": "PY",
          "dial_code": 595,
          "max_length": 9
        },
        {
          "name": "秘鲁",
          "flag": "🇵🇪",
          "code": "PE",
          "dial_code": 51,
          "max_length": 11
        },
        {
          "name": "菲律宾",
          "flag": "🇵🇭",
          "code": "PH",
          "dial_code": 63,
          "max_length": 10
        },
        {
          "name": "皮特凯恩",
          "flag": "🇵🇳",
          "code": "PN",
          "dial_code": 64,
          "max_length": 10
        },
        {
          "name": "波兰",
          "flag": "🇵🇱",
          "code": "PL",
          "dial_code": 48,
          "max_length": 9
        },
        {
          "name": "葡萄牙",
          "flag": "🇵🇹",
          "code": "PT",
          "dial_code": 351,
          "max_length": 1
        },
        {
          "name": "波多黎各",
          "flag": "🇵🇷",
          "code": "PR",
          "dial_code": 1939,
          "max_length": 15
        },
        {
          "name": "卡塔尔",
          "flag": "🇶🇦",
          "code": "QA",
          "dial_code": 974,
          "max_length": 10
        },
        {
          "name": "罗马尼亚",
          "flag": "🇷🇴",
          "code": "RO",
          "dial_code": 40,
          "max_length": 9
        },
        {
          "name": "俄罗斯",
          "flag": "🇷🇺",
          "code": "RU",
          "dial_code": 7,
          "max_length": 10
        },
        {
          "name": "卢旺达",
          "flag": "🇷🇼",
          "code": "RW",
          "dial_code": 250,
          "max_length": 9
        },
        {
          "name": "圣巴塞勒米",
          "flag": "🇧🇱",
          "code": "BL",
          "dial_code": 590,
          "max_length": 9
        },
        {
          "name": "圣卢西亚",
          "flag": "🇱🇨",
          "code": "LC",
          "dial_code": 1758,
          "max_length": 7
        },
        {
          "name": "圣马丁",
          "flag": "🇲🇫",
          "code": "MF",
          "dial_code": 590,
          "max_length": 9
        },
        {
          "name": "萨摩亚",
          "flag": "🇼🇸",
          "code": "WS",
          "dial_code": 685,
          "max_length": 7
        },
        {
          "name": "圣马力诺",
          "flag": "🇸🇲",
          "code": "SM",
          "dial_code": 378,
          "max_length": 10
        },
        {
          "name": "圣多美和普林西比",
          "flag": "🇸🇹",
          "code": "ST",
          "dial_code": 239,
          "max_length": 7
        },
        {
          "name": "沙特阿拉伯",
          "flag": "🇸🇦",
          "code": "SA",
          "dial_code": 966,
          "max_length": 9
        },
        {
          "name": "塞内加尔",
          "flag": "🇸🇳",
          "code": "SN",
          "dial_code": 221,
          "max_length": 9
        },
        {
          "name": "塞尔维亚",
          "flag": "🇷🇸",
          "code": "RS",
          "dial_code": 381,
          "max_length": 12
        },
        {
          "name": "塞舌尔",
          "flag": "🇸🇨",
          "code": "SC",
          "dial_code": 248,
          "max_length": 6
        },
        {
          "name": "塞拉利昂",
          "flag": "🇸🇱",
          "code": "SL",
          "dial_code": 232,
          "max_length": 8
        },
        {
          "name": "新加坡",
          "flag": "🇸🇬",
          "code": "SG",
          "dial_code": 65,
          "max_length": 12
        },
        {
          "name": "斯洛伐克",
          "flag": "🇸🇰",
          "code": "SK",
          "dial_code": 421,
          "max_length": 9
        },
        {
          "name": "斯洛文尼亚",
          "flag": "🇸🇮",
          "code": "SI",
          "dial_code": 386,
          "max_length": 8
        },
        {
          "name": "所罗门群岛",
          "flag": "🇸🇧",
          "code": "SB",
          "dial_code": 677,
          "max_length": 5
        },
        {
          "name": "索马里",
          "flag": "🇸🇴",
          "code": "SO",
          "dial_code": 252,
          "max_length": 8
        },
        {
          "name": "南非",
          "flag": "🇿🇦",
          "code": "ZA",
          "dial_code": 27,
          "max_length": 9
        },
        {
          "name": "南苏丹共和国",
          "flag": "🇸🇸",
          "code": "SS",
          "dial_code": 211,
          "max_length": 9
        },
        {
          "name": "西班牙",
          "flag": "🇪🇸",
          "code": "ES",
          "dial_code": 34,
          "max_length": 9
        },
        {
          "name": "斯里兰卡",
          "flag": "🇱🇰",
          "code": "LK",
          "dial_code": 94,
          "max_length": 9
        },
        {
          "name": "苏丹",
          "flag": "🇸🇩",
          "code": "SD",
          "dial_code": 249,
          "max_length": 9
        },
        {
          "name": "苏里南",
          "flag": "🇸🇷",
          "code": "SR",
          "dial_code": 597,
          "max_length": 7
        },
        {
          "name": "瑞典",
          "flag": "🇸🇪",
          "code": "SE",
          "dial_code": 46,
          "max_length": 13
        },
        {
          "name": "瑞士",
          "flag": "🇨🇭",
          "code": "CH",
          "dial_code": 41,
          "max_length": 12
        },
        {
          "name": "叙利亚",
          "flag": "🇸🇾",
          "code": "SY",
          "dial_code": 963,
          "max_length": 10
        },
        {
          "name": "中国台湾",
          "flag": "🇹🇼",
          "code": "TW",
          "dial_code": 886,
          "max_length": 9
        },
        {
          "name": "塔吉克斯坦",
          "flag": "🇹🇯",
          "code": "TJ",
          "dial_code": 992,
          "max_length": 9
        },
        {
          "name": "坦桑尼亚",
          "flag": "🇹🇿",
          "code": "TZ",
          "dial_code": 255,
          "max_length": 9
        },
        {
          "name": "泰国",
          "flag": "🇹🇭",
          "code": "TH",
          "dial_code": 66,
          "max_length": 9
        },
        {
          "name": "东帝汶",
          "flag": "🇹🇱",
          "code": "TL",
          "dial_code": 670,
          "max_length": 7
        },
        {
          "name": "多哥",
          "flag": "🇹🇬",
          "code": "TG",
          "dial_code": 228,
          "max_length": 7
        },
        {
          "name": "汤加",
          "flag": "🇹🇴",
          "code": "TO",
          "dial_code": 676,
          "max_length": 7
        },
        {
          "name": "特立尼达和多巴哥",
          "flag": "🇹🇹",
          "code": "TT",
          "dial_code": 1868,
          "max_length": 7
        },
        {
          "name": "突尼斯",
          "flag": "🇹🇳",
          "code": "TN",
          "dial_code": 216,
          "max_length": 8
        },
        {
          "name": "土耳其",
          "flag": "🇹🇷",
          "code": "TR",
          "dial_code": 90,
          "max_length": 10
        },
        {
          "name": "土库曼斯坦",
          "flag": "🇹🇲",
          "code": "TM",
          "dial_code": 993,
          "max_length": 8
        },
        {
          "name": "图瓦卢",
          "flag": "🇹🇻",
          "code": "TV",
          "dial_code": 688,
          "max_length": 6
        },
        {
          "name": "乌干达",
          "flag": "🇺🇬",
          "code": "UG",
          "dial_code": 256,
          "max_length": 9
        },
        {
          "name": "乌克兰",
          "flag": "🇺🇦",
          "code": "UA",
          "dial_code": 380,
          "max_length": 9
        },
        {
          "name": "阿拉伯联合酋长国",
          "flag": "🇦🇪",
          "code": "AE",
          "dial_code": 971,
          "max_length": 9
        },
        {
          "name": "英国",
          "flag": "🇬🇧",
          "code": "GB",
          "dial_code": 44,
          "max_length": 10
        },
        {
          "name": "美国",
          "flag": "🇺🇸",
          "code": "US",
          "dial_code": 1,
          "max_length": 10
        },
        {
          "name": "乌拉圭",
          "flag": "🇺🇾",
          "code": "UY",
          "dial_code": 598,
          "max_length": 11
        },
        {
          "name": "乌兹别克斯坦",
          "flag": "🇺🇿",
          "code": "UZ",
          "dial_code": 998,
          "max_length": 9
        },
        {
          "name": "瓦努阿图",
          "flag": "🇻🇺",
          "code": "VU",
          "dial_code": 678,
          "max_length": 7
        },
        {
          "name": "委内瑞拉",
          "flag": "🇻🇪",
          "code": "VE",
          "dial_code": 58,
          "max_length": 10
        },
        {
          "name": "越南",
          "flag": "🇻🇳",
          "code": "VN",
          "dial_code": 84,
          "max_length": 10
        },
        {
          "name": "也门",
          "flag": "🇾🇪",
          "code": "YE",
          "dial_code": 967,
          "max_length": 9
        },
        {
          "name": "赞比亚",
          "flag": "🇿🇲",
          "code": "ZM",
          "dial_code": 260,
          "max_length": 9
        },
        {
          "name": "津巴布韦",
          "flag": "🇿🇼",
          "code": "ZW",
          "dial_code": 263,
          "max_length": 9
        }
      ],
      showSnackbar:false,
      hintContent:'',
      showPicVerify: false,
      picVerifySuccess:false,
      wayOfLogin:'phone',
      password:'',
    }
  },
  computed:{
    phoneLoginClickable(){
      if(this.areaCode === '86'){
        return this.loginVerificationCode.length === 6 && this.loginPhoneNum.length === 11
      }else{
        return this.loginVerificationCode.length === 6 && this.loginPhoneNum.length > 0
      }
    },
    passwordLoginClickable(){
      if(this.areaCode === '86'){
        return this.password.length > 5 && this.loginPhoneNum.length === 11
      }else{
        return this.password.length > 5 && this.loginPhoneNum.length > 0
      }
    },
  },
  methods:{
    sendPhoneVerificationCode(){
      let formData = new FormData()
      formData.append('phone',this.loginPhoneNum)
      formData.append('prefix',"+" + this.areaCode)
      formData.append( 'purpose','loginOrRegister')
      this.$http.post('/userApi/sendCodeForAction',formData)
      this.clock = window.setInterval(() => {
        this.countDownNum--;
        this.sendCodeContent = "重新发送（"+this.countDownNum+')'
        if (this.countDownNum < 0) {
          //当倒计时小于0时清除定时器
          window.clearInterval(this.clock); //关闭
          this.sendingAvailable = true;
          this.countDownNum = 60;
          this.sendCodeContent = "发送验证码"
        }
      }, 1000);
    },
    async checkVerifyCode() {
      if(!this.phoneLoginClickable){
        if(this.areaCode === '86' && this.loginPhoneNum.length !== 11){
            this.hintContent = '请输入正确的手机号'
        }else if(this.areaCode !== '86' && this.loginPhoneNum.length < 1){
          this.hintContent = '请输入正确的手机号'
        } else if(this.loginVerificationCode.length !== 6){
          this.hintContent = '请输入正确的验证码'
        }
        this.showSnackbar = true
        setTimeout(()=>{
          this.showSnackbar = false
        },2000)
        return
      }

      let response = await this.$http.get('/userApi/checkCodeEarth',{params:{
         "captcha":this.loginVerificationCode,
          "phone":this.loginPhoneNum,
          "prefix": "+" + this.areaCode,
          "device": "phone",
          "purpose": "loginOrRegister",
          "site": "earth"
        }});
      if (response.data.code === 200) {
        await this.saveLoginInfo(response.data)
      }else if(response.data.code === 4007){
        this.hintContent = '验证码过期'
        this.showSnackbar = true
        setTimeout(()=>{
          this.showSnackbar = false
        },2000)
      }else{
        this.hintContent = '请输入正确的验证码'
        this.showSnackbar = true
        setTimeout(()=>{
          this.showSnackbar = false
        },2000)
      }
    },
    async loginByPassword() {
      let response = await this.$http.get('/userApi/loginEarth', {
        params: {
          "username": this.loginPhoneNum,
          "password": this.password,
          "device": "phone",
          "site": "earth"
        }
      });
      if (response.data.code === 200) {
        await this.saveLoginInfo(response.data)
      }else if(response.data.code === 4013){
        this.hintContent = '操作频繁，请1分钟后再试'
        this.showSnackbar = true
        setTimeout(()=>{
          this.showSnackbar = false
        },2000)
      }else if(response.data.code === 4005){
        this.hintContent = '用户未注册或设置密码,请使用短信验证码登录'
        this.showSnackbar = true
        setTimeout(()=>{
          this.showSnackbar = false
        },2000)
      }else{
        this.hintContent = '账号或密码不正确'
        this.showSnackbar = true
        setTimeout(()=>{
          this.showSnackbar = false
        },2000)
      }
    },
    async saveLoginInfo(response) {
      this.$store.commit('setToken', response.token)
      this.$store.commit('setPhone', response.data.phone)
      this.$store.commit('setUserId', response.data.userId)
      console.log(this.$store.state.token)
      let formData = new FormData()
      formData.append('userId', this.$store.state.userId)
      let res = await this.$http.post('/earthApi/data/getPersonalData', formData, {headers: {'Authorization': this.$store.state.token}})
      if (res.body.status === 200) {
        console.log(res.body.data)
        this.$store.commit('setNickName', res.body.data.nickName)
        this.$store.commit('setAvatar', res.body.data.picture)
        this.$store.commit('setMember', res.body.data.member.toString())
        this.goBack()
      }
    },
    goBack(){
      this.$router.go(-1)
    },
    showPicSliderForPhone(){
      if(!this.sendingAvailable){
        return
      }


    if(this.areaCode === '86' && this.loginPhoneNum.length !== 11){
      this.hintContent = '请输入正确的手机号'
      this.showSnackbar = true
      setTimeout(()=>{
        this.showSnackbar = false
      },2000)
      return
    }else if(this.areaCode !== '86' && this.loginPhoneNum.length < 1){
      this.hintContent = '请输入正确的手机号'
      this.showSnackbar = true
      setTimeout(()=>{
        this.showSnackbar = false
      },2000)
      return
    }




      this.sendingAvailable = false
      this.showPicVerify = true
    },
    showPicSliderForPsd(){
      if(this.password.length <= 5 || this.loginPhoneNum.length < 1){
        return
      }
      this.showPicVerify = true
    },
    // 用户通过了验证
    onPicVerifySuccess(msg) {
      this.showPicVerify = false;
      this.picVerifySuccess = true
      if(this.wayOfLogin === 'phone'){
        this.sendPhoneVerificationCode()
      }else{
        this.loginByPassword()
      }
      console.log(msg)
      // 通过验证后，需要手动隐藏模态框
    },
    // 用户点击遮罩层，应该关闭模态框
    closePicVerify() {
      this.showPicVerify = false;
    },
    clickHotCountries(index){
      this.areaCode = this.hotCountries[index].dial_code
      this.goBackLogin()
    },
    clickCountries(index){
      this.areaCode = this.countries[index].dial_code
      this.goBackLogin()
    },
    goBackLogin(){
      this.currentPage = 'login'
    },
    goCodeArea(){
      this.currentPage = 'areaCode'
    },
    goPrivacy(){
      this.$router.push(
          {
            path: '/PrivacyAndAgreement/privacy',
          }
      )
    },
    goUserAgreement(){
      this.$router.push(
          {
            path: '/PrivacyAndAgreement/agreement',
          }
      )
    }
  }
}
</script>

<style scoped>
  #login{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  #phoneCodeArea{
    width: calc(100% - 30px);
    height: 40px;
    margin-top: 30px;
    margin-left: 15px;
    margin-right: 15px;
  }

  #phoneNumInput{
    border: none;
    outline: none;
    line-height: 37px;
    margin-left: 9px;
    font-size: 14px;
    width: 60%
  }

  #verificationCodeInput{
    border: none;
    outline: none;
    line-height: 37px;
    margin-left: 9px;
    font-size: 14px;
    width: 80%;
  }

  input::-webkit-input-placeholder{
    color: rgba(151, 151, 151, 1);
    font-size: 14px;
  }

  .verticalDivider{
    width: 1px;
    height: 14px;
    margin-left: 14px;
    float: left;
    background: rgba(151, 151, 151, 1);
    margin-top: 12px
  }

  .hintText{
    font-size: 12px;
    color: rgba(151, 151, 151, 1);
    text-align: center;
  }

  #loginBtn{
    width: calc(100% - 30px);
    height: 36px;
    border-radius: 3px;
    background: rgba(131, 124, 194, 1);
    margin-left: 15px;
    margin-top: 16px;
    font-size: 14px;
    color: white;
    text-align: center;
    line-height: 36px
  }

  .snackBar{
    width: calc(100% - 30px);
    height: 36px;
    background:rgba(255, 47, 0, 1);
    font-size: 14px;
    color: white;
    text-align: center;
    line-height: 36px;
    border-radius: 3px;
  }
</style>