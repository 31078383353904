import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isAlumni:"",
    alumniHeatMapShare:false,
    FireAlarmShare:false,
    alumniMainTag:"",
    alumniVerifyLat:"",
    alumniVerifyLng:"",

    //快闪
    // 1:普通;2:分享;3:游客
    productType:1,
    products:{},
    productTitle:"",
    productsTypeList:[],
    productIndex:0,
    endDate:'',
    personalPicUrlList:[],
    productMainId:'',
    productUserName:'',
    updatePersonalPic:'',
    mainId:'',
    wishContent:'',
    areaCode:'86',
    nickName:'',
    phone:'',
    token:'',
    userId:'',
    avatar:'',
    member:'0',
    font:["Arial Unicode MS Regular","DIN Pro Regular"],
  },
  mutations: {
    alumniHeatMapShare(state,payload){
      state.alumniHeatMapShare = payload
    },
    isAlumni(state,payload){
      state.isAlumni = payload
    },
    alumniVerifyLat(state,payload){
      state.alumniVerifyLat = payload
    },    alumniVerifyLng(state,payload){
      state.alumniVerifyLng = payload
    },
    alumniMainTag(state,payload){
      state.alumniMainTag = payload
    },
    FireAlarmShare(state,payload){
      state.alumniHeatMapShare = payload
    },
    //快闪
    setEndDate(state,endDate) {
      state.endDate = endDate
    },
    setMainId(state,mainId) {
      state.mainId = mainId
    },
    setProductMainId(state,productMainId) {
      state.productMainId = productMainId
    },
    setProductIndex(state,productIndex) {
      state.productIndex = productIndex
    },
    setProductTitle(state,productTitle) {
      state.productTitle = productTitle
    },
    setProductsTypeList(state,productsTypeList) {
      state.productsTypeList = productsTypeList
    },
    setProductUserName(state,productUserName) {
      state.productUserName = productUserName
    },
    setLoginState(state,payLoad) {
      state.userId = payLoad.userId
      state.Id = payLoad.Id
      state.account = payLoad.account
      state.phoneNum = payLoad.phoneNum
      state.avatar = payLoad.avatar
      state.token = payLoad.token
    },
    setProductType(state,type) {
      state.productType = type
    },
    setWishContent(state,param) {
      state.wishContent = param
    },
    setProducts(state,products) {
      state.products = products
    },
    setPersonalPicUrlList(state,personalPicUrlList) {
      // Vue.set(state.personalPicUrlList,'b',personalPicUrlList)
      state.personalPicUrlList = personalPicUrlList
      console.log('Store中：'+personalPicUrlList)
    },
    setUpdatePersonalPic(state,updatePersonalPic) {
      state.personalPicUrlList = updatePersonalPic
    },
    setAreaCode(state,param) {
      state.areaCode = param
    },
    setNickName(state,param) {
      state.nickName = param
    },
    setPhone(state,param) {
      state.phone = param
    },
    setToken(state,param) {
      state.token = param
    },
    setUserId(state,param) {
      state.userId = param
    },
    setAvatar(state,param) {
      state.avatar = param
    },
    setMember(state,param) {
      state.member = param
    },
  },
  getters:{
    alumniHeatMapShare(state){
      return state.alumniHeatMapShare
    },    isAlumni(state){
      return state.isAlumni
    },
    alumniMainTag(state){
      return state.alumniMainTag
    },
    FireAlarmShare(state){
      return state.alumniHeatMapShare
    },    alumniVerifyLng(state){
      return state.alumniVerifyLng
    }, alumniVerifyLat(state){
      return state.alumniVerifyLat
    }
  },
  actions: {
    alumniHeatMapShare(state,payload){
      state.alumniHeatMapShare = payload
    },    isAlumni(state,payload){
      state.isAlumni = payload
    },
    alumniMainTag(state,payload){
      state.alumniMainTag = payload
    },
    FireAlarmShare(state,payload){
      state.alumniHeatMapShare = payload
    } ,   alumniVerifyLat(state,payload){
      state.alumniVerifyLat = payload
    } ,   alumniVerifyLng(state,payload){
      state.alumniVerifyLng = payload
    },
  },
  modules: {
  }
})
