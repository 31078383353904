<template>
    <div></div>
</template>

<script>
    import * as turf from "@turf/turf";

    export default {
        name: "HistogramMap",
        props:{
            featureCollection:{
                required: true // 点数据列表，需要geoJson: FutureCollection<Future<LineString|MultiLineString>>
            },
            map:{
                required: true // mapbox对象
            },
            breadth:{
                required: false, //宽度偏移量
                default:0.02,
            },
            colorList:{
                required: true,
            },
            dataMin:{
                required: true // 最小值
            },
            dataMax:{
                required: true // 最小值
            },
            layerOpacity:{
                required: true // 最小值
            },
        },
        data(){
            return{
                dataRange: 0,
                histogramList: {
                    'type': 'FeatureCollection',
                    'features': []
                },
                color:["interpolate",["linear"],["get", "pValue"]],
                opacity:this.layerOpacity,
            }
        },
        methods:{
            /**
             * 根据点数据生成矩形面数据
             * @param {点数据对象} geometry coordinates
             * @param {偏移量} offset number
             */
            createSquaredByPoint(coordinates,offset){
                let smx = coordinates[0];
                let smy = coordinates[1];
                let offset_int = parseFloat(offset);
                return [[smx,smy],[smx,smy + offset_int],[smx - offset_int,smy + offset_int],[smx - offset_int,smy],[smx,smy]];
            },
            initLayer(){
                //生成数据源
                this.featureCollection.features.forEach(item=>{
                    let array = this.createSquaredByPoint(item.geometry.coordinates,this.breadth);
                    this.histogramList.features.push({
                        "geometry":{
                            "coordinates":[
                                array
                            ],
                            "type":"Polygon"
                        },
                        "type":"Feature",
                        "properties":item.properties
                    })
                })
                this.map.addSource('histogramMap',{
                    'type': 'geojson',
                    'data': this.histogramList
                })
                this.map.addLayer({
                    'id': 'histogramMapLayer',
                    'type': 'fill-extrusion',
                    'source': 'histogramMap',
                    'paint': {
                        // Get the `fill-extrusion-color` from the source `color` property.
                        'fill-extrusion-color': this.color,
                        // Get `fill-extrusion-height` from the source `height` property.
                        'fill-extrusion-height': ['get', 'pValue'],
                        // Get `fill-extrusion-base` from the source `base_height` property.
                        'fill-extrusion-base': 0,
                        // Make extrusions slightly opaque to see through indoor walls.
                        'fill-extrusion-opacity': this.opacity
                    }
                })

                this.mapFitBoundary(this.histogramList)
            },

            mapFitBoundary(geo){
                const bbox = turf.bbox(geo)
                this.map.fitBounds([[bbox[0], bbox[1]], [bbox[2], bbox[3]]],{
                    linear: false,
                    animate: true,
                    // padding: {top: 120, bottom:130, left: 190, right: 500},
                })
            },

            removeLayer(){
                if(this.map.getLayer('histogramMapLayer'))
                    this.map.removeLayer('histogramMapLayer')
                if(this.map.getSource('histogramMap'))
                    this.map.removeSource('histogramMap')
            },

            reSetLayer(val){
                this.opacity = val
                this.map.setPaintProperty('histogramMapLayer', 'fill-extrusion-opacity', this.opacity);
            },
        },
        mounted() {
            if(this.dataMin !==this.dataMax && this.colorList.length >1){
                this.dataRange = parseFloat(((this.dataMax-this.dataMin)/(this.colorList.length-1)).toFixed(1));
                let temp= this.dataMin
                // this.color.push(this.dataMin)
                for(let i = 0;i <this.colorList.length;i++){
                    this.color.push(temp)
                    this.color.push(this.colorList[i].hexColor)
                    temp = temp+this.dataRange
                }
            }
            this.initLayer()
        },
        destroyed() {
            this.removeLayer()
        }
    }
</script>

<style scoped>

</style>
