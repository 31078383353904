<template>
  <!--卫星活动 抽奖     卫星活动类型 1002-->
  <div id="activityPageThree">
    <!--顶部下载栏-->
    <TopShareBar v-if="share"/>

    <div :style="{'background-image':'url(' + activityInfo.background + ')'}" class="activityPageThree-content">

      <!--旋转gif-->
      <div style="margin-top:252px;text-align:center;">
        <img :src="activityInfo.gifPicture" style="width:200px;height:200px;" />
      </div>

      <!--参与次数-->
      <div style="margin-top:37px;text-align:center;">
        <p style="color:#FFFFFF;font-size:14px;">今日还有{{activityInfo.drawChance}}次参与机会</p>
      </div>

      <!--抽奖卡片-->
      <div id="cardArea" style="position:relative;width:276px;height:145px;margin:35px auto 0 auto;display:flex;align-items: end;">
        <div @click="clickDrawLotCard(index)" class="card" v-for="(item,index) in activityInfo.lots" :key="'lot'+index" style="position:absolute;bottom:0;"
             :style="{'width':index === 1 ? '95px' : '73px','left':index === 0 ? '0' : index === 1 ? '91px' : '203px'}">
          <img :src="activityInfo.cardBackground" style="width:100%;" />
          <p :style="{'letter-spacing':platform === 'ios' ? '0' : '-5px','font-size':leftCardIndex === 0 ? index === 1 ? '20px' : '16px' : leftCardIndex === 1 ? index === 2 ? '20px' : '16px' : index === 0 ? '20px' : '16px' }"
             style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);font-family:YouSheBiaoTiHei;color:#E8D4AB;writing-mode:tb-rl;text-orientation: upright;">{{item}}</p>
        </div>
      </div>

      <!--抽签指针-->
      <div style="margin-top:2px;text-align:center;">
        <img src="../../../assets/Satellite/ActivityThree/cardPointer.png" style="width:12px;" />
      </div>
      <div style="margin-top:2px;text-align:center;">
        <p style="color:#FFFFFF;font-size:14px;">点击抽取新年福签</p>
      </div>


      <!--截止时间-->
      <div style="width:276px;margin:25px auto 0 auto;display:flex;">
        <p style="line-height:20px;font-family:YouSheBiaoTiHei;color:#7496D4;font-size:18px;">距离活动截止还剩</p>
        <div style="padding:0 4px;margin:0 4px;background:#7E99FF;border-radius:2px;">
          <p style="line-height:20px;font-size:16px;font-family:YouSheBiaoTiHei;color:#ffffff;">{{activityInfo.days}}</p>
        </div>
        <p style="line-height:20px;font-family:YouSheBiaoTiHei;color:#7496D4;font-size:18px;">天</p>
      </div>

      <!--活动说明-->
      <div style="width:calc(100% - 58px);background:rgba(255, 255, 255, 0.9);border-radius:12px;margin:78px auto 0 auto;padding:8px 17px 50px 17px;box-sizing: border-box;font-size:12px; color:#383838;">
        <p style="font-size:14px;line-height:35px;">活动说明</p>
        <p style="margin-bottom:8px;line-height:18px;">1.打开共生地球APP，参与卫星活动，每人每日可获得3次抽签机会；</p>
        <p style="margin-bottom:8px;line-height:18px;">2.活动时间{{activityInfo.drawStartTime !== undefined ? getFormatDate(activityInfo.drawStartTime) : ""}}-{{activityInfo.drawEndTime !== undefined ? getFormatDate(activityInfo.drawEndTime) : ""}}，活动截止时间{{activityInfo.drawEndTime !== undefined ? getFormatDate(activityInfo.drawEndTime) : ""}}24:00:00；</p>
        <p style="margin-bottom:8px;line-height:18px;">3.本活动需用户注册登陆后参与。</p>
        <p style="margin-top:35px;font-size:14px;line-height:35px;">温馨提示</p>
        <p style="margin-bottom:8px;line-height:18px;">1.活动期间请勿通过作弊手段牟取平台奖励，一旦发现立即取消其活动参与资格，并保留追究其责任权利；</p>
        <p style="margin-bottom:8px;line-height:18px;">2.卫星抽签活动技术服务由长光卫星技术股份有限公司提供；</p>
        <p style="margin-bottom:8px;line-height:18px;">3.本活动最终解释权归长光卫星技术股份有限公司所有；</p>
        <p style="margin-bottom:8px;line-height:18px;">4.用户参与本活动所需的APP均已更新到最新版本。</p>
      </div>

    </div>


  </div>

</template>

<script>
import TopShareBar from '@/components/TopShareBar';
import {getWxSignature} from "@/utils/shareConfig";
export default {
  name: "ActivityPageThree",
  components: {TopShareBar},
  data(){
    return{

      cardActivityId:"",   //url里的id，是卡片绑定的id 不是最新一期活动的

      activityInfo:{},

      //卡片轮播
      leftCardIndex:0,   //最左侧的卡片的index（通过activityInfo.lots循环的index）
      cardIntervalTemp:"",


      share:false,
      token:"",
      platform:"",


    }

  },
  computed:{
    getFormatDate(){
      return function(time){
        let formatDate = time.substr(0,10).split("-")[0]+"年"+parseInt(time.substr(0,10).split("-")[1])+"月"+parseInt(time.substr(0,10).split("-")[2])+"日";
        return formatDate;
      }
    },
  },
  methods:{
    getDetail(){

      this.$http.get('/earthApi/sa-draw-lucky-lots/getNewest', this.token === "" ? "" : {headers:{'Authorization': 'Bearer ' + this.token}}).then(response => {
        if(response.body.status === 200){
          console.log(response.body.data)
          if(response.body.data === null){
            //活动已结束
            this.$router.push(
                {
                  path: '/NotExist',
                }
            )
          }
          else{
            //活动正常
            this.activityInfo = response.body.data;

            setTimeout(()=>{
              this.changeCard();
            },100)


            document.title = this.activityInfo.title;

            if(window["flutter_getActivityDetail"]){
              window["flutter_getActivityDetail"].postMessage(JSON.stringify(this.activityInfo));
            }
          }
        }
        else if(response.body.status === 404){
          this.$router.push(
              {
                path: '/NotExist',
              }
          )
        }
        else{
          console.log(response.data.status + response.data.message)
        }



      })


    },


    //卡片轮播动效，再次开始卡片轮播动效
    changeCard(){

      let cardDom0 = document.getElementById('cardArea').querySelectorAll(".card")[0];
      let cardDom1 = document.getElementById('cardArea').querySelectorAll(".card")[1];
      let cardDom2 = document.getElementById('cardArea').querySelectorAll(".card")[2];

      let animateDuration = 100;

      if(this.cardIntervalTemp!== ''){
        clearInterval(this.cardIntervalTemp)
      }
      this.cardIntervalTemp = setInterval(()=>{
        if(this.leftCardIndex === 0){
          //cardDom0 移动到最右
          cardDom1.animate(
              [{
                left: '0',
                width: '73px'
              }],
              {
                duration: animateDuration,
                easing: 'linear',
                fill: 'forwards',
              }
          )
          cardDom2.animate(
              [{
                left: '91px',
                width: '95px'
              }],
              {
                duration: animateDuration,
                easing: 'linear',
                fill: 'forwards',
              }
          )
          cardDom0.animate(
              [{
                left: '203px',
                width: '73px'
              }],
              {
                duration: animateDuration,
                easing: 'linear',
                fill: 'forwards',
              }
          )

          setTimeout(()=>{
            this.leftCardIndex =  1;
          },animateDuration)


        }
        else if(this.leftCardIndex === 1){
          //cardDom1 移动到最右
          cardDom2.animate(
              [{
                left: '0',
                width: '73px'
              }],
              {
                duration: animateDuration,
                easing: 'linear',
                fill: 'forwards',
              }
          )
          cardDom0.animate(
              [{
                left: '91px',
                width: '95px'
              }],
              {
                duration: animateDuration,
                easing: 'linear',
                fill: 'forwards',
              }
          )
          cardDom1.animate(
              [{
                left: '203px',
                width: '73px'
              }],
              {
                duration: animateDuration,
                easing: 'linear',
                fill: 'forwards',
              }
          )
          setTimeout(()=>{
            this.leftCardIndex =  2;
          },animateDuration)

        }
        else if(this.leftCardIndex === 2){
          //cardDom2 移动到最右
          cardDom0.animate(
              [{
                left: '0',
                width: '73px'
              }],
              {
                duration: animateDuration,
                easing: 'linear',
                fill: 'forwards',
              }
          )
          cardDom1.animate(
              [{
                left: '91px',
                width: '95px'
              }],
              {
                duration: animateDuration,
                easing: 'linear',
                fill: 'forwards',
              }
          )
          cardDom2.animate(
              [{
                left: '203px',
                width: '73px'
              }],
              {
                duration: animateDuration,
                easing: 'linear',
                fill: 'forwards',
              }
          )
          setTimeout(()=>{
            this.leftCardIndex =  0;
          },animateDuration)
        }

      },4000)
    },



    //点击抽签
    clickDrawLotCard(cardIndex){
      console.log(cardIndex)

      if(this.share){
        //分享页 打开app
        this.openDownloadAppPage();
      }
      else{
        //进入抽奖
        let lotNumber = cardIndex + 1;
        if(window["flutter_beginDrawLot"]){
          window["flutter_beginDrawLot"].postMessage(lotNumber);
        }

      }

    },

    //改变剩余抽签次数，被flutter调用
    changeDrawChance(drawChance){
      this.activityInfo.drawChance = drawChance
    },


    //用户登录成功，被flutter调用
    loginSuccess(token){
      this.token = token;
      this.getDetail();
    },


    //分享页 点击任何区域均为打开app
    openDownloadAppPage(){
      window.location.href = '/OpenApp?infoId=' + this.activityInfo.activityId + '&type=1002'
    },

  },
  mounted(){
    window.satelliteActivityH5Three = this;
    this.cardActivityId = this.$route.query.infoId;
    if(this.$route.query.share == 1){
      this.share = true;
    }
    if(this.$route.query.token !== undefined){
      this.token = this.$route.query.token
      window.history.replaceState('', '', this.$route.fullPath.substring(0,this.$route.fullPath.indexOf("token") - 1))
    }
    if(this.$route.query.platform !== undefined){
      this.platform = this.$route.query.platform
    }
    getWxSignature(this.$route.query.infoId,"卫星活动")
    this.getDetail();

  }


}
</script>

<style scoped>
#activityPageThree{
  position:absolute;
  width:100%;
  height:100%;
  overflow:auto;
}

#activityPageThree p{
  margin:0;
}

.activityPageThree-content{
  position:relative;
  padding-bottom:40px;
  /*padding:0 14px 25px 14px;*/
  border-top: 1px solid transparent;
  background-size:100% 100%;
  /*background-position: center;*/
  background-repeat: no-repeat;
}


</style>