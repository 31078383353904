<template>
  <div id="campus_images">
    <l-map id="map" ref="map" style="z-index: 0" :options="option" >
      <l-tile-layer :url="infoUrl" :options="{tms:true}"/>
      <l-tile-layer v-show="campusOutlineShow" :url="layer" :options="{tms:true}" style="opacity: 1"/>
      <l-tile-layer v-show="campusOutlineShow" :url="schoolLayer" :options="{tms:true}" style="opacity: 1"/>
    </l-map>
    <div id="scanner" :style="scannerShow? 'transform: translateY(2000px);transition: transform 4s;':''"></div>
    <TopShareBar v-if="share === 1"/>
  </div>
</template>

<script>

import L from "leaflet";
import {LMap,LTileLayer,} from 'vue2-leaflet';
 import TopShareBar from '@/components/TopShareBar';
import {getWxSignature} from "@/utils/shareConfig";


export default {
  name: "CampusImage",
  components: {
    TopShareBar,
    LMap,
    LTileLayer,
  },
  mounted() {
    window.CampusImage = this
    this.infoId = this.$route.query.infoId
    this.share = this.$route.query.share
    if(this.$route.query.index !== null && this.$route.query.index !== undefined){
      this.index = this.$route.query.index
    }
    getWxSignature(this.infoId)
    this.load()
  },
  data() {
    return {
      index:0,
      infoUrl:this.globalJS.world1mUrl_2021,
      option: {zoomControl: false, attributionControl: false, renderer: L.canvas(),zoom:10},
      share: 0,
      infoId: '',
      detail: {},
      layer:'',
      schoolLayer:'',
      scannerShow:false,
      campusOutlineShow:false,
    }
  },
  methods: {
    load() {
      this.$http.get('/earthApi/main-info/getSchoolHistoryImageDetail?infoId=' + this.infoId).then(response => {
        if(response.data.status === 200){
          this.detail = response.body.data;
          if(window["CampusImageDetail"]){
            window["CampusImageDetail"].postMessage(JSON.stringify(this.detail));
          }
          if(this.$route.query.share === "1"){
            this.share = 1
          }else{
            this.share = 0
          }
          setTimeout(()=>{this.addLayer()}, 100)
        } else {
          this.$router.push(
              {
                path: '/NotExist',
              }
          )
        }
      })
    },
    addLayer(){
      this.layer = this.detail['citeSimpleDtoList'][this.index]['layerName']
      //设置最小zoom
      this.$refs.map.mapObject.fitBounds(L.geoJSON(JSON.parse(this.detail.maxgeo)).getBounds())
      this.zoom = this.$refs.map.mapObject.getZoom()
      this.$refs.map.mapObject.setMinZoom(this.zoom)
      //设置初始视角
      this.setBounds()
      this.$refs.map.mapObject.setMaxBounds(L.geoJSON(JSON.parse(this.detail.maxgeo)).getBounds())
      // this.zoom = this.$refs.map.mapObject.getZoom()
      // this.$refs.map.mapObject.setMinZoom(this.zoom)
      document.title = this.detail.title
      this.scanCampusOutline()
    },
    scanCampusOutline(){
      console.log('scanCampusOutline')
      this.scannerShow = true
      setTimeout(()=>{
        this.scannerShow = false;
        this.campusOutlineShow = true
        this.schoolLayer = this.detail['schoolGeo']
      },1200)
    },
    hideCampusOutline(){
      console.log('hideCampusOutline')
      this.scannerShow = false
      this.campusOutlineShow = false
      this.schoolLayer = ''
    },
    setBounds(){
      this.$refs.map.mapObject.fitBounds(L.geoJSON(JSON.parse(this.detail.geo)).getBounds())
    },
    changeLayer(index){
      this.index = parseInt(index)
      this.layer = this.detail['citeSimpleDtoList'][this.index]['layerName']
    },
    changeLayerAnimation(){
      this.index = (this.index + 1)%this.detail['citeSimpleDtoList'].length
      this.layer = this.detail['citeSimpleDtoList'][this.index]['layerName']
      if(window["updateIndex"]){
        window["updateIndex"].postMessage(this.index.toString());
      }
    },
  },
}

</script>
<style scoped lang="scss">

#campus_images {
  font-family: 微软雅黑;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}


.opacitySlide {
  position: absolute;
  z-index: 2;
  left: 24px;
  right: 24px;
  bottom: 160px;
  input[type=range] {
    width: 100%;
    background: white;
    height: 5px;
    border-radius: 3px;
    outline: none;
  }
}

#scanner{
  position: absolute;
  z-index: 100;
  left: 0;
  right: 0;
  top: -152px;
  height: 150px;
  background: linear-gradient(180deg,rgba(0,0,0,0.00), #99c0e0);
  border-bottom: #5aa6e4 solid 2px;
}

#map {
  width: 100%;
  height: calc(100vh);
  z-index: 1;
}

</style>
