<template>
  <!--卫星活动答题  海报页   卫星活动类型 1001-->
  <div id="activityPageTwo">

    <!--顶部下载栏-->
    <TopShareBar v-if="share"/>


    <div :style="{'background-image':'url(' + activityInfo.background + ')'}" class="activityPageTwo-content">
      <!--扫码区域-->
      <div style="position:absolute;top:217px;right:17px;float:right;">
        <div @click="scanCode" style="width:30px;height:30px;margin-right:8px;border-radius:50%;background:#FFC300;box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);text-align:center;float:right;">
          <p style="line-height:30px;font-size:14px;color:#ffffff;" class="iconfontFamily">&#xe774;</p>
        </div>
        <div style="position:relative;min-height:25px;margin-top:39px;padding:0 9px;background:#ffffff;border-radius:6px;box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);">
          <div style="position:absolute;top:-14px;right:17px;width:0;height:0;border-style: solid;border-width:7px 6px;border-color:transparent transparent #ffffff transparent;"></div>
          <p style="line-height:25px;font-size:12px;color:#383838;">扫图书条形码，兑换专属遥感壁纸</p>
        </div>
      </div>

      <!--标题区域-->
      <div style="position:relative;width:100%;margin-top:295px;">
        <img :src="activityInfo.rewardPicture" style="width:100%;" />
        <div style="position:absolute;left:16px;bottom:25px;display:flex;align-items: center;">
          <p style="font-family:YouSheBiaoTiHei;color:#FF8D1A;font-size:18px;">距离活动截止还剩</p>
          <div style="min-height:20px;padding:0 4px;margin:0 4px;background:#FCAF62;border-radius:2px;">
            <p style="line-height:20px;font-size:16px;font-family:YouSheBiaoTiHei;color:#ffffff;">{{activityInfo.days}}</p>
          </div>
          <p style="font-family:YouSheBiaoTiHei;color:#FF8D1A;font-size:18px;">天</p>
        </div>
      </div>

      <!--图书购买-->
      <div v-if="activityInfo.bookUrl!==''" style="height:20px;margin-top:5px;">
        <p @click="intoBuyBookPage" style="margin-right:7px;line-height:20px;color:#ffffff;font-size:14px;float:right;cursor:pointer;">{{activityInfo.bookText}}></p>
      </div>

      <!--开始答题-->
      <div @click="intoQuestionPage" style="width:232px;min-height:40px;margin:20px auto 0 auto;border-radius:20px;background:linear-gradient(217.29deg, #F7AC4F 0%, #E05E5E 100%);text-align:center;">
        <p style="line-height:40px;font-size:16px;color:#ffffff;">开始答题</p>
      </div>

      <!--按钮行-->
      <div style="min-width:180px;height:20px;margin:15px auto 0 auto;display:flex;justify-content:center;">
        <p @click="intoRankingListPage" style="line-height:20px;color:#ffffff;font-size:14px;">
          <span class="iconfontFamily">&#xe773;</span>
          排行榜
        </p>
        <p @click="intoAnswerRecordPage" style="margin-left:33px;line-height:20px;color:#ffffff;font-size:14px;">
          <span class="iconfontFamily">&#xe775;</span>
          答题记录
        </p>
      </div>

      <!--规则说明-->
      <div style="width:calc(100% - 30px - 34px);background:#ffffff;border-radius:12px;margin:30px auto 0 auto;padding:8px 17px;font-size:12px; color:#383838;">
        <p style="font-size:14px;line-height:35px;">活动说明</p>
        <p style="margin-bottom:8px;line-height:18px;">1.打开共生地球APP，参与卫星活动，每人每日可获得3次答题机会，答题过程中退出视为放弃本次答题机会，最终取答题最佳成绩进行排名；本次活动每人仅可领取一份奖品，活动结束后凭身份证件进行兑换；</p>
        <p style="margin-bottom:8px;line-height:18px;">2.答题时间{{activityInfo.answerStartTime !== undefined ? getFormatDate(activityInfo.answerStartTime) : ""}}-{{activityInfo.answerEndTime !== undefined ? getFormatDate(activityInfo.answerEndTime) : ""}}，答题截止时间{{activityInfo.answerEndTime !== undefined ? getFormatDate(activityInfo.answerEndTime) : ""}}24:00:00；</p>
        <p style="margin-bottom:8px;line-height:18px;">3.答题截止后，排行榜第<span v-for="(item,index) in activityInfo.rewardRank" :key="index">{{item.from}}<span v-show="item.from !== item.to">-{{item.to}}</span>名获得{{item.rankName}}；</span></p>
        <p style="margin-bottom:8px;line-height:18px;">4.工作人员将于活动截至后统计排行榜排名情况，并为获奖用户发送通知。</p>
        <p style="font-size:14px;line-height:35px;">温馨提示</p>
        <p style="margin-bottom:8px;line-height:18px;">1.获奖用户将于5个工作日内，在【共生地球APP】和短信形式收到工作人员私信。获奖用户请于5个工作日内填写地址及联系方式，过期自动作废；</p>
        <p style="margin-bottom:8px;line-height:18px;">2.活动期间请勿通过作弊手段牟取平台奖励，一旦发现立即取消其活动参与资格，并保留追究其责任权利；</p>
        <p style="margin-bottom:8px;line-height:18px;">3.本活动技术服务由长光卫星技术股份有限公司提供；</p>
        <p style="margin-bottom:8px;line-height:18px;">4.本活动最终解释权归长光卫星技术股份有限公司所有。</p>
      </div>



    </div>




  </div>

</template>

<script>
import TopShareBar from '@/components/TopShareBar';
import {getWxSignature} from "@/utils/shareConfig";
export default {
  name: "ActivityPageTwo",
  components: {TopShareBar},
  data(){
    return{

      cardActivityId:"",   //url里的id，是卡片绑定的id 不是最新一期活动的
      activityInfo:{},



      share:false,

      token:"",




    }

  },
  computed:{
    getFormatDate(){
      return function(time){
        let formatDate = time.substr(0,10).split("-")[0]+"年"+parseInt(time.substr(0,10).split("-")[1])+"月"+parseInt(time.substr(0,10).split("-")[2])+"日";
        return formatDate;
      }
    },
  },
  methods:{
    //获取活动详情
    getDetail(){
      this.$http.get('/earthApi/sa-book-answer/getNewest', this.token === "" ? "" : {headers:{'Authorization': 'Bearer ' + this.token}}).then(response => {
        if(response.body.status === 200){
          console.log(response.body.data)
          if(response.body.data === null){
            //活动已结束
            this.$router.push(
                {
                  path: '/NotExist',
                }
            )
          }
          else{
            //活动正常
            this.activityInfo = response.body.data;
            document.title = this.activityInfo.activityName;

            if(window["flutter_getActivityDetail"]){
              window["flutter_getActivityDetail"].postMessage(JSON.stringify(this.activityInfo));
            }
          }
        }
        else if(response.body.status === 404){
          this.$router.push(
              {
                path: '/NotExist',
              }
          )
        }
        else{
          console.log(response.data.status + response.data.message)
        }



      })

    },


    //扫条形码
    scanCode(){
      if(this.share){
        this.openDownloadAppPage();
      }
      else{
        if(window["flutter_scanCode"]){
          window["flutter_scanCode"].postMessage("");
        }
      }

    },

    //进入答题页
    intoQuestionPage(){
      if(this.share){
        this.openDownloadAppPage();
      }
      else{
        if(window["flutter_intoQuestionPage"]){
          window["flutter_intoQuestionPage"].postMessage("");
        }
      }

    },

    //进入排行榜
    intoRankingListPage(){
      if(this.share){
        this.openDownloadAppPage();
      }
      else{
        if(window["flutter_intoRankingListPage"]){
          window["flutter_intoRankingListPage"].postMessage("");
        }
      }
    },

    //进入排行榜
    intoAnswerRecordPage(){
      if(this.share){
        this.openDownloadAppPage();
      }
      else{
        if(window["flutter_intoAnswerRecordPage"]){
          window["flutter_intoAnswerRecordPage"].postMessage("");
        }
      }

    },

    //进入购买图书
    intoBuyBookPage(){
      if(this.share){
        window.open(this.activityInfo.bookUrl)
      }
      else{
        if(window["flutter_intoBuyBookPage"]){
          window["flutter_intoBuyBookPage"].postMessage("");
        }
      }

    },



    //用户登录成功，被flutter调用
    loginSuccess(token){
      this.token = token;
      this.getDetail();
    },


    //分享页 点击任何区域均为打开app
    openDownloadAppPage(){
      window.location.href = '/OpenApp?infoId=' + this.activityInfo.activityId + '&type=1001'
    },


  },
  mounted(){
    window.satelliteActivityH5Two = this;
    this.cardActivityId = this.$route.query.infoId;
    if(this.$route.query.share == 1){
      this.share = true;
    }
    if(this.$route.query.token !== undefined){
      this.token = this.$route.query.token
      window.history.replaceState('', '', this.$route.fullPath.substring(0,this.$route.fullPath.indexOf("token") - 1))


    }
    getWxSignature(this.$route.query.infoId,"卫星活动")

    this.getDetail();

  }




}
</script>

<style scoped>
#activityPageTwo{
  position:absolute;
  width:100%;
  height:100%;
  overflow:auto;
}

#activityPageTwo p{
  margin:0;
}

.activityPageTwo-content{
  position:relative;
  padding:0 14px 25px 14px;
  border-top: 1px solid transparent;
  background-size:100% 100%;
  /*background-position: center;*/
  background-repeat: no-repeat;
}

</style>