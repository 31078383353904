<template>

  <div ref="productPageApp">
    <div v-if="device==='web'" class="productPageApp">
      <!--顶栏-->
      <div style="position:fixed;top:0;left:0;width:calc(100% - 90px);height:80px;background:rgba(21, 21, 40, 0.45);padding:0 45px;display:flex;align-items:center;z-index:99;">
        <img @click="reloadPage" style="width:189px;cursor:pointer;" src="../../assets/App/productPage/app/logo.png" />
        <div style="flex:1;"></div>

        <p @click="gotoApp" style="font-size:14px;cursor:pointer;">首页</p>
        <p @click="gotoAboutUs" style="margin-left:63px;font-size:14px;cursor:pointer;" class="iconfontFamily">关于我们</p>
      </div>

      <!--第一屏-->
      <div id="productPageAppScreen1" style="display:flex;align-items:center;" class="screen">
        <!--底部渐变-->
        <div style="position:absolute;bottom:0;left:0;width:100%;height:113px;background:linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);"></div>

        <div :style="{'min-width':webScreenSize === 'big' ? '620px' : '500px'}" style="width:50%;">
          <div :style="{'width':webScreenSize === 'big' ? '560px' : '440px'}" style="position:relative;left:50%;transform:translate(-50%);height:100%;">
            <p style="text-align:left;" class="productPageAppColorTitle">吉林一号·共生地球APP</p>
            <p style="margin-top:39px;line-height:32px;" class="productPageAppPs">3D立体呈现地球脉动，卫星追踪全球热点<br/>遥感智能解译，服务数字时代，开启智慧生活</p>
            <div style="margin-top:100px;display:flex;">
              <div style="width:145px;">
                <div @click="gotoAppStore" style="width:100%;height:45px;border:1px solid rgba(138, 138, 138, 1);border-radius:4px;
              display:flex;align-items:center;cursor:pointer;">
                  <p style="margin-left:12px;font-size:26px;" class="iconfontFamily">&#xe78a;</p>
                  <p style="margin-left:14px;font-size:16px;">App Store</p>
                </div>
                <div @click="gotoAndroid" style="width:100%;height:45px;border:1px solid rgba(138, 138, 138, 1);border-radius:4px;margin-top:8px;
              display:flex;align-items:center;cursor:pointer;">
                  <p style="margin-left:12px;font-size:26px;" class="iconfontFamily">&#xe78c;</p>
                  <p style="margin-left:20px;font-size:16px;">Android</p>
                </div>
              </div>
              <img style="width:102px;height:102px;margin-left:17px;" src="../../assets/App/appCode.png" />
            </div>
          </div>
        </div>

        <div style="flex:1;height:100%;text-align:center;">
          <img :style="{'width':webScreenSize === 'big' ? '70%' : '93%'}" style="position:relative;left: 0;top:calc(100vh);transform:translate(0,-85%);z-index:1;"
               src="../../assets/App/productPage/app/screen1/mainPicture.png" />
        </div>

      </div>

      <!--第二屏-->
      <div id="productPageAppScreen2" class="screen">

        <div style="position:relative;top:55%;left:0;transform:translate(0,-50%);width:100%;">
          <p id="productPageAppScreen2_title" class="productPageAppColorTitle floatAnimationDom">玩转地球 不止遥感</p>
          <p id="productPageAppScreen2_ps" class="floatAnimationDom productPageAppPs" style="width:100%;margin-top:14px;text-align:center;">以吉林一号·共生地球服务生活</p>

          <div id="productPageAppScreen2_img" class="floatAnimationDom">
            <div style="width:740px;height:62px;margin:55px auto 0 auto;display:flex;justify-content:space-between;">
              <div @click="clickScreen2TabItem(index)" v-for="(item,index) in screen2List" :key="'screen2Item'+index"
                   :style="{'background':screen2CurrIndex === index ? 'rgba(42, 130, 228, 0.75)' : 'rgba(255, 255, 255, 0.11)'}"
                   style="width:157px;height:100%;border-radius:8px;text-align:center;cursor:pointer;">
                <p style="line-height:62px;font-size:18px;">{{item.title}}</p>
              </div>
            </div>
            <div id="screen2ImgContent" style="width:964px;height:385px;margin:55px auto 0 auto;">
              <!--3D世界 路线规划-->
              <img v-show="screen2CurrIndex === 2 || screen2CurrIndex === 3" style="width:100%;height:100%;border-radius:8px;" :src="screen2List[screen2CurrIndex].gif" />

              <!--智能解译-->
              <div @touchmove="screen2CompareMouseMoveFun" @mousemove="screen2CompareMouseMoveFun"
                   v-show="screen2CurrIndex === 0" style="position:relative;width:100%;height:100%;">
                <!--右侧下层 未识别图片-->
                <img style="position:absolute;top:0;left:0;width:100%;height:100%;border-radius:8px;" src="../../assets/App/productPage/app/screen2/compareImgRight.jpg">
                <!--左侧上层 识别图片-->
                <div id="screen2CompareLeftDiv">
                  <div style="width:160px;height:100%;background:linear-gradient(270deg, rgba(42, 130, 228, 0.51) 0%, rgba(42, 130, 228, 0) 100%);border-right:1px solid rgba(204, 204, 204, 1);float:right;pointer-events: none;"></div>
                </div>
              </div>

              <!--超清影像放大镜-->
              <vab-magnifier v-show="screen2CurrIndex === 1" id="vabMagnifier" :width="200" type="circle"
                             :url="require('../../assets/App/productPage/app/screen2/ultraClearImg.jpg')"
                             style="width:100%;height:100%;border-radius:8px;"></vab-magnifier>


            </div>
          </div>




        </div>

        <!--底部渐变-->
        <div style="position:absolute;bottom:0;left:0;width:100%;height:113px;background:linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);"></div>


      </div>

      <!--第三屏-->
      <div id="productPageAppScreen3" style="display:flex;flex-direction:column;align-items:center;overflow:hidden;" class="screen">
        <div style="flex:1;"></div>
        <p id="productPageAppScreen3_title" class="productPageAppColorTitle floatAnimationDom">玩转卫星 趣味互动</p>
        <p id="productPageAppScreen3_ps" class="floatAnimationDom productPageAppPs" style="width:100%;margin-top:14px;text-align:center;">把卫星装进口袋</p>

        <!--卫星模型-->
        <div style="width:calc(100% - 80px);height:63%;padding:0 40px;margin-top:70px;display:flex;align-items:end;justify-content:space-around;">

          <div class="screen3SatelliteImg" :style="{'min-width':webScreenSize === 'big' ? '166px' : '110px'}"
               style="position:relative;top:100%;width:11%;height:calc(100% - 172px);background:rgba(255, 255, 255, 0.09);border-radius:5.5vw 5.5vw 0 0;">
            <img style="width:73%;margin:60px auto 0 auto;display:block;" src="../../assets/App/productPage/app/screen3/satellite1.png" />
            <!--信息-->
            <div class="screen3SatelliteInfo" style="opacity:0;">
              <div style="position:absolute;top:-75px;left:-20px;width:50px;height:167px;border-left:1px dashed rgba(128, 128, 128, 1);border-bottom:1px dashed rgba(128, 128, 128, 1)"></div>
              <div style="position:absolute;top:-78px;left:-23px;width:6px;height:6px;border-radius:50%;background:#ffffff;"></div>
              <p :style="{'font-size':webScreenSize === 'big' ? '18px' : '14px'}" style="position:absolute;top:-110px;left:-55px;">宽幅01A星</p>
            </div>
          </div>
          <div class="screen3SatelliteImg" :style="{'min-width':webScreenSize === 'big' ? '166px' : '110px'}"
               style="position:relative;top:100%;width:11%;height:calc(100% - 81px);background:rgba(255, 255, 255, 0.09);border-radius:5.5vw 5.5vw 0 0;">
            <img style="width:73%;margin:60px auto 0 auto;display:block;" src="../../assets/App/productPage/app/screen3/satellite2.png" />
            <!--信息-->
            <div class="screen3SatelliteInfo" style="opacity:0;">
              <div style="position:absolute;top:-75px;left:-20px;width:50px;height:167px;border-left:1px dashed rgba(128, 128, 128, 1);border-bottom:1px dashed rgba(128, 128, 128, 1)"></div>
              <div style="position:absolute;top:-78px;left:-23px;width:6px;height:6px;border-radius:50%;background:#ffffff;"></div>
              <p :style="{'font-size':webScreenSize === 'big' ? '18px' : '14px'}" style="position:absolute;top:-110px;left:-46px;">光学A星</p>
            </div>
          </div>

          <!--中心手机+卫星-->
          <div style="position:relative;width:22%;min-width:277px;height:100%;">
            <img id="screen3Phone" style="position:absolute;top:0;left:0;width:100%;opacity:0;" src="../../assets/App/productPage/app/screen3/phone.png" />
            <img id="productPageAppScreen3_img" class="floatAnimationDom"
                 style="width:100%;margin:0 auto 0 auto;display:block;" src="../../assets/App/productPage/app/screen3/mainPicture.png" />
          </div>

          <div class="screen3SatelliteImg" :style="{'min-width':webScreenSize === 'big' ? '166px' : '110px'}"
               style="position:relative;top:100%;width:11%;height:calc(100% - 81px);background:rgba(255, 255, 255, 0.09);border-radius:5.5vw 5.5vw 0 0;">
            <img style="width:73%;margin:60px auto 0 auto;display:block;" src="../../assets/App/productPage/app/screen3/satellite3.png" />
            <!--信息-->
            <div class="screen3SatelliteInfo" style="opacity:0;">
              <div style="position:absolute;top:-75px;right:-20px;width:50px;height:167px;border-right:1px dashed rgba(128, 128, 128, 1);border-bottom:1px dashed rgba(128, 128, 128, 1)"></div>
              <div style="position:absolute;top:-78px;right:-23px;width:6px;height:6px;border-radius:50%;background:#ffffff;"></div>
              <p :style="{'font-size':webScreenSize === 'big' ? '18px' : '14px'}" style="position:absolute;top:-110px;right:-58px;">MF02A01星</p>
            </div>
          </div>
          <div class="screen3SatelliteImg" :style="{'min-width':webScreenSize === 'big' ? '166px' : '110px'}"
               style="position:relative;top:100%;width:11%;height:calc(100% - 172px);background:rgba(255, 255, 255, 0.09);border-radius:5.5vw 5.5vw 0 0;">
            <img style="width:73%;margin:60px auto 0 auto;display:block;" src="../../assets/App/productPage/app/screen3/satellite4.png" />
            <!--信息-->
            <div class="screen3SatelliteInfo" style="opacity:0;">
              <div style="position:absolute;top:-75px;right:-20px;width:50px;height:167px;border-right:1px dashed rgba(128, 128, 128, 1);border-bottom:1px dashed rgba(128, 128, 128, 1)"></div>
              <div style="position:absolute;top:-78px;right:-23px;width:6px;height:6px;border-radius:50%;background:#ffffff;"></div>
              <p :style="{'font-size':webScreenSize === 'big' ? '18px' : '14px'}" style="position:absolute;top:-110px;right:-49px;">高分04A星</p>
            </div>
          </div>

          <!--底部渐变-->
          <div style="position:absolute;bottom:0;left:0;width:100%;height:113px;background:linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);"></div>


        </div>

      </div>


      <!--第四屏-->
      <div id="productPageAppScreen4" style="display:flex;flex-direction:column;" class="screen">

        <div style="flex:1;">
          <div style="position:relative;top:60%;left:0;transform:translate(0,-50%);width:100%;display:flex;flex-direction:column;align-items:center;">
            <!--标题-->
            <p :style="{'font-size':webScreenSize === 'big' ? '60px' : '38px'}" style="line-height:80px;" class="productPageAppColorTitle">开启吉林一号·共生地球APP探索之旅</p>
            <!--按钮-->
            <div style="margin-top:59px;display:flex;align-items:center;">
              <div @click="gotoAppStore" style="width:135px;height:42px;border:1px solid rgba(138, 138, 138, 1);border-radius:4px;display:flex;align-items:center;cursor:pointer;">
                <p style="margin-left:12px;font-size:26px;" class="iconfontFamily">&#xe78a;</p>
                <p style="margin-left:8px;font-size:16px;">App Store</p>
              </div>
              <div @click="gotoAndroid" style="width:135px;height:42px;border:1px solid rgba(138, 138, 138, 1);border-radius:4px;margin-left:17px;display:flex;align-items:center;cursor:pointer;">
                <p style="margin-left:14px;font-size:26px;" class="iconfontFamily">&#xe78c;</p>
                <p style="margin-left:14px;font-size:16px;">Android</p>
              </div>
            </div>
          </div>
        </div>

        <!--底栏-->
        <div style="width:100%;height:364px;">
          <app-page-foot-bar :produceList="footBarProductList"></app-page-foot-bar>

        </div>

        <!--顶部渐变-->
        <div style="position:absolute;top:0;left:0;width:100%;height:113px;background:linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);"></div>


      </div>



    </div>

    <div v-if="device==='mobile'" style="padding-top:80px;" class="productPageApp">
      <!--顶栏-->
      <div style="position:fixed;top:0;left:0;width:calc(100% - 30px);height:80px;background:rgba(21, 21, 40, 0.45);padding:0 15px;display:flex;align-items:center;z-index:99;">
        <img @click="reloadPage" style="width:159px;" src="../../assets/App/productPage/app/logo.png" />
        <div style="flex:1;"></div>

        <p @click="gotoApp" style="font-size:14px;">首页</p>
        <p @click="gotoAboutUs" style="margin-left:27px;margin-right:5px;font-size:14px;" class="iconfontFamily">关于我们</p>
      </div>

      <!--第一屏-->
      <div id="productPageAppScreen1_mobile">
        <!--背景图-->
        <div id="productPageAppScreen1_mobile_background">
          <!--底部渐变-->
          <div style="position:absolute;bottom:0;left:0;width:100%;height:120px;background:linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);"></div>
        </div>
        <!--内容-->
        <div style="position:relative;overflow:hidden;">
          <div style="width:calc(100% - 40px);margin:0 auto;padding-top:30px;">
            <p :style="{'font-size':mobileScreenSize === 'small' ? '28px' : '46px'}" class="productPageAppColorTitle_mobile">吉林一号·共生地球APP</p>
            <p style="margin-top:20px;" class="productPageAppPs_mobile">3D立体呈现地球脉动，卫星追踪全球热点</p>
            <p class="productPageAppPs_mobile">遥感智能解译，服务数字时代，开启智慧生活</p>
          </div>
          <div style="width:100%;overflow:hidden;">
            <img :style="{'max-width':mobileScreenSize === 'small' ? '400px' : '450px',
            'float':mobileScreenSize === 'small' ? 'right' : '',left:mobileScreenSize === 'small' ? '0' : '45px',
            'margin':mobileScreenSize === 'small' ? '64px 5% 0 0' : '64px auto 0 auto'}"
                 style="position:relative;width:calc(100% - 75px);margin:64px 5% 0 0;float:right;display:block;" src="../../assets/App/productPage/app/screen1/mainPicture.png" />

          </div>

        </div>
      </div>

      <!--第二屏-->
      <div id="productPageAppScreen2_mobile">
        <div style="width:100%;padding-top:190px;text-align:center;">
          <p id="productPageAppScreen2_title" class="productPageAppColorTitle_mobile floatAnimationDom">玩转地球 不止遥感</p>
          <p id="productPageAppScreen2_ps" style="margin-top:20px;" class="productPageAppPs_mobile floatAnimationDom">以吉林一号·共生地球服务生活</p>
          <div id="productPageAppScreen2_img" class="floatAnimationDom">
            <div :style="{'height':mobileScreenSize === 'small' ? '37px' : '44px'}" style="margin-top:29px;display:flex;justify-content:center;">
              <div @click="clickScreen2TabItem_mobile(index)" v-for="(item,index) in screen2List" :key="'screen2Item'+index"
                   :style="{'background':screen2CurrIndex === index ? 'rgba(42, 130, 228, 0.75)' : 'rgba(255, 255, 255, 0.11)',
                   'width':mobileScreenSize === 'small' ? '82px' : '110px'}"
                   style="width:82px;height:100%;margin:0 5px;border-radius:6px;text-align:center;">
                <p :style="{'line-height':mobileScreenSize === 'small' ? '37px' : '44px','font-size':mobileScreenSize === 'small' ? '14px' : '16px'}">{{item.title}}</p>
              </div>
            </div>
<!--            height:223px;-->
            <img style="width:100%;margin-top:35px;" :src="screen2List[screen2CurrIndex].mobileGif" />
          </div>
        </div>
      </div>

      <!--第三屏-->
      <div id="productPageAppScreen3_mobile">
        <div style="width:100%;padding-top:177px;text-align:center;">
          <p id="productPageAppScreen3_title" class="productPageAppColorTitle_mobile floatAnimationDom">玩转卫星 趣味互动</p>
          <p id="productPageAppScreen3_ps" style="margin-top:20px;" class="productPageAppPs_mobile floatAnimationDom">把卫星装进口袋</p>

          <!--中心手机+卫星-->
          <div style="position:relative;width:300px;height:396px;margin:34px auto 0 auto;">
            <img id="screen3Phone_mobile" class="floatAnimationDom" style="position:absolute;top:0;left:0;width:100%;" src="../../assets/App/productPage/app/screen3/phone.png" />
            <img id="screen3Satellite_mobile" style="position:relative;width:280px;margin:0 auto 0 auto;display:block;animation-fill-mode:forwards;opacity:0;" src="../../assets/App/productPage/app/screen3/mainPicture.png" />
          </div>
        </div>



      </div>

      <!--第三屏-->
      <div id="productPageAppScreen4_mobile">

        <div style="width:100%;margin:0 auto;padding-top:125px;padding-bottom:200px;text-align:center;">
          <p :style="{'font-size':mobileScreenSize === 'small' ? '20px' : '36px'}" class="productPageAppColorTitle_mobile">开启吉林一号·共生地球APP探索之旅</p>
          <!--按钮-->
          <div style="width:208px;margin:30px auto 0 auto;display:flex;align-items:center;">
            <div @click="gotoAppStore" style="width:96px;height:30px;border:1px solid rgba(138, 138, 138, 1);border-radius:4px;display:flex;align-items:center;">
              <p style="margin-left:8px;font-size:18px;" class="iconfontFamily">&#xe78a;</p>
              <p style="margin-left:8px;font-size:11px;">App Store</p>
            </div>
            <div @click="gotoAndroid" style="width:96px;height:30px;border:1px solid rgba(138, 138, 138, 1);border-radius:4px;margin-left:17px;display:flex;align-items:center;">
              <p style="margin-left:8px;font-size:18px;" class="iconfontFamily">&#xe78c;</p>
              <p style="margin-left:13px;font-size:11px;">Android</p>
            </div>
          </div>
        </div>

        <!--上屏底部渐变-->
        <div style="position:absolute;top:-63px;left:0;width:100%;height:63px;background:linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);"></div>

        <!--顶部渐变-->
        <div style="position:absolute;top:0;left:0;width:100%;height:63px;background:linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);"></div>

        <!--底部渐变-->
        <div style="position:absolute;bottom:0;left:0;width:100%;height:120px;background:linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);"></div>

      </div>

      <!--底栏-->
      <div style="position:relative;width:100%;margin-top:-100px;">
        <div style="position:absolute;bottom:0;left:0;width:100%;height:calc(100% - 100px);background:#000000;"></div>
        <app-page-foot-bar-mobile :produceList="footBarProductList" style="position:relative;"></app-page-foot-bar-mobile>
      </div>


    </div>

  </div>




</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import VabMagnifier from 'vab-magnifier';
import 'vab-magnifier/lib/vab-magnifier.css'

import AppPageFootBar from "@/views/AppPage/AppPageFootBar"
import AppPageFootBarMobile from "@/views/AppPage/AppPageFootBar_mobile"


export default {
  name: "ProductPageApp",
  components: {
    VabMagnifier,AppPageFootBar,AppPageFootBarMobile
  },
  data(){
    return{
      device:"web",
      webScreenSize:"big",
      mobileScreenSize:"small",

      screen2List:[
        {
          title:"智能解译",
          mobileGif:require('../../assets/App/productPage/app/screen2/gifCompare.gif')
        },
        {
          title:"超清影像",
          mobileGif:require('../../assets/App/productPage/app/screen2/gifUltraClearImg.gif')
        },
        {
          title:"3D世界",
          gif:require('../../assets/App/productPage/app/screen2/gif3D.gif'),
          mobileGif:require('../../assets/App/productPage/app/screen2/gif3D.gif'),
        },
        {
          title:"路线规划",
          gif:require('../../assets/App/productPage/app/screen2/gifRoute.gif'),
          mobileGif:require('../../assets/App/productPage/app/screen2/gifRoute.gif'),
        }


      ],
      screen2CurrIndex:0,


      //底栏项目列表
      footBarProductList:[
        {
          title:"吉林一号·共生地球web端",
          href:"/ProductPageWebMarker"
        },
        {
          title:"吉林一号·遥感影像库",
          href:"/ProductPageImageLib"
        },


      ],

    }

  },



  methods:{
    //顶bar 刷新页面
    reloadPage(){
      window.location.reload();
    },

    //第二屏 对比 鼠标移动对比效果
    screen2CompareMouseMoveFun(e){
      if(this.webScreenSize === 'big'){
        //mousemove事件
        if(e.offsetX >=0 && e.offsetY >=0){
          document.getElementById("screen2CompareLeftDiv").style.width = e.offsetX + 'px';
        }
      }
      else{
        //touchmove事件
        if(e.targetTouches !== undefined && e.targetTouches.length === 1){
          //单指移动事件
          // let imgTop = document.getElementById("screen2ImgContent").getBoundingClientRect().top;
          let imgLeft = document.getElementById("screen2ImgContent").getBoundingClientRect().left;
          if(e.targetTouches[0].clientX - imgLeft >= 1 && e.targetTouches[0].clientX - imgLeft <= 962){
            document.getElementById("screen2CompareLeftDiv").style.width = e.targetTouches[0].clientX - imgLeft + 'px';
          }
        }
      }
    },
    //点击第二屏tab切换
    clickScreen2TabItem(index){
      this.screen2CurrIndex = index;
      document.getElementById("screen2ImgContent").style.animationName = "opacityAnimation";
      document.getElementById("screen2ImgContent").style.animationDuration = "1.5s";
      setTimeout(()=>{
        document.getElementById("screen2ImgContent").style.animationName = "";
      },1500)
    },


    //标题增加渐变颜色效果(直接写在css里不生效)
    productPageAppColorTitle(){
      let titleList = document.querySelectorAll(".productPageAppColorTitle");
      for(let i = 0;i<titleList.length;i++) {
        titleList[i].style["-webkit-background-clip"] = 'text';
      }
    },


    //跳转首页
    gotoApp(){
      this.$router.push(
          {
            path: '/',
          }
      );
    },
    //跳转关于我们
    gotoAboutUs(){
      this.$router.push(
          {
            path: '/AboutUs',
          }
      );
    },

    //跳转appstore
    gotoAppStore(){
      let u = window.navigator.userAgent;
      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android终端或者uc浏览器
      let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if(isAndroid){
        window.open("https://apps.apple.com/cn/app/%E5%85%B1%E7%94%9F%E5%9C%B0%E7%90%83/id1628699115", '_blank');
      }
      if(isiOS){
        this.ios();
      }
      if(!isAndroid && !isiOS){
        //web
        window.open("https://apps.apple.com/cn/app/%E5%85%B1%E7%94%9F%E5%9C%B0%E7%90%83/id1628699115", '_blank');
      }
    },
    ios(){
      let _clickTime = +(new Date());
      let ifr = document.createElement("iframe");
      ifr.src = 'charmingearth://earth.jl1.cn';
      ifr.style.display = "none";
      document.body.appendChild(ifr);
      //启动间隔20ms运⾏的定时器，并检测累计消耗时间是否超过3000ms，超过则结束
      let _count = 0, intHandle;
      intHandle = setInterval(function(){
        _count++;
        let elsTime =  +(new Date()) - _clickTime;
        console.log(_count,elsTime,+(new Date()),_clickTime)
        if (_count>=100 || elsTime > 3000 ) {
          clearInterval(intHandle);
          document.body.removeChild(ifr);
          //检查app是否打开
          if ( document.hidden || document.webkitHidden) {
            // 打开了
            window.close();
          } else {
            // 没打开
            // alert('没打开')
            window.location.href = "https://apps.apple.com/cn/app/id1628699115";//下载链接
          }
        }
      },20);
    },

    //跳转android
    gotoAndroid(){
      window.open("https://sj.qq.com/appdetail/com.charmingglobe.charmingearth", '_blank');
    },



    //随鼠标滚动进行的动效
    animation(){

      ScrollTrigger.create({
        trigger:'#productPageAppScreen3',
        start:'top top',
        end:'+=1000',
        markers:false,//不显示标注
        pin:true,//定位元素
        scrub:true,//擦除
        animation:
            gsap.timeline()
                .fromTo('#screen3Phone',{opacity:'0'},{opacity:'1'},"<")
                .fromTo('#productPageAppScreen3_img',{width:'100%','margin-top':"0"},{width:'60%','margin-top':"97px"},"<")
                .fromTo('.screen3SatelliteImg',{top:'100%'},{top:'0'},"<")
                .fromTo('#productPageAppScreen3_title',{top:'100px',opacity:0},{top:'0',opacity:1},"<")
                .fromTo('#productPageAppScreen3_ps',{top:'100px',opacity:0},{top:'0',opacity:1},"<")

                .fromTo('.screen3SatelliteInfo',{opacity:'0'},{opacity:'1'},"+")

      })

    },

    //鼠标滚动位置相关的动效
    listenerScrollFun(){
      const scrollTop = window.scrollY || window.pageYOffset;

      //第二屏
      if(scrollTop >= window.innerHeight * 0.5){
        this.titleAndPsAndImgFloatAnimation("productPageAppScreen2_title","productPageAppScreen2_ps","productPageAppScreen2_img");
      }
      //第三屏
      if(scrollTop >= window.innerHeight * 1.5 + 100){
        document.getElementById("productPageAppScreen3_img").style.animationName = "floatAnimation";
        document.getElementById("productPageAppScreen3_img").style.animationDuration = "2s";
      }


    },


    //标题+详情+图片 三者上浮动画
    titleAndPsAndImgFloatAnimation(titleDomId,psDomId,imgDomId){
      document.getElementById(titleDomId).style.animationName = "floatAnimation";
      document.getElementById(titleDomId).style.animationDuration = "1.5s";

      document.getElementById(psDomId).style.animationName = "floatAnimation";
      document.getElementById(psDomId).style.animationDuration = "2s";

      document.getElementById(imgDomId).style.animationName = "floatAnimation";
      document.getElementById(imgDomId).style.animationDuration = "2s";
    },






    /**移动端*******************************************************************************************/
    //点击第二屏tab切换
    clickScreen2TabItem_mobile(index){
      this.screen2CurrIndex = index;
    },


    //标题增加渐变颜色效果(直接写在css里不生效)
    productPageAppColorTitle_mobile(){
      let titleList = document.querySelectorAll(".productPageAppColorTitle_mobile");
      for(let i = 0;i<titleList.length;i++) {
        titleList[i].style["-webkit-background-clip"] = 'text';
      }
    },


    //鼠标滚动位置相关的动效
    listenerScrollFun_mobile(){
      let screen1Height = document.getElementById("productPageAppScreen1_mobile").offsetHeight;
      let screen2Height = document.getElementById("productPageAppScreen2_mobile").offsetHeight;

      const scrollTop = window.scrollY || window.pageYOffset;

      //第二屏
      if(scrollTop >= screen1Height * 0.5){
        this.titleAndPsAndImgFloatAnimation("productPageAppScreen2_title","productPageAppScreen2_ps","productPageAppScreen2_img");
      }
      //第三屏
      if(scrollTop >= screen1Height + screen2Height * 0.5){
        this.titleAndPsAndImgFloatAnimation("productPageAppScreen3_title","productPageAppScreen3_ps","screen3Phone_mobile");
        document.getElementById("screen3Satellite_mobile").style.animationName = "screen3SatelliteAnimation_mobile";
        document.getElementById("screen3Satellite_mobile").style.animationDuration = "2s";
      }
    },



  },

  mounted(){
    setTimeout(()=>{
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    },100)

    if(window.innerWidth >= 1000) {
      //web端
      this.device = "web";
      //适配web尺寸
      if(window.innerWidth < 1330){
        this.webScreenSize = "small";
        this.$refs.productPageApp.style.setProperty('--web-title-font-size', "40px");
        this.$refs.productPageApp.style.setProperty('--web-ps-font-size', "20px");
        this.$refs.productPageApp.style.setProperty('--web-ps-line-height', "30px");
      }
      else{
        this.$refs.productPageApp.style.setProperty('--web-title-font-size', "48px");
        this.$refs.productPageApp.style.setProperty('--web-ps-font-size', "24px");
        this.$refs.productPageApp.style.setProperty('--web-ps-line-height', "46px");
      }

      setTimeout(()=>{
        window.addEventListener('scroll', this.listenerScrollFun);
      },100)


      this.productPageAppColorTitle();

      this.animation();

    }
    else{
      //手机
      this.device = "mobile";

      //适配手机尺寸
      if(window.innerWidth > 650){
        this.mobileScreenSize = "big";
        console.log(this.mobileScreenSize)
        this.$refs.productPageApp.style.setProperty('--mobile-title-font-size', "40px");
        this.$refs.productPageApp.style.setProperty('--mobile-ps-font-size', "20px");
        this.$refs.productPageApp.style.setProperty('--mobile-ps-line-height', "30px");
      }
      else{
        this.$refs.productPageApp.style.setProperty('--mobile-title-font-size', "24px");
        this.$refs.productPageApp.style.setProperty('--mobile-ps-font-size', "16px");
        this.$refs.productPageApp.style.setProperty('--mobile-ps-line-height', "24px");
      }

      //device默认是web，手机端需要延时
      setTimeout(()=>{
        this.productPageAppColorTitle_mobile();
      },0)

      setTimeout(()=>{
        window.addEventListener('scroll', this.listenerScrollFun_mobile);
      },100)

    }


  },
  destroyed() {
    if(window.innerWidth >= 1000){
      window.removeEventListener('scroll', this.listenerScrollFun);
    }
    else{
      window.removeEventListener('scroll', this.listenerScrollFun_mobile);
    }
  }


}
</script>

<style scoped>

.productPageApp{
  background:#000000;
  color:#ffffff;
  overflow:hidden;
  font-family:HarmonyOSSansSCRegular;
}
.productPageApp p{
  margin:0;
}



.screen{
  width: 100%;
  height: 100vh;
}

#productPageAppScreen1{
  background-image:url('../../assets/App/productPage/app/screen1/background.jpg');
  background-size:100% 100%;
  position:relative;
}
#productPageAppScreen2{
  background-image:url('../../assets/App/productPage/app/screen2/background.jpg');
  background-size:100% 100%;
  position:relative;
  margin-top:100px;
}

#screen2CompareLeftDiv{
  position:absolute;
  top:0;
  left:0;
  width:60%;
  height:100%;
  border-radius:8px 0 0 8px;
  background-image:url(../../assets/App/productPage/app/screen2/compareImgLeft.jpg);
  background-size: cover;
  overflow:hidden;
}


#productPageAppScreen3{
  background-image:url('../../assets/App/productPage/app/screen3/background.jpg');
  background-size:100% 100%;
}

#productPageAppScreen4{
  background-image:url('../../assets/App/productPage/app/screen4/background.jpg');
  background-size:100% 100%;
  position:relative;
}



.productPageAppColorTitle{
  width:100%;
  line-height:48px;
  /*font-size:50px;*/
  font-size:var(--web-title-font-size);
  font-weight:bold;
  text-align:center;
  color:#ffffff;
  -webkit-text-fill-color: transparent;
  background:linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(159, 160, 167, 1) 100%);
}
.productPageAppPs{
  font-size:var(--web-ps-font-size);
  line-height:var(--web-ps-line-height);
}

/*上浮动画的元素*/
.floatAnimationDom{
  position:relative;
  top:100px;
  opacity:0;
  animation-fill-mode: forwards;
}




/**移动*****************************************************************************************************/

#productPageAppScreen1_mobile{
  position:relative;
  width:100%;
}
#productPageAppScreen1_mobile_background{
  position:absolute;
  top:0;
  left:0;
  width:100%;height:474px;
  background-image:url('../../assets/App/productPage/app/screen1/background.jpg');
  background-size:100% 100%;
}

#productPageAppScreen2_mobile{
  background-image:url('../../assets/App/productPage/app/screen2/background.jpg');
  background-size:100% calc(100% - 20px);
  position:relative;
  width:100%;
}
#productPageAppScreen3_mobile{
  background-image:url('../../assets/App/productPage/app/screen3/background.jpg');
  background-size:100% 100%;
  width:100%;
  height:850px;
}

#productPageAppScreen4_mobile{
  background-image:url('../../assets/App/productPage/app/screen4/background.jpg');
  background-size:420% 350%;
  background-position:center -200px;
  position:relative;
  width:100%;
  margin-top:-193px;
}





.productPageAppColorTitle_mobile{
  font-size:var(--mobile-title-font-size);
  font-weight:bold;
  color:#ffffff;
  -webkit-text-fill-color: transparent;
  background:linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(159, 160, 167, 1) 100%);
}
.productPageAppPs_mobile{
  font-size:var(--mobile-ps-font-size);
  line-height:var(--mobile-ps-line-height);
}




</style>

<style>

/*上浮动画*/
@keyframes floatAnimation {
  from {top:100px;opacity:0;}
  to {top:0px;opacity:1;}
}

/*渐变动画*/
@keyframes opacityAnimation {
  from {opacity:0;}
  to {opacity:1;}
}


/*放大镜*/
#vabMagnifier .img-zoomer{
  cursor:none;
}
#vabMagnifier img{
  border-radius:8px;
}



/**移动*****************************************************************************************************/
@keyframes screen3SatelliteAnimation_mobile{
  from {width:280px;top:0;opacity:0;}
  to {width:166px;top:97px;opacity:1;}
}

</style>