<!--<template>-->

<template>
  <div class="choosePositionGlobe">
    <div id="map" style="background-color: black"/>
  </div>
</template>

<script>

import mapboxgl from "mapbox-gl";
import tipsPng from '@/assets/ChoosePositionGlobe/tips.png'

export default {
  name: "ChoosePositionGlobe",
  data() {
    return {
      map: '',
      index: 0,
      share:0,
      layerList:[],
      layerInfo:{},
      baseStyleInfo:{}
    }
  },
  mounted() {
    window.choosePositionGlobe = this;
    if (this.$route.query.share != null) {
      this.share = this.$route.query.share
    }
    if(this.$route.query.layer != null){
      this.getGlobeLayerInfo()
    }
  },
  methods: {
    getGlobeLayerInfo(){
      this.$http.post('/earthApi/main-info/getLayerList_v1_1_13').then(response=> {
        if(response.data.status === 200){
          this.layerList = response.body.data[0].layers
          for(let i = 0;i < this.layerList.length;i++){
            if(this.$route.query.layer === this.layerList[i].layer){
              this.layerInfo = this.layerList[i];
            }
          }
          this.getBaseStyleInfo()
        }
      })
    },
    async getBaseStyleInfo() {
      let response = await this.$http.get('/earthApi/main-info/getBaseStyleInfo');
      if(response.data.status === 200){
        this.baseStyleInfo = response.data.data;
      }
      this.init();
    },
    init() {
      mapboxgl.accessToken = this.baseStyleInfo["token"];
      // mapboxgl.accessToken = this.globalJS.mapboxToken;
      this.map = new mapboxgl.Map({
        container: 'map',
        center:[100, 31],
        zoom: 1,
        maxZoom: 17,
        minZoom: 0.8,
        attributionControl: false,
        style: this.baseStyleInfo.styleUrl,
        // interactive: false,
        trackResize: false,
        projection: {name:'globe'},
        transformRequest: (url) => {
          if (url.indexOf('https://api.mapbox.com') > -1) {
            return {
              url: url.replace('https://api.mapbox.com', 'https://earth.jl1.cn/mapbox'),
            };
          }
        }
      });
      this.map.on('load', () => {
        this.map.setFog({
          "horizon-blend": 0.1,
          "space-color": 'rgba(0, 0, 0, 1)',
          "star-intensity": 0.15
        })
        this.map.addLayer({
          id: 'sky',
          type: 'sky',
          paint: {
            'sky-type': 'atmosphere',
            'sky-atmosphere-sun': [0.0, 0.0],
            'sky-atmosphere-sun-intensity': 15,
          }
        });
        this.load()
      });
    },
    load() {
      if(!this.layerInfo.tms){
        this.map.addSource(this.layerInfo.layer, {
          type: 'raster',
          tiles: [
            this.layerInfo.layerUrl
          ],
          tileSize: 256,
        });
      }
      else{
        //tms
        this.map.addSource(this.layerInfo.layer, {
          type: 'raster',
          tiles: [
            this.layerInfo.layerUrl
          ],
          tileSize: 256,
          scheme: 'tms',
        });
      }
      this.map.addLayer({
        id: this.layerInfo.layer,
        type: 'raster',
        source: this.layerInfo.layer,
      }, this.baseStyleInfo.topLayerId);
    },
    //添加tips选择定位的点(被flutter调用)
    addTipsPositionPoint(lng, lat, zoom, panPixel){
      if (zoom === -1){
        this.map.easeTo({center: [parseFloat(lng), parseFloat(lat)], duration: 0});
      } else {
        this.map.easeTo({center: [parseFloat(lng), parseFloat(lat)], zoom: parseFloat(zoom), duration: 0});
      }
      this.map.panBy([0, panPixel],{
        duration: 0
      });
      this.showTipsPositionIcon(lng, lat);
    },
    showTipsPositionIcon(lng, lat){
      this.deleteTipsPositionPoint();
      let _this = this
      this.map.loadImage(
          tipsPng,
          function(error,image){
            if(error) throw error;
            _this.map.addImage("tipsIcon",image);
          }
      )
      this.map.addSource('tipsPositionPoint', {
        type: 'geojson',
        data: {
          'type': 'FeatureCollection',
          'features': [{
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [lng, lat]
            },
            //使用图片做point
            properties: {
              icon: "tipsIcon",
            },
          }]
        }
      });
      this.map.addLayer({
        id: 'tipsPositionPoint',
        type: 'symbol',
        source: 'tipsPositionPoint',
        layout: {
          "icon-image": ["get", "icon"],
          "icon-size": 0.4,//图标的大小
        }
      });
    },
    //删除tips选择定位的点
    deleteTipsPositionPoint(){
      if(this.map.getLayer("tipsPositionPoint") !== undefined){
        this.map.removeLayer("tipsPositionPoint");
        this.map.removeSource("tipsPositionPoint");
      }
    },
  },

}
</script>

<style scoped lang="scss">

.choosePositionGlobe {
  height: 100%;
  width: 100%;
  background-color: black;
  display: flex;
  align-items: center;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

</style>
