<template>
  <!--卫星活动类型 1000-->
  <div id="activityPageOne">
    <!--顶部下载栏-->
    <TopShareBar v-if="share"/>
    <div class="activityPageOne-content">
      <p style="margin-left:9px;margin-top:86px;font-family:YouSheBiaoTiHei;color:#ffffff;font-size:24px;">专属卫星拍摄</p>
      <p style="margin-left:9px;font-family:YouSheBiaoTiHei;color:#ffffff;font-size:24px;">成为太空摄影师</p>
      <!--上期影像-->
      <div @click="intoFullImage" class="historyArea" :style="{'background-image':'url(' + activityInfo.prevImage + ')'}">
        <div style="height:30px;margin-top:10px;padding:0 9px 0 15px;">
          <p v-if="activityInfo.stage !== 1" style="font-family:YouSheBiaoTiHei;color:#ffffff;font-size:24px;float:left;">上期卫星活动帮拍影像公布</p>
<!--          <p v-if="activityInfo.stage !== 1" style="font-family:YouSheBiaoTiHei;color:#ffffff;font-size:24px;float:left;">第1期活动帮拍影像公布</p>-->
<!--          <p v-if="activityInfo.stage === 1" style="font-family:YouSheBiaoTiHei;color:#ffffff;font-size:24px;float:left;">卫星帮拍影像</p>-->
          <div style="width:30px;height:100%;background:rgba(0,0,0,0.6);border-radius:4px;float:right;text-align:center;">
            <p class="iconfontFamily" style="line-height:30px;color: #ffffff;font-size:18px;">&#xe74e;</p>
          </div>
        </div>
        <div style="flex:1;"></div>
        <div v-if="activityInfo.prevWinner!=undefined" class="historyArea-info">
          <div class="historyArea-info-avatar" :style="{'background-image':'url(' + activityInfo.prevWinner.avatar + ')'}"></div>
          <div style="width:calc(100% - 63px - 10px);margin-left:63px;">
            <p style="font-weight:bold;font-size:12px;color:#ffffff;line-height:24px;">{{activityInfo.prevWinner.nickName}}</p>
            <p style="margin-top:2px;font-size:12px;color:#ffffff;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;">{{activityInfo.prevWinner.content}}</p>
          </div>
        </div>
      </div>

      <!--下期选点-->
      <div class="contentCard">
        <div class="contentCard-header">
          <p class="contentCard-header-title">下期拍摄选点</p>
          <div style="flex:1;"></div>
          <div style="height:20px;padding:0 4px;margin-top:10px;margin-right:4px;background:#ffffff;border-radius:2px;">
            <p style="line-height:20px;font-size:16px;font-family:YouSheBiaoTiHei;color:#000000;">{{activityInfo.days}}</p>
          </div>
          <p style="line-height:41px;font-size:16px;font-family:YouSheBiaoTiHei;color:#ffffff;">天</p>
        </div>
<!--        :style="{'height':couldVote ? '245px' : '358px'}"-->
        <div class="contentCard-header-content" style="position:relative;padding-top:10px;padding-bottom:10px;">
          <div v-if="couldVote">
            <p style="font-size:12px;">Pick你喜欢的内容助Ta圆梦</p>
            <div style="margin-top:15px;">
              <div style="height:48px;display:flex;">
                <img @click="scrollTo(index)" v-for="(item,index) in activityInfo.candidates" :key="index"
                     :src="item.avatar" style="width:48px;height:48px;border-radius:50%;margin-right:9px;box-sizing:border-box;"
                     :style="{'border':currTipsIndex===index ? '2px solid #ffffff' : 'none','box-shadow':currTipsIndex===index ? '0px 0px 4px 0px rgba(0,0,0,0.25)' : 'none'}">
              </div>
              <div class="tipsContent">
                <div :style="{'left':currTipsIndex*(48 + 9) + 14 + 'px'}" class="tipsContent-triangle"></div>
                <img :src="activityInfo.candidates[currTipsIndex].picture !== '' ? activityInfo.candidates[currTipsIndex].picture : require('@/assets/Satellite/ActivityOne/defaultTipsThumb.png')"
                     style="width:100px;height:70px;border-radius:8px;">
                <div style="width:calc(100% - 104px);margin-left:4px;">
                  <p style="font-size:14px;color:#4a4a4a;-webkit-box-orient: vertical;-webkit-line-clamp: 1;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;">{{activityInfo.candidates[currTipsIndex].title}}</p>
                  <p style="font-size:10px;color:#4a4a4a;-webkit-box-orient: vertical;-webkit-line-clamp: 1;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;">
                    <span style="color:#675CB8" class="iconfontFamily">&#xe61e;</span>
                    {{activityInfo.candidates[currTipsIndex].geoName}}
                  </p>
                  <p style="margin-top:3px;font-size:12px;color:#4a4a4a;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;">{{activityInfo.candidates[currTipsIndex].content}}</p>
                </div>
                <img :src="activityInfo.satellitePicture" class="tipsContent-img">
              </div>
            </div>
            <div @click="vote" style="width:103px;height:32px;background:linear-gradient(90deg, #6259A0 0%, #62589F 100%);border-radius:20px;margin:12px auto 0 auto;text-align:center;">
              <p style="line-height:32px;color:#ffffff;font-size:16px;font-family:YouSheBiaoTiHei;">为Ta投票</p>
            </div>
            <p style="position:absolute;bottom:6px;left:5px;font-size:44px;color:#DFDDF0;" class="iconfontFamily">&#xe6f2;</p>
          </div>

          <div v-if="!couldVote">
            <p style="margin-bottom:15px;font-size:12px;">目前排行榜情况</p>
            <div v-for="(item,index) in tipsVoteList" :key="'voteResult'+index" style="height:48px;margin-bottom:23px;display:flex;">
              <img :src="item.avatar" style="width:48px;height:48px;border-radius:50%;margin-right:9px;">
              <div style="width:182px;">
                <p style="line-height:16px;font-size:12px;color:#4a4a4a;">{{item.nickName}}</p>
                <div :style="{'width':getVoteWidth(item.poll),'background':index === 0 ? '#FF8D1A' : index === 1 ? '#FFC300' : index === 2 ? '#D43030' : '#FF5733'}"
                     style="height:21px;border-radius:28px;margin-top:6px;"></div>
              </div>
              <div style="flex:1;"></div>
              <p style="margin-right:3px;line-height:48px;font-size:14px;color:#4a4a4a;">{{item.poll}}票</p>
            </div>
            <div @click="shareActivity" style="position:relative;width:103px;height:32px;background:linear-gradient(90deg, #EE856B 0%, #F2BD94 100%);border-radius:20px;margin:12px auto 8px auto;text-align:center;">
              <p style="line-height:32px;color:#ffffff;font-size:16px;font-family:YouSheBiaoTiHei;">分享好友</p>
              <p v-if="this.activityInfo.vote === 1" style="position:absolute;top:-10px;right:-22px;font-size:16px;color:#6259A0;font-family:YouSheBiaoTiHei;">+1投票</p>
            </div>
          </div>
        </div>
      </div>

      <!--参与方式-->
      <div class="contentCard">
        <div class="contentCard-header">
          <p class="contentCard-header-title">参与方式</p>
        </div>
<!--        height:75px;-->
        <div class="contentCard-header-content" style="position:relative;padding-top:20px;padding-bottom:20px;">
          <p style="font-size:18px;font-family:YouSheBiaoTiHei;color:#62589F;">即刻发布下一个太空摄影师就是你</p>
          <div style="height:26px;margin-top:22px;display:flex;">
            <p style="line-height:26px;font-size:14px;">留言写下你想拍的地方</p>
            <div style="flex:1;"></div>
            <div @click="joinActivity" style="width:83px;height:100%;border-radius:20px;background:#9A93C4;text-align:center;">
              <p style="line-height:26px;color:#ffffff;font-size:14px;">去发布></p>
            </div>
          </div>
          <p style="position:absolute;top:7px;right:13px;font-size:42px;color:#DFDDF0;" class="iconfontFamily">&#xe71a;</p>
        </div>
      </div>

      <!--活动说明-->
      <div class="contentCard">
        <div class="contentCard-header">
          <p class="contentCard-header-title">活动说明</p>
        </div>
<!--        height:360px;-->
        <div class="contentCard-header-content" style="padding-top:10px;padding-bottom:10px;">
          <!--活动介绍-->
          <div class="activityIntroduceTitle">
            <div class="activityIntroduceTitle-simple">
              <p class="activityIntroduceTitle-simple-text">打开共生地球APP。发布你想拍的地方~</p>
            </div>
            <div class="activityIntroduceTitle-title">
              <p class="activityIntroduceTitle-title-text">活动介绍</p>
            </div>
          </div>
          <div>
            <p class="activityIntroduceContent">1.每位用户可为喜欢的帮拍留言进行海选投票，票选最高的用户留言将被选中实现拍摄愿望；</p>
            <p class="activityIntroduceContent">2.您可前往共生地球APP发布留言参与活动，活动期间工作人员将选出最具代表性的n位小伙伴的留言，供大家评选；</p>
            <p class="activityIntroduceContent">3.被选中的用户将获得一次免费卫星定制拍摄机会（活动期间共计选取5位用户）。</p>
          </div>

          <!--温馨提示-->
          <div class="activityIntroduceTitle" style="margin-top:15px;">
            <div class="activityIntroduceTitle-simple">
              <p class="activityIntroduceTitle-simple-text">活动好礼不断！</p>
            </div>
            <div class="activityIntroduceTitle-title">
              <p class="activityIntroduceTitle-title-text">温馨提示</p>
            </div>
          </div>
          <div>
            <p class="activityIntroduceContent">1.获奖用户将于活动结束后5个工作日内，在【共生地球APP】内收到工作人员私信。获奖用户请于5个工作日内填写地址及联系方式，过期自动作废；</p>
            <p class="activityIntroduceContent">2.活动期间请勿通过作弊手段牟取平台奖励，一旦发现立即取消其活动参与资格，并保留追究其责任权利；</p>
            <p class="activityIntroduceContent">3.卫星帮拍活动由长光卫星技术股份有限公司提供；</p>
            <p class="activityIntroduceContent">4.本活动最终解释权归长光卫星技术股份有限公司所有。</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopShareBar from '@/components/TopShareBar';
export default {
  name: "ActivityPageOne",
  components: {TopShareBar},
  computed:{
    //票数宽度
    getVoteWidth(){
      return function(voteNum){
        return voteNum/this.tipsVoteList[0].poll * 100 + "%";
      }
    },
    //是否可以投票
    couldVote(){
      if(this.activityInfo.vote === 0){
        return true;
      }
      else if(this.activityInfo.vote === 1 && this.activityInfo.share){
        return true;
      }
      else{
        return false;
      }
    }
  },
  data(){
    return{
      cardActivityId:"",   //url里的id，是卡片绑定的id 不是最新一期活动的
      activityInfo:"",
      tipsVoteList:"",   //投票排行榜
      currTipsIndex:0,   //当前查看的tips
      share:false,
      token:"",
      scrollTimer:'',
      timerInitValue:0,
    }
  },
  methods:{
    //获取卫星拍摄活动详情
    getDetail(){
      this.$http.get('/earthApi/activity-shot-helper/getNewest', this.token === "" ? "" : {headers:{'Authorization': 'Bearer ' + this.token}}).then(response => {
        if(response.body.status === 200){
          this.activityInfo = response.body.data;
          if(this.activityInfo.vote > 1 || (this.activityInfo.vote === 1 && !this.activityInfo.share)){
            //需要显示排行榜，获取投票排行榜
            this.$http.get('/earthApi/activity-shot-helper/getNewestPollList').then(response => {
              if(response.body.status === 200){
                this.tipsVoteList = response.body.data
              }
              else if(response.body.status === 404){
                this.$router.push(
                    {
                      path: '/NotExist',
                    }
                )
              }
            })
          }
          if(window["flutter_getActivityDetail"]){
            window["flutter_getActivityDetail"].postMessage(JSON.stringify(this.activityInfo));
          }
          this.startAutoScroll()
        }
        else if(response.body.status === 404){
          this.$router.push(
              {
                path: '/NotExist',
              }
          )
        }
        else{
          console.log(response.data.status + response.data.message)
        }
      })

    },
    startAutoScroll(){
      if(this.scrollTimer!== ''){
        clearInterval(this.scrollTimer)
      }
      this.scrollTimer = setInterval(()=> {
        this.currTipsIndex = (this.currTipsIndex + 1)%4
      }, 4000);
    },
    scrollTo(index) {
      clearInterval(this.scrollTimer)
      this.currTipsIndex = index
      this.startAutoScroll()
    },
    //进入全屏看大图
    intoFullImage(){
      if(this.share){
        this.openDownloadAppPage();
      }
      else{
        if(window["flutter_intoFullScreenImage"]){
          window["flutter_intoFullScreenImage"].postMessage(this.activityInfo.prevImage);
        }
      }
    },

    //去发布tips
    joinActivity(){
      if(this.share){
        this.openDownloadAppPage();
      }
      else{
        if(window["flutter_createTipsByTopic"]){
          window["flutter_createTipsByTopic"].postMessage("卫星帮拍");
        }
      }
    },

    //投票
    vote(){
      if(this.share){
        this.openDownloadAppPage();
      }
      else{
        if(window["flutter_satelliteActivityOneVote"]){
          window["flutter_satelliteActivityOneVote"].postMessage(this.activityInfo.candidates[this.currTipsIndex].infoId);
        }
      }
    },
    //投票成功效果，被flutter调用
    voteSuccess(){

      this.activityInfo.vote =  this.activityInfo.vote + 1;   //用户票数加1
      //获取投票排行榜
      this.$http.get('/earthApi/activity-shot-helper/getNewestPollList').then(response => {
        if(response.body.status === 200){
          console.log(response.body.data);
          this.tipsVoteList = response.body.data
        }
        else if(response.body.status === 404){
          this.$router.push(
              {
                path: '/NotExist',
              }
          )
        }
        else{
          console.log(response.data.status + response.data.message)
        }
      })

    },

    //分享
    shareActivity(){
      if(this.share){
        this.openDownloadAppPage();
      }
      else{
        if(window["flutter_satelliteActivityShare"]){
          window["flutter_satelliteActivityShare"].postMessage("");
        }
      }
    },
    //分享成功效果，被flutter调用
    shareSuccess(){
      if(!this.activityInfo.share){
        this.activityInfo.share = true;
      }
    },

    //用户登录成功，被flutter调用
    loginSuccess(token){
      this.token = token;
      this.getDetail();
    },



    //分享页 点击任何区域均为打开app
    openDownloadAppPage(){
      window.location.href = '/OpenApp?infoId=' + this.activityInfo.activityId + '&type=1000'
    },




  },
  mounted(){
    window.satelliteActivityH5One = this;
    this.cardActivityId = this.$route.query.infoId;
    if(this.$route.query.share == 1){
      this.share = true;
    }
    if(this.$route.query.token !== undefined){
      this.token = this.$route.query.token
      window.history.replaceState('', '', this.$route.fullPath.substring(0,this.$route.fullPath.indexOf("token") - 1))
    }
    document.title = "卫星帮拍";
    this.getDetail();
  }
}
</script>

<style scoped>
#activityPageOne{
  position:absolute;
  width:100%;
  height:100%;
  overflow:auto;
}

#activityPageOne p{
  margin:0;
}


/*内容区域*/
.activityPageOne-content{
  padding:0 15px 15px 15px;
  border-top: 1px solid transparent;
  background-size:cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image:url(../../../assets/Satellite/ActivityOne/background.jpg);
}


/*上期结果*/
.historyArea{
  position:relative;
  width:100%;
  height:360px;
  border-radius:18px;
  background-image:url(../../../assets/alumni/purpleBackground.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top:5px;
  display:flex;
  flex-direction: column;
}
.historyArea-info{
  position:relative;
  width:100%;
  height:81px;
  background:linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 1) 100%);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
.historyArea-info-avatar{
  position:absolute;
  top:-7px;
  left:5px;
  width:48px;
  height:48px;
  border-radius:50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border:2px solid #ffffff;
}

/*每个信息卡片*/
.contentCard{
  width:100%;
  border-radius:18px;
  margin-top:14px;
  overflow:hidden;
}
.contentCard-header{
  width:calc(100% - 16px - 9px);
  height:41px;
  padding:0 9px 0 16px;
  background:#62589F;
  display:flex;
}
.contentCard-header-title{
  font-size:20px;
  font-family:YouSheBiaoTiHei;
  color:#ffffff;
  line-height:41px;
}
.contentCard-header-content{
  width:calc(100% - 30px);
  background:#f0f0f0;
  padding:0 15px;
}


/*活动说明*/
.activityIntroduceTitle{
  position:relative;
  width:100%;
  height:32px;
  margin-bottom:18px;
}
.activityIntroduceTitle-title{
  position:relative;
  width:70px;
  height:32px;
  background-image:url(../../../assets/Satellite/ActivityOne/introduceTitle.png);
  background-position: center;
  background-size: 70px 32px;
  background-repeat: no-repeat;
}
.activityIntroduceTitle-title-text{
  margin-left:7px !important;
  line-height:32px;
  font-size:14px;
  color:#ffffff;
  font-family:YouSheBiaoTiHei;
  /*overflow: hidden;*/
  /*text-overflow:ellipsis;*/
  /*white-space: nowrap;*/
}

.activityIntroduceTitle-simple{
  position:absolute;
  top:5px;
  right:0;
  width:calc(100% - 61px);
  height:21px;
  background: linear-gradient(90deg, rgba(235, 234, 245, 1) 0%, rgba(204, 204, 204, 0) 100%);
  /*background:linear-gradient(270deg, #EBEAF5 0%, #CCCCCC 100%);*/
}
.activityIntroduceTitle-simple-text{
  margin-left:14px !important;
  line-height:21px;
  font-size:13px;
  color:#383838;
  font-family:YouSheBiaoTiHei;
}

.activityIntroduceContent{
  font-size:12px;
  color:#4a4a4a;
  margin-bottom:5px !important;
  line-height:18px;
}


/*下一期tips*/
.tipsContent{
  position:relative;
  /*height:71px;*/
  background:#ffffff;
  border-radius:16px;
  box-shadow: 0px 0px 10px 0px rgba(44,44,44,0.25);
  padding:15px 5px 15px 10px;
  margin-top:16px;
  display:flex;
}
.tipsContent-triangle{
  position:absolute;
  top:-20px;
  width:0;
  height:0;
  border-style: solid;
  border-width: 10px 10px;
  border-color: transparent transparent #ffffff transparent;

}
.tipsContent-img{
  position:absolute;
  bottom:calc(100% - 17px);
  right:0;
  width:82px;
  /*height:70px;*/

}



</style>