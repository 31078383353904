<template>
    <div></div>
</template>

<script>
    import * as turf from "@turf/turf";

    export default {
        name: "HeatMap",
        props: {
            pointData:{
                required: true // 点数据列表，需要geoJson: FutureCollection<Future<Point>>
            },
            map:{
                required: true // mapbox对象
            },
            dataPropertyName:{
                required: true // 属性字段名
            },
            dataMin:{
                required: true // 最小值
            },
            dataMax:{
                required: true // 最小值
            },
            colorList:{
                required: true
            },
            layerOpacity:{
                required: true // 最小值
            },
        },
        data(){
            return{
                dataRange: 0,
                color:["interpolate", ["linear"], ["heatmap-density"]],
                opacity:this.layerOpacity,
            }
        },
        methods:{
            //初始化图层
            initLayer(){
                this.map.addSource("HeatMap", {
                    type: "geojson",
                    // 数据为mapbox官方数据
                    // 可能访问不到或者跨域，本地测试时可以直接下下来
                    data: this.pointData,
                });
                if(this.dataMin !==this.dataMax && this.colorList.length >1){
                    this.map.addLayer(
                        {
                            'id': 'HeatMap-heat',
                            'type': 'heatmap',
                            'source': 'HeatMap',
                            'paint':{
                                // 根据频率和属性大小增加热力图权重
                                "heatmap-weight": [
                                    "interpolate",
                                    ["linear"],
                                    ["get", "pValue"],
                                    this.dataMin, 0,
                                    this.dataMax, 1,
                                ],
                                // 根据缩放级别增加热力图权重
                                // heatmap-intensity 是热力图权重的乘数
                                "heatmap-intensity": ["interpolate", ["linear"], ["zoom"], 0, 1, 9, 3],
                                // 色带
                               "heatmap-color":this.color,
                                // "heatmap-color": [
                                //     "interpolate",
                                //     ["linear"],
                                //     ["heatmap-density"],
                                //     0,
                                //     "rgba(33,102,172,0)",
                                //     0.2,
                                //     "#BE1111",
                                //     0.4,
                                //     "#F39D00",
                                //     0.6,
                                //     "#1BB32A",
                                //     0.8,
                                //     "#176AD5",
                                //     1,
                                //     "#646464",
                                // ],
                                // 根据缩放级别调整热力图半径
                                "heatmap-radius": ["interpolate", ["linear"], ["zoom"], 0, 2, 9, 20],
                                // "heatmap-opacity": ["interpolate", ["linear"], ["zoom"], 0, 1, 9, 1],
                                "heatmap-opacity": this.opacity,
                            }
                        }
                    )
                    // 添加圆图层，叠加效果更好
                    // this.map.addLayer(
                    //     {
                    //         id: "HeatMap-point",
                    //         type: "circle",
                    //         source: "HeatMap",
                    //         minzoom: 7,
                    //         paint: {
                    //             "circle-radius": [
                    //                 "interpolate",
                    //                 ["linear"],
                    //                 ["zoom"],
                    //                 7,
                    //                 ["interpolate", ["linear"], ["get", "pValue"], this.dataMin, 1, this.dataMax, 4],
                    //                 16,
                    //                 ["interpolate", ["linear"], ["get", "pValue"], this.dataMin, 5, this.dataMax, 50],
                    //             ],
                    //             "circle-color": [
                    //                 "interpolate",
                    //                 ["linear"],
                    //                 ["get", "pValue"],
                    //                 this.dataMin,
                    //                 "rgba(33,102,172,0)",
                    //                 this.dataMin+this.dataRange,
                    //                 "rgb(103,169,207)",
                    //                 this.dataMin+this.dataRange*2,
                    //                 "rgb(209,229,240)",
                    //                 this.dataMin+this.dataRange*3,
                    //                 "rgb(253,219,199)",
                    //                 this.dataMin+this.dataRange*4,
                    //                 "rgb(239,138,98)",
                    //                 this.dataMax,
                    //                 "rgb(178,24,43)",
                    //             ],
                    //             "circle-stroke-color": "white",
                    //             "circle-stroke-width": 1,
                    //             "circle-opacity": this.opacity,
                    //         },
                    //     }
                    // )
                }else{
                    this.map.addLayer(
                        {
                            'id': 'HeatMap-heat',
                            'type': 'heatmap',
                            'source': 'HeatMap',
                            'paint':{
                                // 根据频率和属性大小增加热力图权重
                                "heatmap-weight": 1,
                                // 根据缩放级别增加热力图权重
                                // heatmap-intensity 是热力图权重的乘数
                                "heatmap-intensity": ["interpolate", ["linear"], ["zoom"], 0, 1, 9, 3],
                                // 色带
                                "heatmap-color": this.colorList[0].hexColor,
                                // 根据缩放级别调整热力图半径
                                "heatmap-radius": ["interpolate", ["linear"], ["zoom"], 0, 2, 9, 20],
                                // 透明度
                                // "heatmap-opacity": ["interpolate", ["linear"], ["zoom"], 0, 1, 9, 1],
                                "heatmap-opacity": this.opacity,
                            }
                        }
                    )
                    // 添加圆图层，叠加效果更好
                  /*  this.map.addLayer(
                        {
                            id: "HeatMap-point",
                            type: "circle",
                            source: "HeatMap",
                            minzoom: 7,
                            paint: {
                                "circle-radius": [
                                    "interpolate",
                                    ["linear"],
                                    ["zoom"],
                                    7, ["get", "pValue"],
                                    7,
                                    ["get", "pValue"]
                                ],
                                "circle-color": "rgb(103,169,207)",

                                //     [
                                //     "interpolate",
                                //     ["linear"],
                                //     ["get", "pValue"],
                                //     this.dataMin,
                                //     "rgba(33,102,172,0)",
                                //     this.dataMin+this.dataRange,
                                //     "rgb(103,169,207)",
                                //     this.dataMin+this.dataRange*2,
                                //     "rgb(209,229,240)",
                                //     this.dataMin+this.dataRange*3,
                                //     "rgb(253,219,199)",
                                //     this.dataMin+this.dataRange*4,
                                //     "rgb(239,138,98)",
                                //     this.dataMax,
                                //     "rgb(178,24,43)",
                                // ],
                                "circle-stroke-color": "white",
                                "circle-stroke-width": 1,
                                "circle-opacity": ["interpolate", ["linear"], ["zoom"], 7, 0, 8, 1],
                            },
                        }
                    )*/
                }
                this.mapFitBoundary(this.pointData)
            },

            mapFitBoundary(geo){
                const bbox = turf.bbox(geo)
                this.map.fitBounds([[bbox[0], bbox[1]], [bbox[2], bbox[3]]],{
                    linear: false,
                    animate: true,
                    // padding: {top: 120, bottom:130, left: 190, right: 500},
                })
            },

            removeLayer(){
                if(this.map.getLayer('HeatMap-heat'))
                    this.map.removeLayer('HeatMap-heat')
                if(this.map.getLayer('HeatMap-point'))
                    this.map.removeLayer('HeatMap-point')
                if(this.map.getSource('HeatMap'))
                    this.map.removeSource('HeatMap')
            },

            reSetLayer(val){
                this.opacity = val
               this.map.setPaintProperty('HeatMap-heat', 'heatmap-opacity', this.opacity);
            },
        },
        mounted() {
            if(this.dataMin !==this.dataMax && this.colorList.length >1){
                this.dataRange = parseFloat((1/this.colorList.length).toFixed(1));
                let temp = 0
                this.color.push(0)
                this.color.push('rgba(23,23,23,0)')
                for(let i = 0;i< this.colorList.length;i++){
                    temp = (temp+this.dataRange)
                    this.color.push(temp)
                    this.color.push(this.colorList[i].hexColor)
                }
            }

            //初始化图层
            this.initLayer();
        },
        destroyed(){
            this.removeLayer()
        },
    }
</script>

<style scoped>

</style>
