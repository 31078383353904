<template>
    <div class="shareButton">
        <a id="openApp" @click="openApp">请使用浏览器打开</a>
    </div>
</template>

<script>
export default {
    name: "ShareButton",
  props:["source"],
  data(){
    return{
      sourceThis:"",
    }
  },
  watch:{
      source(){
        this.sourceThis = this.source
      }
  },
    methods:{
        schemeUrl(){
            let url
            if(this.$route.query.infoId != undefined){
                url = 'charmingearth://earth.jl1.cn?infoId=' + this.$route.query.infoId + '&type=' + this.$route.query.type
            } else {
                url = 'charmingearth://earth.jl1.cn'
            }
            return url
        },
        openApp(){
            let u = window.navigator.userAgent;
            let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android终端或者uc浏览器
            let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            if(isAndroid){
                this.android();
            }
            if(isiOS){
                this.ios();
            }
        },
        android(){
            let _clickTime = new Date().getTime();
            window.location.href = this.schemeUrl()
                //启动间隔20ms运⾏的定时器，并检测累计消耗时间是否超过3000ms，超过则结束
            let _count = 0, intHandle;
            let _this = this;
            intHandle = setInterval(function(){
                _count++;
                let elsTime = new Date().getTime() - _clickTime;
                if (_count>=100 || elsTime > 3000 ) {
                    console.log(_count)
                    console.log(elsTime)
                    clearInterval(intHandle);
                    //检查app是否打开
                    if ( document.hidden || document.webkitHidden) {
                        // 打开了
                        window.close();
                    } else {
                        // 没打开
                      if(_this.sourceThis != ""){
                        window.location.href = "/appdownload?source="+_this.sourceThis;//下载链接
                      }
                      else{
                        window.location.href = "/appdownload";//下载链接
                      }
                    }
                }
            }, 20);
        },
        ios(){
            let _clickTime = +(new Date());
            let ifr = document.createElement("iframe");
            ifr.src = this.schemeUrl()
            ifr.style.display = "none";
            document.body.appendChild(ifr);
            //启动间隔20ms运⾏的定时器，并检测累计消耗时间是否超过3000ms，超过则结束
            let _count = 0, intHandle;
            intHandle = setInterval(function(){
                _count++;
                let elsTime =  +(new Date()) - _clickTime;
                console.log(_count,elsTime,+(new Date()),_clickTime)
                if (_count>=100 || elsTime > 3000 ) {
                    clearInterval(intHandle);
                    document.body.removeChild(ifr);
                    //检查app是否打开
                    if ( document.hidden || document.webkitHidden) {
                        // 打开了
                        window.close();
                    } else {
                        // 没打开
                        // alert('没打开')
                        window.location.href = "https://apps.apple.com/cn/app/id1628699115";//下载链接
                    }
                }
            },20);
        }
    },
}
</script>

<style scoped lang="scss">
.shareButton{
    width: 150px;
    background: rgba(0,0,0,0);
    position: absolute;
    left: 0;
    right: 0;
    margin:0 auto;
    bottom: 28px;
    z-index: 9999;
    #openApp{
        text-decoration: none;
        display: block;
        color: white;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        line-height: 40px;
        height: 40px;
        background: linear-gradient(230deg,#6d61d1 14%, #61589d 83%);
        border-radius: 27px;
        box-shadow: 0px 2px 10px 0px rgba(95,95,95,0.50);
    }
}
</style>
