<template>
  <div class="cesium3dTilesHome">

    <div v-show="index === homeDataCurrShowIndex" v-for="(item,index) in homeDataList" :key="index">
      <div style="position:absolute;top:0;left:0;width:100%;height:100%;background:#4a4a4a;overflow:hidden;">
        <video autoplay loop muted>
          <source :src="item.videoUrl" type="video/mp4" />
        </video>
      </div>
      <div style="position:absolute;bottom:20px;right:20px;">
        <p style="font-size:60px;font-weight:1000;color:#ffffff;letter-spacing:5px;text-shadow:2px 0px black,-2px 0px black,0px 2px black,0px -2px black;">{{item.title}}</p>
      </div>
    </div>

    <div class="cesiumDataListScrollArea" style="position:absolute;bottom:20px;left:12px;min-width:150px;max-height:50%;overflow:auto;font-size:18px;font-weight:bold;color:#ffffff;text-align:center;">

      <p @click="cesuimDataListShow = !cesuimDataListShow;" :style="{'margin-bottom':cesuimDataListShow ? '16px' : '0'}"
         style="font-size:20px;text-shadow:2px 0px black,-2px 0px black,0px 2px black,0px -2px black;cursor:pointer;">
        <span v-if="!cesuimDataListShow" class="iconfontFamily" style="margin-right:6px;font-size:14px;" >&#xe73a;</span>
        <span v-if="cesuimDataListShow" class="iconfontFamily" style="margin-right:6px;font-size:14px;" >&#xe737;</span>
        更多效果
        <span v-if="!cesuimDataListShow" class="iconfontFamily" style="margin-left:6px;font-size:14px;" >&#xe73a;</span>
        <span v-if="cesuimDataListShow" class="iconfontFamily" style="margin-left:6px;font-size:14px;" >&#xe737;</span>
      </p>

      <p v-show="cesuimDataListShow" @click="intoCesiumDetailPage(item)" v-for="(item,index) in cesiumDataList" :key="'cesiumItem'+index"
         style="margin-top:10px;cursor:pointer;text-shadow:2px 0px black,-2px 0px black,0px 2px black,0px -2px black;">{{item.mainTitle}}</p>

    </div>




  </div>

</template>

<script>
export default {
  name: "Cesium3dTilesHome",
  data(){
    return{
      homeDataCurrShowIndex:0,
      homeDataList:[
        {
          title:"纳米比亚-矿山",
          videoUrl:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com:443/202401081029000572517.mp4"
        },
        {
          title:"山东泰山",
          videoUrl:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com:443/202401081030000352326.mp4"
        },
        {
          title:"乌鲁木齐-沙漠",
          videoUrl:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com:443/20240108103000056217.mp4"
        },
        {
          title:"长春",
          videoUrl:"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com:443/202401081031000193920.mp4"
        },



      ],

      cesiumDataList:[],
      cesuimDataListShow:false,

    }


  },
  methods:{
    getCesiumDataList(){
      this.$http.get('/earthApi/main-info/get3DTilesMainInfoList').then(response => {
        if(response.body.status === 200){
          console.log(response.body)
          this.cesiumDataList = response.body.data
        }
        else{
          console.log(response.data.status + response.data.message)
        }
      })
    },

    intoCesiumDetailPage(item){
      let path = this.$router.resolve({
        path: '/Cesium3dTiles',
        query:{
          infoId:item.infoId
        }
      })
      window.open(path.href,'_blank')
    }


  },
  mounted(){
    //切换首页展示视频
    setInterval(()=>{
      if(this.homeDataCurrShowIndex === this.homeDataList.length - 1){
        this.homeDataCurrShowIndex = 0;
      }
      else{
        this.homeDataCurrShowIndex ++;
      }

    },5000)

    this.getCesiumDataList()

  }
}
</script>

<style scoped>

.cesium3dTilesHome{
  width:100vw;
  height:100vh;
  /*background:#000000;*/
  /*color:#ffffff;*/
  font-family:HarmonyOSSansSCRegular;
}
.cesium3dTilesHome p{
  margin:0;
}


/*浅色*/
.cesiumDataListScrollArea::-webkit-scrollbar-track-piece{
  -webkit-border-radius:0;
}
.cesiumDataListScrollArea::-webkit-scrollbar{
  width:4px;
}
.cesiumDataListScrollArea::-webkit-scrollbar-thumb{
  background-color: #E5E5E5;
  -webkit-border-radius:1px;
  border: 1px solid #E5E5E5;
}

</style>

