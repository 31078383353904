<template>
  <div style="background-color:#E3F3FD;position: relative;top: 0;bottom: 0;left: 0;right: 0;padding-top: 40px;">
    <div style="width: calc(100% - 94px) ;height: fit-content;margin: 0 27px 0 27px;background-color: white;border-radius: 8px;position: relative;padding: 1px 20px 15px;">
      <img src="/app-assets/image/user_system/iconLogo.png" width="50" height="50" style="position: absolute;left: 50%;right: 50%;transform: translateX(-50%);top: -20px"/>
      <div style="color: #383838;font-size: 14px;margin-top: 45px">
        感谢您对共生地球项目的支持，邀请您领取专属图层兑换码：
      </div>
      <div style="margin-top: 17px;display: flex">
        <div style="width:75px;color: #383838;font-size: 14px;line-height: 30px">
          输入邮箱：
        </div>
        <div style="flex:1;">
          <input v-model="userEmail" type="email" style="width: calc(100% - 8px);height: 30px;background-color: #2A82E4;  border:none;border-radius:4px;outline:none;  font-size:14px;line-height:30px;color: white;padding-left: 8px">
        </div>
      </div>
      <div style="margin-top: 17px;display: flex">
        <div style="width:75px;color: #383838;font-size: 14px;line-height: 30px">
          验证码：
        </div>
        <div style="flex:1;margin-right:6px;">
          <input v-model="verificationCode" type="text" style="width: calc(100% - 10px);height: 30px;background-color: #2A82E4;  border:none;border-radius:4px;outline:none;  font-size:14px;line-height:30px;color: white;padding-left: 8px">
        </div>
        <div style="height:30px;">
          <img @click="getVerificationCode" :src="verificationCodeImageSrc" style="height:100%;border:1px solid #808080;border-radius: 4px;">
        </div>

      </div>
      <div style="color: #808080;font-size: 12px;margin-top: 16px">
        工作人员将尽快发送兑换码至您的邮箱，自动邮件可能存在被屏蔽风险，请注意查看全部邮件或垃圾邮件。
      </div>
      <div @click="getRedeemCode" style="background-color: white;outline: none;border: 1px solid #2A82E4;border-radius: 4px;width: 78px;height: 27px;margin:10px auto 0 auto;text-align: center;line-height: 27px;color: #2A82E4">
        确认
      </div>
      <div style="margin-top: 16px;border: 1px dashed #E5E5E5;"></div>
      <div style="color: #383838;font-size: 14px;margin-top: 10px">
        使用方式：共生地球APP—图层—输入兑换码      </div>
      <div style="color: #808080;font-size: 12px;margin-top: 16px">
        注：兑换码为一次验证后失效，请妥善保存，如泄露他人，造成失效，共生地球将不承担任何责任。      </div>
    </div>
    <div style="color: #383838;font-size: 14px;margin-top: 20px;margin-left: 47px">
      欢迎扫描下图二维码添加共生地球好友      </div>
    <img src="../../assets/GenerateRedeemCode/customer_service.png" style="width:170px;height:170px;margin:27px auto 0 auto;display: block">
    <div style="color: #A6A6A6;font-size: 12px;margin: 33px auto 0 auto;width: fit-content">
      扫一扫上面的二维码图案，加我为好友
    </div>
<!--    <div style="height: 100px"></div>-->
  </div>
</template>

<script>
export default {
  name: "GenerateRedeemCode",
  data(){
    return {
      userEmail:'',
      verificationCode:"",
      verificationCodeImageSrc:"",
      verificationCodeImageKey:"",
    }
  },

  methods:{
    getRedeemCode(){
      // console.log(/.+@.+\..+/.test(this.userEmail))
     if(!/.+@.+\..+/.test(this.userEmail)){
       alert("输入邮箱格式错误")
       return
     }
      this.$http.get('/earthApi/main-info/sendCodeEmail?email='+this.userEmail+'&layer=' + this.$route.query.layer+'&pictureCode=' + this.verificationCode+'&key=' + this.verificationCodeImageKey).then(response => {
        console.log(response.body)
        if(response.body.status === 200){
          alert("发送成功")
        }
        else if(response.body.status === 4015){
          this.getVerificationCode()
          alert("验证码错误")
        }
        else {
          alert("发送失败，请稍后再试")
        }
      })
    },

    getVerificationCode(){
      this.$http.get('/userApi/getPicCaptcha').then(response => {
        console.log(response)
        if(response.body.code === 200){
          this.verificationCodeImageSrc = response.body.data.picture
          this.verificationCodeImageKey = response.body.data.key
        }
        else {
          alert("发送失败，请稍后再试")
        }
      })
    }
  },

  mounted(){
    this.getVerificationCode();
  }
}
</script>

<style scoped>
  #event{
    -webkit-text-size-adjust: none !important;
  }
</style>