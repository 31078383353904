<template>
    <div />
</template>

<script>
    import * as turf from "@turf/turf";
    //import rectangleGrid from "@turf/rectangle-grid";

    // 生成网格图
    export default {
        name: "DataGrid",
        props: {
            pointData:{
                required: true // 点数据列表，需要geoJson: FutureCollection<Future<Point>>
            },
            map:{
                required: true // mapbox对象
            },
            dataPropertyName:{
                required: true // 属性字段名
            },
            colorList:{
                required: true,
            },
            dataMin:{
                required: true // 最小值
            },
            dataMax:{
                required: true // 最小值
            },
            layerOpacity:{
                required: true
            },
        },
        data(){
            return{
                sourceId: '',
                layerId: '',
                layerTextId: '',
                dataRange: 0,
                pointBboxPolygon:'',
                pointBbox:[],
                cellSide: 0,
                deltaX: 0,
                deltaY: 0,
                color:["interpolate",["linear"],["get", "dataPercentage"]],
                opacity:this.layerOpacity
            }
        },
        methods:{
            getWindowBbox() {
                // 获取当前屏幕四角点坐标
                // 获取屏幕四角经纬度
                let widthRange = this.map?.unproject([
                    this.map?.getCanvas().width,
                    0,
                ]);
                let widthRange1 = this.map?.unproject([
                    0,
                    this.map?.getCanvas().width,
                ]);
                let heightRange = this.map?.unproject([
                    0,
                    this.map?.getCanvas().height,
                ]);
                let heightRange1 = this.map?.unproject([
                    this.map?.getCanvas().height,
                    0,
                ]);
                let maxLat = Math.max(
                    widthRange.lat,
                    widthRange1.lat,
                    heightRange.lat,
                    heightRange1.lat
                );
                let minLat = Math.min(
                    widthRange.lat,
                    widthRange1.lat,
                    heightRange.lat,
                    heightRange1.lat
                );
                let maxLng = Math.max(
                    widthRange.lng,
                    widthRange1.lng,
                    heightRange.lng,
                    heightRange1.lng
                );
                let minLng = Math.min(
                    widthRange.lng,
                    widthRange1.lng,
                    heightRange.lng,
                    heightRange1.lng
                );
                const windowPolygon = turf.polygon([[
                    [minLng, minLat],
                    [minLng, maxLat],
                    [maxLng, maxLat],
                    [maxLng, minLat],
                    [minLng, minLat]
                ]]);

                // 根据缓冲区重新生成包围屏幕的矩形
                let bufferBbox = this.getBufferBbox(windowPolygon);
                return turf.bboxPolygon(bufferBbox);
            },
            getInnerGridBbox(bbox){ // 计算bbox在整体网格中的格子，需要先计算整体网格参数(this.setPointsBboxParam())
                const west = this.pointBbox[0] + this.deltaX;
                const south = this.pointBbox[1] + this.deltaY;
                const newWest = west + Math.floor((bbox[0]-west)/this.cellWidthDeg) * this.cellWidthDeg;
                const newSouth = south + Math.floor((bbox[1]-south)/this.cellHeightDeg) * this.cellHeightDeg;
                const newEast = west + (Math.floor((bbox[2]-west)/this.cellWidthDeg)+1) * this.cellWidthDeg;
                const newNorth = south + (Math.floor((bbox[3]-south)/this.cellHeightDeg)+1) * this.cellHeightDeg;
                return [newWest, newSouth, newEast, newNorth];
            },
            getBufferBbox(geometry){
                // 计算缓冲区缓冲后的扩大包围盒
                // 计算包围盒
                const bboxOfGeometry = turf.bbox(turf.buffer(geometry, 500, {units: 'meters'}));
                const west = bboxOfGeometry[0];
                const south = bboxOfGeometry[1];
                const east = bboxOfGeometry[2];
                const north = bboxOfGeometry[3];
                const newWest = west - (east - west) / 5;
                const newEast = east + (east - west) / 5;
                const newSouth = south - (north - south) / 5;
                const newNorth = north + (north - south) / 5;
                // 获取缓冲区缓冲后的扩大包围盒
                return [newWest, newSouth, newEast, newNorth];
            },

            getCellNumInSet() { // 根据zoom获取整体网格的网格数量
                let cellNum = 0;
                let zoom = Math.round(this.map.getZoom());
                do {
                    const west = this.pointBbox[0];
                    const east = this.pointBbox[2];
                    cellNum = Math.round((1 << (zoom)) * (east - west) / 180);
                    zoom = zoom + 1;
                } while (!cellNum && zoom<18);
                return cellNum;
            },

            setPointsBboxParam(){ // 计算点集包围盒的整体网格格子参数，用于后面生成视窗网格
                const west = this.pointBbox[0];
                const south = this.pointBbox[1];
                const east = this.pointBbox[2];
                const north = this.pointBbox[3];
                const cellNum = this.getCellNumInSet();
                this.cellSide = turf.distance([west, south], [east, south], {units: 'meters'}) / cellNum;
                const xFraction = this.cellSide / turf.distance([west, south], [east, south], {units: 'meters'});
                this.cellWidthDeg = xFraction * (east - west);
                const yFraction = this.cellSide / turf.distance([west, south], [west, north], {units: 'meters'});
                this.cellHeightDeg = yFraction * (north - south);
                const bboxWidth = east - west;
                const bboxHeight = north - south;
                const columns = Math.ceil(bboxWidth / this.cellWidthDeg);
                const rows = Math.ceil(bboxHeight / this.cellHeightDeg);
                this.deltaX = (bboxWidth - columns * this.cellWidthDeg) / 2;
                this.deltaY = (bboxHeight - rows * this.cellHeightDeg) / 2;
            },
            squareGrid(bbox, options){ // 方形网格图本地化，生成视窗网格时使用整体网格参数，防止视窗聚合抖动
                if (options === void 0) { options = {}; }
                const west = bbox[0];
                const south = bbox[1];
                const east = bbox[2];
                const north = bbox[3];
                const results = [];
                const bboxWidth = east - west;
                const bboxHeight = north - south;
                // 此处及后面循环使用整体网格参数，使视窗是整体网格的一部分
                const columns = Math.ceil(bboxWidth / this.cellWidthDeg);
                const rows = Math.ceil(bboxHeight / this.cellHeightDeg);
                // 此处移除居中逻辑，使视窗永远贴合整体网格
                let currentX = west;
                for (let column = 0; column < columns; column++) {
                    let currentY = south;
                    for (let row = 0; row < rows; row++) {
                        let cellPoly = turf.polygon([
                            [
                                [currentX, currentY],
                                [currentX, currentY + this.cellHeightDeg],
                                [currentX + this.cellWidthDeg, currentY + this.cellHeightDeg],
                                [currentX + this.cellWidthDeg, currentY],
                                [currentX, currentY],
                            ],
                        ], options.properties);
                        results.push(cellPoly);
                        currentY += this.cellHeightDeg;
                    }
                    currentX += this.cellWidthDeg;
                }
                return turf.featureCollection(results);
            },
            createGrid() {
                // 使用点数据，计算网格图图层
                // 计算当前窗口
                const windowBbox = this.getWindowBbox();

                // 如果当前zoom不够大，直接返回
                if(this.getCellNumInSet()===0){
                    return;
                }

                // 如果当前窗口与点集不相交，则直接返回
                if(turf.booleanDisjoint(windowBbox, this.pointData)){
                    return;
                }
                // 计算窗口与点集的交集包围盒
                const bboxIntersect = turf.bbox(turf.intersect(windowBbox, this.pointBboxPolygon));

                // 计算当前zoom下整体网格格子参数
                this.setPointsBboxParam();

                // 计算交集在整体网格中的格子，作为视窗
                const viewGridBbox = this.getInnerGridBbox(bboxIntersect);

                // 计算视窗网格
                const squareGrid = this.squareGrid(viewGridBbox);

                // 计算点与视窗的交集，减少下面的循环次数
                const ptsWithin = turf.pointsWithinPolygon(this.pointData, turf.bboxPolygon(viewGridBbox));

                // 计算每个网格的数据
                turf.featureEach(squareGrid, (currentGrid) => {
                    currentGrid.properties = {data: 0}
                    turf.featureEach(ptsWithin, (currentPoint) => {
                        if (turf.booleanContains(currentGrid, currentPoint)) {
                            currentGrid.properties.data += currentPoint.properties[this.dataPropertyName];
                        }
                    });
                    currentGrid.properties.data = Math.round(currentGrid.properties.data);
                    currentGrid.properties.dataPercentage = this.dataRange!==0?(currentGrid.properties.data- this.dataMin) / this.dataRange:
                        (currentGrid.properties.data!==0?1:0);
                });

                // 设置source，更新数据
                this.map.getSource(this.sourceId).setData(squareGrid);
            },
            remove(){ // 移除所有网格图图层
                if(this.map.getLayer(this.layerId)){
                    this.map.removeLayer(this.layerId);
                }
                if(this.map.getLayer(this.layerTextId)){
                    this.map.removeLayer(this.layerTextId);
                }
                if(this.map.getSource(this.sourceId)) {
                    this.map.removeSource(this.sourceId);
                }
            },
            initLayer(){
                // 用本次数据重新生成网格图
                this.map.addSource(this.sourceId,
                    {
                        type: 'geojson',
                        data: {
                            "type": "FeatureCollection",
                            "features": []
                        }
                    }
                );
                // 网格图层
                // todo:颜色样式调整
                this.map.addLayer({
                    id: this.layerId,
                    type: 'fill',
                    source: this.sourceId,
                    // filter: ["!=", "data", 0],
                    paint: {
                         'fill-color':this.color,
                        'fill-opacity': this.opacity,
                        'fill-outline-color':'#00ffff'
                    }
                });

                // 文字图层
                // // todo:文字样式调整
                // this.map.addLayer({
                //     id: this.layerTextId,
                //     type: 'symbol',
                //     source: this.sourceId,
                //      minzoom: 4,
                //     filter: ["!=", "data", 0],
                //     layout:{
                //         'text-field': ["get", "data"],
                //     }
                // });

            },
            mapFitBoundary(geo){
                const bbox = turf.bbox(geo)
                this.map.fitBounds([[bbox[0], bbox[1]], [bbox[2], bbox[3]]],{
                    linear: false,
                    animate: true,
                    // padding: {top: 120, bottom:130, left: 190, right: 500},
                })
            },
            reSetLayer(val){
                this.opacity = val
                this.map.setPaintProperty(this.layerId, 'fill-opacity', this.opacity);
            },
        },

        mounted() {
            this.mapFitBoundary(this.pointData)
            // 生成id，用于remove
            const id = Math.random().toString(36);
            this.sourceId = 'source-data-grid-' + id;
            this.layerId = 'layer-data-grid-' + id;
            this.layerTextId = 'layer-data-grid-text-' + id;

            // 计算数据集点数据中数据范围
/*            let max = Number.MIN_VALUE;
            let min = Number.MAX_VALUE;
            turf.featureEach(this.pointData, (currentPoint) => {
                max = Math.max(max, currentPoint.properties[this.dataPropertyName]);
                min = Math.min(min, currentPoint.properties[this.dataPropertyName]);
            });*/
            this.dataRange = this.dataMax - this.dataMin;
            if(this.dataMin !==this.dataMax && this.colorList.length >1){
                let val = parseFloat((1/this.colorList.length).toFixed(1));
                let temp = 0
                this.color.push(0)
                this.color.push('rgba(255,255,255,0)')
                this.color.push(1e-8)
                this.color.push('rgba(255,255,255,1)')
                for(let i = 0;i< this.colorList.length;i++){
                    temp = (temp+val)
                    this.color.push(temp)
                    this.color.push(this.colorList[i].hexColor)
                }
            }


            // 获取点的扩大包围盒
            this.pointBbox = this.getBufferBbox(this.pointData);
            this.pointBboxPolygon = turf.bboxPolygon(this.pointBbox);

            // 生成初始空source和layer
            this.initLayer();

            // 生成一次初始屏幕的网格图
            this.createGrid();
            this.map.on('zoomend', this.createGrid);
            this.map.on('moveend', this.createGrid);
        },

        destroyed() {
            this.map.off('zoomend', this.createGrid);
            this.map.off('moveend', this.createGrid);
            this.remove();
        }
    }
</script>

<style scoped>

</style>
