<template>
  <div id="cesium3dTiles">
    <div id="map" style="position: absolute"></div>
    <div style="position: absolute;top: 30px;left: 20px;font-size: 18px;color: white">
      <input v-model="isGlobalMap" type="checkbox" @change="onChangeMap"> 全球影像
    </div>
    <div v-if="simpleInfo.length > 0" id="bottom-sheet">
      <p style="margin:5px 0 16px 0;font-size:18px;font-weight:bold;color:white;">{{title}}</p>
      <div class="text-style">{{simpleInfo}}</div>
      <p style="margin-bottom:0;font-size:14px;color:white;">{{shareReason}}</p>
    </div>
  </div>
</template>

<script>
import * as Cesium from "cesium";
import {Matrix4} from "cesium";
export default {
  name: "Cesium3dTiles",
  mounted() {
    this.infoId = this.$route.query.infoId
    this.initCesium()
  },
  data(){
    return{
      isGlobalMap:false,
      infoId:'',
      viewer:'',
      layerList:[
        {
          "available":true,
          "code":"共生地球-GS吉(2023)010号",
          "image":"/image/layer_changer/1.jpg",
          "introduce":"",
          "layer":"china1m2021",
          "layerUrl":this.globalJS.world1mUrl_2021,
          "name":"全球卫星图",
          "outLink":"",
          "picture":"",
          "sort":1,
          "status":1,
          "thumb":"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com:443/202212151715000513312.jpg",
          "tms":true,
          "type":"cgwx"
        },
        {
          "available":true,
          "code":"共生地球-GS吉(2023)011号",
          "image":this.globalJS.china1mUrl_2022,
          "introduce":"最新图层 色彩真实 超赞细节！",
          "layer":"china2022",
          "layerUrl":this.globalJS.china1mUrl_2022,
          "name":"2022年全国一张图",
          "outLink":"https://g.h5gdsvip.com/p/yqsfikeh",
          "picture":"https://market.obs.cn-north-4.myhuaweicloud.com/202212281620000347996.png",
          "sort":2,
          "status":1,
          "thumb":"https://cgwxforum.obs.cn-north-4.myhuaweicloud.com:443/202212141630000227831_thumb.jpg",
          "tms":true,
          "type":"cgwx"
        }
      ],
      point:null,
      polyline:null,
      polygon:null,
      marker:null,
      viewType:'3D',
      title:"",
      simpleInfo:'',
      shareReason:'',
    }
  },
  methods:{
    async onChangeMap() {
      this.viewer.imageryLayers.removeAll()
      if (this.isGlobalMap) {
        const globalLayerProvider = await new Cesium.UrlTemplateImageryProvider({
          // url:'https://tile.charmingglobe.com/tile/world/tms/{z}/{x}/{reverseY}?v=v1&token=Bearer a84a40c81f784490a4c5689187054abf',
          url: this.layerList[0].layerUrl.replace("{y}", '{reverseY}'),
          // EPSG4326坐标系下的地图服务，需要添加
          // tilingScheme: new Cesium.GeographicTilingScheme(),
          style: "default",
          format: "image/jpeg"
        })
        this.viewer.imageryLayers.addImageryProvider(globalLayerProvider)
      } else {
        const globalLayerProvider = await new Cesium.UrlTemplateImageryProvider({
          // url:'https://tile.charmingglobe.com/tile/world/tms/{z}/{x}/{reverseY}?v=v1&token=Bearer a84a40c81f784490a4c5689187054abf',
          url: '',
          // EPSG4326坐标系下的地图服务，需要添加
          // tilingScheme: new Cesium.GeographicTilingScheme(),
          style: "default",
          format: "image/jpeg"
        })
        this.viewer.imageryLayers.addImageryProvider(globalLayerProvider)
      }
    },
    initCesium() {
      Cesium.Ion.defaultAccessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmZDYwMDgzNy1kZmRlLTQ3NDEtYjVlZS01YTM1NGQ4NzcwM2UiLCJpZCI6MTMzNzA5LCJpYXQiOjE2ODE0NTkzNTN9.c-bQKuAx16QHPA6S66XtGuqr_M1R1Z7elreAkuqJbPA"
      const globalLayerProvider = new Cesium.UrlTemplateImageryProvider({
        // url:this.layerList[0].layerUrl.replace("{y}",'{reverseY}'),
        url:'',
        // EPSG4326坐标系下的地图服务，需要添加
        // tilingScheme: new Cesium.GeographicTilingScheme(),
        style: "default",
        format: "image/jpeg"
      })
      this.viewer = new Cesium.Viewer('map', {
        homeButton: false,
        sceneModePicker: false,
        baseLayerPicker: false, // 影像切换
        animation: false, // 是否显示动画控件
        infoBox: false, // 是否显示点击要素之后显示的信息
        selectionIndicator: false, // 要素选中框
        geocoder: false, // 是否显示地名查找控件
        timeline: false, // 是否显示时间线控件
        fullscreenButton: false,
        shouldAnimate: false,
        navigationHelpButton: false, // 是否显示帮助信息控件
        scene3DOnly: true,
        navigationInstructionsInitiallyVisible: false,
        showRenderLoopErrors: false,
        shadows:true,
        terrainExaggeration: 1.0,
        requestRenderMode : true,
        contextOptions: {
          webgl: {
            alpha: true,
            depth: true,
            stencil: true,
            antialias: true,
            premultipliedAlpha: true,
            preserveDrawingBuffer: true,//通过canvas.toDataURL()实现截图需要将该项设置为true
            failIfMajorPerformanceCaveat: true
          }
        },
        maximumRenderTimeChange : Infinity,
        imageryProvider:globalLayerProvider,
      });
      document.getElementsByClassName("cesium-viewer-bottom")[0].style.visibility="hidden";//隐藏cesium水印
      this.viewer.scene.globe.baseColor = Cesium.Color.BLACK;
      this.viewer.scene.screenSpaceCameraController.minimumZoomDistance = -0
      this.viewer.scene.screenSpaceCameraController.maximumZoomDistance = 20000000
      this.viewer.scene.globe.showGroundAtmosphere = false;
      this.viewer.scene.skyAtmosphere.show = true;
      this.viewer.scene.fog.enabled = false;
      this.viewer.scene.globe.enableLighting = false;
      this.viewer.shadows = false;
      this.viewer.scene.sun.show=false;
      this.viewer.scene.moon.show=false;
      // this.viewer.scene.globe.lightingFadeOutDistance = 10000000;
      // this.viewer.scene.globe.lightingFadeInDistance =  30000000;
      // this.viewer.scene.globe.nightFadeOutDistance = 10000000;
      // this.viewer.scene.globe.nightFadeInDistance = 50000000;
      // this.viewer.scene.globe.enableLighting = true;
      // if(document.getElementById('mainArea-globe-zoomInfo-level')){
      //   document.getElementById('mainArea-globe-zoomInfo-level').innerText=this.getCameraLevel(Math.ceil(this.viewer.camera.positionCartographic.height));
      // }
      Cesium.ScreenSpaceEventHandler.mouseEmulationIgnoreMilliseconds = 1600;//浏览器忽略两次交互事件的时间间隔（毫秒）
      this.load3DTiles()
    },
    load3DTiles() {
      this.$http.get('/earthApi/tiles3d-info/getTiles3dInfoById?mainId='+this.infoId,).then(async response => {
        if (response.data.status === 200) {
          let tilePath = response.body.data.tilesUrl
          let height = response.body.data.height
          document.title = response.body.data.title
          this.title = response.body.data.title
          this.simpleInfo = response.body.data.simpleInfo
          this.shareReason = response.body.data.shareReason
          let tileset = await this.viewer.scene.primitives.add(new Cesium.Cesium3DTileset({
            url: tilePath, //数据地址
            maximumScreenSpaceError: 2,  //最大的屏幕空间误差
            maximumNumberOfLoadedTiles: 1000, //最大加载瓦片个数
          }));
          tileset.readyPromise.then(() => {
                // 获取经纬度
                let boundingSphere = tileset.boundingSphere;
                let center = boundingSphere.center;
                // 将图中心点坐标转换为WGS84坐标系下的经纬度
                let cartographic = Cesium.Cartographic.fromCartesian(center);
                let longitude = Cesium.Math.toDegrees(cartographic.longitude);
                let latitude = Cesium.Math.toDegrees(cartographic.latitude);
                let altitude = cartographic.height;
                console.log(altitude)
                console.log(longitude)
                console.log(latitude)
                // this.viewer.camera.setView({
                //   destination: Cesium.Cartesian3.fromDegrees(longitude, latitude, height),
                //   orientation: {
                //     heading: Cesium.Math.toRadians(90),
                //     pitch: Cesium.Math.toRadians(-30),
                //     roll: 0.0
                //   }
                // })

                this.viewer.camera.lookAt(center,new Cesium.HeadingPitchRange(Cesium.Math.toRadians(0), Cesium.Math.toRadians(-50), height))
                this.viewer.camera.lookAtTransform(Matrix4.IDENTITY)
              }
          )
        }
      })
    },
  }
}
</script>

<style scoped>
#cesium3dTiles {
  height: 100%;
  width: 100%;
  background-color: black;
  display: flex;
  align-items: center;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

#map{
  width: 100%;
  height: 100%;
}

#bottom-sheet{
  position: fixed;
  bottom: 0;
  background-color:#38383899;
  width: calc(100% - 40px);
  height: fit-content;
  border-radius: 16px 16px 0px 0px;
  padding:20px;
}

.text-style{
  font-size: 16px;
  color: white
}
</style>