<template>
  <div style="top: 0;bottom: 0;right: 0;left: 0;position: absolute">

      <div style="width: 100%;padding-top: 70px">
        <img :src="detail.content2" width="100%" style="display: block">
        <div style="display: flex;justify-content: right;border-radius: 0 0 8px 8px;background: #FFFFFF;height: 44px;box-shadow: 0 2px 10px 0 rgba(95,95,95,0.50);padding-left: 18px;padding-right: 25px">
          <a id="openApp" :href="url">
            <div  style="display: flex;">
              <div style="color: #675CB5;font-size: 14px;line-height: 44px">算我一份</div>
              <span class="iconfontFamily" style="color: #675CB5;font-size: 12px;line-height: 44px;margin-left:6px ">&#xe77c;</span>
            </div>
          </a>
        </div>
      </div>
    <TopShareBar/>
  </div>
</template>

<script>
import TopShareBar from "@/components/TopShareBar";
import {getWxSignature} from "@/utils/shareConfig";

export default {
  name: "CampusActivity",
  components: {
    TopShareBar,
  },
  mounted() {
    if(this.$route.query.share === "1"){
      this.share = 1
    }else{
      this.share = 0
    }
    if(this.$route.query.activityId !== null){
      this.activityId = this.$route.query.activityId
      getWxSignature(this.$route.query.activityId,"校园活动")
    }
    this.getCampusActivityList()
  },
  data(){
    return {
      share:0,
      activityId:"",
      detail:[]
    }
  },
  computed:{
    url(){
      return '/OpenApp?infoId=' + this.$route.query.infoId + '&type=103'
    }
  },
  methods:{
    getCampusActivityList() {
      this.$http.get('/earthApi/s-school-info/getSchoolActivityById?activityId=' + this.activityId).then(response => {
        if(response.data.status === 200){
          this.detail = response.body.data;
          console.log(this.detail)
          // if(window["CampusImageDetail"]){
          //   window["CampusImageDetail"].postMessage(JSON.stringify(this.detail));
          // }
        } else {
          this.$router.push(
              {
                path: '/NotExist',
              }
          )
        }
      })
    },
  }
}
</script>

<style scoped>

</style>