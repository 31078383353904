<template>
    <div id="tipsPage">
      <!--顶部下载栏-->
      <TopShareBar v-if="share"/>
        <!--内容-->
        <div :style="{'padding':share ? '65px 20px 20px 20px' : '0 20px 20px 20px'}">
<!--          <div style="height:10px;background:#ff0000;"></div>-->
            <!--话题行-->
            <div class="topicLine" style="overflow:hidden;">
                <div class="topicLine-topic">
                  <div class="topicLine-topic-icon"><p style="line-height:15px;">#</p></div>
                  <p style="line-height:25px;float:left;">{{articleDetail.topic}}</p>
                </div>
                <p v-if="!share" @click="createTipsByTopic" class="topicLine-joinTopic">+ 参与话题</p>
            </div>
            <!--用户名-->
            <div class="userLine">
                <img :src="articleDetail.avatar" @click="intoUserPage" class="circleAvatar">
                <div style="height:100%;float:left;">
                    <p class="userName">{{articleDetail.nick}}</p>
                    <p class="createTime">{{articleDetail.timeDisplay}}</p>
                </div>
                <div v-if="!share && articleDetail.focusStatus === 0" @click="focusUser" style="margin-left:0;float:right;" class="focusBtn">
                    <p>+ 关注</p>
                </div>
                <div v-if="!share && articleDetail.focusStatus === 1 || articleDetail.focusStatus === 2" @click="unfocusUser"
                     style="border:1px solid #8a8a8a;color:#8a8a8a;margin-left:0;float:right;" class="focusBtn">
                    <p>已关注</p>
                </div>
            </div>
            <!--正文-->
            <div class="contextLine">{{articleDetail.context}}</div>
            <!--配图-普通tips-->
            <div style="overflow:hidden;">
                <div @click="intoFullScreenImage(index)" v-for="(item,index) in articleDetail.picList" :key="index" class="contextImage"
                     :style="{'background-image':'url(' + articleDetail.picList[index] +')','margin-right':(index + 1) % 3 !== 0 ? '10px' : '0'}"></div>
            </div>
            <!--分隔线-->
            <div style="width:100%;height:1px;background:#F4F4F4"></div>
            <!--地图-->
            <div class="mapLine">
                <l-map ref="tipsPageMap" :maxBounds="maxBounds" :zoom="zoom" :center="center" :options="option">
                    <l-tile-layer ref="mapLayer" :url="mapUrl" :options="mapSubdomains" />
                    <l-tile-layer ref="mapLayer" :url="infoUrl" :options="mapSubdomains" />
                    <!--<l-tile-layer ref="mapLayer" :url="chinaOneUrl" :options="{tms:true}" />-->
                </l-map>
            </div>
            <!--地址-->
            <div class="addressLine">
                <div class="addressLine-address">
                    <p>
                        <span style="color:#675CB8" class="iconfontFamily">&#xe61e;</span>
                        {{articleDetail.geoName}}
                    </p>
                </div>

            </div>
        </div>

    </div>

</template>

<script>

    import L from 'leaflet'
    import { LMap, LTileLayer } from 'vue2-leaflet';
    import TopShareBar from '@/components/TopShareBar';
    import {getWxSignature} from "@/utils/shareConfig";
    export default {
        name:"TipsPage",
        components: {LMap, LTileLayer,TopShareBar},
        data(){
            return{

                mainInfoId:"",
                token:"",
                imei:"",
                share:false,
                articleDetail:"",


                //地图
                zoom: 12,
                center: L.latLng(36.2604541134187, 117.081861669118),
                maxBounds: L.latLngBounds([[85,-180], [-85,180]]),
                option: {zoomControl: false, attributionControl: false, renderer: L.canvas()},
                mapUrl:'https://t{s}.tianditu.gov.cn/DataServer?T=vec_w&X={x}&Y={y}&L={z}&tk=05861044e5fe4135647facbd39abc543',
                infoUrl:'https://t{s}.tianditu.gov.cn/DataServer?T=cva_w&X={x}&Y={y}&L={z}&tk=05861044e5fe4135647facbd39abc543',
                // chinaOneUrl:this.globalJS.world1mUrl_2021,
                mapSubdomains:{subdomains: ['0', '1', '2', '3']},

            }

        },
        methods:{
            getDetail(){
                let url
                if(this.share){
                    url = '/earthApi/main-info/getMainInfoDetail?infoId='+this.mainInfoId + '&shareType=1'
                }
                else{
                    url = '/earthApi/main-info/getMainInfoDetail?infoId='+this.mainInfoId
                }

                this.$http.get(url, this.share ? "" : {headers:{'Authorization': 'Bearer ' + this.token,'imei':this.imei}}).then(response => {
                  if(response.body.status === 200){
                    this.articleDetail = response.body.data;

                    document.title = this.articleDetail.title;

                    let icon= L.icon({
                      iconUrl: require('../../assets/Tips/position.png'),//marker图片地址
                      iconSize: [15, 25],//marker宽高
                      // iconAnchor: [28.5, 71]//marker中心点位置
                    })
                    L.marker([JSON.parse(this.articleDetail.geo).coordinates[1], JSON.parse(this.articleDetail.geo).coordinates[0]], { icon: icon })
                        .addTo(this.$refs.tipsPageMap.mapObject)
                    //地图飞到地点
                    this.$refs.tipsPageMap.mapObject.setView(
                        [JSON.parse(this.articleDetail.geo).coordinates[1],JSON.parse(this.articleDetail.geo).coordinates[0]],
                        14
                    )

                    //数据详情传给flutter
                    if(window["flutter_articleInfo"]){
                      window["flutter_articleInfo"].postMessage(JSON.stringify(this.articleDetail));
                    }
                  }
                  else if(response.body.status === 404){
                    this.$router.push(
                        {
                          path: '/NotExist',
                        }
                    )
                  }
                  else{
                    console.log(response.data.status + response.data.message)
                  }



                })

            },

            //点击用户头像
            intoUserPage(){
                if(window["flutter_intoUserPage"]){
                    window["flutter_intoUserPage"].postMessage("");
                }
            },
            //参与话题
            createTipsByTopic(){
                if(window["flutter_createTipsByTopic"]){
                    window["flutter_createTipsByTopic"].postMessage("");
                }
            },
            //点击关注按钮
            focusUser(){
                if(window["flutter_focusUser"]){
                    window["flutter_focusUser"].postMessage("");
                }
            },
            unfocusUser(){
                if(window["flutter_unfocusUser"]){
                    window["flutter_unfocusUser"].postMessage("");
                }
            },
            focusUserChangeStatus(state){
                this.articleDetail.focusStatus = state;
            },
            //tips看大图
            intoFullScreenImage(index){
                if(window["flutter_intoFullScreenImage"]){
                    window["flutter_intoFullScreenImage"].postMessage(index.toString());
                }
            },



        },

        mounted(){
            window.tipsPage = this;
            if(this.$route.query.infoId){
              this.mainInfoId = this.$route.query.infoId;
              this.token = this.$route.query.token
              this.imei = this.$route.query.imei
              // this.token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwaG9uZSI6IjE4NzQ0MDIzMDAxIiwiZXhwIjoxNjUzMjgyNDc3LCJ1c2VySWQiOjEyMzQ1NiwiZW1haWwiOiIifQ.rqEg7XUo5e0rymJLIKBpNWJ2AeWkNXArmn-NUgtUVXw"
              // this.imei = "1652081227956"
              if(this.$route.query.share == 1){
                this.share = true;
              }
              getWxSignature(this.$route.query.infoId)
              this.getDetail();
            }
            else{
              window.location.href = this.$route.fullPath.replace("mainInfoId","infoId")
            }
        }
    }

</script>

<style scoped>
    #tipsPage {
        font-family: 微软雅黑;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        /*text-align: center;*/
        color: #ffffff;
        position: absolute;
        /*width: calc(100% - 40px);*/
        width:100%;
        height: 100%;
        z-index: 0;
        /*overflow: hidden;*/
        /*padding:0 20px 20px 20px;*/
    }
    #tipsPage::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    #tipsPage p{
        margin:0;
    }

    .topicLine{
        margin-bottom:15px;
        font-size:16px;
        color:#000000;
        font-weight:bold;
    }
    .topicLine-topic{
        height:25px;
        padding:0 12px;
        background:#FDF4CC;
        border-radius:12px;
        color:#F7B500;
        text-align:center;
        font-size:14px;
        float:left;
    }
    .topicLine-topic-icon{
        width:15px;
        height:15px;
        background:#F8C943;
        border-radius:6px 6px 0 6px;
        margin-right:5px;
        margin-top:5px;
        float:left;
        color:#ffffff;
        font-size:12px;
        text-align:center;
    }
    .topicLine-joinTopic{
        color:#F7B500;
        font-size:14px;
        line-height:25px;
        float:right;
    }

    .userLine{
        width:100%;
        height:32px;
        margin-bottom:12px;
    }
    .circleAvatar{
        width:30px;
        height:30px;
        border-radius:50%;
        margin-right:3px;
        float:left;
    }
    .userName{
        margin:1px 0 2px 0;
        font-size:12px;
        color:#000000;
    }
    .createTime{
        font-size:10px;
        color:#979797;
    }
    .focusBtn{
        /*width:45px;*/
        /*min-height:20px;*/
        border:1px solid #675CB5;
        border-radius:12px;
        margin:6px 0 0 29px;
        padding:2px 10px;
        float:left;
        font-size:12px;
        color:#675CB5;
        text-align:center;
    }

    .contextLine{
        margin-bottom:10px;
        font-size:14px;
        color:#000000;
        word-wrap: break-word;
    }
    .contextImage{
        width:calc((100vw - 60px) / 3);
        height:calc((100vw - 60px) / 3);
        margin-bottom:10px;
        background-repeat:no-repeat;
        background-position:center;
        background-size:cover;
        float:left;
    }

    .mapLine{
        width:100%;
        height:250px;
        margin:10px 0;
    }

    .addressLine{
        width:100%;
        height:25px;
    }
    .addressLine-address{
        /*height:25px;*/
        padding:4px 10px;
        background:#F5F5F6;
        border-radius:6px;
        color:#979797;
        text-align:center;
        font-size:12px;
        float:left;
    }


</style>
