<template>

  <div id="memberActivation" class="alibabaPuHuiTiFontFamily">
    <!--顶部下载栏 由发愿望也打开时，返回我的愿望页-->
    <TopShareBarWithBack v-if="share && from!=='createWish'"/>
    <TopShareBarWithBack v-if="share && from==='createWish'" backPage="/MyWish" :backPageParam="JSON.stringify({infoId: this.$route.query.infoId,share: 1})"/>

    <div style="width:100%;padding-bottom:30px;">
      <img src="../assets/MemberActivation/background.jpg" style="position:relative;width:100%;">

      <div class="itemTitle">
        <div style="width:10px;height:16px;background: linear-gradient(90deg, #FFECD2 0%, #FED150 100%), #FFFFFF;"></div>
        <p style="margin-left:9px;">我有邀请码，立即认证</p>
      </div>

      <!--未激活-->
      <div v-if="$store.state.member == 0">
        <input v-model="activationCode" placeholder="请输入激活码~" class="inputActivationCode" />
        <div @click="gotoActivation" style="width:160px;height:52px;margin:35px auto;font-size:16px;color:rgba(219, 110, 0, 0.8);text-align:center;border-radius:28px;
      background:linear-gradient(90deg, rgba(255, 201, 125, 0.5) 0%, rgba(254, 209, 80, 0.5) 100%), rgba(255, 255, 255, 1);">
          <p style="line-height:52px;">激活</p>
        </div>

        <!--发愿望进入-->
        <div v-if="from === 'createWish'">
          <div style="margin-top:33px;" class="itemTitle">
            <div style="width:10px;height:16px;background: linear-gradient(90deg, #FFECD2 0%, #FED150 100%), #FFFFFF;"></div>
            <p style="margin-left:9px;">我暂时无法获取邀请码？</p>
          </div>
          <p style="width:calc(100% - 68px);margin:14px 0 0 20px;font-size:16px;color:#ffffff;">点击下方按钮进入卫星许愿池，<span style="color:rgba(255, 143, 31, 1);">高分03A</span> 将定期抽取部分愿望发送至卫星，请耐心等待。</p>
          <div @click="gotoMyWish" style="width:160px;height:52px;margin:33px auto 0 auto;font-size:16px;color:rgba(42, 130, 228, 1);text-align:center;border-radius:28px;
      background:rgba(214, 233, 255, 1);">
            <p style="line-height:52px;">进入卫星许愿池</p>
          </div>
        </div>

      </div>
      <!--已激活-->
      <div v-if="$store.state.member == 1">
        <div style="width:100%;height:50px;margin-top:40px;margin-bottom:40px;display:flex;align-items: center;justify-content: center;">
          <p style="margin-right:7px;color:#43CF7C;font-size:34px;" class="iconfontFamily">&#xe785;</p>
          <p style="font-size:20px;font-weight:bold;color:#ffffff;">激活成功！</p>
        </div>
      </div>




    </div>


    <!--toast-->
    <div v-if="toastShow" style="position:fixed;top:50%;left:50%;transform:translate(-50%,-50%);height:60px;padding:0 20px;background:rgba(0, 0, 0, 0.4);border-radius:10px;text-align:center;">
      <p style="white-space:nowrap;line-height:60px;font-size:14px;color:#ffffff;">
        <span class="iconfontFamily" style="margin-right:4px;">&#xe621;</span>
        {{ toastText }}
      </p>
    </div>


  </div>



</template>

<script>
import TopShareBarWithBack from '@/components/TopShareBarWithBack';
import {getWxSignature} from "@/utils/shareConfig";
export default {
  name: "MemberActivation",
  components: {TopShareBarWithBack},
  data(){
    return{

      activationCode:"",

      toastShow:false,
      toastText:"",

      share:false,
      token:"",
      from:"",
      fromParam:"",


    }

  },
  methods:{

    //激活
    gotoActivation(){
      if(this.share){
        this.activationFun();
      }
      else{
        if(window["flutter_gotoActivation"]){
          window["flutter_gotoActivation"].postMessage("");
        }
      }


    },
    //激活方法
    activationFun(){

      let myToken = "";
      if(!this.share){
        myToken = this.token;
      }
      else{
        myToken = this.$store.state.token;
      }

      this.$http.get('/earthApi/userMemberCode/useMemberCode?code='+this.activationCode, myToken === "" ? "" : {headers:{'Authorization': 'Bearer ' + myToken}}).then(response => {
        if(response.body.status === 200){
          this.toastText = "激活成功";
          this.toastShow = true;
          setTimeout(()=>{
            this.toastShow = false;
            //修改激活状态
            this.$store.state.member = 1;

            //激活成功后方法
            if(this.share){
              //来自哪个页面
              switch(this.from){
                //卫星祈愿，我的愿望
                case "myWish":{
                  this.$router.push(
                      {
                        path: '/MyWish',
                        query: {
                          infoId: this.$route.query.infoId,
                          share: 1
                        }
                      }
                  )
                  break;
                }
                //卫星祈愿，发愿望
                case "createWish":{
                  this.$router.push(
                      {
                        path: '/MyWish',
                        query: {
                          infoId: this.$route.query.infoId,
                          share: 1
                        }
                      }
                  )
                  break;
                }


              }


            }
            else{
              if(window["flutter_activationSuccess"]){
                window["flutter_activationSuccess"].postMessage("");
              }
            }


          },2000)
        }
        else{
          this.toastText = "激活失败";
          this.toastShow = true;
          setTimeout(()=>{
            this.toastShow = false;
          },2000)
        }
      })
    },
    //flutter激活
    activation_flutter(token){
      this.token = token;
      this.activationFun();
    },


    //不激活，进入我的愿望
    gotoMyWish(){
      if(this.share){
        if(this.$store.state.token === ""){
          this.$router.push(
              {
                path: '/Login',
              }
          )
        }
        else{
          this.$router.push(
              {
                path: '/MyWish',
                query: {
                  infoId: this.cardActivityId,
                  share: 1
                }
              }
          )
        }

      }
      else{
        if(window["flutter_gotoMyWish"]){
          window["flutter_gotoMyWish"].postMessage("");
        }
      }



    }




  },
  mounted(){
    window.memberActivation = this;
    if(this.$route.query.share == 1){
      this.share = true;
    }
    else{
      //从flutter调用
      if(this.$route.query.memberState !== undefined){
        if(this.$route.query.memberState === "" || this.$route.query.memberState === "0"){
          this.$store.state.member = 0
        }
        else{
          this.$store.state.member = 1
        }
      }
    }
    if(this.$route.query.from !== undefined){
      this.from = this.$route.query.from;
    }
    if(this.$route.query.fromParam !== undefined){
      this.fromParam = JSON.stringify(this.$route.query.fromParam);
    }
    if(this.$route.query.token !== undefined){
      this.token = this.$route.query.token
      window.history.replaceState('', '', this.$route.fullPath.substring(0,this.$route.fullPath.indexOf("token") - 1))
    }
    getWxSignature(this.$route.query.infoId,"卫星活动")



  }
}
</script>

<style scoped>

#memberActivation{
  position:absolute;
  width:100%;
  height:100%;
  background:#383F8C;
  overflow:auto;
}

#memberActivation p{
  margin:0;
}


.itemTitle{
  height:32px;
  font-family: YouSheBiaoTiHei;
  font-size:24px;
  display:flex;
  align-items:center;
  color:rgba(255, 229, 186, 1);
  /*color:transparent;*/
  /*background: linear-gradient(90deg, rgba(255, 201, 125, 0.5) 0%, rgba(254, 209, 80, 0.5) 100%), rgba(255, 255, 255, 1);*/
  -webkit-background-clip:text;
}
.inputActivationCode{
  width:calc(100% - 60px - 40px);
  height:54px;
  margin-top:35px;
  margin-left:30px;
  padding:0 20px;
  border:none;
  background: linear-gradient(90deg, rgba(255, 201, 125, 0.25) 0%, rgba(254, 209, 80, 0.31) 100%), rgba(255, 255, 255, 1);
  border-radius:10px;
  outline:none;
  font-size:16px;
  line-height:56px;
  color:rgba(219, 110, 0, 0.8);
}
.inputActivationCode::-webkit-input-placeholder{
  color:rgba(219, 110, 0, 0.8);
}


</style>