<template>

  <div ref="productPageWebMarker">
    <div v-if="device==='web'" class="productPageWebMarker">
      <!--顶栏-->
      <div style="position:fixed;top:0;left:0;width:calc(100% - 90px);height:80px;background:rgba(21, 21, 40, 0.45);padding:0 45px;display:flex;align-items:center;z-index:99;">
        <div style="flex:1;">
          <img @click="reloadPage" style="width:206px;cursor:pointer;" src="../../assets/App/productPage/webMarker/logo.png" />
        </div>

        <p @click="gotoApp" style="font-size:16px;cursor:pointer;">首页</p>
        <p @click="gotoWebMarker" style="margin-left:49px;font-size:16px;cursor:pointer;">在线体验</p>
        <p @click="gotoAboutUsDetailPage" style="margin-left:63px;font-size:16px;cursor:pointer;">关于我们</p>

        <div style="flex:1;">
          <div @click="gotoWebMarker" style="width:128px;height:38px;border-radius:20px;background:linear-gradient(175deg,rgba(255, 255, 255, 0.5) 0%,rgba(255, 255, 255, 0) 100%);padding:1px;float:right;cursor:pointer;">
            <div style="width:128px;height:38px;border-radius:20px;background:rgba(42, 130, 228, 1);text-align:center;">
              <p style="line-height:38px;font-size:14px;">注册/登录</p>
            </div>
          </div>
        </div>

      </div>

      <!--第一屏-->
      <div id="productPageWebMarkerScreen1" style="display:flex;align-items:center;" class="screen">
        <div :style="{'min-width':webScreenSize === 'big' ? '640' : '550px'}" style="width:50%;">
          <div :style="{'width':webScreenSize === 'big' ? '580px' : '490px'}" style="position:relative;left:50%;transform:translate(-50%);height:100%;">
            <p style="text-align:left;" class="productPageWebMarkerColorTitle">吉林一号·共生地球web端</p>
            <div>
              <p style="margin-top:39px;line-height:35px;" class="productPageWebMarkerPs">为创作者而生的地图标记工具<br/>支持标记、规划、多人协同等各类主题的多种地图制作<br/>帮助创作者创作出更有价值的信息</p>
              <div @click="gotoWebMarker" style="width:128px;height:38px;margin-top:96px;border-radius:20px;background:linear-gradient(175deg,rgba(255, 255, 255, 0.5) 0%,rgba(255, 255, 255, 0) 100%);padding:1px;cursor:pointer;">
                <div style="width:128px;height:38px;border-radius:20px;background:rgba(42, 130, 228, 1);text-align:center;">
                  <p style="line-height:38px;font-size:14px;">
                    立即体验
                    <span style="margin-left:9px;" class="iconfontFamily">&#xe7a0;</span>
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div style="flex:1;height:100%;">
          <div id="screen1MainImage" :style="{'max-width':webScreenSize === 'big' ? '780px' : '600px'}" style="position:relative;left:0;top:50%;transform:translate(0,-50%);width:100%;max-width:780px;">
            <img style="width:95%;border-radius:10px;"
                 src="../../assets/App/productPage/webMarker/screen1/mainPicture.png" />
            <!--线 animation-duration:2s;animation-fill-mode: forwards;-->
            <div id="screen1LineShowArea" style="position:absolute;bottom:-25%;left:22%;width:53%;overflow:hidden;">
              <img src="../../assets/App/productPage/webMarker/screen1/line.png" :style="{'width':webScreenSize === 'big' ? screen1LineWidth : '100%'}" style="object-fit: contain;" />
            </div>
          </div>
        </div>
      </div>

      <!--第二屏-->
      <div id="productPageWebMarkerScreen2" class="screen">
        <div :style="{'width':webScreenSize === 'big' ? '1290px' : '1000px'}"
             style="position:relative;top:50%;left:0;transform:translate(0,-50%);margin:0 auto;display:flex;flex-direction:column;align-items:center;">
          <p id="productPageWebMarkerScreen2_title" class="productPageWebMarkerColorTitle floatAnimationDom">便捷操作 灵活标记</p>
          <p id="productPageWebMarkerScreen2_ps" class="floatAnimationDom productPageWebMarkerPs" style="width:100%;margin-top:14px;text-align:center;">海量数据 智能管理</p>

          <div id="productPageWebMarkerScreen2_img" class="floatAnimationDom" style="width:100%;margin-top:72px;display:flex;align-items:center;">
            <div :style="{'width':webScreenSize === 'big' ? '660px' : '460px'}">
              <img style="width:100%;" src="../../assets/App/productPage/webMarker/screen2/mainPicture.png" />
            </div>
            <div :style="{'width':webScreenSize === 'big' ? '580px' : '490px'}" style="position:relative;margin-left:50px;">
              <p :style="{'font-size':webScreenSize === 'big' ? '24px' : '20px'}" style="line-height:35px;">随意发挥和创作地图<br/>只需轻轻点击即可标记地点、绘制路线、拖拽缩放地球</p>
            </div>
          </div>
        </div>
        <!--底部渐变-->
        <div style="position:absolute;bottom:0;left:0;width:100%;height:120px;background:linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(19,20,22,1) 100%);"></div>



      </div>

      <!--第三屏-->
      <div id="productPageWebMarkerScreen3" class="screen">
        <div :style="{'width':webScreenSize === 'big' ? '1290px' : '1000px'}"
             style="position:relative;top:50%;left:0;transform:translate(0,-50%);margin:0 auto;display:flex;flex-direction:column;align-items:center;">
          <p id="productPageWebMarkerScreen3_title" class="productPageWebMarkerColorTitle floatAnimationDom">丰富的录制效果</p>
          <p id="productPageWebMarkerScreen3_ps" class="floatAnimationDom productPageWebMarkerPs" style="width:100%;margin-top:14px;text-align:center;">样式丰富的地球特效</p>

          <div id="productPageWebMarkerScreen3_img" class="floatAnimationDom" style="width:100%;margin-top:72px;display:flex;align-items:center;">
            <div :style="{'width':webScreenSize === 'big' ? '660px' : '460px'}">
              <img style="width:100%;" src="../../assets/App/productPage/webMarker/screen3/mainPicture.png" />
            </div>
            <div :style="{'width':webScreenSize === 'big' ? '580px' : '490px'}" style="position:relative;margin-left:50px;">
              <p :style="{'font-size':webScreenSize === 'big' ? '24px' : '20px'}" style="line-height:35px;">地平线飞跃、螺旋环绕、多样特效支持自由创作<br/>轻松制作独特视角地球浏览效果</p>
            </div>
          </div>
        </div>
      </div>

      <!--第四屏-->
      <div id="productPageWebMarkerScreen4" class="screen">
        <div :style="{'width':webScreenSize === 'big' ? '1290px' : '1000px'}"
             style="position:relative;top:50%;left:0;transform:translate(0,-50%);margin:0 auto;display:flex;flex-direction:column;align-items:center;">
          <p id="productPageWebMarkerScreen4_title" class="productPageWebMarkerColorTitle floatAnimationDom">多人协同 地图创作</p>
          <p id="productPageWebMarkerScreen4_ps" class="floatAnimationDom productPageWebMarkerPs" style="width:100%;margin-top:14px;text-align:center;">随时随地 多端联动</p>

          <div id="productPageWebMarkerScreen4_img" class="floatAnimationDom" style="width:100%;margin-top:72px;display:flex;align-items:center;">
            <div :style="{'width':webScreenSize === 'big' ? '660px' : '460px'}">
              <img style="width:100%;" src="../../assets/App/productPage/webMarker/screen4/mainPicture.png" />
            </div>
            <div :style="{'width':webScreenSize === 'big' ? '580px' : '490px'}" style="position:relative;margin-left:50px;">
              <p :style="{'font-size':webScreenSize === 'big' ? '24px' : '20px'}" style="line-height:35px;">邀请朋友、亲人、同学或团队成员参与地球协作编辑<br/>在地球上添加地点、绘制路线</p>
            </div>
          </div>
        </div>
      </div>

      <!--第五屏-->
      <div id="productPageWebMarkerScreen5" class="screen">
        <div style="position:relative;top:50%;left:0;transform:translate(0,-50%);width:100%;display:flex;flex-direction:column;align-items:center;">
          <p id="productPageWebMarkerScreen5_title" class="productPageWebMarkerColorTitle floatAnimationDom">丰富创作场景 不止于地图</p>
          <p id="productPageWebMarkerScreen5_ps" class="floatAnimationDom productPageWebMarkerPs" style="width:100%;margin-top:14px;text-align:center;">生活记录 办公提效</p>

          <div id="productPageWebMarkerScreen5_img" class="floatAnimationDom" style="margin-top:85px;display:flex;align-items:center;">

            <div @mouseover="screen5ContentItemMouseover(index)" @mouseout="screen5ContentItemMouseout(index)" v-for="(item,index) in screen5ContentList" :key="'screen5ContentItem'+index"
                 :style="{'background-image':'url('+item.img+')','width':webScreenSize === 'big' ? '250px' : '200px','height':webScreenSize === 'big' ? '375px' : '300px'}"
                 style="position:relative;height:375px;margin:0 20px;border-radius:12px;background-size:100% 100%;">
              <div :id="'screen5ContentItem'+index"
                   style="position:absolute;bottom:0;left:0;width:calc(100% - 30px);height:40px;padding:0 15px;background:rgba(0, 0, 0, 0.56);border-radius:0 0 8px 8px;
                 animation-duration:200ms;animation-fill-mode: forwards;overflow:hidden;">
                <p style="line-height:40px;font-size:18px;">{{item.title}}</p>
                <p style="margin-top:21px;margin-bottom:21px;">{{item.ps}}</p>
              </div>
            </div>

          </div>
        </div>

        <!--底部渐变-->
        <div style="position:absolute;bottom:0;left:0;width:100%;height:113px;background:linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);z-index:1;"></div>

      </div>

      <!--第六屏-->
      <div id="productPageWebMarkerScreen6" style="display:flex;flex-direction:column;" class="screen">

        <div style="flex:1;">
          <div style="position:relative;top:60%;left:0;transform:translate(0,-50%);width:100%;display:flex;flex-direction:column;align-items:center;">
            <!--标题-->
            <p :style="{'font-size':webScreenSize === 'big' ? '60px' : '38px'}" style="line-height:80px;" class="productPageWebMarkerColorTitle">立即开启遥感影像创作之旅</p>
            <!--按钮-->
            <div @click="gotoWebMarker" style="width:128px;height:38px;margin-top:96px;border-radius:20px;background:linear-gradient(175deg,rgba(255, 255, 255, 0.5) 0%,rgba(255, 255, 255, 0) 100%);padding:1px;cursor:pointer;">
              <div style="width:128px;height:38px;border-radius:20px;background:rgba(42, 130, 228, 1);text-align:center;">
                <p style="line-height:38px;font-size:14px;">
                  立即体验
                  <span style="margin-left:9px;" class="iconfontFamily">&#xe7a0;</span>
                </p>
              </div>
            </div>

          </div>
        </div>

        <!--底栏-->
        <div style="width:100%;height:364px;">
          <app-page-foot-bar :produceList="footBarProductList"></app-page-foot-bar>

        </div>

        <!--顶部渐变-->
        <div style="position:absolute;top:0;left:0;width:100%;height:113px;background:linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);"></div>


      </div>


    </div>

    <div v-if="device==='mobile'" style="padding-top:80px;" class="productPageWebMarker">
      <!--顶栏-->
      <div style="position:fixed;top:0;left:0;width:calc(100% - 30px);height:80px;background:rgba(21, 21, 40, 0.45);padding:0 15px;display:flex;align-items:center;z-index:99;">
        <img @click="reloadPage" style="width:146px;" src="../../assets/App/productPage/webMarker/logo.png" />
        <div style="flex:1;"></div>

        <p @click="gotoApp" style="font-size:14px;">首页</p>
        <p @click="gotoWebMarker" style="margin-left:14px;font-size:14px;">在线体验</p>
        <p @click="gotoAboutUsDetailPage" style="margin-left:14px;margin-right:5px;font-size:14px;">关于我们</p>

      </div>


      <!--第一屏-->
      <div id="productPageWebMarkerScreen1_mobile">
        <div style="position:relative;padding-bottom:80px;">
          <div style="width:calc(100% - 40px);margin:0 auto;padding-top:30px;">
            <p :style="{'font-size':mobileScreenSize === 'small' ? '28px' : '46px'}" class="productPageWebMarkerColorTitle_mobile">吉林一号·共生地球web端</p>
            <p style="margin-top:20px;" class="productPageWebMarkerPs_mobile">为创作者而生的地图标记工具</p>
            <p class="productPageWebMarkerPs_mobile">支持标记、规划、多人协同</p>
            <p class="productPageWebMarkerPs_mobile">各类主题的多种地图制作</p>
            <p class="productPageWebMarkerPs_mobile">帮助创作者创作出更有价值的信息</p>

            <div @click="gotoWebMarker" style="width:116px;height:38px;margin-top:34px;border-radius:20px;background:linear-gradient(175deg,rgba(255, 255, 255, 0.5) 0%,rgba(255, 255, 255, 0) 100%);padding:1px;">
              <div style="width:115px;height:37px;border-radius:20px;background:rgba(42, 130, 228, 1);text-align:center;">
                <p style="line-height:36px;font-size:14px;">
                  立即体验
                  <span style="margin-left:9px;" class="iconfontFamily">&#xe7a0;</span>
                </p>
              </div>
            </div>
          </div>

          <div :style="{'max-width':mobileScreenSize === 'small' ? '550px' : '600px'}" style="position:relative;width:calc(100% - 40px);margin:40px auto 0 auto;">
            <img style="width:100%;border-radius:10px;" src="../../assets/App/productPage/webMarker/screen1/mainPicture.png" />
            <div id="screen1LineShowArea" style="position:absolute;bottom:-25%;left:22%;width:53%;">
              <img src="../../assets/App/productPage/webMarker/screen1/line.png" style="width:100%;object-fit: contain;" />
            </div>
          </div>

        </div>
      </div>

      <!--第二屏-->
      <div id="productPageWebMarkerScreen2_mobile">
        <div style="width:calc(100% - 40px);margin:0 auto;padding-top:80px;padding-bottom:80px;text-align:center;">
          <p class="productPageWebMarkerColorTitle_mobile">便捷操作 灵活标记</p>
          <p style="margin-top:20px;" class="productPageWebMarkerPs_mobile">海量数据 智能管理</p>
          <img :style="{'max-width':mobileScreenSize === 'small' ? '550px' : '600px'}" style="width:100%;margin-top:30px;" src="../../assets/App/productPage/webMarker/screen2/mainPicture.png" />
        </div>

        <!--顶部渐变-->
        <div style="position:absolute;top:0;left:0;width:100%;height:63px;background:linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(19,20,22,1) 100%);"></div>

        <!--底部渐变-->
        <div style="position:absolute;bottom:0;left:0;width:100%;height:63px;background:linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(19,20,22,1) 100%);"></div>

      </div>

      <!--第三屏-->
      <div id="productPageWebMarkerScreen3_mobile">
        <div style="width:calc(100% - 40px);margin:0 auto;padding-top:80px;text-align:center;">
          <p class="productPageWebMarkerColorTitle_mobile">丰富的录制效果</p>
          <p style="margin-top:20px;" class="productPageWebMarkerPs_mobile">样式丰富的地球特效</p>
          <img :style="{'max-width':mobileScreenSize === 'small' ? '550px' : '600px'}" style="width:100%;margin-top:30px;" src="../../assets/App/productPage/webMarker/screen3/mainPicture.png" />
        </div>
      </div>


      <!--第四屏-->
      <div id="productPageWebMarkerScreen4_mobile">
        <div style="position: relative;width:calc(100% - 40px);margin:0 auto;padding-top:190px;padding-bottom:80px;text-align:center;z-index: 1;">
          <p class="productPageWebMarkerColorTitle_mobile">多人协同 地图创作</p>
          <p style="margin-top:20px;" class="productPageWebMarkerPs_mobile">随时随地 多端联动</p>
          <img :style="{'max-width':mobileScreenSize === 'small' ? '550px' : '600px'}" style="width:100%;margin-top:30px;" src="../../assets/App/productPage/webMarker/screen4/mainPicture.png" />
        </div>

        <!--底部渐变-->
        <div style="position:absolute;bottom:0;left:0;width:100%;height:120px;background:linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);"></div>

      </div>

      <!--第五屏-->
      <div id="productPageWebMarkerScreen5_mobile">
        <div style="width:calc(100% - 40px);margin:0 auto;padding-top:100px;padding-bottom:70px;text-align:center;">
          <p class="productPageWebMarkerColorTitle_mobile">丰富创作场景 不止于地图</p>
          <p style="margin-top:20px;" class="productPageWebMarkerPs_mobile">生活记录 办公提效</p>

          <div style="position:relative;width:100%;margin-top:30px;">
            <swiper :options="screen5SwiperOption_mobile">
              <swiper-slide v-for="i in screen5ContentList.length / 2" :key="'screen5SwiperItem'+i">
                <div style="width:100%;margin-bottom:50px;display:flex;justify-content:center;">
                  <div @click="screen5ContentItemClick_mobile($event)"
                       style="position:relative;width:calc(50% - 6px);max-width:200px;margin:0 6px;">
                    <img style="width:100%;border-radius:8px;pointer-events:none;" :src="screen5ContentList[2*(i-1)].img">
                    <div class="screen5ContentItemAnimationDom" style="position:absolute;bottom:0;left:0;width:calc(100% - 24px);height:40px;padding:0 12px;background:rgba(0, 0, 0, 0.56);border-radius:0 0 8px 8px;
                 animation-duration:200ms;animation-fill-mode: forwards;overflow:hidden;pointer-events: none">
                      <p style="line-height:40px;font-size:16px;text-align:left;">{{screen5ContentList[i-1].title}}</p>
                      <p style="margin-top:8px;font-size:14px;text-align:justify;">{{screen5ContentList[i-1].ps}}</p>
                    </div>
                  </div>
                  <div @click="screen5ContentItemClick_mobile($event)"
                       style="position:relative;width:calc(50% - 6px);max-width:200px;">
                    <img style="width:100%;border-radius:8px;pointer-events:none;" :src="screen5ContentList[2*(i-1)+1].img">
                    <div class="screen5ContentItemAnimationDom" style="position:absolute;bottom:0;left:0;width:calc(100% - 30px);height:40px;padding:0 15px;background:rgba(0, 0, 0, 0.56);border-radius:0 0 8px 8px;
                 animation-duration:200ms;animation-fill-mode: forwards;overflow:hidden;pointer-events: none">
                      <p style="line-height:40px;font-size:16px;text-align:left;">{{screen5ContentList[i].title}}</p>
                      <p style="margin-top:8px;font-size:14px;text-align:justify;">{{screen5ContentList[i].ps}}</p>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <div class="screen5Swiper_mobile" slot="pagination"></div>
            </swiper>
          </div>

        </div>

      </div>


      <!--第六屏-->
      <div id="productPageWebMarkerScreen6_mobile">

        <div style="width:100%;margin:0 auto;padding-top:130px;padding-bottom:200px;text-align:center;">
          <p :style="{'font-size':mobileScreenSize === 'small' ? '20px' : '36px'}" class="productPageWebMarkerColorTitle_mobile">立即开启遥感影像创作之旅</p>

          <div @click="gotoWebMarker" style="width:116px;height:38px;margin:43px auto 0 auto;border-radius:20px;background:linear-gradient(175deg,rgba(255, 255, 255, 0.5) 0%,rgba(255, 255, 255, 0) 100%);padding:1px;">
            <div style="width:115px;height:37px;border-radius:20px;background:rgba(42, 130, 228, 1);text-align:center;">
              <p style="line-height:36px;font-size:14px;">
                立即体验
                <span style="margin-left:9px;" class="iconfontFamily">&#xe7a0;</span>
              </p>
            </div>
          </div>
        </div>

        <!--顶部渐变-->
        <div style="position:absolute;top:0;left:0;width:100%;height:63px;background:linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);"></div>

        <!--底部渐变-->
        <div style="position:absolute;bottom:0;left:0;width:100%;height:120px;background:linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);"></div>

      </div>

      <!--底栏-->
      <div style="position:relative;width:100%;margin-top:-100px;">
        <div style="position:absolute;bottom:0;left:0;width:100%;height:calc(100% - 100px);background:#000000;"></div>
        <app-page-foot-bar-mobile :produceList="footBarProductList" style="position:relative;"></app-page-foot-bar-mobile>
      </div>


    </div>

  </div>





</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

import AppPageFootBar from "@/views/AppPage/AppPageFootBar"
import AppPageFootBarMobile from "@/views/AppPage/AppPageFootBar_mobile"

export default {
  name: "ProductPageWebMarker",
  components: {
    swiper,
    swiperSlide,
    AppPageFootBar,
    AppPageFootBarMobile
  },
  data(){
    return{

      device:"web",
      webScreenSize:"big",
      mobileScreenSize:"small",


      //第一屏 线区域宽度
      screen1LineWidth:"0",

      //第五屏 内容列表
      screen5ContentList:[
        {
          img:require('../../assets/App/productPage/webMarker/screen5/img1.jpg'),
          title:"日常统计管理",
          ps:"共生地球web端可为企、事业单位和个人所应用，为基层政务、销售地推、物流配送、设备巡检等多个不同领域，解决外勤常见难题，以达到降本增效的目的。"
        },
        {
          img:require('../../assets/App/productPage/webMarker/screen5/img2.jpg'),
          title:"人员位置打卡",
          ps:"共生地球web端可为企、事业单位和个人提供便捷的任意时间、任意地点打卡功能。在户外勘探、工作记录的场景下提供便捷服务。"
        },
        {
          img:require('../../assets/App/productPage/webMarker/screen5/img3.jpg'),
          title:"旅行记忆收藏",
          ps:"在旅行途中，随手记录下风景，无论是漫步在古老的城市街头，还是探索未知的自然风光，只需轻轻一点，即可将那一刻的美好定格在心中。让您的旅行故事更加丰富多彩。"
        },
        {
          img:require('../../assets/App/productPage/webMarker/screen5/img4.jpg'),
          title:"出行规划 一键分享",
          ps:"让您的旅行计划更加便捷、高效。行程安排、住宿推荐、景点攻略等信息，一键分享。让每一次旅行都成为一次美好的分享，让每一次出行都充满期待和惊喜。"
        },


      ],

      //底栏项目列表
      footBarProductList:[
        {
          title:"吉林一号·共生地球APP",
          href:"/ProductPageApp"
        },
        {
          title:"吉林一号·遥感影像库",
          href:"/ProductPageImageLib"
        }

      ],


      /**移动端**********************************************************************************************/
      //第六屏轮播
      screen5SwiperOption_mobile: {
        pagination: {
          el: '.screen5Swiper_mobile', //与slot="pagination"处 class 一致
          clickable: true, //轮播按钮支持点击
        },
        // //自动播放
        // autoplay: {
        //   delay: 5000,
        //   disableOnInteraction: false
        // },
        // //循环
        // loop:true,
      },

    }


  },


  methods:{
    //顶bar 刷新页面
    reloadPage(){
      window.location.reload();
    },

    //跳转首页
    gotoApp(){
      this.$router.push(
          {
            path: '/',
          }
      );
    },
    //跳转web端标记项目
    gotoWebMarker(){
      window.open("https://earth.jl1.cn/tools/", '_blank');
    },
    //关于我们 进入详情页
    gotoAboutUsDetailPage(){
      this.$router.push(
          {
            path: '/AboutUs',
          }
      );
    },


    //首屏
    //线图片宽度
    getScreenLineWidth(){
      this.screen1LineWidth = document.getElementById("screen1MainImage").offsetWidth * 0.53 + 'px';
      console.log(this.screen1LineWidth);
    },
    //首屏动画
    screen1Animation(){
      document.getElementById("screen1LineShowArea").style.animationName = "screen1LineAnimation";
    },


    //第五屏
    screen5ContentItemMouseover(index){
      document.getElementById("screen5ContentItem"+index).style.animationName = "screen5ItemMouseoverAnimation";
    },
    screen5ContentItemMouseout(index){
      document.getElementById("screen5ContentItem"+index).style.animationName = "screen5ItemMouseoutAnimation";
    },




    //标题增加渐变颜色效果(直接写在css里不生效)
    productPageWebMarkerColorTitle(){
      let titleList = document.querySelectorAll(".productPageWebMarkerColorTitle");
      for(let i = 0;i<titleList.length;i++) {
        titleList[i].style["-webkit-background-clip"] = 'text';
      }
    },


    //鼠标滚动位置相关的动效
    listenerScrollFun(){
      const scrollTop = window.scrollY || window.pageYOffset;

      //第二屏
      if(scrollTop >= 450){
        this.titleAndPsAndImgFloatAnimation("productPageWebMarkerScreen2_title","productPageWebMarkerScreen2_ps","productPageWebMarkerScreen2_img");
      }
      //第三屏
      if(scrollTop >= 1350){
        this.titleAndPsAndImgFloatAnimation("productPageWebMarkerScreen3_title","productPageWebMarkerScreen3_ps","productPageWebMarkerScreen3_img");
      }
      //第四屏
      if(scrollTop >= 2250){
        this.titleAndPsAndImgFloatAnimation("productPageWebMarkerScreen4_title","productPageWebMarkerScreen4_ps","productPageWebMarkerScreen4_img");
      }
      //第五屏
      if(scrollTop >= 3050){
        this.titleAndPsAndImgFloatAnimation("productPageWebMarkerScreen5_title","productPageWebMarkerScreen5_ps","productPageWebMarkerScreen5_img");
      }




    },

    //标题+详情+图片 三者上浮动画
    titleAndPsAndImgFloatAnimation(titleDomId,psDomId,imgDomId){
      document.getElementById(titleDomId).style.animationName = "floatAnimation";
      document.getElementById(titleDomId).style.animationDuration = "1.5s";

      document.getElementById(psDomId).style.animationName = "floatAnimation";
      document.getElementById(psDomId).style.animationDuration = "2s";

      document.getElementById(imgDomId).style.animationName = "floatAnimation";
      document.getElementById(imgDomId).style.animationDuration = "2s";
    },






    /**移动端*******************************************************************************************/
    //第五屏 点击弹出内容
    screen5ContentItemClick_mobile(e){
      if(e.target.querySelector(".screen5ContentItemAnimationDom").style.animationName !== 'screen5ItemMouseoverAnimation'){
        let screen5ContentItemAnimationDomList = document.querySelectorAll(".screen5ContentItemAnimationDom");
        for(let i=0;i<screen5ContentItemAnimationDomList.length;i++){
          if(screen5ContentItemAnimationDomList[i].style.animationName === "screen5ItemMouseoverAnimation"){
            screen5ContentItemAnimationDomList[i].style.animationName = "screen5ItemMouseoutAnimation";
          }
        }
        e.target.querySelector(".screen5ContentItemAnimationDom").style.animationName = "screen5ItemMouseoverAnimation";
      }
      else{
        e.target.querySelector(".screen5ContentItemAnimationDom").style.animationName = "screen5ItemMouseoutAnimation";
      }
    },


    //标题增加渐变颜色效果(直接写在css里不生效)
    productPageWebMarkerColorTitle_mobile(){
      let titleList = document.querySelectorAll(".productPageWebMarkerColorTitle_mobile");
      for(let i = 0;i<titleList.length;i++) {
        titleList[i].style["-webkit-background-clip"] = 'text';
      }
    },


    //鼠标滚动位置相关的动效
    listenerScrollFun_mobile(){
      // let screen1Height = document.getElementById("productPageWebMarkerScreen1_mobile").offsetHeight;
      // let screen2Height = document.getElementById("productPageWebMarkerScreen2_mobile").offsetHeight;
      // let screen3Height = document.getElementById("productPageWebMarkerScreen3_mobile").offsetHeight;
      // let screen4Height = document.getElementById("productPageWebMarkerScreen4_mobile").offsetHeight;
      // let screen5Height = document.getElementById("productPageWebMarkerScreen5_mobile").offsetHeight;
      // let screen6Height = document.getElementById("productPageWebMarkerScreen6_mobile").offsetHeight;
      //
      //
      //
      // const scrollTop = window.scrollY || window.pageYOffset;
      //
      // //第二屏
      // if(scrollTop >= 350){
      //   this.titleAndPsAndImgFloatAnimation("productPageWebMarkerScreen2_title","productPageWebMarkerScreen2_ps","productPageWebMarkerScreen2_img")
      // }
      // //第三屏
      // if(scrollTop >= 1100){
      //   this.titleAndPsAndImgFloatAnimation("productPageWebMarkerScreen3_title","productPageWebMarkerScreen3_ps","productPageWebMarkerScreen3_img")
      // }
      // //第四屏
      // if(scrollTop >= 1875){
      //   this.titleAndPsAndImgFloatAnimation("productPageWebMarkerScreen4_title","productPageWebMarkerScreen4_ps","productPageWebMarkerScreen4_img")
      // }
      // //第五屏
      // if(scrollTop >= 2600){
      //   this.titleAndPsAndImgFloatAnimation("productPageWebMarkerScreen5_title","productPageWebMarkerScreen5_ps","productPageWebMarkerScreen5_img")
      // }




    },

  },
  mounted(){
    setTimeout(()=>{
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    },100)


    if(window.innerWidth >= 1000) {
      //web端
      this.device = "web";
      //适配web尺寸
      if(window.innerWidth < 1330){
        this.webScreenSize = "small";
        this.$refs.productPageWebMarker.style.setProperty('--web-title-font-size', "40px");
        this.$refs.productPageWebMarker.style.setProperty('--web-ps-font-size', "20px");
        this.$refs.productPageWebMarker.style.setProperty('--web-ps-line-height', "30px");
      }
      else{
        this.$refs.productPageWebMarker.style.setProperty('--web-title-font-size', "48px");
        this.$refs.productPageWebMarker.style.setProperty('--web-ps-font-size', "24px");
        this.$refs.productPageWebMarker.style.setProperty('--web-ps-line-height', "46px");
      }

      setTimeout(()=>{
        window.addEventListener('scroll', this.listenerScrollFun);
      },100)

      this.getScreenLineWidth();

      this.productPageWebMarkerColorTitle();

    }
    else{

      //手机
      this.device = "mobile";

      //适配手机尺寸
      if(window.innerWidth > 650){
        this.mobileScreenSize = "big";
        console.log(this.mobileScreenSize);
        this.$refs.productPageWebMarker.style.setProperty('--mobile-title-font-size', "40px");
        this.$refs.productPageWebMarker.style.setProperty('--mobile-ps-font-size', "20px");
        this.$refs.productPageWebMarker.style.setProperty('--mobile-ps-line-height', "30px");
      }
      else{
        this.$refs.productPageWebMarker.style.setProperty('--mobile-title-font-size', "24px");
        this.$refs.productPageWebMarker.style.setProperty('--mobile-ps-font-size', "16px");
        this.$refs.productPageWebMarker.style.setProperty('--mobile-ps-line-height', "24px");
      }

      //device默认是web，手机端需要延时
      setTimeout(()=>{
        this.productPageWebMarkerColorTitle_mobile();
      },0)

      setTimeout(()=>{
        window.addEventListener('scroll', this.listenerScrollFun_mobile);
      },100)

    }

  },
  destroyed() {

    if(window.innerWidth >= 1000){
      window.removeEventListener('scroll', this.listenerScrollFun);
    }
    else{
      window.removeEventListener('scroll', this.listenerScrollFun_mobile);
    }

  }


}
</script>

<style scoped>

.productPageWebMarker{
  background:#000000;
  color:#ffffff;
  overflow:hidden;
  font-family:HarmonyOSSansSCRegular;
}
.productPageWebMarker p{
  margin:0;
}


.screen{
  width: 100%;
}
#productPageWebMarkerScreen1{
  background-image:url('../../assets/App/productPage/webMarker/screen1/background.jpg');
  background-size:100% 100%;
  height: 100vh;
}
#productPageWebMarkerScreen2{
  background-image:url('../../assets/App/productPage/webMarker/screen2/background.jpg');
  background-size:100% 100%;
  height: 900px;
  position:relative;
}
#productPageWebMarkerScreen3{
  background-image:url('../../assets/App/productPage/webMarker/screen3/background.jpg');
  background-size:100% 100%;
  height: 900px;
}
#productPageWebMarkerScreen4{
  background-image:url('../../assets/App/productPage/webMarker/screen4/background.jpg');
  background-size:100% 100%;
  height: 900px;
}
#productPageWebMarkerScreen5{
  background-image:url('../../assets/App/productPage/webMarker/screen5/background.jpg');
  background-size:100% 100%;
  height: 700px;
  position:relative;
}
#productPageWebMarkerScreen6{
  background-image:url('../../assets/App/productPage/webMarker/screen6/background.jpg');
  background-size:100% 100%;
  height: 100vh;
  position:relative;
}




.productPageWebMarkerColorTitle{
  width:100%;
  line-height:48px;
  font-size:var(--web-title-font-size);
  font-weight:bold;
  text-align:center;
  color:#ffffff;
  -webkit-text-fill-color: transparent;
  background:linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(159, 160, 167, 1) 100%);
}
.productPageWebMarkerPs{
  font-size:var(--web-ps-font-size);
  line-height:var(--web-ps-line-height);
}


/*上浮动画的元素*/
.floatAnimationDom{
  position:relative;
  top:100px;
  opacity:0;
  animation-fill-mode: forwards;
}



/**移动*****************************************************************************************************/

#productPageWebMarkerScreen1_mobile{
  background-image:url('../../assets/App/productPage/webMarker/screen1/background.jpg');
  background-size:100% 100%;
}
#productPageWebMarkerScreen2_mobile{
  background-image:url('../../assets/App/productPage/webMarker/screen2/background_mobile.jpg');
  background-size:100% 100%;
  position:relative;
}
#productPageWebMarkerScreen3_mobile{
  background-image:url('../../assets/App/productPage/webMarker/screen3/background.jpg');
  background-size:100% 100%;
}
#productPageWebMarkerScreen4_mobile{
  background-image:url('../../assets/App/productPage/webMarker/screen4/background.jpg');
  background-size:100% 100%;
  position:relative;
}
#productPageWebMarkerScreen5_mobile{
  background-image:url('../../assets/App/productPage/webMarker/screen5/background_mobile.jpg');
  background-size:100% 100%;
  position:relative;
}
#productPageWebMarkerScreen6_mobile{
  background-image:url('../../assets/App/productPage/webMarker/screen6/background.jpg');
  background-size:420% 340%;
  background-position:center -200px;
  position:relative;
  width:100%;
}



.productPageWebMarkerColorTitle_mobile{
  font-size:var(--mobile-title-font-size);
  font-weight:bold;
  color:#ffffff;
  -webkit-text-fill-color: transparent;
  background:linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(159, 160, 167, 1) 100%);
}

.productPageWebMarkerPs_mobile{
  font-size:var(--mobile-ps-font-size);
  line-height:var(--mobile-ps-line-height);
}


.screen5Swiper_mobile{
  position: absolute;
  bottom:10px;
  width:100%;
  z-index: 1;
  text-align:center;
}


</style>


<style>

/*上浮动画*/
@keyframes floatAnimation {
  from {top:100px;opacity:0;}
  to {top:0px;opacity:1;}
}

/*首屏线动效*/
@keyframes screen1LineAnimation {
  from {width:0}
  to {width:53%}
}

/*第五屏 内容hover*/
@keyframes screen5ItemMouseoverAnimation {
  from {height:40px;padding-top:0;border-radius:0 0 8px 8px;}
  to {height:calc(100% - 7px);padding-top:7px;border-radius:8px;}
}
@keyframes screen5ItemMouseoutAnimation {
  from {height:calc(100% - 7px);padding-top:7px;border-radius:8px;}
  to {height:40px;padding-top:0;border-radius:0 0 8px 8px;}
}


/**移动端******************************************************************************************************/
/*第五屏 轮播导航*/
.screen5Swiper_mobile .swiper-pagination-bullet{
  width:10px;
  height:10px;
  background:rgba(128, 128, 128, 0.5);
  margin:0 6px;
  opacity:1;
}
.screen5Swiper_mobile .swiper-pagination-bullet-active{
  background:rgba(204, 204, 204, 1);
}



</style>