import Global from "@/lib/global";
import  {Http} from 'vue-resource'

//获取mapbox可用token和style
export async function getMapboxToken() {
    let response = await Http.get('/earthApi/main-info/getBaseStyleInfo');
    if (response.data.status === 200) {
        Global.mapboxToken =  response.data.data['token'];
        Global.mapboxStyle =  response.data.data['styleUrl'];
    }
}