<template>
    <div id="dronesView">
        <l-map ref="map" :maxBounds="maxBounds" :zoom="zoom" :center="center" :options="option" :minZoom="minZoom" :maxZoom="18">
            <l-tile-layer ref="mapLayer" :url="infoUrl" :options="{tms:true}" />
            <l-tile-layer v-if="citeShow" :url="citeUrl" :options="{tms:true}"/>

          <!--分享说明-->
          <share-reason v-if="share ==1" :shareReason="dronesDetail.shareReason" style="line-height: 18px;text-align: left;z-index:500;"></share-reason>

        </l-map>
<!--       <div v-if="share == 1" class="titleBar">-->
<!--            {{dronesDetail.title}}-->
<!--        </div>-->
        <TopShareBar v-if="share ==1" />
    </div>
</template>

<script>
    //import L from "leaflet";
    import { LMap, LTileLayer} from 'vue2-leaflet';
    import TopShareBar from '@/components/TopShareBar';
    import ShareReason from '@/components/ShareReason';
    import L from "leaflet";
    import {getWxSignature} from "@/utils/shareConfig";
    export default {
        name: "Drones",
        components:{
            LMap,
            LTileLayer,
            TopShareBar,ShareReason
        },
        data(){
            return{
                zoom: 12,
                minZoom:2,
                center: L.latLng(40.074, 116.604),
                maxBounds: L.latLngBounds([[85,-180], [-85,180]]),
                option: {zoomControl: false, attributionControl: false, renderer: L.canvas()},
                mapUrl:'https://t{s}.tianditu.gov.cn/DataServer?T=vec_w&X={x}&Y={y}&L={z}&tk=05861044e5fe4135647facbd39abc543',
                //infoUrl:'https://t{s}.tianditu.gov.cn/DataServer?T=cva_w&X={x}&Y={y}&L={z}&tk=05861044e5fe4135647facbd39abc543',
                //infoUrl:this.globalJS.china1mUrl_2020,
                infoUrl:this.globalJS.world1mUrl_2021,
                mapSubdomains:{subdomains: ['0', '1', '2', '3']},
                citeUrl:'',//图层地址
                share:0,
                infoId:'',
                citeShow:true,
                dronesDetail:{
                    cite: "202104211100000272486",
                    commentAmount: 4,
                    focusAmount: 0,
                    geo: "",
                    maxgeo:"",
                    infoId: "202111271053000071881",
                    likeStatus: 0,
                    shareAmount: 0,
                    simpleInfo: "",
                    thumbPicture: "https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202104211043000507500.jpg",
                    title: "无人机影像",
                    userId: "123456"
                },
            }
        },
        methods:{
            getDronesDetail(){
                //earthapi
                this.$http.get('/earthApi/main-info/getUVADetail?infoId='+this.infoId+ '&shareType= 1').then(response=> {
                    if(response.data.status === 200){
                        this.dronesDetail = response.body.data
                       // this.citeUrl = this.globalJS.charmingglobeLayerUrl+response.body.data.cite+'/{z}/{x}/{y}?token=Bearer%20a84a40c81f784490a4c5689187054abf'
                        this.citeUrl = response.body.data.cite
                        this.maxBounds =  L.geoJSON(JSON.parse(this.dronesDetail.maxgeo)).getBounds()
                        this.$refs.map.mapObject.fitBounds(L.geoJSON(JSON.parse(this.dronesDetail.geo)).getBounds())
                        this.minZoom = response.body.data.maxZoom
                        document.title = response.body.data.title
                        //console.log(this.dronesDetail.maxgeo)
                        //this.minZoom = parseInt(this.$refs.map.mapObject.getZoom())-2
                        // console.log(parseInt(this.$refs.map.mapObject.getZoom())-2)
                    }else{
                        this.$router.push(
                            {
                                path: '/NotExist',
                            }
                        )
                    }

                })
            },
            setCite(){
                this.citeShow = true
            },
            hideCite(){
                this.citeShow = false;
            },
            viewReset(){
                this.$refs.map.mapObject.fitBounds(L.geoJSON(JSON.parse(this.dronesDetail.geo)).getBounds())
            }
        },
        mounted() {
            window.leaflet = this
            this.infoId = this.$route.query.infoId
            this.share = this.$route.query.share
          getWxSignature(this.infoId)
          this.getDronesDetail()
        }
    }
</script>

<style lang="scss" scoped>

    #dronesView {
        font-family: 微软雅黑;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        overflow: hidden;
    }
    .titleBar {
        width: 100%;
        height: 22px;
        background-color: rgba(0, 0, 0, 0);
        position: absolute;
        left: 0;
        top: 45px;
        z-index: 9999;
        line-height: 22px;
        text-align: center;
        color: white;
        font-weight: bold;
    }
    .btnBar{
        width: 100%;
        height: 25px;
        background-color: rgba(0, 0, 0, 0);
        position: absolute;
        left: 0;
        top: 80px;
        z-index: 9999;
        line-height: 25px;
        text-align: center;
        color: white;
    }
    .btnBatBg{
        width: 116px;
        height: 25px;
        background-color: rgba(255,255,255,0.45);
        border-radius: 8px;
        margin: 0 auto;
        display: flex;
    }
    .statusBar{
        width: 50px;
        background-color: rgba(0, 0, 0, 0);
        position: absolute;
        right: 10px;
        bottom: 160px;
        z-index: 9999;
        text-align: center;
        color: white;
    }
    .bottomBar {
        width: 100%;
        height: 64px;
        background: rgba(0, 0, 0, 0);
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 9999;

        #openApp {
            color: white;
            font-size: 12px;
            font-weight: 500;
            text-align: center;
            line-height: 32px;
            margin: 16px 24px;
            height: 32px;
            background: #675cb8;
            border-radius: 18px;
        }
    }
</style>
