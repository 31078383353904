<template>
  <div class="articlePage">
    <!--内容-->
    <div style="padding:0px 28px 20px 28px;">
      <div class="articlePage_title">{{articleTitle}}</div>
      <div v-html="showHtmlData"></div>

    </div>
  </div>
</template>

<script>

export default {
  name: "ArticlePage",
  data(){
    return{

      mainInfoId:"",
      token:"",
      imei:"",

      articleTitle:"",
      showHtmlData:"",


    }

  },
  methods:{

    //获取详情
    getDetail(){
      this.$http.get('/earthApi/main-info/getMainInfoDetail?infoId='+this.mainInfoId, {headers:{'Authorization': 'Bearer ' + this.token,'imei':this.imei}}).then(response => {
        if(response.body.status === 200){
          console.log(response.body.data)
          this.articleTitle = response.body.data.mainTitle;
          this.initHtmlData(response.body.data.context);
        }
        else if(response.body.status === 404){
          this.$router.push(
              {
                path: '/NotExist',
              }
          )
        }
        else{
          console.log(response.data.status + response.data.message)
        }
      })
    },




    initHtmlData(htmlData){

      //如果存在图片，处理图片宽度适配
      let imageWidthFinish = htmlData
      if(imageWidthFinish.indexOf('<img') !== -1){
        //拆分图片
        let contextList = imageWidthFinish.split(/(<img.*\/>)/g);
        let contextTemp = ""
        for(let i=0;i<contextList.length;i++){
          if(contextList[i].indexOf("<img") === 0){
            //是图片的html
            let imageWidth = contextList[i].slice(contextList[i].indexOf('width="') + 7,contextList[i].length)
            imageWidth = imageWidth.slice(0,imageWidth.indexOf('"'))
            if(parseInt(imageWidth) > document.body.clientWidth){
              //超出屏幕宽度
              let beforeWidth = contextList[i].slice(0,contextList[i].indexOf('width="'));
              let afterHeight = contextList[i].slice(contextList[i].indexOf('height="') + 8,contextList[i].length);
              afterHeight = afterHeight.slice(afterHeight.indexOf('"') + 1,afterHeight.length)
              let currContext = beforeWidth + 'width="100%" height="auto"' + afterHeight;
              contextTemp = contextTemp + currContext;
            }
            else{
              //小图
              contextTemp = contextTemp + contextList[i];
            }
          }
          else{
            //不是图片的html
            contextTemp = contextTemp + contextList[i];
          }
        }
        imageWidthFinish = contextTemp;
      }


      //处理图片，增加点击事件
      let imageFinish = imageWidthFinish;
      if(imageWidthFinish.indexOf("<img") !== -1){
        imageFinish = "";
        let htmlDataTemp = imageWidthFinish.replaceAll("<img","%&%<img");
        htmlDataTemp = htmlDataTemp.replaceAll("/>","/>%&%");
        let htmlDataList = htmlDataTemp.split("%&%");
        for(let i=0;i<htmlDataList.length;i++){
          if(htmlDataList[i].indexOf('<img') !== -1){
            let imageInfoList = htmlDataList[i].split('"');
            let imageSrc = "";
            for(let j=0;j<imageInfoList.length;j++){
              if(imageInfoList[j].indexOf("src=") !== -1){
                imageSrc = imageInfoList[j+1];
                break;
              }
            }
            let imageHtml = htmlDataList[i].slice(0,5) + 'onclick="intoFullScreenImage(\''+ imageSrc +'\')"' + htmlDataList[i].slice(5,htmlDataList[i].length)
            imageFinish = imageFinish + imageHtml
          }
          else{
            imageFinish = imageFinish + htmlDataList[i]
          }
        }
      }

      //处理视频，换成展示缩略图
      let videoFinish = imageFinish;
      if(imageFinish.indexOf('<video') !== -1){
        videoFinish = "";
        let htmlDataTemp = imageFinish.replaceAll("<video","%&%<video");
        htmlDataTemp = htmlDataTemp.replaceAll("</video>","</video>%&%");
        let htmlDataList = htmlDataTemp.split("%&%");
        for(let i=0;i<htmlDataList.length;i++){
          if(htmlDataList[i].indexOf('<video') !== -1){
            console.log(htmlDataList[i])
            //视频
            let videoInfoList = htmlDataList[i].split('"');
            let thumb = "";
            let playerSrc = "";
            for(let j=0;j<videoInfoList.length;j++){
              if(videoInfoList[j].indexOf("poster=") !== -1){
                thumb = videoInfoList[j+1];
              }
              else if(videoInfoList[j].indexOf("src=") !== -1){
                playerSrc = videoInfoList[j+1];
              }
            }
            let videoToImage = '<div onclick="intoVideoPlayer(\''+ playerSrc +'\')" style="position:relative;"><img src="' + thumb + '" alt="" width="100%" height="auto" /><div class="videoPlayBtn"><p style="color:rgba(255,255,255,0.8);font-size:28px;" class="iconfontFamily">&#xe66d;</p></div></div>'
            videoFinish = videoFinish + videoToImage
          }
          else{
            videoFinish = videoFinish + htmlDataList[i]
          }
        }
      }

      //处理<a>，换成点击调用flutter
      let aFinish = videoFinish;
      if(videoFinish.indexOf('<a') !== -1){
        aFinish = "";
        let htmlDataTemp = videoFinish.replaceAll("<a","%&%<a");
        htmlDataTemp = htmlDataTemp.replaceAll("</a>","</a>%&%");
        let htmlDataList = htmlDataTemp.split("%&%");

        for(let i=0;i<htmlDataList.length;i++){
          if(htmlDataList[i].indexOf('<a') !== -1){
            //a标签
            let hrefInfoList = htmlDataList[i].split('"');
            let hrefSrc = "";
            for(let j=0;j<hrefInfoList.length;j++){
              if(hrefInfoList[j].indexOf("href=") !== -1){
                hrefSrc = hrefInfoList[j+1];
                break;
              }
            }
            let hrefIndex = htmlDataList[i].indexOf("href=")
            let imageHtml = htmlDataList[i].slice(0,3) + 'onclick="intoATagPage(\''+ hrefSrc +'\')"' + htmlDataList[i].slice(2,hrefIndex+6) + 'javascript:void(0);' + htmlDataList[i].slice(hrefIndex + 6 + hrefSrc.length,htmlDataList[i].length)
            aFinish = aFinish + imageHtml

          }
          else{
            aFinish = aFinish + htmlDataList[i]
          }
        }

      }


      //处理完成
      this.showHtmlData = aFinish
    },


    intoVideoPlayer(videoSrc){
      if(window["flutter_articleIntoVideoPlayer"]){
        window["flutter_articleIntoVideoPlayer"].postMessage(videoSrc);
      }

    },

    intoFullScreenImage(imageSrc){
      if(window["flutter_articleIntoFullScreenImage"]){
        window["flutter_articleIntoFullScreenImage"].postMessage(imageSrc);
      }
    },

    intoATagPage(hrefSrc){
      if(window["flutter_articleIntoATagPage"]){
        window["flutter_articleIntoATagPage"].postMessage(hrefSrc);
      }
    },


  },
  mounted(){

    //将vue方法挂载到window对象
    window.intoVideoPlayer = this.intoVideoPlayer
    window.intoFullScreenImage = this.intoFullScreenImage
    window.intoATagPage = this.intoATagPage

    // this.mainInfoId = "79b3d460e852436aaa5ef0c95fa4e400";
    // this.token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwaG9uZSI6IjE4NzQ0MDIzMDAxIiwiZXhwIjoxNjUzMjgyNDc3LCJ1c2VySWQiOjEyMzQ1NiwiZW1haWwiOiIifQ.rqEg7XUo5e0rymJLIKBpNWJ2AeWkNXArmn-NUgtUVXw"
    // this.imei = "1652081227956"
    this.mainInfoId = this.$route.query.mainInfoId;
    this.token = this.$route.query.token
    this.imei = this.$route.query.imei
    this.getDetail();

  }


}
</script>


<style scoped>
.articlePage {
  font-family: 微软雅黑;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: auto;
}
.articlePage::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.articlePage_title{
  margin-bottom:20px;
  font-size:18px;
  color:#4a4a4a;
  font-weight:bold;
}

</style>

<style>
.articlePage p{
  margin:0;
}
.videoPlayBtn{
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  width:29px;
  height:29px;
  background:transparent;
  border-radius:50%;
  text-align:center;
}
</style>