<template>
    <div class="MarkFilePlay">
        <div id="MarkFilePlayMap" style="width: 100%;height: 100%;background: black;position: absolute;top: 0;left: 0; overflow: hidden;">
        </div>
    </div>
</template>

<script>
    import marker_one_red1 from '@/assets/MarkFileDetail/marker_image/marker_one/marker_one_red1.png'
    import marker_one_red2 from '@/assets/MarkFileDetail/marker_image/marker_one/marker_one_red2.png'
    import marker_one_red3 from '@/assets/MarkFileDetail/marker_image/marker_one/marker_one_red3.png'
    import marker_one_blue1 from '@/assets/MarkFileDetail/marker_image/marker_one/marker_one_blue1.png'
    import marker_one_blue2 from '@/assets/MarkFileDetail/marker_image/marker_one/marker_one_blue2.png'
    import marker_one_blue3 from '@/assets/MarkFileDetail/marker_image/marker_one/marker_one_blue3.png'
    import marker_one_yellow1 from '@/assets/MarkFileDetail/marker_image/marker_one/marker_one_yellow1.png'
    import marker_one_yellow2 from '@/assets/MarkFileDetail/marker_image/marker_one/marker_one_yellow2.png'
    import marker_one_yellow3 from '@/assets/MarkFileDetail/marker_image/marker_one/marker_one_yellow3.png'
    import marker_one_green1 from '@/assets/MarkFileDetail/marker_image/marker_one/marker_one_green1.png'
    import marker_one_green2 from '@/assets/MarkFileDetail/marker_image/marker_one/marker_one_green2.png'
    import marker_one_green3 from '@/assets/MarkFileDetail/marker_image/marker_one/marker_one_green3.png'
    import marker_one_cyan1 from '@/assets/MarkFileDetail/marker_image/marker_one/marker_one_cyan1.png'
    import marker_one_cyan2 from '@/assets/MarkFileDetail/marker_image/marker_one/marker_one_cyan2.png'
    import marker_one_cyan3 from '@/assets/MarkFileDetail/marker_image/marker_one/marker_one_cyan3.png'

    import marker_two_red1 from '@/assets/MarkFileDetail/marker_image/marker_two/marker_two_red1.png'
    import marker_two_red2 from '@/assets/MarkFileDetail/marker_image/marker_two/marker_two_red2.png'
    import marker_two_red3 from '@/assets/MarkFileDetail/marker_image/marker_two/marker_two_red3.png'
    import marker_two_blue1 from '@/assets/MarkFileDetail/marker_image/marker_two/marker_two_blue1.png'
    import marker_two_blue2 from '@/assets/MarkFileDetail/marker_image/marker_two/marker_two_blue2.png'
    import marker_two_blue3 from '@/assets/MarkFileDetail/marker_image/marker_two/marker_two_blue3.png'
    import marker_two_yellow1 from '@/assets/MarkFileDetail/marker_image/marker_two/marker_two_yellow1.png'
    import marker_two_yellow2 from '@/assets/MarkFileDetail/marker_image/marker_two/marker_two_yellow2.png'
    import marker_two_yellow3 from '@/assets/MarkFileDetail/marker_image/marker_two/marker_two_yellow3.png'
    import marker_two_green1 from '@/assets/MarkFileDetail/marker_image/marker_two/marker_two_green1.png'
    import marker_two_green2 from '@/assets/MarkFileDetail/marker_image/marker_two/marker_two_green2.png'
    import marker_two_green3 from '@/assets/MarkFileDetail/marker_image/marker_two/marker_two_green3.png'
    import marker_two_cyan1 from '@/assets/MarkFileDetail/marker_image/marker_two/marker_two_cyan1.png'
    import marker_two_cyan2 from '@/assets/MarkFileDetail/marker_image/marker_two/marker_two_cyan2.png'
    import marker_two_cyan3 from '@/assets/MarkFileDetail/marker_image/marker_two/marker_two_cyan3.png'

    import marker_three_red1 from '@/assets/MarkFileDetail/marker_image/marker_three/marker_three_red1.png'
    import marker_three_red2 from '@/assets/MarkFileDetail/marker_image/marker_three/marker_three_red2.png'
    import marker_three_red3 from '@/assets/MarkFileDetail/marker_image/marker_three/marker_three_red3.png'
    import marker_three_blue1 from '@/assets/MarkFileDetail/marker_image/marker_three/marker_three_blue1.png'
    import marker_three_blue2 from '@/assets/MarkFileDetail/marker_image/marker_three/marker_three_blue2.png'
    import marker_three_blue3 from '@/assets/MarkFileDetail/marker_image/marker_three/marker_three_blue3.png'
    import marker_three_yellow1 from '@/assets/MarkFileDetail/marker_image/marker_three/marker_three_yellow1.png'
    import marker_three_yellow2 from '@/assets/MarkFileDetail/marker_image/marker_three/marker_three_yellow2.png'
    import marker_three_yellow3 from '@/assets/MarkFileDetail/marker_image/marker_three/marker_three_yellow3.png'
    import marker_three_green1 from '@/assets/MarkFileDetail/marker_image/marker_three/marker_three_green1.png'
    import marker_three_green2 from '@/assets/MarkFileDetail/marker_image/marker_three/marker_three_green2.png'
    import marker_three_green3 from '@/assets/MarkFileDetail/marker_image/marker_three/marker_three_green3.png'
    import marker_three_cyan1 from '@/assets/MarkFileDetail/marker_image/marker_three/marker_three_cyan1.png'
    import marker_three_cyan2 from '@/assets/MarkFileDetail/marker_image/marker_three/marker_three_cyan2.png'
    import marker_three_cyan3 from '@/assets/MarkFileDetail/marker_image/marker_three/marker_three_cyan3.png'

    import marker_four_red1 from '@/assets/MarkFileDetail/marker_image/marker_four/marker_four_red1.png'
    import marker_four_red2 from '@/assets/MarkFileDetail/marker_image/marker_four/marker_four_red2.png'
    import marker_four_red3 from '@/assets/MarkFileDetail/marker_image/marker_four/marker_four_red3.png'
    import marker_four_blue1 from '@/assets/MarkFileDetail/marker_image/marker_four/marker_four_blue1.png'
    import marker_four_blue2 from '@/assets/MarkFileDetail/marker_image/marker_four/marker_four_blue2.png'
    import marker_four_blue3 from '@/assets/MarkFileDetail/marker_image/marker_four/marker_four_blue3.png'
    import marker_four_yellow1 from '@/assets/MarkFileDetail/marker_image/marker_four/marker_four_yellow1.png'
    import marker_four_yellow2 from '@/assets/MarkFileDetail/marker_image/marker_four/marker_four_yellow2.png'
    import marker_four_yellow3 from '@/assets/MarkFileDetail/marker_image/marker_four/marker_four_yellow3.png'
    import marker_four_green1 from '@/assets/MarkFileDetail/marker_image/marker_four/marker_four_green1.png'
    import marker_four_green2 from '@/assets/MarkFileDetail/marker_image/marker_four/marker_four_green2.png'
    import marker_four_green3 from '@/assets/MarkFileDetail/marker_image/marker_four/marker_four_green3.png'
    import marker_four_cyan1 from '@/assets/MarkFileDetail/marker_image/marker_four/marker_four_cyan1.png'
    import marker_four_cyan2 from '@/assets/MarkFileDetail/marker_image/marker_four/marker_four_cyan2.png'
    import marker_four_cyan3 from '@/assets/MarkFileDetail/marker_image/marker_four/marker_four_cyan3.png'

    import marker_five_red1 from '@/assets/MarkFileDetail/marker_image/marker_five/marker_five_red1.png'
    import marker_five_red2 from '@/assets/MarkFileDetail/marker_image/marker_five/marker_five_red2.png'
    import marker_five_red3 from '@/assets/MarkFileDetail/marker_image/marker_five/marker_five_red3.png'
    import marker_five_blue1 from '@/assets/MarkFileDetail/marker_image/marker_five/marker_five_blue1.png'
    import marker_five_blue2 from '@/assets/MarkFileDetail/marker_image/marker_five/marker_five_blue2.png'
    import marker_five_blue3 from '@/assets/MarkFileDetail/marker_image/marker_five/marker_five_blue3.png'
    import marker_five_yellow1 from '@/assets/MarkFileDetail/marker_image/marker_five/marker_five_yellow1.png'
    import marker_five_yellow2 from '@/assets/MarkFileDetail/marker_image/marker_five/marker_five_yellow2.png'
    import marker_five_yellow3 from '@/assets/MarkFileDetail/marker_image/marker_five/marker_five_yellow3.png'
    import marker_five_green1 from '@/assets/MarkFileDetail/marker_image/marker_five/marker_five_green1.png'
    import marker_five_green2 from '@/assets/MarkFileDetail/marker_image/marker_five/marker_five_green2.png'
    import marker_five_green3 from '@/assets/MarkFileDetail/marker_image/marker_five/marker_five_green3.png'
    import marker_five_cyan1 from '@/assets/MarkFileDetail/marker_image/marker_five/marker_five_cyan1.png'
    import marker_five_cyan2 from '@/assets/MarkFileDetail/marker_image/marker_five/marker_five_cyan2.png'
    import marker_five_cyan3 from '@/assets/MarkFileDetail/marker_image/marker_five/marker_five_cyan3.png'

    import marker_six_red1 from '@/assets/MarkFileDetail/marker_image/marker_six/marker_six_red1.png'
    import marker_six_red2 from '@/assets/MarkFileDetail/marker_image/marker_six/marker_six_red2.png'
    import marker_six_red3 from '@/assets/MarkFileDetail/marker_image/marker_six/marker_six_red3.png'
    import marker_six_blue1 from '@/assets/MarkFileDetail/marker_image/marker_six/marker_six_blue1.png'
    import marker_six_blue2 from '@/assets/MarkFileDetail/marker_image/marker_six/marker_six_blue2.png'
    import marker_six_blue3 from '@/assets/MarkFileDetail/marker_image/marker_six/marker_six_blue3.png'
    import marker_six_yellow1 from '@/assets/MarkFileDetail/marker_image/marker_six/marker_six_yellow1.png'
    import marker_six_yellow2 from '@/assets/MarkFileDetail/marker_image/marker_six/marker_six_yellow2.png'
    import marker_six_yellow3 from '@/assets/MarkFileDetail/marker_image/marker_six/marker_six_yellow3.png'
    import marker_six_green1 from '@/assets/MarkFileDetail/marker_image/marker_six/marker_six_green1.png'
    import marker_six_green2 from '@/assets/MarkFileDetail/marker_image/marker_six/marker_six_green2.png'
    import marker_six_green3 from '@/assets/MarkFileDetail/marker_image/marker_six/marker_six_green3.png'
    import marker_six_cyan1 from '@/assets/MarkFileDetail/marker_image/marker_six/marker_six_cyan1.png'
    import marker_six_cyan2 from '@/assets/MarkFileDetail/marker_image/marker_six/marker_six_cyan2.png'
    import marker_six_cyan3 from '@/assets/MarkFileDetail/marker_image/marker_six/marker_six_cyan3.png'

    import marker_seven_red1 from '@/assets/MarkFileDetail/marker_image/marker_seven/marker_seven_red1.png'
    import marker_seven_red2 from '@/assets/MarkFileDetail/marker_image/marker_seven/marker_seven_red2.png'
    import marker_seven_red3 from '@/assets/MarkFileDetail/marker_image/marker_seven/marker_seven_red3.png'
    import marker_seven_blue1 from '@/assets/MarkFileDetail/marker_image/marker_seven/marker_seven_blue1.png'
    import marker_seven_blue2 from '@/assets/MarkFileDetail/marker_image/marker_seven/marker_seven_blue2.png'
    import marker_seven_blue3 from '@/assets/MarkFileDetail/marker_image/marker_seven/marker_seven_blue3.png'
    import marker_seven_yellow1 from '@/assets/MarkFileDetail/marker_image/marker_seven/marker_seven_yellow1.png'
    import marker_seven_yellow2 from '@/assets/MarkFileDetail/marker_image/marker_seven/marker_seven_yellow2.png'
    import marker_seven_yellow3 from '@/assets/MarkFileDetail/marker_image/marker_seven/marker_seven_yellow3.png'
    import marker_seven_green1 from '@/assets/MarkFileDetail/marker_image/marker_seven/marker_seven_green1.png'
    import marker_seven_green2 from '@/assets/MarkFileDetail/marker_image/marker_seven/marker_seven_green2.png'
    import marker_seven_green3 from '@/assets/MarkFileDetail/marker_image/marker_seven/marker_seven_green3.png'
    import marker_seven_cyan1 from '@/assets/MarkFileDetail/marker_image/marker_seven/marker_seven_cyan1.png'
    import marker_seven_cyan2 from '@/assets/MarkFileDetail/marker_image/marker_seven/marker_seven_cyan2.png'
    import marker_seven_cyan3 from '@/assets/MarkFileDetail/marker_image/marker_seven/marker_seven_cyan3.png'

    import marker_eight_red1 from '@/assets/MarkFileDetail/marker_image/marker_eight/marker_eight_red1.png'
    import marker_eight_red2 from '@/assets/MarkFileDetail/marker_image/marker_eight/marker_eight_red2.png'
    import marker_eight_red3 from '@/assets/MarkFileDetail/marker_image/marker_eight/marker_eight_red3.png'
    import marker_eight_blue1 from '@/assets/MarkFileDetail/marker_image/marker_eight/marker_eight_blue1.png'
    import marker_eight_blue2 from '@/assets/MarkFileDetail/marker_image/marker_eight/marker_eight_blue2.png'
    import marker_eight_blue3 from '@/assets/MarkFileDetail/marker_image/marker_eight/marker_eight_blue3.png'
    import marker_eight_yellow1 from '@/assets/MarkFileDetail/marker_image/marker_eight/marker_eight_yellow1.png'
    import marker_eight_yellow2 from '@/assets/MarkFileDetail/marker_image/marker_eight/marker_eight_yellow2.png'
    import marker_eight_yellow3 from '@/assets/MarkFileDetail/marker_image/marker_eight/marker_eight_yellow3.png'
    import marker_eight_green1 from '@/assets/MarkFileDetail/marker_image/marker_eight/marker_eight_green1.png'
    import marker_eight_green2 from '@/assets/MarkFileDetail/marker_image/marker_eight/marker_eight_green2.png'
    import marker_eight_green3 from '@/assets/MarkFileDetail/marker_image/marker_eight/marker_eight_green3.png'
    import marker_eight_cyan1 from '@/assets/MarkFileDetail/marker_image/marker_eight/marker_eight_cyan1.png'
    import marker_eight_cyan2 from '@/assets/MarkFileDetail/marker_image/marker_eight/marker_eight_cyan2.png'
    import marker_eight_cyan3 from '@/assets/MarkFileDetail/marker_image/marker_eight/marker_eight_cyan3.png'
    import * as turf from '@turf/turf'
    import {getMapboxToken} from "@/utils/utils";
    import mapboxgl from "mapbox-gl";
    export default {
        name: "MarkFilePlay",
        data(){
            return{
                map:'',
                globeOperationMarkerList:[],
                markerList:{
                    'type': 'FeatureCollection',
                    'features': []
                },
                //线集合
                lineJson:{
                    linePoint:{
                        'type': 'FeatureCollection',
                        'features': []
                    },
                    lineGeo:{
                        "type": "FeatureCollection",
                        "features": []
                    },
                    lineNamePoint:{
                        'type': 'FeatureCollection',
                        'features': []
                    }
                },
                //路线集合
                roadJson:{
                    roadPoint:{
                        'type': 'FeatureCollection',
                        'features': []
                    },
                    roadGeo:{
                        "type": "FeatureCollection",
                        "features": []
                    },
                    roadNamePoint:{
                        'type': 'FeatureCollection',
                        'features': []
                    }
                },
                polygonJson:{
                    polygonPoint:{
                        'type': 'FeatureCollection',
                        'features': []
                    },
                    polygonGeo:{
                        "type": "FeatureCollection",
                        "features": []
                    },
                    polygonNamePoint:{
                        "type": "FeatureCollection",
                        "features": []
                    },
                    polygonLenPoint:{
                        "type": "FeatureCollection",
                        "features": []
                    },
                    circleRadiusPoint:{
                        "type": "FeatureCollection",
                        "features": []
                    }
                },
                initDoneFlag:0,
                markId:'',
            }
        },
        watch:{
            // initDoneFlag(){
            //     if(this.initDoneFlag === 1){
            //
            //         this.initDoneFlag = 0
            //     }
            // }
        },
        methods:{
            async initMapbox(){
                await getMapboxToken()
                mapboxgl.accessToken = this.globalJS.mapboxToken;
                this.map = new mapboxgl.Map({
                    container: 'MarkFilePlayMap',
                    center:[100, 31],
                    zoom: 3,
                    style: this.globalJS.mapboxStyle,
                    preserveDrawingBuffer:true,
                    localIdeographFontFamily: '',
                    projection: {name:'globe'},
                    transformRequest: (url) => {
                        if (url.indexOf('https://api.mapbox.com') > -1) {
                            return {
                                url: url.replace('https://api.mapbox.com', 'https://earth.jl1.cn/mapbox'),
                            };
                        }
                    }
                });
                this.map.on('load', () => {
                    this.map.addSource('mapbox-dem', {
                        type: 'raster-dem',
                        url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
                        tileSize: 512,
                        maxZoom: 14,
                    });
                    this.map.setTerrain({'source': 'mapbox-dem', 'exaggeration': 1});
                    this.map.addLayer({
                        id: 'sky',
                        type: 'sky',
                        paint: {
                            'sky-type': 'atmosphere',
                            'sky-atmosphere-sun': [0.0, 0.0],
                            'sky-atmosphere-sun-intensity': 15,
                        }
                    });

                    this.map.addSource('china1m2021', {
                        type: 'raster',
                        tiles: [
                            this.globalJS.world1mUrl_2021
                        ],
                        tileSize: 256,
                        scheme: 'tms',
                    });
                    this.map.addLayer({
                        id: 'china1mLayer',
                        type: 'raster',
                        source: 'china1m2021',
                    });

                    this.mapInit = true
                    this.map.addSource('markerLayer',{
                        type:"geojson",
                        data:this.markerList
                    })
                    this.map.addLayer({
                        "id": "markerList2",
                        "type": "symbol",
                        "source": "markerLayer",
                        "layout":{
                            "icon-size":['get', 'size'],
                            "icon-image":[
                                'match',
                                ["concat", ['get', 'code'],['get', 'color']],
                                '&#xe657;#FF0000','marker_one_red1',
                                '&#xe657;#DE0000','marker_one_red2',
                                '&#xe657;#AB0000','marker_one_red3',
                                '&#xe657;#FFFF00','marker_one_yellow1',
                                '&#xe657;#C9C900','marker_one_yellow2',
                                '&#xe657;#969600','marker_one_yellow3',
                                '&#xe657;#2A82E4','marker_one_blue1',
                                '&#xe657;#226ABD','marker_one_blue2',
                                '&#xe657;#174D8A','marker_one_blue3',
                                '&#xe657;#00FF00','marker_one_green1',
                                '&#xe657;#00C900','marker_one_green2',
                                '&#xe657;#009600','marker_one_green3',
                                '&#xe657;#00FFFF','marker_one_cyan1',
                                '&#xe657;#00C9C9','marker_one_cyan2',
                                '&#xe657;#009696','marker_one_cyan3',

                                '&#xe653;#FF0000','marker_two_red1',
                                '&#xe653;#DE0000','marker_two_red2',
                                '&#xe653;#AB0000','marker_two_red3',
                                '&#xe653;#FFFF00','marker_two_yellow1',
                                '&#xe653;#C9C900','marker_two_yellow2',
                                '&#xe653;#969600','marker_two_yellow3',
                                '&#xe653;#2A82E4','marker_two_blue1',
                                '&#xe653;#226ABD','marker_two_blue2',
                                '&#xe653;#174D8A','marker_two_blue3',
                                '&#xe653;#00FF00','marker_two_green1',
                                '&#xe653;#00C900','marker_two_green2',
                                '&#xe653;#009600','marker_two_green3',
                                '&#xe653;#00FFFF','marker_two_cyan1',
                                '&#xe653;#00C9C9','marker_two_cyan2',
                                '&#xe653;#009696','marker_two_cyan3',

                                '&#xe65a;#FF0000','marker_three_red1',
                                '&#xe65a;#DE0000','marker_three_red2',
                                '&#xe65a;#AB0000','marker_three_red3',
                                '&#xe65a;#FFFF00','marker_three_yellow1',
                                '&#xe65a;#C9C900','marker_three_yellow2',
                                '&#xe65a;#969600','marker_three_yellow3',
                                '&#xe65a;#2A82E4','marker_three_blue1',
                                '&#xe65a;#226ABD','marker_three_blue2',
                                '&#xe65a;#174D8A','marker_three_blue3',
                                '&#xe65a;#00FF00','marker_three_green1',
                                '&#xe65a;#00C900','marker_three_green2',
                                '&#xe65a;#009600','marker_three_green3',
                                '&#xe65a;#00FFFF','marker_three_cyan1',
                                '&#xe65a;#00C9C9','marker_three_cyan2',
                                '&#xe65a;#009696','marker_three_cyan3',

                                '&#xe63c;#FF0000','marker_four_red1',
                                '&#xe63c;#DE0000','marker_four_red2',
                                '&#xe63c;#AB0000','marker_four_red3',
                                '&#xe63c;#FFFF00','marker_four_yellow1',
                                '&#xe63c;#C9C900','marker_four_yellow2',
                                '&#xe63c;#969600','marker_four_yellow3',
                                '&#xe63c;#2A82E4','marker_four_blue1',
                                '&#xe63c;#226ABD','marker_four_blue2',
                                '&#xe63c;#174D8A','marker_four_blue3',
                                '&#xe63c;#00FF00','marker_four_green1',
                                '&#xe63c;#00C900','marker_four_green2',
                                '&#xe63c;#009600','marker_four_green3',
                                '&#xe63c;#00FFFF','marker_four_cyan1',
                                '&#xe63c;#00C9C9','marker_four_cyan2',
                                '&#xe63c;#009696','marker_four_cyan3',

                                '&#xe651;#FF0000','marker_five_red1',
                                '&#xe651;#DE0000','marker_five_red2',
                                '&#xe651;#AB0000','marker_five_red3',
                                '&#xe651;#FFFF00','marker_five_yellow1',
                                '&#xe651;#C9C900','marker_five_yellow2',
                                '&#xe651;#969600','marker_five_yellow3',
                                '&#xe651;#2A82E4','marker_five_blue1',
                                '&#xe651;#226ABD','marker_five_blue2',
                                '&#xe651;#174D8A','marker_five_blue3',
                                '&#xe651;#00FF00','marker_five_green1',
                                '&#xe651;#00C900','marker_five_green2',
                                '&#xe651;#009600','marker_five_green3',
                                '&#xe651;#00FFFF','marker_five_cyan1',
                                '&#xe651;#00C9C9','marker_five_cyan2',
                                '&#xe651;#009696','marker_five_cyan3',

                                '&#xe664;#FF0000','marker_six_red1',
                                '&#xe664;#DE0000','marker_six_red2',
                                '&#xe664;#AB0000','marker_six_red3',
                                '&#xe664;#FFFF00','marker_six_yellow1',
                                '&#xe664;#C9C900','marker_six_yellow2',
                                '&#xe664;#969600','marker_six_yellow3',
                                '&#xe664;#2A82E4','marker_six_blue1',
                                '&#xe664;#226ABD','marker_six_blue2',
                                '&#xe664;#174D8A','marker_six_blue3',
                                '&#xe664;#00FF00','marker_six_green1',
                                '&#xe664;#00C900','marker_six_green2',
                                '&#xe664;#009600','marker_six_green3',
                                '&#xe664;#00FFFF','marker_six_cyan1',
                                '&#xe664;#00C9C9','marker_six_cyan2',
                                '&#xe664;#009696','marker_six_cyan3',

                                '&#xe654;#FF0000','marker_seven_red1',
                                '&#xe654;#DE0000','marker_seven_red2',
                                '&#xe654;#AB0000','marker_seven_red3',
                                '&#xe654;#FFFF00','marker_seven_yellow1',
                                '&#xe654;#C9C900','marker_seven_yellow2',
                                '&#xe654;#969600','marker_seven_yellow3',
                                '&#xe654;#2A82E4','marker_seven_blue1',
                                '&#xe654;#226ABD','marker_seven_blue2',
                                '&#xe654;#174D8A','marker_seven_blue3',
                                '&#xe654;#00FF00','marker_seven_green1',
                                '&#xe654;#00C900','marker_seven_green2',
                                '&#xe654;#009600','marker_seven_green3',
                                '&#xe654;#00FFFF','marker_seven_cyan1',
                                '&#xe654;#00C9C9','marker_seven_cyan2',
                                '&#xe654;#009696','marker_seven_cyan3',

                                '&#xe656;#FF0000','marker_eight_red1',
                                '&#xe656;#DE0000','marker_eight_red2',
                                '&#xe656;#AB0000','marker_eight_red3',
                                '&#xe656;#FFFF00','marker_eight_yellow1',
                                '&#xe656;#C9C900','marker_eight_yellow2',
                                '&#xe656;#969600','marker_eight_yellow3',
                                '&#xe656;#2A82E4','marker_eight_blue1',
                                '&#xe656;#226ABD','marker_eight_blue2',
                                '&#xe656;#174D8A','marker_eight_blue3',
                                '&#xe656;#00FF00','marker_eight_green1',
                                '&#xe656;#00C900','marker_eight_green2',
                                '&#xe656;#009600','marker_eight_green3',
                                '&#xe656;#00FFFF','marker_eight_cyan1',
                                '&#xe656;#00C9C9','marker_eight_cyan2',
                                '&#xe656;#009696','marker_eight_cyan3',
                                'marker_one_blue1'
                            ],
                            "text-font":this.$store.state.font,
                            "text-field": ["get", "name"],
                            "text-size":16,
                            "text-anchor": "bottom",
                            // "text-offset": [0, -2.3],
                            "text-offset":[ 'interpolate',
                                ["linear"],
                                ['get', 'size'],
                                0.5,
                                ["literal", [0, -1.5]],
                                0.7,
                                ["literal", [0, -2.6]],
                                0.9,
                                ["literal", [0, -3.2]],
                                1.1,
                                ["literal", [0, -3.8]],
                            ],
                            "icon-allow-overlap":true,
                            "text-allow-overlap":true,
                            // "visibility":this.$route.query.markAllVisible === 'true' ? 'visible' : 'none',
                            "visibility":this.$route.query.markAllVisible === 'true' ? 'visible' : 'none',
                        },
                        "filter":['==','visible','visible'],
                        "paint":{
                            "text-color": "#ffffff",
                            "text-halo-color":'rgba(0, 0, 0, 1)',
                            "text-halo-blur":3,
                            "text-halo-width": 1,
                            "icon-opacity": 1,
                            //"icon-color":["get", "color"],
                            // "icon-halo-color":'rgba(0, 0, 0, 1)',
                            // "icon-halo-blur":2,
                            // "icon-halo-width":1.5,
                        }
                    },)//点
                    //加载地图上的线
                    this.map.addSource('linePointLayer',{
                        type:"geojson",
                        data:this.lineJson.linePoint
                    })
                    this.map.addSource('lineLayer',{
                        type:"geojson",
                        data:this.lineJson.lineGeo
                    })
                    this.map.addSource('lineNamePoint',{
                        type:"geojson",
                        data:this.lineJson.lineNamePoint
                    })
                    this.map.addLayer({
                        id:'lineNamePoint',
                        type:'symbol',
                        source: 'lineNamePoint',
                        layout:{
                            "text-field": ["get", "name"],
                            "text-font":this.$store.state.font,
                            "text-size":16,
                            "text-anchor": "top",
                            "text-offset": [-1.3,1],
                            "visibility":this.$route.query.markAllVisible === 'true' ? 'visible' : 'none',
                            "icon-allow-overlap":true,
                            "text-allow-overlap":true,
                        },
                        filter:['==','visible','visible'],
                        paint:{
                            "text-color": "#ffffff",
                            "text-halo-color":'rgba(0, 0, 0, 1)',
                            "text-halo-blur":3,
                            "text-halo-width": 1,
                        }
                    },'markerList2')//线名
                    this.map.addLayer({
                        id: 'linePointLayer',
                        type: 'circle',
                        source: 'linePointLayer',
                        layout:{
                            "visibility":this.$route.query.markAllVisible === 'true' ? 'visible' : 'none',
                        },
                        filter:['==','visible','visible'],
                        paint: {
                            'circle-color': ["get", "color"],
                            'circle-radius': 5,
                            'circle-stroke-width': 3,
                            'circle-stroke-color': '#ffffff'
                        }
                    },'lineNamePoint');//线-点
                    this.map.addLayer({
                        id:'linePointSymbol',
                        type:'symbol',
                        source: 'linePointLayer',
                        layout:{
                            "text-field": ["get", "name"],
                            "text-font":this.$store.state.font,
                            "text-size":16,
                            "text-anchor": "bottom",
                            "text-offset": [0,-0.8],
                            "visibility":this.$route.query.markAllVisible === 'true' ? 'visible' : 'none',
                            "icon-allow-overlap":true,
                            "text-allow-overlap":true,
                        },
                        filter:['==','visible','visible'],
                        paint:{
                            "text-color": "#ffffff",
                            "text-halo-color":'rgba(0, 0, 0, 1)',
                            "text-halo-blur":3,
                            "text-halo-width": 1,
                        }
                    },'linePointLayer')//点名
                    this.map.addLayer({
                        id: 'lineLayer',
                        type: 'line',
                        source: 'lineLayer',
                        layout:{
                            "visibility":this.$route.query.markAllVisible === 'true' ? 'visible' : 'none',
                        },
                        filter:['==','visible','visible'],
                        paint: {
                            'line-color': ["get", "color"],
                            'line-width':["get", "width"],
                            'line-opacity': 1,
                            // 'line-blur':5,
                        }
                    },'linePointSymbol')//线
                    //加载地图上路线规划
                    this.map.addSource('roadPointLayer',{
                        type:"geojson",
                        data:this.roadJson.roadPoint
                    })
                    this.map.addSource('roadLayer',{
                        type:"geojson",
                        data:this.roadJson.roadGeo
                    })
                    this.map.addSource('roadNamePoint',{
                        type:"geojson",
                        data:this.roadJson.roadNamePoint
                    })
                    this.map.addLayer({
                        id:'roadNamePoint',
                        type:'symbol',
                        source: 'roadNamePoint',
                        layout:{
                            "text-field": ["get", "name"],
                            "text-font":this.$store.state.font,
                            "text-size":16,
                            "text-anchor": "top",
                            "text-offset": [-1.3,1],
                            "visibility":this.$route.query.markAllVisible === 'true' ? 'visible' : 'none',
                        },
                        filter:['==','visible','visible'],
                        paint:{
                            "text-color": "#ffffff",
                            "text-halo-color":'rgba(0, 0, 0, 1)',
                            "text-halo-blur":3,
                            "text-halo-width": 1,
                        }
                    },'lineLayer')
                    this.map.addLayer({
                        id: 'roadPointLayer',
                        type: 'circle',
                        source: 'roadPointLayer',
                        layout:{
                            "visibility":this.$route.query.markAllVisible === 'true' ? 'visible' : 'none',
                        },
                        filter:['==','visible','visible'],
                        paint: {
                            'circle-color':["get", "color"],
                            'circle-radius': 5,
                            'circle-stroke-width': 3,
                            'circle-stroke-color': '#ffffff'
                        }
                    },'roadNamePoint');
                    this.map.addLayer({
                        id:'roadPointSymbol',
                        type:'symbol',
                        source: 'roadPointLayer',
                        layout:{
                            "text-field": ["get", "name"],
                            "text-font":this.$store.state.font,
                            "text-size":16,
                            "text-anchor": "bottom",
                            "text-offset": [0,-0.8],
                            "visibility":this.$route.query.markAllVisible === 'true' ? 'visible' : 'none',
                        },
                        filter:['==','visible','visible'],
                        paint:{
                            "text-color": "#ffffff",
                            "text-halo-color":'rgba(0, 0, 0, 1)',
                            "text-halo-blur":3,
                            "text-halo-width": 1,
                        }
                    },'roadPointLayer')
                    this.map.addLayer({
                        id: 'roadLayer',
                        type: 'line',
                        source: 'roadLayer',
                        layout:{
                            "visibility":this.$route.query.markAllVisible === 'true' ? 'visible' : 'none',
                        },
                        filter:['==','visible','visible'],
                        paint: {
                            'line-color': ["get", "color"],
                            'line-width':["get", "width"],
                            'line-opacity': 1
                        }
                    },'roadPointSymbol')
                    //加载地图上的多边形
                    this.map.addSource('polygonLayer',{
                        type:"geojson",
                        data:this.polygonJson.polygonGeo
                    })
                    this.map.addSource('polygonPointLayer',{
                        type:"geojson",
                        data:this.polygonJson.polygonPoint
                    })
                    this.map.addSource('polygonNameLayer',{
                        type:"geojson",
                        data:this.polygonJson.polygonNamePoint
                    })
                    this.map.addSource('polygonLenLayer',{
                        type:"geojson",
                        data:this.polygonJson.polygonLenPoint
                    })
                    this.map.addSource('circleRadiusPoint',{
                        type:"geojson",
                        data:this.polygonJson.polygonLenPoint
                    })
                    this.map.addLayer({
                        id:'circleRadiusPoint',
                        type:'symbol',
                        source: 'circleRadiusPoint',
                        layout:{
                            "text-field": ["get", "name"],
                            "text-font":this.$store.state.font,
                            "text-size":16,
                            "text-anchor": "left",
                            "text-offset": [1,0],
                            "text-line-height":2,
                            "visibility":this.$route.query.markAllVisible === 'true' ? 'visible' : 'none',
                        },
                        filter:['==','visible','visible'],
                        paint:{
                            "text-color": "#ffffff",
                            "text-halo-color":'rgba(0, 0, 0, 1)',
                            "text-halo-blur":3,
                            "text-halo-width": 1,
                        }
                    },'roadLayer')//圆半径
                    this.map.addLayer({
                        id:'polygonNameLayer',
                        type:'symbol',
                        source: 'polygonNameLayer',
                        layout:{
                            "text-field": ["get", "name"],
                            "text-font":this.$store.state.font,
                            "text-size":16,
                            "text-anchor": "center",
                            "visibility":this.$route.query.markAllVisible === 'true' ? 'visible' : 'none',
                        },
                        filter:['==','visible','visible'],
                        paint:{
                            "text-color": "#ffffff",
                            "text-halo-color":'rgba(0, 0, 0, 1)',
                            "text-halo-blur":3,
                            "text-halo-width": 1,
                        }
                    },'circleRadiusPoint')//名字
                    this.map.addLayer({
                        id: 'polygonPointLayer',
                        type: 'circle',
                        source: 'polygonPointLayer',
                        layout: {
                            "visibility":this.$route.query.markAllVisible === 'true' ? 'visible' : 'none',
                        },
                        filter:['==','visible','visible'],
                        paint: {
                            'circle-color': ["get", "color"],
                            'circle-radius': 5,
                            'circle-stroke-width': 3,
                            'circle-stroke-color': '#ffffff'
                        }
                    },'polygonNameLayer')//点
                    this.map.addLayer({
                        id:'polygonPointSymbol',
                        type:'symbol',
                        source: 'polygonPointLayer',
                        layout:{
                            "text-field": ["get", "name"],
                            "text-font":this.$store.state.font,
                            "text-size":16,
                            "text-anchor": "bottom",
                            "text-offset": [0,-0.8],
                            "visibility":this.$route.query.markAllVisible === 'true' ? 'visible' : 'none',
                        },
                        filter:['==','visible','visible'],
                        paint:{
                            "text-color": "#ffffff",
                            "text-halo-color":'rgba(0, 0, 0, 1)',
                            "text-halo-blur":3,
                            "text-halo-width": 1,
                        }
                    },'polygonPointLayer')//点名
                    this.map.addLayer({
                        id:'polygonLenLayer',
                        type:'symbol',
                        source: 'polygonLenLayer',
                        layout:{
                            "text-field": ["get", "name"],
                            "text-font":this.$store.state.font,
                            "text-size":16,
                            "text-anchor": "center",
                            "visibility":this.$route.query.markAllVisible === 'true' ? 'visible' : 'none',
                        },
                        filter:['==','visible','visible'],
                        paint:{
                            "text-color": "#ffffff",
                            "text-halo-color":'rgba(0, 0, 0, 1)',
                            "text-halo-blur":3,
                            "text-halo-width": 1,
                        }
                    },'polygonPointSymbol')//线名
                    this.map.addLayer({
                        id: 'polygonLineLayer',
                        type: 'line',
                        source: 'polygonLayer',
                        layout: {
                            "visibility":this.$route.query.markAllVisible === 'true' ? 'visible' : 'none',
                        },
                        filter:['==','visible','visible'],
                        paint: {
                            'line-color': ["get", "lineColor"],
                            'line-width': ["get", "width"]
                        }
                    },'polygonLenLayer')//线
                    this.map.addLayer({
                        id: 'polygonLayer',
                        type: 'fill',
                        source: 'polygonLayer',
                        layout: {
                            "visibility":this.$route.query.markAllVisible === 'true' ? 'visible' : 'none',
                        },
                        filter:['==','visible','visible'],
                        paint: {
                            'fill-color':["get", "color"],
                            'fill-opacity': 0.5,
                        }
                    },'polygonLineLayer')//多边形
                    this.loadMarkImage()
                    if(this.$route.query.infoId){
                        this.getFolderDataList(this.$route.query.infoId)
                    }
                });
            },
            loadMarkImage(){
                const _this=this;
                //第一个图标
                this.map.loadImage(
                    marker_one_red1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_one_red1',image)
                    }
                )
                this.map.loadImage(
                    marker_one_red2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_one_red2',image)
                    }
                )
                this.map.loadImage(
                    marker_one_red3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_one_red3',image)
                    }
                )
                this.map.loadImage(
                    marker_one_blue1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_one_blue1',image)
                    }
                )
                this.map.loadImage(
                    marker_one_blue2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_one_blue2',image)
                    }
                )
                this.map.loadImage(
                    marker_one_blue3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_one_blue3',image)
                    }
                )
                this.map.loadImage(
                    marker_one_yellow1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_one_yellow1',image)
                    }
                )
                this.map.loadImage(
                    marker_one_yellow2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_one_yellow2',image)
                    }
                )
                this.map.loadImage(
                    marker_one_yellow3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_one_yellow3',image)
                    }
                )
                this.map.loadImage(
                    marker_one_green1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_one_green1',image)
                    }
                )
                this.map.loadImage(
                    marker_one_green2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_one_green2',image)
                    }
                )
                this.map.loadImage(
                    marker_one_green3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_one_green3',image)
                    }
                )
                this.map.loadImage(
                    marker_one_cyan1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_one_cyan1',image)
                    }
                )
                this.map.loadImage(
                    marker_one_cyan2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_one_cyan2',image)
                    }
                )
                this.map.loadImage(
                    marker_one_cyan3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_one_cyan3',image)
                    }
                )
                //第二个图标
                this.map.loadImage(
                    marker_two_red1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_two_red1',image)
                    }
                )
                this.map.loadImage(
                    marker_two_red2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_two_red2',image)
                    }
                )
                this.map.loadImage(
                    marker_two_red3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_two_red3',image)
                    }
                )
                this.map.loadImage(
                    marker_two_blue1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_two_blue1',image)
                    }
                )
                this.map.loadImage(
                    marker_two_blue2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_two_blue2',image)
                    }
                )
                this.map.loadImage(
                    marker_two_blue3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_two_blue3',image)
                    }
                )
                this.map.loadImage(
                    marker_two_yellow1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_two_yellow1',image)
                    }
                )
                this.map.loadImage(
                    marker_two_yellow2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_two_yellow2',image)
                    }
                )
                this.map.loadImage(
                    marker_two_yellow3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_two_yellow3',image)
                    }
                )
                this.map.loadImage(
                    marker_two_green1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_two_green1',image)
                    }
                )
                this.map.loadImage(
                    marker_two_green2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_two_green2',image)
                    }
                )
                this.map.loadImage(
                    marker_two_green3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_two_green3',image)
                    }
                )
                this.map.loadImage(
                    marker_two_cyan1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_two_cyan1',image)
                    }
                )
                this.map.loadImage(
                    marker_two_cyan2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_two_cyan2',image)
                    }
                )
                this.map.loadImage(
                    marker_two_cyan3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_two_cyan3',image)
                    }
                )
                //第三个个图标
                this.map.loadImage(
                    marker_three_red1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_three_red1',image)
                    }
                )
                this.map.loadImage(
                    marker_three_red2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_three_red2',image)
                    }
                )
                this.map.loadImage(
                    marker_three_red3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_three_red3',image)
                    }
                )
                this.map.loadImage(
                    marker_three_blue1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_three_blue1',image)
                    }
                )
                this.map.loadImage(
                    marker_three_blue2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_three_blue2',image)
                    }
                )
                this.map.loadImage(
                    marker_three_blue3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_three_blue3',image)
                    }
                )
                this.map.loadImage(
                    marker_three_yellow1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_three_yellow1',image)
                    }
                )
                this.map.loadImage(
                    marker_three_yellow2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_three_yellow2',image)
                    }
                )
                this.map.loadImage(
                    marker_three_yellow3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_three_yellow3',image)
                    }
                )
                this.map.loadImage(
                    marker_three_green1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_three_green1',image)
                    }
                )
                this.map.loadImage(
                    marker_three_green2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_three_green2',image)
                    }
                )
                this.map.loadImage(
                    marker_three_green3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_three_green3',image)
                    }
                )
                this.map.loadImage(
                    marker_three_cyan1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_three_cyan1',image)
                    }
                )
                this.map.loadImage(
                    marker_three_cyan2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_three_cyan2',image)
                    }
                )
                this.map.loadImage(
                    marker_three_cyan3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_three_cyan3',image)
                    }
                )
                //第四个图标
                this.map.loadImage(
                    marker_four_red1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_four_red1',image)
                    }
                )
                this.map.loadImage(
                    marker_four_red2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_four_red2',image)
                    }
                )
                this.map.loadImage(
                    marker_four_red3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_four_red3',image)
                    }
                )
                this.map.loadImage(
                    marker_four_blue1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_four_blue1',image)
                    }
                )
                this.map.loadImage(
                    marker_four_blue2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_four_blue2',image)
                    }
                )
                this.map.loadImage(
                    marker_four_blue3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_four_blue3',image)
                    }
                )
                this.map.loadImage(
                    marker_four_yellow1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_four_yellow1',image)
                    }
                )
                this.map.loadImage(
                    marker_four_yellow2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_four_yellow2',image)
                    }
                )
                this.map.loadImage(
                    marker_four_yellow3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_four_yellow3',image)
                    }
                )
                this.map.loadImage(
                    marker_four_green1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_four_green1',image)
                    }
                )
                this.map.loadImage(
                    marker_four_green2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_four_green2',image)
                    }
                )
                this.map.loadImage(
                    marker_four_green3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_four_green3',image)
                    }
                )
                this.map.loadImage(
                    marker_four_cyan1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_four_cyan1',image)
                    }
                )
                this.map.loadImage(
                    marker_four_cyan2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_four_cyan2',image)
                    }
                )
                this.map.loadImage(
                    marker_four_cyan3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_four_cyan3',image)
                    }
                )
                //第五个图标
                this.map.loadImage(
                    marker_five_red1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_five_red1',image)
                    }
                )
                this.map.loadImage(
                    marker_five_red2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_five_red2',image)
                    }
                )
                this.map.loadImage(
                    marker_five_red3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_five_red3',image)
                    }
                )
                this.map.loadImage(
                    marker_five_blue1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_five_blue1',image)
                    }
                )
                this.map.loadImage(
                    marker_five_blue2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_five_blue2',image)
                    }
                )
                this.map.loadImage(
                    marker_five_blue3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_five_blue3',image)
                    }
                )
                this.map.loadImage(
                    marker_five_yellow1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_five_yellow1',image)
                    }
                )
                this.map.loadImage(
                    marker_five_yellow2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_five_yellow2',image)
                    }
                )
                this.map.loadImage(
                    marker_five_yellow3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_five_yellow3',image)
                    }
                )
                this.map.loadImage(
                    marker_five_green1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_five_green1',image)
                    }
                )
                this.map.loadImage(
                    marker_five_green2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_five_green2',image)
                    }
                )
                this.map.loadImage(
                    marker_five_green3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_five_green3',image)
                    }
                )
                this.map.loadImage(
                    marker_five_cyan1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_five_cyan1',image)
                    }
                )
                this.map.loadImage(
                    marker_five_cyan2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_five_cyan2',image)
                    }
                )
                this.map.loadImage(
                    marker_five_cyan3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_five_cyan3',image)
                    }
                )
                //第六个图标
                this.map.loadImage(
                    marker_six_red1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_six_red1',image)
                    }
                )
                this.map.loadImage(
                    marker_six_red2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_six_red2',image)
                    }
                )
                this.map.loadImage(
                    marker_six_red3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_six_red3',image)
                    }
                )
                this.map.loadImage(
                    marker_six_blue1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_six_blue1',image)
                    }
                )
                this.map.loadImage(
                    marker_six_blue2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_six_blue2',image)
                    }
                )
                this.map.loadImage(
                    marker_six_blue3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_six_blue3',image)
                    }
                )
                this.map.loadImage(
                    marker_six_yellow1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_six_yellow1',image)
                    }
                )
                this.map.loadImage(
                    marker_six_yellow2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_six_yellow2',image)
                    }
                )
                this.map.loadImage(
                    marker_six_yellow3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_six_yellow3',image)
                    }
                )
                this.map.loadImage(
                    marker_six_green1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_six_green1',image)
                    }
                )
                this.map.loadImage(
                    marker_six_green2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_six_green2',image)
                    }
                )
                this.map.loadImage(
                    marker_six_green3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_six_green3',image)
                    }
                )
                this.map.loadImage(
                    marker_six_cyan1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_six_cyan1',image)
                    }
                )
                this.map.loadImage(
                    marker_six_cyan2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_six_cyan2',image)
                    }
                )
                this.map.loadImage(
                    marker_six_cyan3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_six_cyan3',image)
                    }
                )
                //第7个图标
                this.map.loadImage(
                    marker_seven_red1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_seven_red1',image)
                    }
                )
                this.map.loadImage(
                    marker_seven_red2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_seven_red2',image)
                    }
                )
                this.map.loadImage(
                    marker_seven_red3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_seven_red3',image)
                    }
                )
                this.map.loadImage(
                    marker_seven_blue1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_seven_blue1',image)
                    }
                )
                this.map.loadImage(
                    marker_seven_blue2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_seven_blue2',image)
                    }
                )
                this.map.loadImage(
                    marker_seven_blue3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_seven_blue3',image)
                    }
                )
                this.map.loadImage(
                    marker_seven_yellow1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_seven_yellow1',image)
                    }
                )
                this.map.loadImage(
                    marker_seven_yellow2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_seven_yellow2',image)
                    }
                )
                this.map.loadImage(
                    marker_seven_yellow3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_seven_yellow3',image)
                    }
                )
                this.map.loadImage(
                    marker_seven_green1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_seven_green1',image)
                    }
                )
                this.map.loadImage(
                    marker_seven_green2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_seven_green2',image)
                    }
                )
                this.map.loadImage(
                    marker_seven_green3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_seven_green3',image)
                    }
                )
                this.map.loadImage(
                    marker_seven_cyan1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_seven_cyan1',image)
                    }
                )
                this.map.loadImage(
                    marker_seven_cyan2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_seven_cyan2',image)
                    }
                )
                this.map.loadImage(
                    marker_seven_cyan3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_seven_cyan3',image)
                    }
                )
                //第8个图标
                this.map.loadImage(
                    marker_eight_red1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_eight_red1',image)
                    }
                )
                this.map.loadImage(
                    marker_eight_red2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_eight_red2',image)
                    }
                )
                this.map.loadImage(
                    marker_eight_red3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_eight_red3',image)
                    }
                )
                this.map.loadImage(
                    marker_eight_blue1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_eight_blue1',image)
                    }
                )
                this.map.loadImage(
                    marker_eight_blue2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_eight_blue2',image)
                    }
                )
                this.map.loadImage(
                    marker_eight_blue3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_eight_blue3',image)
                    }
                )
                this.map.loadImage(
                    marker_eight_yellow1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_eight_yellow1',image)
                    }
                )
                this.map.loadImage(
                    marker_eight_yellow2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_eight_yellow2',image)
                    }
                )
                this.map.loadImage(
                    marker_eight_yellow3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_eight_yellow3',image)
                    }
                )
                this.map.loadImage(
                    marker_eight_green1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_eight_green1',image)
                    }
                )
                this.map.loadImage(
                    marker_eight_green2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_eight_green2',image)
                    }
                )
                this.map.loadImage(
                    marker_eight_green3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_eight_green3',image)
                    }
                )
                this.map.loadImage(
                    marker_eight_cyan1,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_eight_cyan1',image)
                    }
                )
                this.map.loadImage(
                    marker_eight_cyan2,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_eight_cyan2',image)
                    }
                )
                this.map.loadImage(
                    marker_eight_cyan3,
                    function (error,image) {
                        if(error) throw error
                        _this.map.addImage('marker_eight_cyan3',image)
                    }
                )
            },
            //初始化
            initMapMarker(val){
                for(let i =0;i<val.length;i++){
                    if(val[i].type !=='folder'){
                        this.addToMap(val[i])
                    }
                    this.initMapMarker(val[i].children)
                }
                //需要找到第一个点
            },
            //找到第一个点或者线
            findFirstGeo(val){
                for(let i =0;i<val.length;i++){

                    if(val[i].id ===this.markId){
                        this.animationGeo(val[i])
                        return
                    }
                    this.findFirstGeo(val[i].children)
                }
            },
            animationGeo(val){
                if(val.type ==='point'){
                    this.animationPointGeo(val.geoJson.geo)
                }else{
                    this.animationLineGeo(val.geoJson.geo)
                }
            },
            rotateCamera(timestamp) {
                this.map.rotateTo((timestamp / 100) % 360, { duration: 0 });
                requestAnimationFrame(this.rotateCamera);
            },
            animationPointGeo(geo){
                const _this = this
                const bbox = turf.bbox(geo)
                this.map.fitBounds([[bbox[0], bbox[1]], [bbox[2], bbox[3]]],{
                    linear: false,
                    animate: true,
                    maxZoom:14,
                    padding: {top: 20, bottom:30, left: 40, right: 40},
                    easing(t){
                        if(t === 1){
                            _this.rotateCamera(0)
                        }
                        return t
                    }
                })
           },
            animationLineGeo(geo){
                const _this = this
                let lintFirstPoint = {
                    geometry:{
                        coordinates:geo.geometry.coordinates[0],
                        type:'Point'
                    },
                    properties:{},
                    type:'Feature',
                }
                const bbox = turf.bbox(lintFirstPoint)

                this.map.fitBounds([[bbox[0], bbox[1]], [bbox[2], bbox[3]]],{
                    linear: false,
                    animate: true,
                    maxZoom:14,
                    padding: {top: 20, bottom:30, left: 40, right: 40},
                    easing(t){
                        if(t === 1){
                            _this.lineAnimation(geo)
                        }
                        return t
                    }
                })
            },
            lineAnimation(geo){
                const length = turf.length(geo, {units: 'kilometers'});
                let animationDuration
                if(length>1)
                    animationDuration = 5000*length;
                else
                    animationDuration = 5000
                //const cameraAltitude = 4000;
                const cameraAltitude = 2000;

                const routeDistance = turf.lineDistance(geo);
                const cameraRouteDistance = turf.lineDistance(geo);
                let start;
                const _this = this
                function frame(time) {
                    if (!start) start = time;
                    // phase determines how far through the animation we are
                    const phase = (time - start) / animationDuration;

                    // phase is normalized between 0 and 1
                    // when the animation is finished, reset start to loop the animation
                    if (phase > 1) {
                    // wait 1.5 seconds before looping
                        setTimeout(() => {
                            start = 0.0;
                        }, 100);
                    }
                    const alongRoute = _this.along(geo, routeDistance * phase).geometry.coordinates;

                    const alongCamera = _this.along(geo, cameraRouteDistance * phase).geometry.coordinates;

                    const camera = _this.map.getFreeCameraOptions();
                    const startElevation = _this.map.queryTerrainElevation({lng: alongCamera[0], lat: alongCamera[1]}, { exaggerated: false });
                    camera.position = mapboxgl.MercatorCoordinate.fromLngLat(
                        {
                            lng: alongCamera[0],
                            lat: alongCamera[1]
                        },
                        cameraAltitude+startElevation
                    );
                    camera.lookAtPoint({
                        lng: alongRoute[0],
                        lat: alongRoute[1]
                    });
                    _this.map.setFreeCameraOptions(camera);

                    window.requestAnimationFrame(frame);
                }
                window.requestAnimationFrame(frame);
            },
            along(line, distance, options) {
                if (options === void 0) { options = {}; }
                // Get Coords
                let geom = turf.getGeom(line);
                let coords = geom.coordinates;
                let travelled = 0;
                for (let i = 0; i < coords.length; i++) {
                    if (distance >= travelled && i === coords.length - 1) {
                        break;
                    }
                    else if (travelled >= distance) {
                        var overshot = distance - travelled;
                        if (!overshot) {
                            return turf.point(coords[i]);
                        }
                        else {
                            var direction = turf.rhumbBearing(coords[i], coords[i - 1]) - 180;
                            var interpolated = turf.rhumbDestination(coords[i], overshot, direction, options);
                            return interpolated;
                        }
                    }
                    else {
                        travelled += turf.rhumbDistance(coords[i], coords[i + 1], options);
                    }
                }
                return turf.point(coords[coords.length - 1]);
            },
            addToMap(val){
                const map={
                    "point":(val)=>{this.addPointToMap(val)},
                    "lineString":(val)=>{this.addLinToMap(val)},
                    "roadString":(val)=>{this.addRoadToMap(val)},
                    "circle":(val)=>{this.addCircleToMap(val)},
                    "rectangle":(val)=>{this.addRectangleToMap(val)},
                    "polygon":(val)=>{this.addPolygonToMap(val)},
                    "administrativeArea":(val)=>{this.addAreaToMap(val)},
                }
                map[val.type](val)
            },
            addPointToMap(val){
                const point = {
                    type:'Feature',
                    id: val.id,
                    geometry: val.geoJson.geo.geometry,
                    properties: val.geoJson.geo.properties
                }
                this.markerList.features.push(point)
                this.map.getSource('markerLayer').setData(this.markerList)
            },
            addLinToMap(val){
                const line = {
                    type:'Feature',
                    id: val.id,
                    geometry: val.geoJson.geo.geometry,
                    properties: val.geoJson.geo.properties
                }
                this.lineJson.lineGeo.features.push(line)
                this.map.getSource('lineLayer').setData(this.lineJson.lineGeo);
                if(val.geoJson.points.length !==0){
                    const lineName = {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: val.geoJson.points[0].geometry.coordinates
                        },
                        id: val.id,
                        properties:{
                            "type":'point',
                            "name": val.geoJson.geo.properties.name,
                            "visible":val.visible ? "visible":"none",
                            "country":"",
                            "addrName":"",
                            "imageList":[],
                            "secondId":'',
                        }
                    }
                    this.lineJson.lineNamePoint.features.push(lineName)
                    this.map.getSource('lineNamePoint').setData(this.lineJson.lineNamePoint)
                    this.lineJson.linePoint.features.push(...val.geoJson.points)
                    this.map.getSource('linePointLayer').setData(this.lineJson.linePoint);
                }
            },
            addRoadToMap(val){
                const line = {
                    type:'Feature',
                    id: val.id,
                    geometry: val.geoJson.geo.geometry,
                    properties: val.geoJson.geo.properties
                }
                this.roadJson.roadGeo.features.push(line)
                this.map.getSource('roadLayer').setData(this.roadJson.roadGeo)
                if(val.geoJson.points.length !==0){
                    const lineName = {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: val.geoJson.points[0].geometry.coordinates
                        },
                        id: val.id,
                        properties:{
                            "type":'point',
                            "name": val.geoJson.geo.properties.name,
                            "visible":val.visible ? "visible":"none",
                            "country":"",
                            "addrName":"",
                            "imageList":[],
                            "secondId":'',
                        }
                    }
                    this.roadJson.roadNamePoint.features.push(lineName)
                    this.map.getSource('roadNamePoint').setData(this.roadJson.roadNamePoint)
                    this.roadJson.roadPoint.features.push(...val.geoJson.points)
                    this.map.getSource('roadPointLayer').setData(this.roadJson.roadPoint)
                }
            },
            addCircleToMap(val){
                const circle={
                    type:'Feature',
                    id: val.id,
                    geometry: val.geoJson.geo.geometry,
                    properties: val.geoJson.geo.properties
                }
                this.polygonJson.polygonGeo.features.push(circle)
                const circleName={
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: val.geoJson.geo.properties.center
                    },
                    id: val.id,
                    properties:{
                        "type":'point',
                        "name": val.geoJson.geo.properties.name,
                        "visible":val.visible ? "visible":"none",
                        "country":"",
                        "addrName":"",
                        "imageList":[],
                        "secondId":'',
                    }
                }
                this.polygonJson.polygonNamePoint.features.push(circleName)
                const circleRadius = {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: val.geoJson.geo.properties.center
                    },
                    id: val.id,
                    properties:{
                        "type":'point',
                        "name": "半径："+this.lengthUomConversion(val.geoJson.geo.properties.len),
                        "visible":"none",
                        "country":"",
                        "addrName":"",
                        "imageList":[],
                        "secondId":'',
                    }
                }
                const translation = turf.transformTranslate(circleRadius,val.geoJson.geo.properties.len/1000,90)
                this.polygonJson.circleRadiusPoint.features.push(translation)
                this.map.getSource('polygonLayer').setData(this.polygonJson.polygonGeo);
                this.map.getSource('circleRadiusPoint').setData(this.polygonJson.circleRadiusPoint);
                this.map.getSource('polygonNameLayer').setData(this.polygonJson.polygonNamePoint);
            },
            addRectangleToMap(val){
                const rectangle={
                    type:'Feature',
                    id: val.id,
                    geometry: val.geoJson.geo.geometry,
                    properties: val.geoJson.geo.properties
                }
                this.polygonJson.polygonGeo.features.push(rectangle)
                this.map.getSource('polygonLayer').setData(this.polygonJson.polygonGeo);
                const rectangleName = {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: val.geoJson.geo.properties.center
                    },
                    id: val.id,
                    properties:{
                        "type":'point',
                        "name": val.geoJson.geo.properties.name,
                        "visible":val.visible ? "visible":"none",
                        "country":"",
                        "addrName":"",
                        "imageList":[],
                        "secondId":'',
                    }
                }
                this.polygonJson.polygonNamePoint.features.push(rectangleName)
                this.map.getSource('polygonNameLayer').setData(this.polygonJson.polygonNamePoint);
                if(val.geoJson.points.length !==0){
                    this.polygonJson.polygonPoint.features.push(...val.geoJson.points)
                    this.map.getSource('polygonPointLayer').setData(this.polygonJson.polygonPoint);
                }
                const leftCenter = turf.midpoint(turf.point(val.geoJson.geo.geometry.coordinates[0][0]),turf.point(val.geoJson.geo.geometry.coordinates[0][3]))
                const leftLen = turf.distance(turf.point(val.geoJson.geo.geometry.coordinates[0][0]),turf.point(val.geoJson.geo.geometry.coordinates[0][3]),{units:'kilometers'})
                this.polygonJson.polygonLenPoint.features.push(
                    {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: leftCenter.geometry.coordinates
                        },
                        id: val.id,
                        properties: {
                            "type":'point',
                            "name":this.lengthUomConversion(leftLen*1000),
                            "visible":"none",
                            "country":'',
                            "addrName":'',
                            "imageList":[],
                            "secondId":''
                        },
                    }
                )
                const rightCenter = turf.midpoint(turf.point(val.geoJson.geo.geometry.coordinates[0][2]),turf.point(val.geoJson.geo.geometry.coordinates[0][3]))
                const rightLen = turf.distance(turf.point(val.geoJson.geo.geometry.coordinates[0][2]),turf.point(val.geoJson.geo.geometry.coordinates[0][3]),{units:'kilometers'})
                this.polygonJson.polygonLenPoint.features.push(
                    {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: rightCenter.geometry.coordinates
                        },
                        id: val.id,
                        properties: {
                            "type":'point',
                            "name":this.lengthUomConversion(rightLen*1000),
                            "visible":"none",
                            "country":'',
                            "addrName":'',
                            "imageList":[],
                            "secondId":''
                        },
                    }
                )
                this.map.getSource('polygonLenLayer').setData(this.polygonJson.polygonLenPoint);
            },
            addPolygonToMap(val){
                const polygon={
                    type:'Feature',
                    id: val.id,
                    geometry: val.geoJson.geo.geometry,
                    properties: val.geoJson.geo.properties
                }
                this.polygonJson.polygonGeo.features.push(polygon)
                this.map.getSource('polygonLayer').setData(this.polygonJson.polygonGeo);
                const polygonName = {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: val.geoJson.geo.properties.center
                    },
                    id: val.id,
                    properties:{
                        "type":'point',
                        "name": val.geoJson.geo.properties.name,
                        "visible":val.visible ? "visible":"none",
                        "country":"",
                        "addrName":"",
                        "imageList":[],
                        "secondId":'',
                    }
                }
                this.polygonJson.polygonNamePoint.features.push(polygonName)
                this.map.getSource('polygonNameLayer').setData(this.polygonJson.polygonNamePoint);
                if(val.geoJson.points.length !==0){
                    this.polygonJson.polygonPoint.features.push(...val.geoJson.points)
                    this.map.getSource('polygonPointLayer').setData(this.polygonJson.polygonPoint);
                }
                // for(let i=0;i<val.geoJson.geo.geometry.coordinates[0].length-1;i++){
                //     let center = turf.midpoint(turf.point(val.geoJson.geo.geometry.coordinates[0][i]),turf.point(val.geoJson.geo.geometry.coordinates[0][i+1]))
                //     let len = turf.distance(turf.point(val.geoJson.geo.geometry.coordinates[0][i]),turf.point(val.geoJson.geo.geometry.coordinates[0][i+1]),{units:'kilometers'})
                //     this.polygonJson.polygonLenPoint.features.push(
                //         {
                //             type: 'Feature',
                //             geometry: {
                //                 type: 'Point',
                //                 coordinates: center.geometry.coordinates
                //             },
                //             id: val.id,
                //             properties: {
                //                 "type":'point',
                //                 "name":this.lengthUomConversion(len*1000),
                //                 "visible":"none",
                //                 "country":'',
                //                 "addrName":'',
                //                 "imageList":[],
                //                 "secondId":i+1
                //             },
                //         }
                //     )
                //     this.map.getSource('polygonLenLayer').setData(this.polygonJson.polygonLenPoint);
                // }
            },
            addAreaToMap(val){
                const area={
                    type:'Feature',
                    id: val.id,
                    geometry: val.geoJson.geo.geometry,
                    properties: val.geoJson.geo.properties
                }
                this.polygonJson.polygonGeo.features.push(area)
                const areaName={
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: val.geoJson.geo.properties.center
                    },
                    id: val.id,
                    properties:{
                        "type":'point',
                        "name": val.geoJson.geo.properties.name,
                        "visible":val.visible ? "visible":"none",
                        "country":"",
                        "addrName":"",
                        "imageList":[],
                        "secondId":'',
                    }
                }
                this.polygonJson.polygonNamePoint.features.push(areaName)
                this.map.getSource('polygonLayer').setData(this.polygonJson.polygonGeo);
                this.map.getSource('polygonNameLayer').setData(this.polygonJson.polygonNamePoint);
            },
            //整理线段长度方法
            lengthUomConversion(len){
                if(len >1000)
                    return (len/1000).toFixed(2) +"千米"
                else
                    return len.toFixed(2) +"米"
            },
            initData(val){
                // //清理点
                // this.markerList.features =[]
                // //清理线
                // this.lineJson.lineGeo.features =[]
                // this.lineJson.linePoint.features =[]
                // this.lineJson.lineNamePoint.features =[]
                // //清理路线
                // this.roadJson.roadGeo.features = []
                // this.roadJson.roadPoint.features = []
                // this.roadJson.roadNamePoint.features = []
                // //清理面
                // this.polygonJson.polygonGeo.features = []
                // this.polygonJson.polygonPoint.features = []
                // this.polygonJson.polygonNamePoint.features = []
                // this.polygonJson.polygonLenPoint.features = []
                // this.polygonJson.circleRadiusPoint.features = []
                // this.map.getSource('markerLayer').setData(this.markerList)
                // this.map.getSource('lineLayer').setData(this.lineJson.lineGeo);
                // this.map.getSource('linePointLayer').setData(this.lineJson.linePoint);
                // this.map.getSource('lineNamePoint').setData(this.lineJson.lineNamePoint)
                // this.map.getSource('roadNamePoint').setData(this.roadJson.roadNamePoint)
                // this.map.getSource('roadPointLayer').setData(this.roadJson.roadPoint)
                // this.map.getSource('roadLayer').setData(this.roadJson.roadGeo)
                // this.map.getSource('polygonLayer').setData(this.polygonJson.polygonGeo);
                // this.map.getSource('circleRadiusPoint').setData(this.polygonJson.circleRadiusPoint);
                // this.map.getSource('polygonNameLayer').setData(this.polygonJson.polygonNamePoint);
                // this.map.getSource('polygonLenLayer').setData(this.polygonJson.polygonLenPoint);
                // this.map.getSource('polygonPointLayer').setData(this.polygonJson.polygonPoint);
            this.initMapMarker(val)
            },
            getFolderDataList(folderId){
                this.$http.get('/authorApi/z-geo-file//openFileNoLogin?fileId='+folderId).then((resultData)=>{
                    resultData = resultData.data;
                    if(resultData.status === 200){
                        this.globeOperationMarkerList = resultData.data.content
                        let listData = resultData.data.content
                        // if(this.$route.query.markAllVisible !== 'true'){
                        //   this.setMarkVisibility(listData)
                        // }
                        this.initData(listData)
                        this.findFirstGeo(listData)
                    }
                    else{
                        // console.log(resultData.status + resultData.message);
                    }
                }).catch(()=>{})
            },
            setMarkVisibility(list) {
            for (let i = 0; i < list.length; i++) {
              if (list[i].children.length > 0) {
                this.setMarkVisibility(list[i]['children'])
              } else {
                list[i].visible = false
              }
            }
          },
        },
        mounted() {
            if(this.$route.query.markId){
               this.markId =  this.$route.query.markId
                this.initMapbox()
            }

        }
    }
</script>

<style scoped>
.MarkFilePlay{
    font-family: 微软雅黑;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden;
    /*// 禁止长按选择文字*/
    -webkit-user-select: none;
    /*// 禁止长按呼出菜单*/
    -webkit-touch-callout: none;
}
</style>
