<template>
  <!--转盘抽奖 冰雪大转盘-->
  <div id="turntableGame">
    <!--标题-->
    <div id="turntableGameTitle" :style="{'margin-left':titleMarginLeft}" class="turntableGame-title">
      <p style="line-height:23px;font-weight:bold;">·</p>
      <p style="line-height:26px;font-weight:bold;margin:0 30px;">随机赢{{activityInfo.prize}}</p>
      <p style="line-height:23px;font-weight:bold;">·</p>
    </div>

    <!--转盘-->
    <div class="turntableGame-turntableBorder" style="margin-top:290px;">
      <img ref="turntable" class="turntableGame-turntable" :src="activityInfo.picture" alt="turntable">
      <img class="turntableGame-pointer" src="../../assets/TurntableGame/turntable_pointer.png" alt="pointer">
    </div>

    <!--剩余时间-->
    <div style="margin-top:10px;display:flex;align-items: center;">
      <p style="font-family:YouSheBiaoTiHei;color:#2A82E4;font-size:18px;">距离活动截止还剩</p>
      <div style="min-height:20px;padding:0 4px;margin:0 4px;background:#2A82E4;border-radius:2px;">
        <p style="line-height:20px;font-size:16px;font-family:YouSheBiaoTiHei;color:#ffffff;">{{activityInfo.endDay}}</p>
      </div>
      <p style="font-family:YouSheBiaoTiHei;color:#2A82E4;font-size:18px;">天</p>
    </div>

    <!--抽奖按钮-->
    <div @click="beginTurntable" style="width:258px;height:49px;margin:22px auto 0 auto;border-radius:25px;background: linear-gradient(#CBD7F7, #6D7BFF);box-shadow:inset 0px 3px 3px 0px #E6EDFF;text-align:center;cursor:pointer;">
      <p style="line-height:49px;font-family:YouSheBiaoTiHei;color:#ffffff;font-size:22px;">参与抽奖</p>
    </div>

    <!--活动说明-->
    <div class="turntableGame-introduce">
      <p style="font-size:14px;">活动说明</p>

      <p>1.参与抽奖活动，有机会赢取免费滑雪门票；活动期间第1-3天，每天抽取天定山双人滑雪门票10份，第4-7天，每天抽取松花湖度假区门票10份；xxxx</p>
      <p>2.活动时间为{{activityInfo.startTime !== undefined ? getFormatDate(activityInfo.startTime) : ""}}-{{activityInfo.endTime !== undefined ? getFormatDate(activityInfo.endTime) : ""}}；</p>
      <p>3.每人每天可参与{{activityInfo.every}}次活动，参与活动需登记姓名联系方式，该信息仅用于工作人员联系获奖用户兑换奖品。请确保信息填写正确，以免因填写信息错误、造成无法领取奖品。工作人员将在活动结束后5个工作日内与您联系，请保持电话畅通。中奖后将向您发送短信，奖品以短信为准。</p>
      <p>4.中奖概率说明：</p>
      <p>{{activityInfo.prize}}中奖概率：3.0%</p>
      <p>图书中奖概率：1.0%</p>
      <p>长春冰雪新天地中奖概率：1.0%</p>

      <p style="font-size:14px;margin-top:10px;">温馨提示</p>
      <p>1.活动期间请勿通过作弊手段牟取平台奖励，一旦发现立即取消其活动参与资格，并保留追究其责任权利；</p>
      <p>2.本活动技术服务由长光卫星技术股份有限公司提供；</p>
      <p>3.本活动最终解释权归长光卫星技术股份有限公司所有；如有任何疑问可通过APP中用户反馈与我们联系。</p>
    </div>


    <!--输入信息-->
    <div v-if="inputUserInfoShow" style="position:fixed;top:0;left:0;bottom:0;right:0;background:rgba(0, 0, 0, 0.6);">
      <div style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);width:calc(100% - 60px);box-sizing: border-box;background:#ffffff;border-radius:12px;padding:15px 16px 15px 11px;">
        <p style="line-height:24px;font-family:YouSheBiaoTiHei;color:#6D7BFF;font-size:18px;text-align:center;">参与抽奖</p>
        <p class="inputUserInfo-title">用户姓名：</p>
        <input v-model="userName" class="inputUserInfo" />
        <p class="inputUserInfo-title">联系方式：</p>
        <input v-model="userTel" class="inputUserInfo" />
        <p style="margin-top:15px;font-size:12px;color:#979797;text-align:justify;">温馨提示：您填写的个人信息仅用于通知您领取奖品，未填写个人信息无法参与活动；请确保个人信息填写正确，工作人员将在活动结束后5个工作日内与您联系，请保持电话畅通。</p>

        <div @click="submitUserInfo" :style="{'background':(userName!==''&&userTel!=='') ? 'linear-gradient(90deg, #6259A0 0%, #71A2FF 100%)' : '#cccccc'}" style="width:120px;height:36px;margin:10px auto 0 auto;border-radius:20px;text-align:center;cursor:pointer;">
          <p style="line-height:36px;font-family:YouSheBiaoTiHei;color:#ffffff;font-size:18px;">提交</p>
        </div>

        <!--关闭按钮-->
        <div @click="hideInputUserInfo" style="position:absolute;bottom:-66px;left:50%;transform:translate(-50%);width:26px;height:26px;border:1px solid #ffffff;border-radius:50%;text-align:center;cursor:pointer;">
          <p class="iconfontFamily" style="line-height:26px;font-size:12px;color:#ffffff;">&#xe72c;</p>
        </div>
      </div>
    </div>


    <!--获奖页面  中奖-->
    <div v-if="awardYesShow" @click="awardYesShow = false;" style="position:fixed;top:0;left:0;bottom:0;right:0;background:rgba(0, 0, 0, 0.6);">
      <div @click.stop="" style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);width:288px;height:404px;background:#ffffff;">
        <div style="position:relative;width:100%;height:304px;border-top:1px solid rgba(0, 0, 0, 0.6);">
          <img style="position:absolute;top:0;left:0;width:100%;z-index:0;" src="../../assets/TurntableGame/award_yes.png" alt="awardYes" />
          <p style="position:relative;width:100%;margin-top:61px;line-height:36px;text-align:center;color:#333333;font-size:16px;">获得{{awardInfo.prizeName}}</p>
          <div style="position:relative;width:180px;height:195px;margin:0 auto;">
            <img style="position:absolute;width:100%;" src="../../assets/TurntableGame/award_border.png" alt="awardBorder" />
            <img style="width:calc(100% - 23px);margin-top:19px;margin-left:10px;" :src="awardInfo.picture" alt="awardPicture" />
            <p style="position:absolute;bottom:10px;left:16px;line-height:24px;font-family:YouSheBiaoTiHei;color:#ffffff;font-size:18px;z-index:1;text-shadow:rgba(0, 0, 0, .25) 0 2px 4px;">{{awardInfo.context}}</p>
          </div>
        </div>
        <div style="width:120px;height:36px;margin:28px auto 0 auto;border-radius:20px;background:linear-gradient(90deg, #6259A0 0%, #71A2FF 100%);text-align:center;cursor:pointer;">
          <p @click="getAwardOk" style="line-height:36px;font-family:YouSheBiaoTiHei;color:#ffffff;font-size:18px;">{{awardType === "遥感壁纸" ? "查看图片" : "收下奖品"}}</p>
        </div>
      </div>
    </div>
    <!--获奖页面  未中奖-->
    <div v-if="awardNoShow" @click="awardNoShow = false;" style="position:fixed;top:0;left:0;bottom:0;right:0;background:rgba(0, 0, 0, 0.6);">
      <div @click.stop="" style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);width:288px;height:404px;background:#ffffff;">
        <div style="position:relative;width:100%;height:304px;border-top:1px solid rgba(0, 0, 0, 0.6);">
          <img style="position:absolute;top:0;left:0;width:100%;z-index:0;" src="../../assets/TurntableGame/award_no.png" alt="awardNo" />
          <p style="position:relative;width:100%;margin-top:61px;line-height:36px;text-align:center;color:#333333;font-size:16px;">再接再厉，继续加油!</p>
          <div style="position:relative;width:187px;height:187px;margin:6px auto 0 auto;">
            <img style="width:100%;" src="../../assets/TurntableGame/award_no_picture.png" alt="awardNoPicture" />
          </div>
        </div>
        <div :style="{'background':goTimes>0 ? 'linear-gradient(90deg, #6259A0 0%, #71A2FF 100%)' : '#cccccc'}" style="width:120px;height:36px;margin:28px auto 0 auto;border-radius:20px;text-align:center;cursor:pointer;">
          <p @click="beginTurntableNext" style="line-height:36px;font-family:YouSheBiaoTiHei;color:#ffffff;font-size:18px;">再抽一次</p>
        </div>
      </div>
    </div>


    <!--toast-->
    <div v-if="toastShow" style="position:fixed;top:50%;left:50%;transform:translate(-50%,-50%);height:60px;padding:0 20px;background:rgba(0, 0, 0, 0.6);border-radius:10px;text-align:center;">
      <p style="white-space:nowrap;line-height:60px;font-size:14px;color:#ffffff;">
        <span class="iconfontFamily" style="margin-right:4px;">{{toastText === '提交成功' ? '&#xe621;' : '&#xe724;'}}</span>
        {{toastText}}
      </p>
    </div>



  </div>

</template>

<script>
export default {
  name: "TurntableGame",
  data(){
    return{

      activityId:"",
      //活动信息
      activityInfo:{},

      //标题元素位置
      titleMarginLeft:"",

      //参与用户
      inputUserInfoShow:false,
      userName:"",
      userTel:"",
      userInfoRight:false,

      goTimes:0,					//用户可抽奖次数

      //转盘抽奖
      isGo: false,              //是否正在执行动画
      oTurntable:'',            //执行动画的对象
      randomDeg: 0,             //即将旋转的度数
      lastDeg:0,                //上一次旋转的度数


      //toast
      toastShow:false,
      toastText:"",

      //中奖信息
      awardYesShow:false,
      awardInfo:{},
      awardType:"",    //各种特殊类型奖品

      awardNoShow:false,


      token:"",



    }
  },
  computed:{
    getFormatDate(){
      return function(time){
        let formatDate = time.substr(0,10).split("-")[0]+"年"+parseInt(time.substr(0,10).split("-")[1])+"月"+parseInt(time.substr(0,10).split("-")[2])+"日";
        return formatDate;
      }
    },
  },
  methods:{
    //获取活动详情
    getDetail(){
      this.$http.get('/earthApi/lotterDraw/getLotterById?activityId='+this.activityId).then(response => {
        if(response.body.status === 200){
          console.log(response.body.data);
          this.activityInfo = response.body.data;

          //计算标题位置
          setTimeout(()=>{
            this.titleMarginLeft = (document.body.clientWidth - document.getElementById("turntableGameTitle").offsetWidth)/2 - 15 + "px"
            console.log(this.titleMarginLeft)
          },10)
        }
        else if(response.body.status === 404){
          this.$router.push(
              {
                path: '/NotExist',
              }
          )
        }
        else{
          console.log(response.data.status + response.data.message)
        }

      })
    },


    //转盘方法
    beginTurntable(){
      if(this.activityInfo.isEnd){
        //活动已结束
        this.toastText = "活动已结束";
        this.toastShow = true;
        setTimeout(()=>{
          this.toastShow = false;
        },2000)
      }
      else{
        //app活动，进入活动页面时一定已经登陆
        if(!this.userInfoRight){
          //没输入过用户信息
          this.tokenGetUsrInfo();
        }
        else{
          //输入过用户信息，开始转盘
          if(!this.isGo && this.goTimes >0){
            //开始抽奖
            this.getNumber()
          }
          else if(!this.isGo && this.goTimes <=0){
            //抽奖次数超出
            this.toastText = "今日抽奖次数已耗尽";
            this.toastShow = true;
            setTimeout(()=>{
              this.toastShow = false;
            },2000)
          }
          else return
        }
      }
    },
    getNumber(){
      this.$http.get('/earthApi/lotterDraw/lotteryDraw?activityId='+this.activityId+'&phone='+this.userTel).then(response => {
        if(response.body.status === 200){
          console.log(response.body.data);
          this.awardInfo = response.body.data;

          // 0：三等奖；2：二等奖；4：一等奖

          switch(this.awardInfo.value){
            case 0:
              this.rotating(0,"遥感壁纸");
              break;
            case 1:
              this.rotating(360/6,"继续加油");
              break;
            case 2:
              this.rotating(360/6*2,"二等奖");
              break;
            case 3:
              this.rotating(360/6*3,"继续加油");
              break;
            case 4:
              this.rotating(360/6*4,"一等奖");
              break;
            case 5:
              this.rotating(360/6*5,"继续加油");
              break;
          }

        }
        else if(response.body.status === 4000){
          //抽奖次数0
          this.toastText = "今日抽奖次数已耗尽";
          this.toastShow = true;
          setTimeout(()=>{
            this.toastShow = false;
          },2000)

        }
        else if(response.body.status === 4001){
          //稍后再试
          this.toastText = "点击太快啦，请稍后再试";
          this.toastShow = true;
          setTimeout(()=>{
            this.toastShow = false;
          },2000)
        }
        else if(response.body.status === 4002){
          //稍后再试
          this.toastText = "您的联系方式有误";
          this.toastShow = true;
          setTimeout(()=>{
            this.toastShow = false;
          },2000)
        }
        else if(response.body.status === 4003){
          //稍后再试
          this.toastText = "活动已结束";
          this.toastShow = true;
          setTimeout(()=>{
            this.toastShow = false;
          },2000)
        }
        else if(response.body.status === 4004){
          //稍后再试
          this.toastText = "活动未开始";
          this.toastShow = true;
          setTimeout(()=>{
            this.toastShow = false;
          },2000)
        }
        else{
          console.log(response.data.status + response.data.message)

          //后台报错
          this.toastText = "参与人数太多，请稍后再试";
          this.toastShow = true;
          setTimeout(()=>{
            this.toastShow = false;
          },2000)


        }

      })
    },
    rotating(deg,text){
      //deg 度数   text 奖品名
      console.log(text)
      this.goTimes --
      this.isGo = true
      let times = this.getRandom(3,6)//圈数(从用户体验角度考虑，设计随机转3-6圈。最少3圈，最多6圈)

      this.randomDeg =  deg + 360 * times      //记录这次要旋转的度数(传来的度数+圈数)
      let realDeg = (360 - this.lastDeg % 360) + this.lastDeg + this.randomDeg
      /*上次指针离初始状态的度数 + 上次的度数 + 这次要旋转的度数
      (这样的目的是为了每次旋转都从原点开始，保证数据准确)*/
      this.oTurntable.style.transform = `rotate(${realDeg}deg)`;
      setTimeout(() => {
        this.isGo = false
        this.lastDeg = realDeg    //把这次度数存储起来，方便下一次获取

        if(text === "继续加油"){
          //未中奖
          this.awardNoShow = true;
        }
        else{
          //中奖
          this.awardYesShow = true;
          if(text === "遥感壁纸"){
            this.awardType = "遥感壁纸"
          }
          else{
            this.awardType = ""
          }
        }

      },4000)                     //4000ms为css里面写的执行动画的时间
    },
    getRandom(n,m){             //该方法能产生[n,m]之间随机数
      return Math.floor(Math.floor(Math.random()*(m-n+1)+n));
    },



    //用户信息

    //通过token判断用户是否有手机号信息
    tokenGetUsrInfo(){

      this.$http.get('/earthApi/lotterDraw/addUserInfoToken?activityId='+this.activityId,this.token === "" ? "" : {headers:{'Authorization': 'Bearer ' + this.token}}).then(response => {

        if(response.body.status === 200){
          //该用户有手机号信息
          this.goTimes = response.body.data.restNumber;
          this.userName = response.body.data.name
          this.userTel = response.body.data.phone
          this.userInfoRight = true;

          //开始转盘
          if(!this.isGo && this.goTimes >0){
            //开始抽奖
            this.getNumber()
          }
          else if(!this.isGo && this.goTimes <=0){
            //抽奖次数超出
            this.toastText = "今日抽奖次数已耗尽";
            this.toastShow = true;
            setTimeout(()=>{
              this.toastShow = false;
            },2000)
          }


          // if(response.body.data.canPrize){
          //   this.goTimes = response.body.data.restNumber;
          //   this.userName = response.body.data.name
          //   this.userTel = response.body.data.phone
          //   this.userInfoRight = true;
          //
          //   //开始转盘
          //   if(!this.isGo && this.goTimes >0){
          //     //开始抽奖
          //     this.getNumber()
          //   }
          //   else if(!this.isGo && this.goTimes <=0){
          //     //抽奖次数超出
          //     this.toastText = "今日抽奖次数已耗尽";
          //     this.toastShow = true;
          //     setTimeout(()=>{
          //       this.toastShow = false;
          //     },2000)
          //   }
          //
          // }
          // else{
          //   //该用户有手机号信息，但是已经不能再抽奖
          //   this.goTimes = 0;
          //   this.userInfoRight = false;
          //
          //   this.toastText = "您已中奖，不可重复参与";
          //   this.toastShow = true;
          //   setTimeout(()=>{
          //     this.toastShow = false;
          //   },2000)
          // }

        }
        else if(response.body.status === 4001){
          //该用户没有手机号信息
          this.inputUserInfoShow = true;
        }
        else{
          console.log(response.data.status + response.data.message)
        }

      })

    },


    //输入用户手机号
    hideInputUserInfo(){
      this.inputUserInfoShow = false
    },
    submitUserInfo(){
      if(this.userName !== "" && this.userTel !== ""){

        this.$http.get('/earthApi/lotterDraw/addUserInfo2?activityId='+this.activityId + '&name=' + this.userName + '&phone=' + this.userTel).then(response => {
          if(response.body.status === 200){
            console.log(response.body.data);
            this.goTimes = response.body.data.restNumber;
            this.userInfoRight = true;
            this.inputUserInfoShow = false;

            this.toastText = "提交成功";
            this.toastShow = true;
            setTimeout(()=>{
              this.toastShow = false;
            },2000)


            // if(response.body.data.canPrize){
            //   this.goTimes = response.body.data.restNumber;
            //   this.userInfoRight = true;
            //   this.inputUserInfoShow = false;
            //
            //   this.toastText = "提交成功";
            //   this.toastShow = true;
            //   setTimeout(()=>{
            //     this.toastShow = false;
            //   },2000)
            // }
            // else{
            //   this.goTimes = 0;
            //   this.userInfoRight = false;
            //
            //   this.toastText = "您已中奖，不可重复参与";
            //   this.toastShow = true;
            //   setTimeout(()=>{
            //     this.toastShow = false;
            //   },2000)
            // }

          }
          else if(response.body.status === 4000){
            //抽奖次数超出
            this.toastText = "请填写APP注册手机号";
            this.toastShow = true;
            setTimeout(()=>{
              this.toastShow = false;
            },2000)
          }
          else{
            console.log(response.data.status + response.data.message)
          }

        })

      }

    },


    //再次抽奖
    beginTurntableNext(){
      if(this.goTimes > 0){
        this.awardNoShow = false;
        this.beginTurntable();
      }
    },


    //收下奖品
    getAwardOk(){
      switch(this.awardType){
        case "遥感壁纸":{
          this.awardYesShow = false;

          if(window["flutter_appActivity_intoFullScreenImage"]){
            window["flutter_appActivity_intoFullScreenImage"].postMessage(this.awardInfo.vPrize);
          }

          break;
        }

        case "":{
          this.awardYesShow = false;
          break;
        }
      }


    }



  },

  mounted(){
    if(this.$route.query.appToken !== undefined){
      this.token = this.$route.query.appToken
    }

    this.activityId = this.$route.query.activityId;
    this.getDetail();

    //转盘对象初始化
    this.oTurntable = this.$refs.turntable

  }


}
</script>

<style scoped>
#turntableGame{
  position:relative;
  padding:0 15px 25px 15px;
  /*padding-bottom:20px;*/
  border-top: 1px solid transparent;
  background-size:cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image:url(../../assets/TurntableGame/background.jpg);
  z-index:0;
}
#turntableGame p{
  margin:0;
}


.turntableGame-title{
  margin:247px auto 0 auto;
  padding:0 15px;
  height:27px;
  background:#FAE160;
  border-radius:26px;
  font-size:16px;
  color:#A05E2A;
  float:left;
  display:flex;
  justify-content:space-between;
}

/*转盘*/
.turntableGame-turntableBorder{
  position: relative;
  width: 100%;
  height: calc(100vw - 30px);
  background-image: url(../../assets/TurntableGame/turntable_border.png);
  background-size:cover;
  background-position: center;
  background-repeat: no-repeat;
}
.turntableGame-pointer{
  position: absolute;
  width:90px;
  height:auto;
  top: 50%;
  left: 50%;
  transform:translate(-50%,-50%);
}
.turntableGame-turntable{
  position: absolute;
  width:calc(100% - 56px);
  height:calc(100% - 56px);
  margin:28px;
  transition: all 4s;         /*动画执行时间为4s */
}


/*说明*/
.turntableGame-introduce{
  width:calc(100% - 20px - 32px);
  margin:24px auto 0 auto;
  padding:14px 16px;
  background:rgba(255, 255, 255, 0.9);
  border-radius:12px;
  font-size:12px;
  color:#383838;
}

.turntableGame-introduce p{
  line-height:21px;
}


/*输入用户信息*/
.inputUserInfo-title{
  line-height:19px;
  margin-top:10px !important;
  color:#000000;
  font-size:14px;
  font-weight:600;
}
.inputUserInfo{
  width:calc(100% - 5px - 18px);
  height:32px;
  margin-top:6px;
  margin-left:5px;
  padding:0 9px;
  border:none;
  background:#E5E5E5;
  border-radius:6px;
  outline:none;
  font-size:14px;
  line-height:32px;
}
</style>