<template>
  <div class="appPageTopBar_mobile">
    <!--顶栏-->
    <div style="position:fixed;top:0;left:0;width:calc(100% - 52px);height:80px;background:rgba(21, 21, 40, 0.45);padding:0 26px;
    font-size:14px;display:flex;align-items:center;z-index:99;">
      <p @click="gotoAppPage" style="font-size:22px;cursor:pointer;" class="iconfontFamily">&#xe78d;</p>
      <div style="flex:1;"></div>
      <div v-if="!productMantleShow && !aboutUsMantleShow">
        <p @click="openProductMantle" style="float:left;cursor:pointer;">产品与服务</p>
        <p @click="openAboutUsMantle" style="margin-left:27px;float:left;cursor:pointer;">关于我们</p>
      </div>

      <!--关闭按钮-->
      <div v-if="productMantleShow || aboutUsMantleShow" @click="productMantleShow = false;aboutUsMantleShow = false;">
        <p style="font-size:15px;" class="iconfontFamily">&#xe72c;</p>
      </div>
    </div>


    <!--产品与服务-->
    <div v-if="productMantleShow" style="position:fixed;top:0;left:0;width:100vw;height:100vh;background:#000000;padding-top:80px;z-index:10;">
      <div style="position:relative;width:calc(100% - 78px);height:100%;background:rgba(9, 9, 18, 0.8);padding:3px 39px;">
        <div @mouseover="gotoProductPage(item.href)" v-for="(item,index) in productMantleProductList" :key="'productMantleItem'+index"
             style="height:23px;margin-top:40px;">
          <p style="font-size:16px;">{{item.title}}</p>
        </div>
      </div>
    </div>


    <!--关于我们-->
    <div v-if="aboutUsMantleShow" style="position:fixed;top:0;left:0;width:100vw;height:100vh;background:#000000;padding-top:80px;z-index:10;">
      <div style="position:relative;width:calc(100% - 78px);height:100%;background:rgba(9, 9, 18, 0.8);padding:3px 39px;">
        <div @mouseover="gotoAboutUsDetailPage(item.title)" v-for="(item,index) in aboutUsMantleList" :key="'aboutUsMantleItem'+index"
             style="height:23px;margin-top:40px;">
          <p style="font-size:16px;">{{item.title}}</p>
        </div>
      </div>
    </div>


  </div>

</template>

<script>


export default {
  name: "AppPageTopBarMobile",
  props:["fromPage"],
  data(){
    return{


      //产品与服务蒙层
      productMantleShow:false,
      //产品服务列表
      productMantleProductList:[
        {
          title:"吉林一号·共生地球APP",
          href:"/ProductPageApp",
        },
        {
          title:"吉林一号·共生地球web端",
          href:"/ProductPageWebMarker",
        },
        {
          title:"吉林一号·遥感影像库",
          href:"/ProductPageImageLib",
        },
        {
          title:"吉林一号·特色服务",
          href:"首页特色服务",
        },
      ],


      //关于我们蒙层
      aboutUsMantleShow:false,
      aboutUsMantleList:[
        {
          title:"项目介绍",
        },
        {
          title:"关于吉林一号",
        },
      ],


    }

  },
  methods:{

    //点击logo返回首页
    gotoAppPage(){
      if(this.$route.path === "/"){
        window.location.reload()
      }
      else{
        this.$router.push(
            {
              path: '/',
            }
        )
      }
    },


    /**产品与服务蒙层*********************************************************/
    //显示产品与服务蒙层
    openProductMantle(){
      this.productMantleShow = true;
    },

    //点击某项产品了解详情
    gotoProductPage(pageUrl){
      if(pageUrl === "首页特色服务"){
        //跳转首页，滚动到特色服务
        window.open("/?target=appScreen5_mobile", '_self');
      }
      else{
        this.$router.push(
            {
              path: pageUrl,
            }
        )
      }
    },


    /**关于我们蒙层*********************************************************/
    openAboutUsMantle(){
      this.aboutUsMantleShow = true;
    },


    //关于我们 进入详情页
    gotoAboutUsDetailPage(title){
      switch(title){
        case '项目介绍':{
          //项目介绍
          if(this.fromPage === "aboutUsPage"){
            //在关于我们页面
            this.aboutUsMantleShow = false;
            document.documentElement.scrollTop = 0;
            document.body.scrollTop = 0;
          }
          else{
            this.$router.push(
                {
                  path: '/AboutUs',
                }
            )
          }

          break;
        }
        case '关于吉林一号':{
          //关于吉林一号
          window.location.href = 'http://www.jl1.cn/index.aspx';
          // window.open("http://www.jl1.cn/product2.aspx?id=22&page=1", '_blank');

          break;
        }


      }


    }


  }
}
</script>

<style scoped>

.appPageTopBar_mobile{
  font-family:HarmonyOSSansSCRegular;
}
.appPageTopBar_mobile p{
  margin:0;
}


</style>