<template>

  <div id="activityPageFour" class="alibabaPuHuiTiFontFamily">
    <!--顶部下载栏-->
    <TopShareBarWithBack v-if="share" :backBtnShow="false"/>
    <!--加载页面-->
    <div v-show="loadingPageShow" :style="{'animation':!loading ? 'loadingHide 2s' : '','animation-fill-mode':!loading ? 'forwards' : ''}"
         class="activityPageFour-loading">
      <div class="activityPageFour-loading-text">
        <p style="font-family:YouSheBiaoTiYuan;color:#ffffff;font-size:36px;">长光卫星</p>
        <p style="margin-top:10px;font-family:YouSheBiaoTiYuan;color:#ffffff;font-size:36px;">星空祈愿</p>
        <div style="position:absolute;top:-8px;left:50%;transform:translate(-77px);">
          <p style="font-size:20px;color:#FED150;" class="iconfontFamily">&#xe782;</p>
        </div>
        <div style="position:absolute;top:49px;left:50%;transform:translate(65px);">
          <p style="font-size:12px;color:#FED150;" class="iconfontFamily">&#xe783;</p>
        </div>
      </div>

<!--      <div style="transform: translate(-50%,-40%);" class="activityPageFour-loading-text">-->
<!--        <p style="font-family:YouSheBiaoTiYuan;color:#ffffff;font-size:36px;text-shadow: 0px 1px 2px black;">-->
<!--          <span style="color:#ffff00;">龙</span>里<span style="color:#ffff00;">龙</span>气迎新年-->
<!--        </p>-->
<!--        <p style="margin-top:10px;font-family:YouSheBiaoTiYuan;color:#ffffff;font-size:36px;text-shadow: 0px 1px 2px black;">星空祈愿</p>-->
<!--        <div style="position:absolute;top:-8px;left:50%;transform:translate(-130px);">-->
<!--          <p style="font-size:20px;color:#FED150;" class="iconfontFamily">&#xe782;</p>-->
<!--        </div>-->
<!--        <div style="position:absolute;top:49px;left:50%;transform:translate(65px);">-->
<!--          <p style="font-size:12px;color:#FED150;" class="iconfontFamily">&#xe783;</p>-->
<!--        </div>-->


<!--        <p style="margin-top:150px;font-family:YouSheBiaoTiYuan;color:#ffffff;font-size:20px;">长白山华美胜地 x 长光卫星</p>-->


<!--      </div>-->

    </div>

    <!--首页-->
    <div class="activityPageFour-home">
      <p style="margin-top:100px;font-size:18px;color:#ffffff;">当前已发出<span style="color:#FED150;">{{wishNum}}</span>个祈愿</p>
      <div style="position:absolute;top:140px;width:100%;font-size:14px;color:#ffffff;text-align:center;">
        <p>点击头像查看愿望</p>
      </div>

      <div style="position:relative;width:100%;flex:1;max-height:460px;margin-top:20px;">
        <!--背景圆环-->
        <div class="activityPageFour-home-circleBackground"></div>

        <!--愿望头像列表-->
        <div @click="checkOtherWish(item)" v-for="(item,index) in wishList" :key="'wish'+index" class="wishAvatar"
             :style="{'top':getWishPositionTop(index),'left':getWishPositionLeft(index),
             'background-image':(item.wishStatus === 2 || item.wishStatus === 3 || item.wishStatus === 4) ? 'url(' + wishBorder + ')' : '',
             'animation':wishAvatarShow ? 'wishAvatarShow 1s' : '','animation-fill-mode':wishAvatarShow ? 'forwards' : ''}">
          <div :style="{'border':(item.wishStatus === 2 || item.wishStatus === 3 || item.wishStatus === 4) ? '2px solid #ffffff' : '','margin':(item.wishStatus === 2 || item.wishStatus === 3 || item.wishStatus === 4) ? '9px' : '10px'}"
               style="width:calc(100% - 20px);height:calc(100% - 20px);border-radius:50%;box-shadow: 0px 0px 4px 2px rgba(194, 233, 251, 0.25);">
            <img :src="item.avatar" style="width:100%;height:100%;border-radius:50%;object-fit: cover;">
          </div>
        </div>

        <!--我的愿望 'border':$store.state.member == 1 ? '3px solid #FFD752' : ''-->
        <div @click="gotoMyWish" :style="{'animation':wishAvatarShow ? 'myWishAvatarShow 1s' : '','animation-fill-mode':wishAvatarShow ? 'forwards' : '',
        'box-shadow': $store.state.member == 1 ? '0 0 0 3px #FFD752' : ''}"
             style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);height:21%;aspect-ratio:1/1;border-radius:50%;">

          <img :src="myWishAvatar" style="position:absolute;top:0;left:0;width:100%;height:100%;border-radius:50%;object-fit: cover;">
          <div v-show="$store.state.member == 1" style="position:absolute;top:-18px;left:50%;transform:translate(-50%);height:19px;text-align:center;">
            <p style="font-size:20px;color:transparent;background: linear-gradient(90deg, #F6D365 0%, #FFFFFF 100%);-webkit-background-clip:text;" class="iconfontFamily">&#xe784;</p>
          </div>

          <div v-show="wishAvatarShowFinish" style="position:absolute;top:0;left:0;width:100%;height:100%;
          display:flex;flex-direction:column;align-items:center;justify-content: center;">
            <p :style="{'font-size':screenSmallSize ? '14px' : '20px'}" style="margin-top:10px;font-family:YouSheBiaoTiYuan;color:#ffffff;">我的愿望</p>
            <p :style="{'font-size':screenSmallSize ? '16px' : '24px'}" style="margin-top:5px;font-family:YouSheBiaoTiYuan;font-size:24px;color:#ffffff;">{{myWishNum}}</p>
          </div>
        </div>

      </div>

      <div @click="gotoCreateWish" style="position:relative;width:140px;height:52px;margin-top:30px;background:linear-gradient(180deg, #FFE499 0%, #FFC16B 100%), linear-gradient(180deg, #A1C4FD 0%, #C2E9FB 100%);border-radius:28px;text-align:center;">
        <p style="line-height:52px;font-size:18px;font-weight:bold;color:#DB6E00;">开始祈愿</p>
        <div style="position:absolute;top:-12px;left:-6px;">
          <p style="font-size:20px;color:#FED150;" class="iconfontFamily">&#xe782;</p>
        </div>
      </div>
      <p @click="gotoCGWX" style="margin-top:33px;margin-bottom:15px;font-size:14px;color:#ffffff;">当前活动由长光卫星技术股份有限公司支持
        <span style="margin-left:7px;" class="iconfontFamily">&#xe74b;</span>
      </p>


    </div>

    <!--他人愿望 蒙层-->
    <div v-if="checkOtherWishIng" style="position:absolute;top:0;left:0;width:100%;height:100%;background:rgba(0, 0, 0, 0.8);">

      <!--未拆封-->
      <div v-show="!otherWishOpen" style="width:100%;height:100%;display:flex;flex-direction:column;align-items:center;justify-content:center;">
        <p style="font-size:20px;color:#ffffff;">点击卫星拆封心愿</p>
        <div @click="openOtherWish" style="position:relative;height:147px;margin-top:88px;margin-bottom:100px;">
          <div style="position:absolute;top: 50%;left: 50%;transform:translate(-50%,-50%);
          height:calc(100% + 60px);aspect-ratio:1/1;background:rgba(255, 255, 255, 0.6);border-radius:50%;filter: blur(100px);"></div>
          <img src="../../../../assets/Satellite/ActivityFour/wishClose.png" class="checkOtherWish-wishClose"
               :style="{'animation':checkOtherWishWaitIng ? 'wishCloseShake 2.3s infinite' : otherWishOpenIng ? 'wishCloseHide 0.6s' : 'wishCloseShow 0.6s','animation-fill-mode':'forwards'}">
        </div>
      </div>

      <!--拆封 不能使用v-show="otherWishOpen"，字体无法加载width:100%;height:calc(100% - 130px);-->
      <div @click="hideOtherWish" :style="{'animation':!otherWishOpenIng ? 'wishOpenShow 0.4s' : '','animation-fill-mode':!otherWishOpenIng ? 'forwards' : ''}"
           style="width:100%;height:calc(100% - 130px);padding-top:97px;padding-bottom:33px;display:flex;flex-direction:column;align-items:center;justify-content: space-between;overflow:auto;">
        <p :style="{'color':otherWishStatus === 1 ? '#ffffff' : '#FF8D1A'}" style="font-size:20px;">{{otherWishStatus === 1 ? "愿望等待发射中" : "愿望已抵达太空"}}</p>

        <!--愿望-->
        <div style="position:relative;aspect-ratio:280/563;flex:1;max-height:559px;max-width: calc(100% - 110px);
        margin-top:20px;display: flex;flex-direction: column;">
          <!--背景图aspect-ratio:14/25;-->
          <img src="../../../../assets/Satellite/ActivityFour/wishOpen.png" style="position:absolute;top:0;left:0;width:100%;height: calc(100% - 38px - 25px);">
          <img v-show="otherWishStatus === 2 || otherWishStatus === 3 || otherWishStatus === 4" src="../../../../assets/Satellite/ActivityFour/wishOpenSatellite.png" style="position:absolute;top:-25px;left:-13px;width:70px;height:70px;">

          <!--愿望卡片-->
          <div @click.stop="" style="position:relative;z-index:1;width:calc(100% - 50px);flex:1;
          padding:35px 25px 43px 25px;display: flex;flex-direction: column;overflow: auto;">
            <p :style="{'font-size':screenSmallSize ? '16px' : '18px'}" style="max-height: calc(100% - 30px);overflow: auto;margin-bottom:auto;text-align:justify;font-family:HongLeiBanShuJianTi;color:#ffffff;" v-html="otherWishContent"></p>
            <div style="flex:1;"></div>
            <p v-if="otherWishStatus === 1" :style="{'font-size':screenSmallSize ? '14px' : '16px'}" style="color:rgba(255, 143, 31, 0.5);">心愿池</p>
            <p v-else :style="{'font-size':screenSmallSize ? '14px' : '16px'}" style="color:#ffffff;">环绕地球<span style="color:#FF8D1A;"> {{otherWishOrbitNumber}} </span>圈</p>
          </div>

          <!--用户信息margin-top:25px;-->
          <div style="width:100%;height:38px;display:flex;align-items:center;">
            <img :src="otherWishAvatar" style="width:38px;height:38px;border-radius:50%;object-fit: cover;">
            <p :style="{'max-width':otherWishMemberStatus ? 'calc(100% - 38px - 12px - 4px - 100px)' : 'calc(100% - 38px - 12px)'}" style="margin-left:12px;font-size:14px;color:#ffffff;
            white-space: nowrap;overflow: hidden; text-overflow: ellipsis;">{{otherWishNickName}}</p>
            <img v-if="otherWishMemberStatus" src="../../../../assets/Satellite/ActivityFour/memberIcon.png" style="width:100px;height:28px;margin-left:4px;">
          </div>
        </div>



        <p style="margin-top:43px;font-size:14px;color:#ffffff;">点击空白区关闭</p>



      </div>



    </div>





  </div>





</template>

<script>
import TopShareBarWithBack from '@/components/TopShareBarWithBack';
import {getWxSignature} from "@/utils/shareConfig";
export default {
  name: "ActicityPageFour",
  components: {TopShareBarWithBack},
  data(){

    return{

      cardActivityId:"",
      activityInfo:"",


      //初始加载页
      loading:true,
      loadingPageShow:true,

      //首页愿望
      wishAvatarShow:false,
      wishAvatarShowFinish:false,
      wishNum:0,
      wishList:[],
      wishBorder:require('../../../../assets/Satellite/ActivityFour/wishBorder.png'),
      myWishAvatar:"",
      myWishNum:0,


      //他人愿望查看状态
      checkOtherWishIng:false,   //在查看他人愿望（信封页+愿望卡片）
      checkOtherWishWaitIng:false,   //信封页，没有点开信封
      otherWishOpen:false,   //已拆信封，进入愿望卡片
      otherWishOpenIng:false,   //开信封过程中
      currOtherWish:"",

      //他人愿望信息
      otherWishStatus:"",
      otherWishContent:"",
      otherWishOrbitNumber:"",
      otherWishAvatar:"",
      otherWishNickName:"",
      otherWishMemberStatus:"",

      //屏幕是否为小尺寸，调整愿望文字像素
      screenSmallSize:false,



      share:false,
      token:"",


    }


  },
  computed:{
    //祝愿列表的位置
    getWishPositionTop(){
      return function(index){
        switch(index) {
          case 0:{
            return '7%'
          }
          case 1:{
            return '15%'
          }
          case 2:{
            return '7%'
          }
          case 3:{
            return '28%'
          }
          case 4:{
            return '32%'
          }
          case 5:{
            return '52%'
          }
          case 6:{
            return '54%'
          }
          case 7:{
            return '80%'
          }
          case 8:{
            return '70%'
          }
          case 9:{
            return '76%'
          }


        }

      }
    },
    getWishPositionLeft(){
      return function(index){
        switch(index) {
          case 0:{
            return '18%'
          }
          case 1:{
            return '43%'
          }
          case 2:{
            return '75%'
          }
          case 3:{
            return '5%'
          }
          case 4:{
            return '82%'
          }
          case 5:{
            return '5%'
          }
          case 6:{
            return '70%'
          }
          case 7:{
            return '15%'
          }
          case 8:{
            return '50%'
          }
          case 9:{
            return '75%'
          }

        }

      }
    },
  },
  methods:{
    //获取活动详情
    getDetail(){
      let myToken = "";
      if(!this.share){
        myToken = this.token;
      }
      else{
        myToken = this.$store.state.token;
      }

      this.$http.get('/earthApi/satellite-activity-info/getById?activityId='+this.cardActivityId, myToken === "" ? "" : {headers:{'Authorization': 'Bearer ' + myToken}}).then(response => {
        if(response.body.status === 200){
          console.log(response.body.data)
          if(response.body.data === null){
            //活动已结束
            this.$router.push(
                {
                  path: '/NotExist',
                }
            )
          }
          else{
            //活动正常
            this.activityInfo = response.body.data;

            document.title = this.activityInfo.name;

            if(window["flutter_getActivityDetail"]){
              window["flutter_getActivityDetail"].postMessage(JSON.stringify(this.activityInfo));
            }
          }
        }
        else if(response.body.status === 404){
          this.$router.push(
              {
                path: '/NotExist',
              }
          )
        }
        else{
          console.log(response.data.status + response.data.message)
        }



      })
    },

    //获取首页愿望信息
    getWishList(onlyUpdateWishNum){
      let myToken = "";
      if(!this.share){
        myToken = this.token;
      }
      else{
        myToken = this.$store.state.token;
      }

      this.$http.get('/earthApi/sa-user-wish/getWishHomePage', myToken === "" ? "" : {headers:{'Authorization': 'Bearer ' + myToken}}).then(response => {
        if(response.body.status === 200){
          console.log(response.body.data)
          if(onlyUpdateWishNum){
            this.wishNum = response.body.data.wishTotalNumber
            this.myWishNum = response.body.data.myWishNumber
          }
          else{
            this.wishNum = response.body.data.wishTotalNumber
            this.wishList = response.body.data.tenOtherWishes
            this.myWishAvatar = response.body.data.avatar
            this.myWishNum = response.body.data.myWishNumber
          }
        }
        else{
          console.log(response.data.status + response.data.message)
        }
      })
    },

    //跳转公司官网
    gotoCGWX(){
      if(this.share){
        window.location.href="http://www.jl1.cn/";
      }
      else{
        if(window["flutter_gotoCGWX"]){
          window["flutter_gotoCGWX"].postMessage("");
        }
      }
    },


    //查看他人愿望
    checkOtherWish(item){
      this.otherWishOpen = false;
      this.checkOtherWishIng = true;
      this.currOtherWish = item;

      setTimeout(()=>{
        this.checkOtherWishWaitIng = true;
      },600)

      //隐藏顶bar
      if(window["flutter_showOrHideTopBar"]){
        window["flutter_showOrHideTopBar"].postMessage("false");
      }
    },
    //打开他人愿望
    openOtherWish() {
      this.$http.get('/earthApi/sa-user-wish/getWishById?id='+this.currOtherWish.id).then(response => {
        if(response.body.status === 200){
          this.otherWishStatus = response.body.data.wishStatus;
          this.otherWishContent = response.body.data.wishContent;
          this.otherWishOrbitNumber = response.body.data.orbitNumber;
          this.otherWishAvatar = response.body.data.avatar;
          this.otherWishNickName = response.body.data.nickName;
          this.otherWishMemberStatus = response.body.data.memberStatus === 1;

          this.checkOtherWishWaitIng = false;
          this.otherWishOpenIng = true;
          setTimeout(()=>{
            this.otherWishOpenIng = false;
            this.otherWishOpen = true;
          },300)

        }
        else{
          console.log(response.data.status + response.data.message)
        }
      })

    },

    //关闭他人愿望
    hideOtherWish(){
      this.checkOtherWishIng = false;
      this.otherWishOpen = false;

      //显示顶bar
      if(window["flutter_showOrHideTopBar"]){
        window["flutter_showOrHideTopBar"].postMessage("true");
      }
    },


    //进入我的愿望
    gotoMyWish(){
      if(this.share){
        if(this.$store.state.token === ""){
          this.$router.push(
              {
                path: '/Login',
              }
          )
        }
        else{
          this.$router.push(
              {
                path: '/MyWish',
                query: {
                  infoId: this.cardActivityId,
                  share: 1
                }
              }
          )
        }

      }
      else{
        if(window["flutter_gotoMyWish"]){
          window["flutter_gotoMyWish"].postMessage("");
        }
      }
    },


    //进入发愿望
    gotoCreateWish(){
      if(this.share){
        if(this.$store.state.token === ""){
          this.$router.push(
              {
                path: '/Login',
              }
          )
        }
        else{
          this.$router.push(
              {
                path: '/EditWishContent',
                query: {
                  infoId: this.cardActivityId,
                  share: 1
                }
              }
          )
        }
      }
      else{
        if(window["flutter_gotoCreateWish"]){
          window["flutter_gotoCreateWish"].postMessage("");
        }
      }
    },



    //用户登录成功，被flutter调用
    loginSuccess(token){
      this.token = token;
      this.getDetail();
      this.getWishList(false);
    },

    //我的愿望数更新，被flutter调用
    myWishNumUpdate(){
      this.getWishList(true);
    },

    //激活成为会员，被flutter调用
    memberActivationSuccess(){
      this.$store.state.member = 1
    }








  },

  mounted(){
    window.satelliteActivityH5Four = this;

    this.cardActivityId = this.$route.query.infoId;
    if(this.$route.query.share == 1){
      this.share = true;
    }
    if(this.$route.query.token !== undefined){
      this.token = this.$route.query.token
      window.history.replaceState('', '', this.$route.fullPath.substring(0,this.$route.fullPath.indexOf("token") - 1))
    }
    if(this.$route.query.memberState !== undefined){
      if(this.$route.query.memberState === "" || this.$route.query.memberState == "0"){
        this.$store.state.member = 0
      }
      else{
        this.$store.state.member = 1
      }
    }

    //获取屏幕高度，确定他的愿望内容的字号
    if(document.getElementsByClassName("activityPageFour-home")[0].clientHeight < 720){
      this.screenSmallSize = true;
    }
    else{
      this.screenSmallSize = false;
    }

    //loading页效果
    setTimeout(()=>{
      this.loading = false;
      setTimeout(()=>{
        this.loadingPageShow = false;
        this.wishAvatarShow = true;
        setTimeout(()=>{
          this.wishAvatarShowFinish = true;

          //有默认打开的愿望
          if(this.$route.query.openWishId !== undefined){
            this.checkOtherWish({id:this.$route.query.openWishId});
            window.history.replaceState('', '', this.$route.fullPath.substring(0,this.$route.fullPath.indexOf("openWishId") - 1))
          }

        },1000)
      },1000)
    },2000)
    getWxSignature(this.$route.query.infoId,"卫星活动")
    this.getDetail();
    this.getWishList(false);
  }


}
</script>

<style scoped>

#activityPageFour{
  position:absolute;
  width:100%;
  height:100%;
  overflow:hidden;
}

#activityPageFour p{
  margin:0;
}


.activityPageFour-loading{
  width:100%;
  height:100%;
  background-size:cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image:url(../../../../assets/Satellite/ActivityFour/background.jpg);
}
.activityPageFour-loading-text{
  position:relative;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  width:100%;
  text-align:center;
  animation:loadingTextShow 2s;
  animation-fill-mode:forwards;
}
@keyframes loadingTextShow {
  from {opacity:0;}
  to {opacity:1;}
}


.activityPageFour-home{
  position:relative;
  width:100%;
  height:100%;
  background-size:cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image:url(../../../../assets/Satellite/ActivityFour/background_home.jpg);
  text-align:center;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: space-between;
  overflow:hidden;
}

.activityPageFour-home-circleBackground{
  width:calc(100% + 60px);
  height:100%;
  margin-left:-30px;
  background-size:contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image:url(../../../../assets/Satellite/ActivityFour/circleBackground.png);
}



.wishAvatar{
  position:absolute;
  width:60px;
  height:60px;
  /*border-radius:50%;*/
  background-size:cover;
  background-position: center;
  background-repeat: no-repeat;
}


.checkOtherWish-wishClose{
  height:147px;
}




</style>

<style>

/*动态绑定动画*/
@keyframes loadingHide {
  from {opacity:1;}
  to {opacity:0;}
}
@keyframes wishAvatarShow {
  from {
    transform: scale(0.3);
  }
  to {
    transform: scale(1);
  }
}
@keyframes myWishAvatarShow {
  from {
    /*transform: scale(0);*/
    height:0;
  }
  to {
    /*transform: scale(1) translate(-50%,-50%);*/
    height:21%;

  }
}

@keyframes wishCloseShow {
  from {height:0;}
  to {height:147px;}
}
@keyframes wishCloseHide {
  from {height:147px;opacity:1;}
  to {height:0;opacity:0;}
}


@keyframes wishOpenShow {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}


@keyframes wishCloseShake {
  86% {
    transform: rotate(0deg);
  }
  89.5% {
    transform: rotate(-15deg);
  }
  93% {
    transform: rotate(0deg);
  }
  96.5% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

</style>
