<template>
    <div id="main" :style="{backgroundImage:'url('+ require('@/assets/openApp.jpg') +')'}" >
<!--        <div class="button"><a id="gotoSurvey" href="https://www.wjx.cn/vj/QROpS5t.aspx">立即预约内测名额</a></div>-->
        <ShareButton :source="source"/>
    </div>
</template>

<script>

import ShareButton from "@/components/ShareButton";
export default {
    name: "OpenApp",
    components: {ShareButton},
  data(){
    return{
      source:"",
    }
  },
    mounted() {
      if(this.$route.query.source != undefined){
        this.source = this.$route.query.source;
      }


      window.location.href="charmingearth://earth.jl1.cn?infoId=" + this.$route.query.infoId + '&type=' + this.$route.query.type

      // var u = navigator.userAgent;
      // var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android终端
      // var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      //
      // if (isAndroid) {
      //   //安卓app的scheme协议
      //   window.location.href="charmingearth://earth.jl1.cn?infoId=" + this.$route.query.infoId + '&type=' + this.$route.query.type;
      //   setTimeout(function(){
      //     let hidden = window.document.hidden || window.document.mozHidden || window.document.msHidden ||window.document.webkitHidden
      //     if(typeof hidden =="undefined" || hidden ==false){
      //       //应用宝下载地址 (emmm 找不到淘宝应用宝的地址，这里放的是 lucky coffee 地址)
      //       window.location.href ="https://a.app.qq.com/o/simple.jsp?pkgname=com.lucky.luckyclient";
      //     }
      //   }, 3000);
      // }
      // //ios端
      // if (isIOS) {
      //   //ios的scheme协议
      //   window.location.href="charmingearth://earth.jl1.cn?infoId=" + this.$route.query.infoId + '&type=' + this.$route.query.type
      //   setTimeout(function(){
      //     let hidden = window.document.hidden || window.document.mozHidden || window.document.msHidden ||window.document.webkitHidden
      //     if(typeof hidden =="undefined" || hidden ==false){
      //       //App store下载地址
      //       window.location.href = "http://itunes.apple.com/app/id387682726";
      //     }
      //   }, 3000);
      // }


    }
}
</script>

<style scoped lang="scss">

#main{
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size:cover;
    width: 100%;
    height: 100%
}


.button{
    background: rgba(0,0,0,0);
    position: absolute;
    left: 0;
    right: 0;
    margin:0 34px;
    bottom: 100px;
    z-index: 9999;
    #gotoSurvey{
        text-decoration: none;
        display: block;
        color: white;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        line-height: 40px;
        height: 40px;
        background: linear-gradient(230deg,#6d61d1 14%, #61589d 83%);
        border-radius: 6px;
        box-shadow: 0px 2px 10px 0px rgba(95,95,95,0.50);
    }
}
</style>
