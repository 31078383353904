import { render, staticRenderFns } from "./ShareReason.vue?vue&type=template&id=428f664f&scoped=true&"
import script from "./ShareReason.vue?vue&type=script&lang=js&"
export * from "./ShareReason.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "428f664f",
  null
  
)

export default component.exports