<template>

  <div>
    <div v-if="device==='web'" class="aboutUsPage">

      <img style="position:absolute;top:0;left:0;width:100%;" src="../../assets/App/aboutUsPage/background.jpg" />

      <app-page-top-bar fromPage="aboutUsPage"></app-page-top-bar>

      <!--标题 绝对定位，使下一屏视频页面的开始位置在页面顶部-->
      <div style="position:absolute;width:100%;margin-top:27vh;display:flex;flex-direction:column;align-items:center;">
        <p style="line-height:48px;font-size:40px;font-weight:bold;letter-spacing:7px;">吉林一号·共生地球</p>
        <p style="width:799px;margin-top:30px;line-height:26px;font-size:18px;text-align:center;">
          吉林一号·共生地球项目是长光卫星技术股份有限公司下，面向大众用户开展的遥感大众化服务系列产品。产品与服务形式多样，包含APP、网页版、个性化服务等，项目自2021年至今以服务超过200万用户，未来将致力于让遥感走进千家万户，让遥感服务生活。
        </p>
      </div>

      <!--第一屏 视频max-width:176vh;-->
      <div id="appScreen1" class="screen">
        <div id="screen1Video" style="position:relative;top:80%;left:50%;transform:translate(-50%,-50%);width:810px;">
          <video style="width:100%;" controls="controls" autoplay loop muted>
            <source src="https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221431000597203.mp4" type="video/mp4" />
          </video>

        </div>
      </div>

      <!--第二屏 尾页-->
      <div style="height:1000px;display:flex;flex-direction:column;" class="screen">

        <div id="appScreen2Top" style="flex:1;">
          <div style="position:relative;top:58%;left:0;transform:translate(0,-50%);width:100%;display:flex;flex-direction:column;align-items:center;">
            <p id="screen2Title" :style="{'font-size':webScreenSize === 'big' ? '60px' : '38px'}" style="line-height:80px;font-weight:bold;letter-spacing:7px;color:#ffffff;
          -webkit-text-fill-color: transparent;background:linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(159, 160, 167, 1) 100%);">以遥感服务全球70亿用户</p>

            <p style="margin-top:24px;font-size:14px;letter-spacing:5px;">SERVING THE WORLD WITH REMOTE SENSING</p>

          </div>
        </div>

        <!--底栏-->
        <div style="width:100%;height:364px;">
          <app-page-foot-bar :produceList="footBarProductList"></app-page-foot-bar>

        </div>

      </div>
  </div>


    <div v-if="device==='mobile'" style="padding-top:80px;" class="aboutUsPage">

      <!--顶栏-->
      <app-page-top-bar-mobile fromPage="aboutUsPage"></app-page-top-bar-mobile>


      <!--第一屏-->
      <div id="appScreen1_mobile">
        <div style="width:calc(100% - 44px);margin:0 auto;padding-top:67px;text-align:center;">
          <p :style="{'font-size':mobileScreenSize === 'small' ? '24px' : '36px'}" style="font-weight:bold;">吉林一号·共生地球</p>
          <p style="margin-top:60px;line-height:24px;text-align:justify;font-size:16px;">吉林一号·共生地球项目是长光卫星技术股份有限公司下，面向大众用户开展的遥感大众化服务系列产品。产品与服务形式多样，包含APP、网页版、个性化服务等，项目自2021年至今以服务超过200万用户，未来将致力于让遥感走进千家万户，让遥感服务生活。</p>
        </div>

        <div style="width:100%;margin-top:90px;">
          <video controls="controls" poster="../../assets/App/aboutUsPage/videoPoster.jpg" style="width:100%;" autoplay loop muted>
            <source src="https://cgwxforum.obs.cn-north-4.myhuaweicloud.com/202311221431000597203.mp4" type="video/mp4" />
          </video>
        </div>
      </div>

      <!--第二屏-->
      <div id="appScreen2_mobile">
        <div style="width:100%;margin:0 auto;padding-top:132px;text-align:center;">
          <p id="screen2Title_mobile" :style="{'font-size':mobileScreenSize === 'small' ? '20px' : '36px'}" style="font-weight:bold;letter-spacing:5px;color:#ffffff;
          -webkit-text-fill-color: transparent;background:linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(159, 160, 167, 1) 100%);">
            以遥感服务全球70亿用户</p>
          <p :style="{'font-size':mobileScreenSize === 'small' ? '8px' : '12px'}" style="margin-top:8px;letter-spacing:3px;">SERVING THE WORLD WITH REMOTE SENSING</p>
        </div>

        <!--顶部渐变-->
        <div style="position:absolute;top:0;left:0;width:100%;height:63px;background:linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);"></div>

      </div>

      <!--底栏-->
      <div style="position:relative;width:100%;margin-top:-30px;">
        <app-page-foot-bar-mobile :produceList="footBarProductList"></app-page-foot-bar-mobile>
      </div>

    </div>



  </div>

</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import AppPageTopBar from "@/views/AppPage/AppPageTopBar"
import AppPageFootBar from "@/views/AppPage/AppPageFootBar"

import AppPageTopBarMobile from "@/views/AppPage/AppPageTopBar_mobile"
import AppPageFootBarMobile from "@/views/AppPage/AppPageFootBar_mobile"

export default {
  name: "AboutUsPage",
  components:{
    AppPageTopBar,AppPageFootBar,AppPageTopBarMobile,AppPageFootBarMobile
  },
  data(){
    return{
      device:"web",
      webScreenSize:"big",
      mobileScreenSize:"small",

      //底栏项目列表
      footBarProductList:[
        {
          title:"吉林一号·共生地球APP",
          href:"/ProductPageApp"
        },
        {
          title:"吉林一号·共生地球web端",
          href:"/ProductPageWebMarker"
        },
        {
          title:"吉林一号·遥感影像库",
          href:"/ProductPageImageLib"
        },


      ],

    }


  },
  methods:{

    //随鼠标滚动进行的动效
    animation(){

      ScrollTrigger.create({
        trigger:'#appScreen1',
        start:'top top',
        end:'+=1000',
        markers:false,//不显示标注
        pin:true,//定位元素
        scrub:true,//擦除
        animation:
            gsap.timeline()
                .fromTo('#screen1Video',{width:'810px',top:'80%'},{width:'100%',top:'50%'},"<")
      });

    },

    //第2屏 标题增加渐变颜色效果(直接写在css里不生效)
    screen2TitleColor(){
      document.getElementById("screen2Title").style["-webkit-background-clip"] = 'text';
    },




    /**移动端********************************************************************************************/
    //第2屏 标题增加渐变颜色效果(直接写在css里不生效)
    screen2TitleColor_mobile(){
      document.getElementById("screen2Title_mobile").style["-webkit-background-clip"] = 'text';
    },



  },
  mounted(){

    setTimeout(()=>{
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    },100)

    if(window.innerWidth >= 1000){
      //web端
      this.device = "web";
      //适配web尺寸
      if(window.innerWidth < 1330){
        this.webScreenSize = "small";
      }

      this.animation();
      this.screen2TitleColor();
    }
    else{
      //手机
      this.device = "mobile";
      //适配手机尺寸
      if(window.innerWidth > 650){
        this.mobileScreenSize = "big";
      }

      //device默认是web，手机端需要延时
      setTimeout(()=>{
        this.screen2TitleColor_mobile();
      },0)




    }

  }


}
</script>

<style scoped>

.aboutUsPage{
  background:#000000;
  color:#ffffff;
  overflow:hidden;
  font-family:HarmonyOSSansSCRegular;
}
.aboutUsPage p{
  margin:0;
}


.screen{
  width: 100%;
  height: 100vh;
}

#appScreen2Top{
  background-image:url('../../assets/App/aboutUsPage/screen2/background.jpg');
  background-size:100% ;
  background-repeat:no-repeat;
}




/**移动端***********************************************************************************************/
#appScreen1_mobile{
  width:100%;
  background-image:url('../../assets/App/aboutUsPage/background.jpg');
  background-size:100% ;
  background-repeat:no-repeat;
  /*background-position:center top;*/
}
#appScreen2_mobile{
  width:100%;
  background-image:url('../../assets/App/aboutUsPage/screen2/background.jpg');
  background-size:360% 220%;
  background-position:center top;
  position:relative;
  height:359px;
  margin-top:62px;
}


</style>