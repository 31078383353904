<template>
  <div class="articleSharePage">
    <!--顶部下载栏-->
    <TopShareBar />
    <!--内容-->
    <div style="padding:65px 20px 20px 20px;">

      <div class="articleSharePage_title">{{articleTitle}}</div>

      <div v-html="articleHtmlData"></div>

    </div>
  </div>

</template>

<script>
import TopShareBar from '@/components/TopShareBar';
import {getWxSignature} from "@/utils/shareConfig";
export default {
  name: "ArticleSharePage",
  components: {TopShareBar},
  data(){
    return{

      mainInfoId:"",

      articleTitle:"",
      articleHtmlData:"",
      preview:0

    }


  },
  methods:{

    //获取详情
    getDetail(){
      this.$http.get('/earthApi/main-info/getMainInfoDetail?shareType=1&infoId='+this.mainInfoId+'&preview='+this.preview).then(response => {
        if(response.body.status === 200){
          console.log(response.body.data)
          this.articleTitle = response.body.data.mainTitle;
          this.articleHtmlData = response.body.data.context;

          //如果存在图片，处理图片宽度适配
          if(this.articleHtmlData.indexOf('<img') !== -1){
            //拆分图片
            let contextList = this.articleHtmlData.split(/(<img.*\/>)/g);
            let contextTemp = ""
            for(let i=0;i<contextList.length;i++){
              if(contextList[i].indexOf("<img") === 0){
                //是图片的html
                let imageWidth = contextList[i].slice(contextList[i].indexOf('width="') + 7,contextList[i].length)
                imageWidth = imageWidth.slice(0,imageWidth.indexOf('"'))
                if(parseInt(imageWidth) > document.body.clientWidth){
                  //超出屏幕宽度
                  let beforeWidth = contextList[i].slice(0,contextList[i].indexOf('width="'));
                  let afterHeight = contextList[i].slice(contextList[i].indexOf('height="') + 8,contextList[i].length);
                  afterHeight = afterHeight.slice(afterHeight.indexOf('"') + 1,afterHeight.length)
                  let currContext = beforeWidth + 'width="100%" height="auto"' + afterHeight;
                  contextTemp = contextTemp + currContext;
                }
                else{
                  //小图
                  contextTemp = contextTemp + contextList[i];
                }
              }
              else{
                //不是图片的html
                contextTemp = contextTemp + contextList[i];
              }
            }
            this.articleHtmlData = contextTemp;
          }



          document.title = this.articleTitle;
        }
        else if(response.body.status === 404){
          this.$router.push(
              {
                path: '/NotExist',
              }
          )
        }
        else{
          console.log(response.data.status + response.data.message)
        }
      })
    },

  },
  mounted(){
    if(this.$route.query.infoId){
      this.mainInfoId = this.$route.query.infoId;
      if(this.$route.query.preview){
        this.preview = this.$route.query.preview
      }
      this.getDetail();
      getWxSignature(this.$route.query.infoId)
    }
    else{
      window.location.href = this.$route.fullPath.replace("mainInfoId","infoId")
    }
  }


}
</script>

<style scoped>
.articleSharePage {
  font-family: 微软雅黑;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: auto;
}
.articleSharePage_title{
  margin-bottom:20px;
  font-size:18px;
  color:#4a4a4a;
  font-weight:bold;
}

</style>

<style>
.articleSharePage p{
  margin:0;
}
</style>
