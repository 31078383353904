<template>
    <div id="compare" :style="style">
        <l-map ref="map" style="z-index: 0" :options="option" :maxBounds="maxBounds">

          <!--分享说明-->
          <share-reason v-if="share ==1" :shareReason="detail.shareReason" style="z-index:500"></share-reason>

        </l-map>
        <div v-if="infoShow && share == 0">
            <img class="infoImage" style="transform: translateY(calc(-100% - 4px))" :style="'clip: rect(0px,100vw,'+ topLayerInfoHeight + 'px,0);'" :src="topLayer.infoUrl" @load="getTopLayerInfoImageHeight"/>
            <img class="infoImage" :style="'clip: rect('+ bottomLayerInfoHeight + 'px,100vw,100vh ,0);'" :src="bottomLayer.infoUrl"/>
        </div>

        <TopShareBar v-if="share ==1"  style="width: 100vw; position: fixed;top: 0;left: 0"/>

      <!--提示-->
      <div v-if="toastShow" class="toast">
        <p style="line-height:32px;margin:0;">滑动中央卷轴 查看卫星对比影像</p>
      </div>


    </div>
</template>

<script>

import L from "leaflet";
import {LMap} from 'vue2-leaflet';
import '@/lib/side-by-side/leaflet-side-by-side'
import TopShareBar from '@/components/TopShareBar';
import ShareReason from '@/components/ShareReason';
import {getWxSignature} from "@/utils/shareConfig";

export default {
    name: "LayerCompare",
    components: {
        TopShareBar,ShareReason,
        LMap,
    },
    data() {
        return {
            zoom: 14,
            center: L.latLng(44.0, 125.4),
            maxBounds: L.latLngBounds([[85,-180], [-85,180]]),
            option: {zoomControl: false, attributionControl: false, renderer: L.canvas(), maxZoom: 18, minZoom: 5,zoomSnap:0.0},
            areaGeo:'{"coordinates":[[[125.31955561825227,44.0186853140785],[125.43682190653972,44.0186853140785],[125.43682190653972,43.928546500305295],[125.31955561825227,43.928546500305295],[125.31955561825227,44.0186853140785]]],"type":"Polygon"}',
            topLayer:{
                cite:'202012100204000213229',
                infoUrl: ''
            },
            bottomLayer:{
                cite:'202101182109000276486',
                infoUrl: ''
            },
            share: 0,
            infoShow: true,
            pageHeight: 0,
            dividerTop: 0,
            topLayerInfoImageHeight: 0,
            infoId: '',
            detail: {
                "cite1": "20211210102642_881_MOSIC",
                "cite2": "20211210102713_486_MOSIC",
                "geo": "{\"type\":\"Polygon\",\"coordinates\":[[[115.812800928682,39.0669029093355],[115.831430548947,39.0669029093355],[115.831430548947,39.0428287798444],[115.812800928682,39.0428287798444],[115.812800928682,39.0669029093355]]]}",
                "infoId": "202205191459000525662",
                "picture1": "https://cgwxforum.obs.cn-north-4.myhuaweicloud.com:443/202205191508000113399.png",
                "picture2": "https://cgwxforum.obs.cn-north-4.myhuaweicloud.com:443/202205191508000147839.png",
                "title": "雄安容东安置区"
            },

          toastShow:false,
        }
    },
    methods: {
        load() {
            this.$http.get('/earthApi/main-info/getNewsRollerDetail?infoId=' + this.infoId).then(response => {
                if(response.data.status === 200){

                  document.title = this.detail.title;

                    this.detail = response.body.data;

                  document.title = this.detail.title;

                    this.topLayer = {
                        cite: this.detail.cite1,
                        infoUrl: this.detail.picture1,
                    }
                    this.bottomLayer = {
                        cite: this.detail.cite2,
                        infoUrl: this.detail.picture2,
                    }
                    this.areaGeo = this.detail.geo
                    if(window["Detail"]){
                        window["Detail"].postMessage(JSON.stringify(this.detail));
                    }
                    if(window["Title"]){
                        window["Title"].postMessage(this.detail.title);
                    }
                    if(this.$route.query.share === "1"){
                        this.share = 1

                      //分享页，显示隐藏toast
                      this.toastShow = true;
                      setTimeout(()=>{
                        this.toastShow = false;
                      },5000)
                    }
                    setTimeout(()=>{this.addLayer()}, 100)



                } else {
                    this.$router.push(
                        {
                            path: '/NotExist',
                        }
                    )
                }
            })
        },
        addLayer(){
            let topLayer = L.tileLayer(
                this.globalJS.charmingglobeLayerUrl + this.topLayer.cite + '/{z}/{x}/{y}?token=Bearer%20a84a40c81f784490a4c5689187054abf',
                {
                  tileSize:128,
                  zoomOffset:1,
                  maxNativeZoom:17,
                  tms:true
                }
            ).addTo(this.$refs.map.mapObject);
            let bottomLayer = L.tileLayer(
                this.globalJS.charmingglobeLayerUrl + this.bottomLayer.cite + '/{z}/{x}/{y}?token=Bearer%20a84a40c81f784490a4c5689187054abf',
                {
                  tileSize:128,
                  zoomOffset:1,
                  maxNativeZoom:17,
                  tms:true
                }
            ).addTo(this.$refs.map.mapObject)
            L.control.sideBySide(topLayer, bottomLayer).addTo(this.$refs.map.mapObject);
            //调整input位置
            if(document.documentElement.clientHeight >= document.documentElement.clientWidth){
                document.getElementsByClassName("leaflet-sbs-range")[0].style.transform = "rotate(90deg) translateY(50vh)"
            } else {
                document.getElementsByClassName("leaflet-sbs-range")[0].style.transform = "rotate(90deg) translateY(50vw)"
            }
            document.getElementsByClassName("leaflet-sbs-range")[0].ontouchmove = ()=>{this.getDividerTop()}
            document.getElementsByClassName("leaflet-sbs-range")[0].onmousemove = ()=>{this.getDividerTop()}
            this.$refs.map.mapObject.setMaxBounds(L.geoJSON(JSON.parse(this.areaGeo)).getBounds())
            this.$refs.map.mapObject.fitBounds(L.geoJSON(JSON.parse(this.areaGeo)).getBounds())
            this.$refs.map.mapObject.setMinZoom(this.$refs.map.mapObject.getZoom())
        },
        getDividerTop() {
            this.dividerTop = parseFloat(document.getElementsByClassName("leaflet-sbs-divider")[0].style.top)
        },
        getTopLayerInfoImageHeight(){
            this.topLayerInfoImageHeight = document.getElementsByClassName("infoImage")[0].offsetHeight
        },
        toggleInfoShow(){
            this.infoShow = ! this.infoShow
        }
    },
    mounted() {
        window.leaflet = this
        this.infoId = this.$route.query.infoId
        this.pageHeight = document.getElementById("compare").offsetHeight
        this.dividerTop = this.pageHeight / 2
        this.load()
      getWxSignature(this.$route.query.infoId)
    },
    computed:{
        topLayerInfoHeight() {
            return this.dividerTop - (this.pageHeight / 2 - this.topLayerInfoImageHeight)
        },
        bottomLayerInfoHeight() {
            return (this.dividerTop - this.pageHeight/2)
        },
        style() {
            //整体移至中心
            if(document.documentElement.clientHeight >= document.documentElement.clientWidth){
                return "width: 100vh; height: 100vh;margin-left:-" + parseInt((document.documentElement.clientHeight - document.documentElement.clientWidth)/2) + "px"
            } else {
                return "width: 100vw; height: 100vw;margin-top:-" + parseInt((document.documentElement.clientWidth - document.documentElement.clientHeight)/2) + "px"
            }
        },
    }
}
</script>
<style scoped lang="scss">

#compare {
    font-family: 微软雅黑;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    position: fixed;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 0;
    overflow: hidden;
}

#mainTitle{
    img{
        position: absolute;
        left: 24px;
        top:54px;
        z-index: 10;
    }
    span{
        position: absolute;
        left: 50px;
        top:52px;
        z-index: 10;
    }
}

.backButton{
    z-index: 10;
    height:48px;
    width: 48px;
    text-align: center;
    line-height: 48px;
    font-size: 12px;
    position: fixed;
    left: 10px;
    top:40px;
}

.infoImage{
    position: absolute;
    top: calc(50% + 2px);
    left: calc((100vh - 100vw) / 2 - 1px);
    z-index: 9998;
    pointer-events: none;
}


.toast{
  position:absolute;
  top:112px;
  left:calc((100% - 278px) / 2);
  width:278px;
  height:32px;
  background:rgba(255,255,255,0.8);
  border-radius:6px;
  color:#393939;
  font-size:12px;
  text-align:center;
}

</style>
