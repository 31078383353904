<template>

  <div class="privacyPolicyPage">
    <!--顶bar-->
    <app-page-top-bar v-if="device === 'web'" fromPage="privacyPolicyPage"></app-page-top-bar>
    <app-page-top-bar-mobile v-if="device === 'mobile'" fromPage="termsOfUsePage"></app-page-top-bar-mobile>

    <div style="width:calc(100% - 40px);max-width:800px;margin:0 auto;">
      <p style="font-size:36px;">隐私政策</p>

      <div style="width:calc(100% - 40px);margin:0 auto; padding-bottom:70px;text-align:justify;">
        <p style="margin-top:62px;line-height:24px;font-size:14px;">引言</p>
        <p style="margin-top:28px;line-height:24px;font-size:14px;">
          欢迎您使用长光卫星技术股份有限公司自主运营的吉林一号·共生地球系列产品(以下简称“我们”)。您在使用我们的服务时，我们可能会收集和使用您的相关信息。我们深知个人信息对您的重要性!因此，我们将严格遵守《风络安全法》、《电信和互联网用户个人信息保护规定》、《信息安全技术公共及商用服务信息系统个人信息保护指南》等法律法规的相关规定。采取相应的安全保护措施来保证您的个人信息安全。我们将通过《吉林一号·共生地球隐私政策》(以下简称“本政策”)向您说用我们会如何收集、存储、保护和使用您的信息，以及我们为您提供的访问、更新、控制和保护这些信息的方式。在阅读本政策时调注息以下几点：
          <br/>1.在您注册及开始使用本产品与服务前，请您务必仔细阅读并理解本政策，确保您充分理解和同意后再开始使用，注册/继续使用表示您已完整阅读、理解并接受本政策；
          <br/>2.您同意本政策表示您已了解本网站所提供的功能，以及在功能运行时所需要的必要个人信息，井给予相应的收集使用授权。处理敏感个人信息或用于本政策未涵盖的用途前，我们会根据您的实际使用情况单独征求您的同意；
          <br/>3.我们尽量以更清晰、更易理解的方式展现本政策，以便您更容易理解。如对本政策内容有任何疑问、意见或建议，您可通过本政策文末的联系方式与我们联系。
          <br/>4.如因本政策产生的任何争议，均应首先通过友好协商解决，协商不成，贵我双方均有权向本司所在地有管辖权的人民法院提起诉讼。本网站所在地为吉林省长春市。
        </p>
        <p style="margin-top:28px;line-height:24px;font-size:14px;">我们如何收集您的个人信息</p>
        <p style="margin-top:28px;line-height:24px;font-size:14px;">在您使用本网站服务时，我们将自动接收并记录您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间，软硬件特征信息及您需求的网页记录等数据。</p>
        <p style="margin-top:28px;line-height:24px;font-size:14px;">Cookies或同类技术</p>
        <p style="margin-top:28px;line-height:24px;font-size:14px;">在您未拒绝接受cookies的情况下，我们可能通过cookies或同类技术获取和使用您的信息，为您提供更加周到的个性化服务。您有权选择接受或拒绝接受cookies。但如果您选择拒绝接受cookies，可能无法登录或使用依赖于cookies的本网站服务或功能。</p>
        <p style="margin-top:28px;line-height:24px;font-size:14px;">使用和披露的个人信息</p>
        <p style="margin-top:28px;line-height:24px;font-size:14px;">
          1.我们可能将在向您提供服务的过程之中所收集的信息用作下列用途：
          <br/>(1)在我们提供服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性；
          <br/>(2)帮助我们设计新服务，改善我们现有服务；使我们更加了解您如何接入和使用我们的服务，从而针对性地回应您的个性化需求，例如语言设定、位置设定、个性化的帮助服务和指示。或对您和其他用户作出其他方面的回应；
          <br/>2.我们不会向任何无关第三方共享您的个人信息。仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息。当本网站与第三方合作伙伴共同为您提供服务时，会事先得到您明确同意。对于我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本政策以及其他任何相关的保密和安全措施来处理信息。
          <br/>3.在如下情况下，本网站将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息；
          <br/>(1)经您事先同意，向第三方披露；
          <br/>(2)根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您信息的情况下，向第三方或者行政、司法机构披露。
        </p>
        <p style="margin-top:28px;line-height:24px;font-size:14px;">我们如何存储和保护您的个人信息</p>
        <p style="margin-top:28px;line-height:24px;font-size:14px;">
          除法律法规或监管部门另有规定外，我们仅在本政策所述目的所必需且最短的时限内存储您的个人信息。我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内，以下情形除外：
          <br/>1.法律法规有明确规定；
          <br/>2.获得您的授权同意；
          <br/>针对以上情形，我们会确保依据国家法律法规和相关监管部门的规定执行，并对您的个人信息提供足够的保护。
          <br/>我们非常重视信息安全。会采用符合业界标准的安全防护措施。包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问、使用、修改，避免数据的损坏或丢失。但互联网并非绝对安全的环境，电子邮件、即时通讯、社交软件或其他服务软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的信息安全。请您妥善保护自己的信息，仅在必要的情形下向他人提供。
        </p>
        <p style="margin-top:28px;line-height:24px;font-size:14px;">我们如何管理您的个人信息</p>
        <p style="margin-top:28px;line-height:24px;font-size:14px;">访问、更新和删除：我们鼓励您更新和修改您的个人信息以使其更准确有效。如您想查询、修改或删除您的部分信息，请前往网站各产品相关功能体验页面，我们为您提供了相关操作指引和操作设置。若您无法通过上述方式访问您的个人信息，可以通过本政策“如何联系我们”章节所列的反馈渠道联系我们为您处理。除法律法规另有规定，当您更正、删除您的个人信息时，我们可能不会立即从备份系统中更正或删除相应的信息，但会在备份更新时更正或删除这些信息。</p>
        <p style="margin-top:28px;line-height:24px;font-size:14px;">本隐私条款的更改</p>
        <p style="margin-top:28px;line-height:24px;font-size:14px;">
          为不断向您提供更好的服务，我们会适时修改本政策，并在本政策中、本公司网站中以及我们认为适当的位置发布这些更改。对于重大变更，我们会提供更显著的通如。所述重大变更包括但不限于：
          <br/>1.我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
          <br/>2.我们在所有权结构方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
          <br/>3.个人信息共享、转让或公开披露的主要对象发生重大变化；
          <br/>4.您参与个人信息处理方面的权利及其行使方式发生重大变化；
          <br/>5.其他重要的或可能严重影响您的个人权益的情况发生。
        </p>
        <p style="margin-top:28px;line-height:24px;font-size:14px;">如何联系我们</p>
        <p style="margin-top:28px;line-height:24px;font-size:14px;">
          如您有关于网络信息安全的投诉和举报。或您对本隐私政策、您的信息的相关事宜有任何问题、意见或建议。请通过以下方式与我们联系：
          <br/>公司名称：长光卫星技术股份有限公司
          <br/>地址：吉林省长春市北湖科技开发区明溪路1299号
          <br/>热线：0431-86008580(服务时间：法定工作日08:30-17:30)
          <br/>我们将有专人跟进负责处理，一般情况下，我们将在收到您的问题、意见或建议，并验证您的用户身份后的七个工作日内予以处理。如您不满意我们的处理。您还可以通过法律途径向履行个人信息保护职责的部门进行投诉、举报。
        </p>

      </div>


    </div>


    <div style="width:calc(100% - 40px);margin:0 auto;padding-bottom:20px;">
      <p style="font-size:14px;color:rgba(161, 161, 167, 1);text-align:center;">
        <span @click="gotoPsPage('/TermsOfUsePage')" style="cursor:pointer;">使用条款</span>
        ｜
        <span @click="gotoPsPage('/PrivacyPolicyPage')" style="cursor:pointer;">隐私协议</span>
        ｜© 长光卫星 版权所有归长光卫星技术股份有限公司 | 吉ICP备15001825号-7 | 吉公网安备22017302000265号 | 甲测资字22100079
      </p>
    </div>


  </div>


</template>

<script>

import AppPageTopBar from "@/views/AppPage/AppPageTopBar"
import AppPageTopBarMobile from "@/views/AppPage/AppPageTopBar_mobile"

export default {
  name: "PrivacyPolicyPage",
  components: {
    AppPageTopBar,AppPageTopBarMobile
  },
  data(){
    return{

      device:"web"

    }

  },
  methods:{

    //跳转附件页面
    gotoPsPage(pageUrl){
      if(pageUrl === '/PrivacyPolicyPage'){
        window.location.reload();
      }
      else{
        this.$router.push(
            {
              path: pageUrl,
            }
        );
      }

    }

  },
  mounted(){
    setTimeout(()=>{
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    },100)

    if(window.innerWidth >= 1000) {
      //web端
      this.device = "web";
    }
    else{
      //手机
      this.device = "mobile";
    }


  }


}
</script>

<style scoped>

.privacyPolicyPage{
  background:#000000;
  color:#ffffff;
  /*overflow:hidden;*/
  font-family:HarmonyOSSansSCRegular;
  background-image:url('../../assets/App/privacyPolicyPage/background.jpg');
  background-size:100% ;
  background-repeat:no-repeat;
  padding:160px 0 0 0;
}
.privacyPolicyPage p{
  margin:0;
}




</style>