<template>
  <div class="termsOfUsePage">
    <!--顶bar-->
    <app-page-top-bar v-if="device === 'web'" fromPage="termsOfUsePage"></app-page-top-bar>
    <app-page-top-bar-mobile v-if="device === 'mobile'" fromPage="termsOfUsePage"></app-page-top-bar-mobile>

    <div style="width:calc(100% - 40px);max-width:800px;margin:0 auto;">
      <p style="font-size:36px;">用户协议</p>


      <div style="max-width:649px;margin:0 auto; padding-bottom:70px;text-align:justify;">
        <p style="margin-top:62px;line-height:24px;font-size:14px;">一、使用协议适用范围</p>
        <p style="margin-top:28px;line-height:24px;font-size:14px;">本使用协议适用于吉林一号·共生地球官网（以下简称“本网站”）。本网站在此特别提醒用户认真阅读及充分理解本协议内容。您使用本网站，即表示您同意本使用协议；如果您不同意，请勿使用本网站。在法律允许范围内，长光卫星技术股份有限公司（以下简称“长光卫星”“我们”）保留自行决定不定期变更本使用协议内容的权利，您有责任定期查看本使用协议的变更。如您在变更信息发布后继续使用本网站，即表示您同意且接受该变更。</p>
        <p style="margin-top:28px;line-height:24px;font-size:14px;">二、知识产权声明</p>
        <p style="margin-top:28px;line-height:24px;font-size:14px;">
          本网站包含的所有内容，包括但不限于文字、图片、音乐、视频、动画、商标、图案、图表、可视界面及计算机编码（“内容”），相关知识产权为长光卫星所有或已获得合法授权而使用。除另有明确书面规定，未经长光卫星事先书面同意，您不得为任何商业目的以任何方式使用本网站内容。
          <br/>本网站提供的关于吉林一号·共生地球产品和服务的信息，您可通过本网站浏览查看下载共生地球系列产品服务，但不得用于商业目的，且不得对上述信息进行修改，及对上述信息及载有信息的文件进行进一步保证和陈述，且需要保留长光卫星合法权益的声明等。
        </p>
        <p style="margin-top:28px;line-height:24px;font-size:14px;">三、使用规范</p>
        <p style="margin-top:28px;line-height:24px;font-size:14px;">
          本网站为展示呈现吉林一号·共生地球系列产品应用服务的介绍门户网站，旨在为用户提供了解产品信息最新资讯服务。您不可为任何非法或本使用协议禁止的目的使用本网站，不得利用本网站及其内容侵犯其他组织或个人的合法权益。
          <br/>您不可使用包括“深层链接”、“网页搜罗”、“网页机器人”、“网页采集工具”或其他自动设备、程序、算法或方法，或者任何类似或同等的手动流程来访问、获取、拷贝或监控本网站的任何组成部分或任何内容，结构或呈现形式，从而通过本网站并未有意提供的任何手段获取或试图获取任何资料、文稿或信息。我们保留禁止任何此类活动的权利。您不可试图通过任何非法方式，未经授权访问本网站的任何内容或使用本网站的任何服务。您不可向本网站发起任何形式的网络攻击。
          <br/>您必须遵守任何和所有适用的国内和国际出口管制法律和限制的规定，包括有关目的地、最终用户和最终用途的任何限制。我们保留为遵守任何该等出口管制法律和限制之目的中止、限制或终止您访问本网站或其任何部分的权利。
          <br/>长光卫星始终保留相关的信息披露权利，以便为了遵守任何适用的法律、法规、法律程序或政府要求而披露必需的信息。当长光卫星认为适用的法律要求或允许此类披露时，也可能会披露您的信息，包括出于欺诈保护目的与其他公司和组织交换信息。
          <br/>您确认并同意，我们有权保留您通过本网站、本网站上提供的任何服务或通过本网站提供的任何服务与我们之间进行的任何传输或通信，并可在以下情况下披露这些数据：应法律要求必须这样做，或者长光卫星技术股份有限公司有足够的理由相信，只有这样做才能合理地 (1) 遵守法律程序；(2) 执行本使用协议；(3) 对此类数据侵犯他人权利的主张作出回应；或者 (4) 保护长光卫星、其员工、本网站的用户或访客以及公众的权利、财产或个人安全。
          <br/>如果长光卫星认为您违反了本使用协议或其他可能与您对本网站的使用相关的协议或准则，则可在不事先通知的情况下自行决定终止您对本网站的访问权限，并/或阻止您在未来访问本网站。您的任何违反本使用协议的行为将会构成非法及不正当商业行为，将会对我们造成仅靠金钱赔偿难以弥补的损害，在这种情形下，我方可以申请采取其认为必要或适当的禁令或等效的补救措施。这些补救措施是长光卫星根据现行普通法或衡平法享有的任何其他补救措施的补充，在我方享有的任何其他救济之外，我方还将有权向您追讨相关款项，并且您同意支付全部合理的律师费和诉讼费用。您同意，对于因违反本使用协议而导致您对本网站的访问权限被终止的情况，我方对您或任何第三方均不承担任何责任。
        </p>
        <p style="margin-top:28px;line-height:24px;font-size:14px;">四、第三方链接</p>
        <p style="margin-top:28px;line-height:24px;font-size:14px;">本网站可能会有进入第三方网站链接；所有第三方内容由第三方提供，长光卫星不对内容、其真实性、准确性，或第三方网站的可用性、可靠性、安全性或其他方面以及我们无法控制的任何其他方面（包括其任何权利）作出任何承诺、保证或声明，并且不对您使用该等第三方内容产生的任何后果承担责任。您访问和使用该等第三方内容的风险由您自行承担。</p>
        <p style="margin-top:28px;line-height:24px;font-size:14px;">五、不可抗力及第三方原因</p>
        <p style="margin-top:28px;line-height:24px;font-size:14px;">
          长光卫星依照法律规定履行相关义务，但对于下述原因导致的协议履行障碍、履行瑕疵、履行延后或履行内容变更等情形，我方并不承担违约责任：
          <br/>(1)因自然灾害、罢工、暴乱、战争、政府行为、司法行政命令等不可抗力因素；
          <br/>(2)因电力供应故障、通讯网络故障等公共服务因素或任何由于黑客攻击，电脑病毒的侵入，非法内容信息、骚扰信息的屏蔽，政府管制以及其他任何网络、技术、通信线路、信息安全管理措施等原因造成的服务中断、受阻等不能满足您要求的情形；
          <br/>(3)在长光卫星已尽善意管理的情况下，因常规或紧急的设备与系统维护、设备与系统故障、网络信息与数据安全等因素。
        </p>
        <p style="margin-top:28px;line-height:24px;font-size:14px;">六、隐私</p>
        <p style="margin-top:28px;line-height:24px;font-size:14px;">
          我们尊重并致力于保护您的隐私。有关我们保护您的个人信息的具体措施，请参见我们的隐私政策。
          <br/>我们（或第三方广告商）可能出于营销目的与您联系，并且根据我们的隐私政策向您发送广告、促销或宣传信息。
          <br/>必须提醒的是，您与本网站之间的网络连接、数据传输并非完全是安全或保密的，您发送至本网站的信息、数据存在被他人拦截的可能性，我方无法对您与本网站之间的网络连接、数据传输的安全性与保密性进行保证。
        </p>
        <p style="margin-top:28px;line-height:24px;font-size:14px;">七、责任限制与免责声明</p>
        <p style="margin-top:28px;line-height:24px;font-size:14px;">
          对本网站及其所载内容与服务的可用性、持续性、可访问性，我方不做任何形式的保证，本网站及其内容是基于“现状”提供。 您对本网站及任何链接网站的使用，由您承担全部责任。如果对本网站的全部或部分有任何不满意，您可停止使用全部或部分本网站。
          <br/>我方不为本网站所载内容的正确性、完全性、可靠性提供保证。本网站公布的各类产品或服务的信息，包括但不限于产品功能、配置、参数、技术规格、服务内容等，可能因不同版本更新发生不同变化；即使是同一产品，在其产品生命周期的不同阶段，其产品功能、配置、参数、技术规格等也可能进行调整，而我们需要时间去完成相应信息的更新，因此您所看到的产品或服务信息和您实际体验或购买产品或服务可能存在差异。
          <br/>长光卫星不应对间接、附带、特殊或任何形式的惩罚性赔偿承担任何责任，也不应对任何间接损失承担责任，包括但不限于利润、收入、数据、数据使用的损失。
          <br/>因您使用本网站而产生的任何损害或损失，我方及其合作伙伴对您全部的责任，不超过因您使用本网站任何服务或功能有关的订购费用或类似费用的总额。某些司法管辖区不允许责任限制，因此前述限制可能不适用于您。
        </p>
        <p style="margin-top:28px;line-height:24px;font-size:14px;">八、法律管辖适用及其他</p>
        <p style="margin-top:28px;line-height:24px;font-size:14px;">
          本使用协议的订立、执行、解释及争议的解决均适用中华人民共和国法律。
          <br/>本使用协议签订地为吉林省长春市，如双方就本使用协议的内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向协议签订地有管辖权的人民法院提起诉讼。
        </p>

      </div>

    </div>

    <div style="width:calc(100% - 40px);margin:0 auto;padding-bottom:20px;">
      <p style="font-size:14px;color:rgba(161, 161, 167, 1);text-align:center;">
        <span @click="gotoPsPage('/TermsOfUsePage')" style="cursor:pointer;">使用条款</span>
        ｜
        <span @click="gotoPsPage('/PrivacyPolicyPage')" style="cursor:pointer;">隐私协议</span>
        ｜© 长光卫星 版权所有归长光卫星技术股份有限公司 | 吉ICP备15001825号-7 | 吉公网安备22017302000265号 | 甲测资字22100079
      </p>
    </div>

  </div>

</template>

<script>

import AppPageTopBar from "@/views/AppPage/AppPageTopBar"
import AppPageTopBarMobile from "@/views/AppPage/AppPageTopBar_mobile"

export default {
  name: "TermsOfUsePage",
  components: {
    AppPageTopBar,AppPageTopBarMobile
  },
  data(){
    return{

      device:"web"

    }

  },
  methods:{

    //跳转附件页面
    gotoPsPage(pageUrl){
      if(pageUrl === '/TermsOfUsePage'){
        window.location.reload();
      }
      else{
        this.$router.push(
            {
              path: pageUrl,
            }
        )
      }

    }

  },
  mounted(){
    setTimeout(()=>{
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    },100)

    if(window.innerWidth >= 1000) {
      //web端
      this.device = "web";
    }
    else{
      //手机
      this.device = "mobile";
    }

  }


}
</script>

<style scoped>


.termsOfUsePage{
  background:#000000;
  color:#ffffff;
  /*overflow:hidden;*/
  font-family:HarmonyOSSansSCRegular;
  background-image:url('../../assets/App/privacyPolicyPage/background.jpg');
  background-size:100% ;
  background-repeat:no-repeat;
  padding:160px 0 0 0;
}
.termsOfUsePage p{
  margin:0;
}


</style>