<template>
  <div class="appPageFootBar_mobile" style="width:calc(100% - 64px);padding:17px 32px;">

    <div v-if="screenSize === 'small'">
      <p style="color:#ffffff;">联系我们</p>
      <div style="margin-top:25px;display:flex;">
        <p style="width:113px;">官方QQ交流群</p>
        <p>712093290</p>
      </div>
      <div style="margin-top:25px;display:flex;">
        <p style="width:113px;">商务合作</p>
        <p>18813148445&nbsp;&nbsp;&nbsp;&nbsp;王经理</p>
      </div>
      <p style="margin-top:42px;color:#ffffff;">更多产品服务</p>
      <p @click="gotoProductPage(item.href)" v-for="(item,index) in produceList" :key="'produceItem'+index" style="margin-top:25px;">{{item.title}}</p>

      <div style="width:100%;margin-top:41px;display:flex;justify-content:space-around">
        <div style="text-align:center;">
          <img style="width:100px;height:100px;" src="../../assets/App/footBar/weixin.jpg" />
          <p style="margin-top:6px;">官方微信公众号</p>
        </div>
        <div style="text-align:center;">
          <img style="width:100px;height:100px;" src="../../assets/App/footBar/weibo.jpg" />
          <p style="margin-top:6px;">官方微博账号</p>
        </div>
      </div>
    </div>

    <div v-if="screenSize === 'big'">
      <div style="width:100%;display:flex;align-items:end;justify-content:space-evenly;">
        <div>
          <p style="color:#ffffff;">联系我们</p>
          <div style="margin-top:25px;display:flex;">
            <p style="width:113px;">官方QQ交流群</p>
            <p>712093290</p>
          </div>
          <div style="margin-top:25px;display:flex;">
            <p style="width:113px;">商务合作</p>
            <p>18813148445&nbsp;&nbsp;&nbsp;&nbsp;王经理</p>
          </div>
          <p style="margin-top:42px;color:#ffffff;">更多产品服务</p>
          <p @click="gotoProductPage(item.href)" v-for="(item,index) in produceList" :key="'produceItem'+index" style="margin-top:25px;">{{item.title}}</p>
        </div>

        <div style="flex:1;max-width:300px;margin-top:41px;display:flex;justify-content:space-evenly;">
          <div style="text-align:center;">
            <img style="width:100px;height:100px;" src="../../assets/App/footBar/weixin.jpg" />
            <p style="margin-top:6px;">官方微信公众号</p>
          </div>
          <div style="text-align:center;">
            <img style="width:100px;height:100px;" src="../../assets/App/footBar/weibo.jpg" />
            <p style="margin-top:6px;">官方微博账号</p>
          </div>
        </div>


      </div>


    </div>




    <p style="margin-top:42px;text-align:center;">
      <span @click="gotoPsPage('/TermsOfUsePage')" style="cursor:pointer;">使用条款</span>
      ｜
      <span @click="gotoPsPage('/PrivacyPolicyPage')" style="cursor:pointer;">隐私协议</span>
      ｜© 长光卫星 版权所有归长光卫星技术股份有限公司 | 吉ICP备15001825号-7 | 吉公网安备22017302000265号 | 甲测资字22100079
    </p>



  </div>

</template>

<script>
export default {
  name: "AppPageFootBarMobile",


  props:["produceList"],
  data(){
    return{
      screenSize:"small",

    }

  },

  methods:{
    //跳转产品页
    gotoProductPage(pageUrl){
      this.$router.push(
          {
            path: pageUrl,
          }
      );
    },

    //跳转附件页面
    gotoPsPage(pageUrl){
      this.$router.push(
          {
            path: pageUrl,
          }
      );
    }

  },
  mounted(){

    if(window.innerWidth > 650){
      this.screenSize = "big";
    }


  }
}
</script>

<style scoped>

.appPageFootBar_mobile{
  font-family:HarmonyOSSansSCRegular;
  font-size:14px;
  color:rgba(161, 161, 167, 1)
}
.appPageFootBar_mobile p{
  margin:0;
}



</style>