<template>
    <div class="panoramaSharePage">
        <!--主页-->
        <iframe :src="panoramaUrl" id="panoramaIframe" style="width:100%;height:100%;border:none;"></iframe>
        <!--提示-->
        <div v-if="toastShow" class="toast">
            <p style="line-height:32px;">移动或缩放可查看更多地理信息</p>
        </div>

        <!--分享按钮-->
        <TopShareBar></TopShareBar>
    </div>


</template>

<script>

    import TopShareBar from '@/components/TopShareBar';
    import {getWxSignature} from "@/utils/shareConfig";
    export default {
        name: "panorama-share-page",
        components: {TopShareBar},
        data(){
            return{
                mainInfoId:"",
                panoramaTitle:"",
                panoramaUrl:"",

                toastShow:true,
            }
        },
        methods:{
            getDetail(){
                this.$http.get("/earthApi/main-info/getMainInfoDetail?shareType=1&infoId=" + this.mainInfoId).then(response => {
                    console.log(response.data)
                    if(response.data.status === 200){
                        this.panoramaTitle = response.data.data.mainTitle;
                        document.title = this.panoramaTitle;
                        this.panoramaUrl = response.data.data.picture1;

                        //隐藏toast
                        setTimeout(()=>{
                            this.toastShow = false;
                        },5000)

                    }
                    else if(response.data.status === 404){
                      this.$router.push(
                          {
                            path: '/NotExist',
                          }
                      )
                    }
                    else{
                        console.log(response.data.status + response.data.message)
                    }
                })

            }

        },
        mounted(){
            this.mainInfoId = this.$route.query.infoId
            this.getDetail();
          getWxSignature(this.$route.query.infoId)

        }
    }
</script>

<style scoped>
    .panoramaSharePage {
        font-family: 微软雅黑;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        /*text-align: center;*/
        color: #2c3e50;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        overflow: hidden;
    }
    .panoramaSharePage p{
        margin:0;
    }

    .toast{
        position:absolute;
        top:112px;
        left:calc((100% - 278px) / 2);
        width:278px;
        height:32px;
        background:rgba(255,255,255,0.8);
        border-radius:6px;
        color:#393939;
        font-size:12px;
        text-align:center;
    }

</style>
