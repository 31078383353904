<template>
  <div class="AppHelpPage">
    <div style="width: 100%;height: 100%;display: flex;flex-direction: column;padding: 0 26px 50px 26px;background: white;overflow-y: scroll">
      <div style="height: 24px;width: calc(100% - 52px);display: flex;align-items: center">
        <div style="width: 4px;height: 18px;background: rgba(105, 94, 192, 1)"></div>
        <div style="line-height: 24px;font-size: 16px;font-weight: bold;margin-left: 12px">基本手势操作</div>
      </div>

      <img src="../assets/App/appHelpPage/P1.png" style="width: calc(100% - 52px);margin-top: 16px;object-fit: contain" />
      <img src="../assets/App/appHelpPage/P2.png" style="width: calc(100% - 52px);margin-top: 4px;object-fit: contain" />
      <img src="../assets/App/appHelpPage/P3.png" style="width: calc(100% - 52px);margin-top: 4px;object-fit: contain" />
      <img src="../assets/App/appHelpPage/P4.png" style="width: calc(100% - 52px);margin-top: 4px;object-fit: contain" />
      <img src="../assets/App/appHelpPage/P5.png" style="width: calc(100% - 52px);margin-top: 4px;object-fit: contain" />

      <div style="width: calc(100% - 52px);height:1px;margin: 20px 0 15px 0;background:#D8D8D8;"></div>

      <div style="height: 24px;width: calc(100% - 52px);display: flex;align-items: center">
        <div style="width: 4px;height: 18px;background: rgba(105, 94, 192, 1)"></div>
        <div style="line-height: 24px;font-size: 16px;font-weight: bold;margin-left: 12px">主界面功能说明图解</div>
      </div>
      <img src="../assets/App/appHelpPage/P6.png" style="width: calc(100% - 52px);margin-top: 16px;object-fit: contain" />
      <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 12px;text-align: justify">在【推荐】模式下，选择二级分类标签，可对地图上显示的内容进行分类筛选。</div>
      <img src="../assets/App/appHelpPage/P7.png" style="width: calc(100% - 52px);margin-top: 12px;object-fit: contain" />
      <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 12px;text-align: justify">在【推荐】模式下，点击搜索按钮，打开关闭搜索推荐页。搜索推荐页下，可进行地点及内容搜索。</div>
      <img src="../assets/App/appHelpPage/P8.png" style="width: calc(100% - 52px);margin-top: 12px;object-fit: contain" />

      <div style="width: calc(100% - 52px);height:1px;margin: 20px 0 15px 0;background:#D8D8D8;"></div>

      <div style="height: 24px;width: calc(100% - 52px);display: flex;align-items: center">
        <div style="width: 4px;height: 18px;background: rgba(105, 94, 192, 1)"></div>
        <div style="line-height: 24px;font-size: 16px;font-weight: bold;margin-left: 12px">智能工具箱使用说明</div>
      </div>
      <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 12px;text-align: justify">点击智能小助手后，可开启智能工具箱</div>
      <img src="../assets/App/appHelpPage/P9.png" style="width: calc(100% - 52px);margin-top: 12px;object-fit: contain" />
      <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 12px;text-align: justify">智能工具箱中点击【智能问答】，可开启共生地球智能助手问答；点击【吉林一号·星旅】可进行智能旅行规划；点击【智能区域识别】可对当前区域进行智能内容推荐。</div>
      <img src="../assets/App/appHelpPage/P10.png" style="width: calc(100% - 52px);margin-top: 12px;object-fit: contain" />

      <div style="width: calc(100% - 52px);height:1px;margin: 20px 0 15px 0;background:#D8D8D8;"></div>

      <div style="height: 24px;width: calc(100% - 52px);display: flex;align-items: center">
        <div style="width: 4px;height: 18px;background: rgba(105, 94, 192, 1)"></div>
        <div style="line-height: 24px;font-size: 16px;font-weight: bold;margin-left: 12px">世界-标记模块使用说明</div>
      </div>
      <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 12px;text-align: justify">选择标记模块，进入标记文件主界面。标记主界面功能说明简介：</div>
      <img src="../assets/App/appHelpPage/P11.png" style="width: calc(100% - 52px);margin-top: 12px;object-fit: contain" />
      <img src="../assets/App/appHelpPage/P12.png" style="width: calc(100% - 52px);margin-top: 4px;object-fit: contain" />
      <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 12px;text-align: justify">点击页面左边操作栏中的的【文件】按钮，或者点击上方文件名称，屏幕下方弹出文件管理弹窗。</div>
      <img src="../assets/App/appHelpPage/P13.png" style="width: calc(100% - 52px);margin-top: 12px;object-fit: contain" />
      <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 12px;text-align: justify">弹窗中显示当前打开的文件名称，点击【打开文件列表】，即可查看所有标记文件。</div>
      <img src="../assets/App/appHelpPage/P14.png" style="width: calc(100% - 52px);margin-top: 12px;object-fit: contain" />
      <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 12px;text-align: justify">点击列表中某个文件，此文件所在行展开，显示基本信息。点击【打开】，即可打开此标记文件进行预览；点击【详情】，跳转至文件详情页面；点击分享按钮，加入文件进行共同创作，如果打开，则其他用户可依据此次分享加入标记文件，与您进行共同标记创作；点击【删除】，即可删除文件。</div>
      <img src="../assets/App/appHelpPage/P15.png" style="width: calc(100% - 52px);margin-top: 12px;object-fit: contain" />
      <img src="../assets/App/appHelpPage/P16.png" style="width: calc(100% - 52px);margin-top: 4px;object-fit: contain" />
      <img src="../assets/App/appHelpPage/P17.png" style="width: calc(100% - 52px);margin-top: 4px;object-fit: contain" />
      <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 12px;text-align: justify">点击页面左边操作栏中的【数据】按钮，弹出子窗口，子窗口中有标记列表与图层列表两个按钮。</div>
      <img src="../assets/App/appHelpPage/P18.png" style="width: calc(100% - 52px);margin-top: 12px;object-fit: contain" />
      <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 12px;text-align: justify">点击标记列表后，弹窗中显示当前文件标记的点、线、面数据。</div>
      <img src="../assets/App/appHelpPage/P19.png" style="width: calc(100% - 52px);margin-top: 12px;object-fit: contain" />
      <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 12px;text-align: justify">点击某个点标记后，可查看该点位位置，点击隐藏按钮可将该标记点隐藏，再次点击即可显示。</div>
      <img src="../assets/App/appHelpPage/P20.png" style="width: calc(100% - 52px);margin-top: 12px;object-fit: contain" />
      <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 12px;text-align: justify">点击图层列表后，显示当前文件中所有可视化图层。</div>
      <img src="../assets/App/appHelpPage/P21.png" style="width: calc(100% - 52px);margin-top: 12px;object-fit: contain" />
      <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 12px;text-align: justify">点击某个图层后，显示该图层，可调整图层样式、透明度，是否显示该图层。</div>
      <img src="../assets/App/appHelpPage/P22.png" style="width: calc(100% - 52px);margin-top: 12px;object-fit: contain" />
      <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 12px;text-align: justify">点击显示按钮后，弹出子窗口。子窗口中包含地图样式、标记样式等选项。</div>
      <img src="../assets/App/appHelpPage/P23.png" style="width: calc(100% - 52px);margin-top: 12px;object-fit: contain" />
      <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 12px;text-align: justify">【底图选择】可切换地图；【标记点样式】可更改当前文件下标记点图例；【显示标记名称】可对标记点名称进行隐藏；【标记点聚合】可控制当前文件下点位是否聚合；【标记图标大小】可控制图标相对大小；【显示所有标记】默认打开，关闭则所有标记不显示。</div>
      <img src="../assets/App/appHelpPage/P24.png" style="width: calc(100% - 52px);margin-top: 12px;object-fit: contain" />
      <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 12px;text-align: justify">点击更多显示播放、帮助功能。</div>
      <img src="../assets/App/appHelpPage/P25.png" style="width: calc(100% - 52px);margin-top: 12px;object-fit: contain" />
      <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 12px;text-align: justify">点击【播放】按钮，跳转到播放页面，将针对第一个点、线素材生成空中视角视频，如果文件中没有素材，则无法播放。点击【帮助】按钮，可查看此文档。</div>
      <img src="../assets/App/appHelpPage/P26.png" style="width: calc(100% - 52px);margin-top: 12px;object-fit: contain" />

      <div style="width: calc(100% - 52px);height:1px;margin: 20px 0 15px 0;background:#D8D8D8;"></div>

      <div style="height: 24px;width: calc(100% - 52px);display: flex;align-items: center">
        <div style="width: 4px;height: 18px;background: rgba(105, 94, 192, 1)"></div>
        <div style="line-height: 24px;font-size: 16px;font-weight: bold;margin-left: 12px">地球仪模块使用说明</div>
      </div>
      <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 12px;text-align: justify">1.测量长度、测量面积：<br/>在【世界】中点击【地球仪】模式，点击【工具箱】，点击【测量长度】或【测量面积】，进入测量界面；</div>
      <img src="../assets/App/appHelpPage/P27.png" style="width: calc(100% - 52px);margin-top: 12px;object-fit: contain" />
      <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 12px;text-align: justify">在测量界面点击【添加】，对地图进行标点测量；测量过程中，可【撤销】标点或全部【删除】重新添加，测量完成点击【完成】；</div>
      <img src="../assets/App/appHelpPage/P28.png" style="width: calc(100% - 52px);margin-top: 12px;object-fit: contain" />
      <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 12px;text-align: justify">【测量长度】的数据结果为长度和方位角；【测量面积】的数据结果为面积和周长；测量完成，可对数据进行【再编辑】和【复制】；测量结束，点击【保存】，测量结果可在【历史数据】中查看。</div>
      <img src="../assets/App/appHelpPage/P29.png" style="width: calc(100% - 52px);margin-top: 12px;object-fit: contain" />
      <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 12px;text-align: justify">2.路线规划</div>
      <img src="../assets/App/appHelpPage/P30.png" style="width: calc(100% - 52px);margin-top: 12px;object-fit: contain" />
      <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 12px;text-align: justify">点击【添加】，对地图进行标点手绘/规划。画线过程中，可【撤销】标点或对路线添加【标记】；</div>
      <img src="../assets/App/appHelpPage/P31.png" style="width: calc(100% - 52px);margin-top: 12px;object-fit: contain" />
      <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 12px;text-align: justify">点击添加【标记】按钮，可输入标记点【名称】和对该标记点的【描述】，添加标记点【图标】，可修改标记点位置信息，还可在该标记点的详细内容中【添加图片】介绍；</div>
      <img src="../assets/App/appHelpPage/P32.png" style="width: calc(100% - 52px);margin-top: 12px;object-fit: contain" />
      <img src="../assets/App/appHelpPage/P33.png" style="width: calc(100% - 52px);margin-top: 4px;object-fit: contain" />
      <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 12px;text-align: justify">在标记点处添加【图标】：在图标区任选一个图标类型，可更换图标的颜色，预览图标后点击【完成】，则成功添加标记点。</div>
      <img src="../assets/App/appHelpPage/P34.png" style="width: calc(100% - 52px);margin-top: 12px;object-fit: contain" />
      <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 12px;text-align: justify">输入路线名称，点击【保存】；该路线可在【历史数据】中查看，也可对已有路线【再编辑】和【分享】。</div>
      <img src="../assets/App/appHelpPage/P35.png" style="width: calc(100% - 52px);margin-top: 12px;object-fit: contain" />
      <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 12px;text-align: justify">3.图层选择<br/>在【世界】中点击【地球仪】模式，点击【图层选择】，选择并更换地球仪模式下的不同底图。</div>
      <img src="../assets/App/appHelpPage/P36.png" style="width: calc(100% - 52px);margin-top: 12px;object-fit: contain" />


      <div style="width: calc(100% - 52px);height:1px;margin: 20px 0 15px 0;background:#D8D8D8;"></div>

      <div style="height: 24px;width: calc(100% - 52px);display: flex;align-items: center">
        <div style="width: 4px;height: 18px;background: rgba(105, 94, 192, 1)"></div>
        <div style="line-height: 24px;font-size: 16px;font-weight: bold;margin-left: 12px">卫星</div>
      </div>
      <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 12px;text-align: justify">1.如何在卫星中查看卫星详细信息？<br/>点击【卫星】，进入卫星页面，滑动底部卡片可选择卫星查看详细信息。</div>
      <img src="../assets/App/appHelpPage/P37.png" style="width: calc(100% - 52px);margin-top: 12px;object-fit: contain" />
      <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 12px;text-align: justify">点击卫星卡片，可切换查看卫星详细参数，在轨示意状态等。</div>
      <img src="../assets/App/appHelpPage/P38.png" style="width: calc(100% - 52px);margin-top: 12px;object-fit: contain" />
      <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 12px;text-align: justify">点击卫星活动卡片，可参与具体的卫星活动。</div>
      <img src="../assets/App/appHelpPage/P39.png" style="width: calc(100% - 52px);margin-top: 12px;object-fit: contain" />

      <div style="width: calc(100% - 52px);height:1px;margin: 20px 0 15px 0;background:#D8D8D8;"></div>

      <div style="height: 24px;width: calc(100% - 52px);display: flex;align-items: center">
        <div style="width: 4px;height: 18px;background: rgba(105, 94, 192, 1)"></div>
        <div style="line-height: 24px;font-size: 16px;font-weight: bold;margin-left: 12px">个人主页</div>
      </div>
      <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 12px;text-align: justify">1.如何在个人主页中查看全部主题？<br/>点击【我的】，进入个人主页，查看个人信息情况。</div>
      <img src="../assets/App/appHelpPage/P40.png" style="width: calc(100% - 52px);margin-top: 12px;object-fit: contain" />
      <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 12px;text-align: justify">2.如何管理已发布的内容？<br/>点击【我的】进入个人主页，点击【发布】查看已发布内容，点击右侧【管理】；</div>
      <img src="../assets/App/appHelpPage/P41.png" style="width: calc(100% - 52px);margin-top: 12px;object-fit: contain" />
      <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 12px;text-align: justify">点击【隐藏内容】，可将该条已发布的内容隐藏，此时他人不可见，仅自己可见；点击【删除】，可删除该条已发布内容，删除后不可恢复。</div>
      <img src="../assets/App/appHelpPage/P42.png" style="width: calc(100% - 52px);margin-top: 12px;object-fit: contain" />



      <!--结尾处理-->
      <div style="width:100%;height:0px;margin-bottom:50px;"></div>

    </div>
  </div>
</template>

<script>
export default {
  name: "AppHelpPage"


}
</script>

<style scoped>
.AppHelpPage{
  font-family: 微软雅黑;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
  /*// 禁止长按选择文字*/
  -webkit-user-select: none;
  /*// 禁止长按呼出菜单*/
  -webkit-touch-callout: none;
}
</style>