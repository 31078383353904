<!--<template>-->

<template>
  <div class="applyForWish alibabaPuHuiTiFontFamily">
    <div id="map" style="background-color: black"/>
    <div v-show="currentPanel === 'applyForWish'" id="missionApplication">
      <div style="font-size: 16px;color: black;text-align: center">申请探索任务</div>
      <div style="color: rgba(172, 177, 192, 1);font-size: 14px;text-align: center;margin-top: 13px">您的愿望在探索完毕后将及时与您获取联系</div>
      <div style="width: 100%;height: 1px;background: rgba(234, 234, 234, 1);margin-top: 16px"></div>
      <div style="width: calc(100% - 50px);height: 240px;padding-left: 25px;padding-right: 25px;margin-top: 26px">
        <div style="color: rgba(172, 177, 192, 1);font-size: 12px;text-align: left;margin-top: 13px">用户名</div>
        <input placeholder="请输入用户名" v-model="userNameForWish" class="inputStyle">
        <div class="wishDivider"></div>
        <div style="color: rgba(172, 177, 192, 1);font-size: 12px;text-align: left;margin-top: 26px">联系方式</div>
        <input placeholder="请输入联系方式" type="number" v-model="userPhoneForWish" class="inputStyle">
        <div class="wishDivider"></div>
        <div style="margin-top: 38px;height: 20px">
          <span class="iconfontFamily" style="float: left;color: #8b51ff;font-size:12px;line-height: 16px" >&#xe6a2;</span>
          <div style="color: rgba(172, 177, 192, 1);font-size: 12px;float: left;margin-left: 3px">{{userLocationForWish}}</div>
          <div @click="gotoSelectPosition" style="color: rgba(255, 143, 31, 1);font-size: 12px;float: right">目标地点选择</div>
        </div>
        <div @click="applyForWish" id="applicationBtn">申请任务</div>
      </div>
      <div v-show="showCompleteInfoHint" class="snackBar completeInfoHintPosition">请输入完整的申请信息</div>
    </div>
    <div v-show="currentPanel === 'chooseLocation'" style="position: absolute;bottom: 380px;right: 15px;font-size: 14px;color: white;text-shadow: 0px 1px 1px  rgba(0, 0, 0, 0.9);text-align: right">当前位置：{{currentLocation}}</div>
    <div id="choosePositionPanel" v-show="currentPanel === 'chooseLocation'">
      <div style="width: 100%;height: 35px">
        <div @click="cancelLocation" style="float: left;font-size: 16px;color: black">取消</div>
        <div @click="confirmLocation" style="float: right;font-size: 16px;color: rgba(255, 141, 26, 1)">确定</div>
      </div>
      <div style="width: 100%;height: 44px;background: rgba(247, 248, 250, 1);border-radius: 5px">
        <span class="iconfontFamily" style="float: left;margin-left: 15px;color: rgba(196, 196, 196, 1);font-size:14px;line-height: 44px" >&#xe76e;</span>
        <input v-model="keyWord" v-on:input="getLocations" id="searchInput" type="text" placeholder="搜索地点">
      </div>
      <div v-show="keyWord.length < 1" style="width: 100%;height: 240px;color: rgba(166, 166, 166, 1);font-size: 14px;text-align: center;line-height: 240px">
        未搜索到相关关键词，请尝试更换关键词</div>
      <div v-show="keyWord.length >0" style="height: 260px;overflow: auto">
        <div v-for="(item,index) in this.locationList" :key='index' style="width: 100%;">
          <div @click="clickItem(index)" style="width: 100%;height: 65px;display: flex">
            <div style="width: 95%">
              <div style="font-size: 14px;color: rgba(0, 0, 0, 1);margin-top: 12px;text-overflow: ellipsis;-webkit-line-clamp: 1" :style="{'color':currentLocationIndex === index ? 'rgba(255, 141, 26, 1)':'rgba(0, 0, 0, 1)'}">{{item.name}}</div>
              <div style="font-size: 12px;color: rgba(166, 166, 166, 1);margin-top: 2px;text-overflow: ellipsis;-webkit-line-clamp: 1"
                   :style="{'color':currentLocationIndex === index ? 'rgba(255, 141, 26, 0.6)':'rgba(0, 0, 0, 1)'}">{{item.address}}</div>
            </div>
            <div v-show="currentLocationIndex === index" style="float: right">
              <span class="iconfontFamily" style="float: left;color: rgba(255, 141, 26, 1);font-size:14px;line-height: 65px" >&#xe621;</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TopShareBarWithBack  v-if="share === '1'"/>
  </div>
</template>

<script>

import mapboxgl from "mapbox-gl";
import tipsPng from '@/assets/ChoosePositionGlobe/tips.png'
import getCurrentCityName from "@/plugins/baiduMapApi";
import TopShareBarWithBack from "@/components/TopShareBarWithBack";

let panby = (window.screen.height - 350)/2

export default {
  name: "ChoosePositionGlobe",
  components:{
    TopShareBarWithBack
  },
  data() {
    return {
      map:'',
      index: 0,
      share:0,
      layerInfo:{},
      baseStyleInfo:{},
      currentPanel:'applyForWish',
      // currentPanel:'chooseLocation',
      userNameForWish:'',
      userPhoneForWish:'',
      userLocationForWish:'请选择愿望目标地点',
      keyWord:'',
      userLat:'43',
      userLng:'125',
      locationList:[],
      currentLocationIndex:-1,
      currentPOI:{},
      currentLocation:'',
      showCompleteInfoHint:false,
      token:"",
      avatar:'',
      wishContent:'',
      member:"0",
      infoId:'',
    }
  },
  mounted() {
    window.applyForWish = this;
    this.share = this.$route.query.share
    this.infoId = this.$route.query.infoId
    if(this.$route.query.share === '0'){
      //来自app
      this.wishContent = this.$route.query.wishContent
      this.token = this.$route.query.token
      this.userNameForWish = this.$route.query.nickName
      this.userPhoneForWish = this.$route.query.phone
      this.member = this.$route.query.member
    }else{
      //来自h5
      this.wishContent = this.$store.state.wishContent
      this.token = this.$store.state.token
      this.userNameForWish = this.$store.state.nickName
      this.userPhoneForWish = this.$store.state.phone
      this.member = this.$store.state.member
    }
    this.getGlobeLayerInfo()
  },
  methods: {
    goToLogin(){
      this.$router.push(
          {
            path: '/Login',
          }
      )
    },
    gotoSelectPosition(){
      this.currentPanel = 'chooseLocation'
      this.getGeoByLnglat(this.userLat,this.userLng)
      this.currentPOI = {}
    },
    getInitialLocation(mode){
      if(mode === 'city'){
        this.$http.get('/authorApi/POI/searchPoiList?keyWord='+this.keyWord+'&lat='+ this.userLat + '&lon=' + this.userLng).then((res)=>{
          this.userLocationForWish = res.body.result.city
        })
      }else{
        this.$http.get('/authorApi/POI/searchPoiList?keyWord='+this.keyWord+'&lat='+ this.userLat + '&lon=' + this.userLng).then((res)=>{
          this.userLocationForWish = res.body.result.pois[0].name
        })
      }
    },
    clickItem(index){
      this.currentLocationIndex = index
      this.currentLocation =  this.locationList[index].name
      this.addTipsPositionPoint(this.locationList[index].location.lng,this.locationList[index].location.lat,12,panby)
      this.currentPOI = this.locationList[this.currentLocationIndex]
    },
    getLocations(){
      if(this.keyWord.length < 1){
        this.locationList = []
        return
      }
     this.$http.get('/earthApi/POI/search?keyWord='+this.keyWord+'&lat='+ this.userLat + '&lon=' + this.userLng).then((res)=>{
       this.locationList = res.body.result
       this.currentLocationIndex = -1
       // if(this.currentPOI !== {}){
       //    for(let i = 0;i < this.locationList.length;i++){
       //      if(this.currentPOI.uid === this.locationList[i].uid){
       //        this.currentLocationIndex = i
       //      }
       //    }
       // }
     })
    },
    cancelLocation(){
      this.currentPanel = 'applyForWish'
      this.keyWord = ''
      this.currentLocation = ''
      this.locationList = []
      this.currentLocationIndex = -1
    },
    confirmLocation(){
      // this.currentLocationIndex < 0:搜索没选中
      console.log(this.currentLocationIndex < 0)
      console.log(this.currentPOI !== {})
      console.log(this.currentPOI)
      if(this.currentLocationIndex < 0 ){
        if(this.currentPOI !== {}){
          this.userLocationForWish = this.currentPOI.name
          this.currentPanel = 'applyForWish'
          this.keyWord = ''
          this.locationList = []
          this.userLng = this.currentPOI.location.lng
          this.userLat = this.currentPOI.location.lat
          this.currentLocationIndex = -1
          this.currentPOI = {}
        }
      }else{
        this.userLocationForWish = this.currentPOI.name
        this.currentPanel = 'applyForWish'
        this.keyWord = ''
        this.locationList = []
        this.userLng = this.currentPOI.location.lng
        this.userLat = this.currentPOI.location.lat
        this.currentLocationIndex = -1
        this.currentPOI = {}
      }
      // this.userLocationForWish = this.currentPOI.name
      // this.currentPanel = 'applyForWish'
      // this.keyWord = ''
      // this.locationList = []
      // this.userLng = this.currentPOI.location.lng
      // this.userLat = this.currentPOI.location.lat
      // this.currentLocationIndex = -1
      // this.currentPOI = {}
    },
    applyForWish(){
      if(this.userNameForWish.length < 1 || this.userPhoneForWish.length < 1 || this.userLocationForWish === '请选择愿望目标地点'){
        this.showCompleteInfoHint = true
        setTimeout(()=>{
          this.showCompleteInfoHint = false
        },2000)
        return
      }
      let data = new FormData()
      data.append('wishContent',this.wishContent)
      data.append('writeName',this.userNameForWish)
      data.append('writePhone',this.userPhoneForWish)
      data.append('lat',this.userLat)
      data.append('lng',this.userLng)
      data.append('geoName',this.userLocationForWish)
      this.$http.post('/earthApi/sa-user-wish/addSaUserWish',data,{headers:{'Authorization':this.token}}).then(response=> {
        if(response.data.status === 200){
            console.log(response.data)
            if(this.member === "0"){
              if(this.share === '1'){
                this.$router.push(
                    {
                      path: '/MemberActivation',
                      query: {
                        share:1,
                        from:'createWish',
                        infoId: this.infoId,
                      }
                    }
                )
              }else{
                if(window["GotoMemberActivation"]){
                  window["GotoMemberActivation"].postMessage(this.infoId);
                }
              }

            }else{
              if(this.share === '1'){
                this.$router.push(
                    {
                      path: '/MyWish',
                      query: {
                        share:1,
                        infoId: this.infoId,
                      }
                    }
                )
              }else{
                if(window["GotoMyWish"]){
                  window["GotoMyWish"].postMessage(this.infoId);
                }
              }

            }
        }
      })
    },
    getGeoByLnglat(lat,lng){
      //获取地址信息
      this.$http.get("/authorApi/POI/searchFromLocation",{
        params:{
          lat:lat,
          lon:lng
        }
      }).then((resultData)=>{
        resultData = resultData.data;
        // console.log(resultData)
        if(resultData.message === "ok"){
          this.currentLocation = resultData.result.name;
          this.currentPOI = {
            name:resultData.result.name,
            location:{
              lng:lng,
              lat:lat
          }
        }
        }
      })
    },
    getGlobeLayerInfo(){
      this.layerInfo = {
        "available": true,
        "code": "共生地球-GS吉(2023)011号",
        "image": this.globalJS.china1mUrl_2022,
        "introduce": "最新图层 色彩真实 超赞细节！",
        "layer": "china2022",
        "layerUrl": this.globalJS.china1mUrl_2022,
        "name": "2022年全国一张图",
        "outLink": "https://g.h5gdsvip.com/p/yqsfikeh",
        "picture": "https://market.obs.cn-north-4.myhuaweicloud.com/202212281620000347996.png",
        "sort": 1,
        "status": 0,
        "thumb": "https://cgwxforum.obs.cn-north-4.myhuaweicloud.com:443/202212141630000227831_thumb.jpg",
        "tms": true,
        "type": "cgwx"
      };
      this.getBaseStyleInfo()
    },
    async getBaseStyleInfo() {
      let response = await this.$http.get('/earthApi/main-info/getBaseStyleInfo');
      if(response.data.status === 200){
        this.baseStyleInfo = response.data.data;
      }
      this.init();
    },
    init() {
      mapboxgl.accessToken = this.baseStyleInfo["token"];
      // mapboxgl.accessToken = this.globalJS.mapboxToken;
      this.map = new mapboxgl.Map({
        container: 'map',
        center:[100, 31],
        zoom: 12,
        maxZoom: 17,
        minZoom: 0.8,
        attributionControl: false,
        style: this.baseStyleInfo.styleUrl,
        // interactive: false,
        trackResize: false,
        projection: {name:'globe'},
        transformRequest: (url) => {
          if (url.indexOf('https://api.mapbox.com') > -1) {
            return {
              url: url.replace('https://api.mapbox.com', 'https://earth.jl1.cn/mapbox'),
            };
          }
        }
      });
      let _this = this
      this.map.on('load', () => {
        this.map.setFog({
          "horizon-blend": 0.1,
          "space-color": 'rgba(0, 0, 0, 1)',
          "star-intensity": 0.15
        })
        this.map.addLayer({
          id: 'sky',
          type: 'sky',
          paint: {
            'sky-type': 'atmosphere',
            'sky-atmosphere-sun': [0.0, 0.0],
            'sky-atmosphere-sun-intensity': 15,
          }
        });
        this.load()
      });
      // 监听地图点击事件
      this.map.on('click', function(e) {
        // 获取点击的坐标信息
        const lngLat = e.lngLat;
        // 在控制台输出坐标信息
        if(_this.currentPanel === 'chooseLocation'){
          _this.showTipsPositionIcon(lngLat.lng,lngLat.lat);
          // _this.addTipsPositionPoint(lngLat.lng,lngLat.lat,12,panby)
          _this.userLat = lngLat.lat
          _this.userLng = lngLat.lng
          _this.getGeoByLnglat(lngLat.lat,lngLat.lng)
          _this.currentLocationIndex = -1
          // if(_this.keyWord !== ''){
          //   _this.getLocations()
          // }
        }
      });
    },
    load() {
      if(!this.layerInfo.tms){
        this.map.addSource(this.layerInfo.layer, {
          type: 'raster',
          tiles: [
            this.layerInfo.layerUrl
          ],
          tileSize: 256,
        });
      }
      else{
        //tms
        this.map.addSource(this.layerInfo.layer, {
          type: 'raster',
          tiles: [
            this.layerInfo.layerUrl
          ],
          tileSize: 256,
          scheme: 'tms',
        });
      }
      this.map.addLayer({
        id: this.layerInfo.layer,
        type: 'raster',
        source: this.layerInfo.layer,
      }, this.baseStyleInfo.topLayerId);
      this.getLocation()
    },
    getLocation(){
      let L = (typeof window !== "undefined" ? window['L'] : typeof global !== "undefined" ? global['L'] : null)
      if(L.Browser.mobile){
        this.getLocationByH5()
      }else{
        this.getLocationByBaiduAPI()
      }
    },
    getLocationByH5(){
      //使用h5自带的定位，只能在https或localhost使用，chrome浏览器不支持，edge可用，其他没实验
      if("geolocation" in navigator) {
        // console.log("支持定位")
        let _this = this
        navigator.geolocation.getCurrentPosition(function(position){
          // console.log("获取定位成功")
          let lat = position.coords.latitude;
          let lng = position.coords.longitude;
          // console.log( "您所在的位置： 经度" + lng + "，纬度" + lat)
          _this.map.flyTo({
            center:[lng,lat],
            zoom: 10}
          )
          _this.userLat = lat
          _this.userLng = lng
          _this.map.panBy([0, panby],{
            duration: 0
          });
          _this.getInitialLocation("city")
        },function(){
          // console.log("获取定位失败")
          _this.getLocationByBaiduAPI()
        },{timeout: 3000, enableHighAccuracy: true, maximumAge: 75000})
      }
      else {
        this.getLocationByBaiduAPI()
        alert("无法进行定位，很遗憾您的浏览器不支持Geolocation API功能");
      }

    },
    getLocationByBaiduAPI(){
      // 百度api定位
      getCurrentCityName.init().then(BMap=>{
        const geolocation=new BMap.Geolocation();
        let _this =this;
        geolocation.getCurrentPosition(function(position){
          _this.map.jumpTo({
            center:[parseFloat(position.longitude),parseFloat(position.latitude)],
            zoom: 13}
          )
          _this.map.panBy([0, panby],{
            duration: 0
          });
          _this.userLat = position.latitude
          _this.userLng = position.longitude
          let lat = parseFloat(position.latitude)
          _this.getInitialLocation("city")
          _this.showTipsPositionIcon(position.longitude, lat);
        },function(){
        })
      })
    },
    //添加tips选择定位的点(被flutter调用)
    addTipsPositionPoint(lng, lat, zoom, panPixel){
      if (zoom === -1){
        this.map.easeTo({center: [parseFloat(lng), parseFloat(lat)], duration: 0});
      } else {
        this.map.easeTo({center: [parseFloat(lng), parseFloat(lat)], zoom: parseFloat(zoom), duration: 0});
      }
      this.map.panBy([0, panPixel],{
        duration: 0
      });
      this.showTipsPositionIcon(lng, lat);
    },
    showTipsPositionIcon(lng, lat){
      this.deleteTipsPositionPoint();
      let _this = this
      this.map.loadImage(
          tipsPng,
          function(error,image){
            if(error) throw error;
            _this.map.addImage("tipsIcon",image);
          }
      )
      this.map.addSource('tipsPositionPoint', {
        type: 'geojson',
        data: {
          'type': 'FeatureCollection',
          'features': [{
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [lng, lat]
            },
            //使用图片做point
            properties: {
              icon: "tipsIcon",
            },
          }]
        }
      });
      this.map.addLayer({
        id: 'tipsPositionPoint',
        type: 'symbol',
        source: 'tipsPositionPoint',
        layout: {
          "icon-image": ["get", "icon"],
          "icon-size": 0.4,//图标的大小
        }
      });
    },
    //删除tips选择定位的点
    deleteTipsPositionPoint(){
      if(this.map.getLayer("tipsPositionPoint") !== undefined){
        this.map.removeLayer("tipsPositionPoint");
        this.map.removeSource("tipsPositionPoint");
      }
    },
  },

}
</script>

<style scoped lang="scss">

.applyForWish {
  height: 100%;
  width: 100%;
  background-color: black;
  display: flex;
  align-items: center;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#missionApplication{
  background-color: white;
  width: calc(100% - 40px);
  height: 433px;
  position: absolute;
  bottom: 0;
  border-radius: 16px 16px 0 0;
  //box-shadow: 0 -5px 10px  rgba(0, 0, 0, 0.1);
  padding: 17px 20px 0 20px;
}

#applicationBtn{
  margin: 40px auto 0 auto;
  width:280px;
  height: 52px;
  background:linear-gradient(90deg, rgba(255, 201, 125, 1) 0%, rgba(254, 209, 80, 1) 100%);
  border-radius: 26px;
  color: rgba(219, 110, 0, 1);
  font-size:18px;
  text-align: center;
  line-height: 52px;
  cursor: default;
}

.inputStyle{
  border: none;
  outline: none;
  width: 100%;
  height: 48px;
  font-size: 14px;
  color: rgba(255, 143, 31, 1)
}

.inputStyle::-webkit-input-placeholder{
  color: rgba(219, 110, 0, 0.5);
  font-size: 14px;
}

.wishDivider{
  width: 100%;
  height: 1px;
  background:linear-gradient(360deg, rgba(255, 201, 125, 1) 100%, rgba(254, 209, 80, 1) 100%);
}

#choosePositionPanel{
  background-color: white;
  width: calc(100% - 40px);
  height: 350px;
  position: absolute;
  bottom: 0;
  border-radius: 16px 16px 0 0;
  //box-shadow: 0 -5px 10px  rgba(0, 0, 0, 0.1);
  padding: 17px 20px 0 20px;
}

#searchInput{
  border: none;
  outline: none;
  margin-left: 10px;
  margin-top: 1px;
  height: 36px;
  line-height: 42px;
  font-size: 14px;
  width: calc(100% - 60px);
  background: rgba(247, 248, 250, 1);
}

#searchInput::-webkit-input-placeholder{
  color: rgba(166, 166, 166, 1);
  font-size: 14px;
}

::-webkit-scrollbar {
  display: none;
}

.snackBar{
  width: 280px;
  height: 36px;
  background:rgba(255, 141, 26, 1);
  font-size: 14px;
  color: white;
  text-align: center;
  line-height: 36px;
  border-radius: 3px;
}

.completeInfoHintPosition{
  position: absolute;
  top: 9px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
