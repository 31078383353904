<template>
  <div class="editWish alibabaPuHuiTiFontFamily">
    <div class="editWishTitle">编辑愿望内容</div>
    <div class="editWishSubTitle">您的愿望将前往太空</div>
    <div class="editWishSubTitle" style="margin-top: 10px;">在
      <span style="color:rgba(255, 143, 31, 1) ">高分03A</span>
      的带领下围绕地球浏览风景</div>
    <div id="editCard">
      <div style="margin:0 17px 30px 30px;height: 50px;width: calc(100% - 47px)">
        <div style="display: flex;justify-content: space-between">
          <div style="display: flex">
            <img style="width: 38px;height: 38px;border-radius: 50%" :src="avatar">
            <div style="margin-left: 12px;margin-top: 5px">{{nickName}}</div>
          </div>

          <img style="width: 48px;height: 48px;margin-top: -2px;float: right" src="../../../../../assets/Satellite/ActivityFour/JL03.jpg">
        </div>
      </div>
      <!--文本输入-->
      <div id="editWishZone">
        <textarea v-model="wishContent" rows="7" maxlength="100" placeholder="点击输入愿望内容" id="wishContentArea"></textarea>
        <div class="EWBottomLine" style="top: 37px"></div>
        <div class="EWBottomLine" style="top: 75px"></div>
        <div class="EWBottomLine" style="top: 113px"></div>
        <div class="EWBottomLine" style="top: 151px"></div>
        <div class="EWBottomLine" style="top: 189px"></div>
        <div class="EWBottomLine" style="top: 228px"></div>
        <div class="EWBottomLine" style="top: 266px"></div>
      </div>
      <div v-show="emptyHint" class="snackBar emptyHintPosition">请输入祈愿内容</div>
    </div>
    <div style="position: relative;margin-bottom: 35px">
      <div id="textAreaCounter">{{wishContent.length}}/100</div>
      <div style="clear: both;"></div>
      <div class="nextStepBtn" @click="nextStep">下一步</div>
    </div>
    <TopShareBarWithBack  v-if="share === '1'"/>
  </div>
</template>

<script>
import TopShareBarWithBack from "@/components/TopShareBarWithBack";

export default {
  name: "EditWishContent",
  components:{
    TopShareBarWithBack
  },
  mounted() {
    this.share = this.$route.query.share;
    this.infoId = this.$route.query.infoId
    if(this.$route.query.share === '0'){
      //来自app
      this.nickName = this.$route.query.nickName
      this.avatar = this.$route.query.avatar
    }else{
      this.nickName = this.$store.state.nickName
      this.avatar = this.$store.state.avatar
    }
  },
  data(){
    return{
      share:undefined,
      wishContent:'',
      emptyHint:false,
      nickName:'',
      phone:"",
      avatar:'',
      infoId:''
    }
  },
  methods:{
    nextStep(){
      if(this.wishContent.length < 1){
        this.emptyHint = true
        setTimeout(()=>{
          this.emptyHint = false
        },2000)
        return
      }
      if(this.share === '1'){
        this.$router.push(
            {
              path: '/ApplyForWIsh',
              query: {
                share:1,
                infoId: this.infoId,
              }
            }
        )
      }else if(this.share === '0'){
        if(window["GotoApplyForWish"]){
          window["GotoApplyForWish"].postMessage(
              JSON.stringify({
                'infoId':this.infoId,
                'wishContent':this.wishContent,
              })
          );
        }
      }
      this.$store.commit("setWishContent",this.wishContent)
    }
  }
}
</script>

<style scoped>

  .editWishTitle{
    margin-top: 15%;
    text-align: center;
    font-weight: 900;
    color: white;
    font-size: 24px
  }

  .editWishSubTitle{
    margin-top: 10%;
    text-align: center;
    font-weight: 500;
    color: white;
    font-size: 16px
  }

  #editCard{
    padding-top:27px;
    margin: 7% auto 0 auto;
    width: 310px;
    height: 400px;
    background: rgba(255, 236, 210, 1);
    border-radius: 16px;
    position: relative;
  }

  textarea::-webkit-input-placeholder{
    color: rgba(219, 110, 0, 0.5);
    font-size: 16px;
  }

  .editWish{
    background-image: url(../../../../../assets/Satellite/ActivityFour/myWish_bg.jpg);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  }

  #wishContentArea{
    font-size: 16px;
    line-height: 38px;
    color: rgba(219, 110, 0, 0.5);
    height: 266px;width:calc(100% - 4px);
    background-color: transparent;
    border-radius: 16px;
    outline: none;
    border: none;
    resize: none
  }

  #editWishZone{
    margin:0 auto;
    width: calc(100% - 60px);
    height: 266px;
    position: relative
  }

  .EWBottomLine{
    width:calc(100% - 4px);
    height: 1px;
    background-color: rgba(214, 214, 214, 1);
    position: absolute;
  }

  #textAreaCounter{
    color: white;
    float: right;
    margin-top: 15px;
    margin-right: 70px;
  }

  .nextStepBtn{
    margin: 10% auto 0 auto;
    width:280px;
    height: 52px;
    background:linear-gradient(90deg, rgba(255, 201, 125, 1) 0%, rgba(254, 209, 80, 1) 100%);
    border-radius: 26px;
    color: rgba(219, 110, 0, 1);
    font-size:18px;
    text-align: center;
    line-height: 52px;
    cursor: default;
  }

  .snackBar{
    width: 280px;
    height: 36px;
    background:rgba(255, 141, 26, 1);
    font-size: 14px;
    color: white;
    text-align: center;
    line-height: 36px;
    border-radius: 3px;
  }

  .emptyHintPosition{
    position: absolute;
    top: 9px;
    left: 50%;
    transform: translateX(-50%);
  }

</style>