
<!--<template>-->
<!--  &lt;!&ndash;长图&ndash;&gt;-->
<!--  <img style="width: 100%; margin-bottom: -4px" src="../assets/app.jpg" />-->
<!--</template>-->

<!--<script>-->
<!--export default {-->
<!--  name: "App",-->

<!--}-->

<!--</script>-->






<template>
  <!--长图-->
<!--    <img style="width: 100%; margin-bottom: -4px" src="../assets/app.jpg" />-->

  <div ref="appPage">

    <!--web端-->
    <div v-if="device==='web'" class="appPage">
      <!--顶bar-->
      <app-page-top-bar fromPage="app"></app-page-top-bar>

      <!--第一屏 轮播-->
      <div id="appScreen1" class="screen">
        <swiper :options="screen1SwiperOption" style="height:100%;">
          <swiper-slide v-for=" i in 2" :key="i">
            <!--轮播项1-->
            <div v-if="i===1" style="width:100%;height:100%;display:flex;align-items:center;">
              <div :style="{'min-width':webScreenSize === 'big' ? '500px' : '440px'}" style="width:40%;">
                <div :style="{'width':webScreenSize === 'big' ? '440px' : '380px'}" style="position:relative;left:50%;transform:translate(-50%);height:100%;">
                  <p :style="{'font-size':webScreenSize === 'big' ? '50px' : '42px'}" style="line-height:57px;font-weight:bold;" class="appPageColorTitle">吉林一号·共生地球</p>
                  <p :style="{'font-size':webScreenSize === 'big' ? '24px' : '20px'}" style="margin-top:39px;line-height:29px;font-weight:bold;">连接太空卫星，探索地球边界！</p>
                  <div style="margin-top:100px;display:flex;">
                    <div style="width:145px;">
                      <div @click="gotoAppStore" style="width:100%;height:45px;border:1px solid rgba(138, 138, 138, 1);border-radius:4px;
              display:flex;align-items:center;cursor:pointer;">
                        <p style="margin-left:12px;font-size:26px;" class="iconfontFamily">&#xe78a;</p>
                        <p style="margin-left:14px;font-size:16px;">App Store</p>
                      </div>
                      <div @click="gotoAndroid" style="width:100%;height:45px;border:1px solid rgba(138, 138, 138, 1);border-radius:4px;margin-top:8px;
              display:flex;align-items:center;cursor:pointer;">
                        <p style="margin-left:12px;font-size:26px;" class="iconfontFamily">&#xe78c;</p>
                        <p style="margin-left:20px;font-size:16px;">Android</p>
                      </div>
                    </div>
                    <img style="width:102px;height:102px;margin-left:17px;" src="../assets/App/appCode.png" />
                  </div>
                </div>
              </div>
              <div style="flex:1;height:100%;display:flex;align-items:center;justify-content:center;">
                <img :style="{'width':webScreenSize === 'big' ? '80%' : '90%'}" src="../assets/App/appPage/screen1/swiper1Img.png" />
              </div>
            </div>
            <!--轮播项2-->
            <div v-if="i===2" style="width:100%;height:100%;display:flex;align-items:center;">
              <div :style="{'min-width':webScreenSize === 'big' ? '660px' : '560px'}" style="width:40%;">
                <div :style="{'width':webScreenSize === 'big' ? '590px' : '510px'}" style="position:relative;left:50%;transform:translate(-50%);height:100%;">
                  <p :style="{'font-size':webScreenSize === 'big' ? '50px' : '42px'}" style="line-height:57px;font-weight:bold;" class="appPageColorTitle">吉林一号·共生地球web端</p>
                  <p :style="{'font-size':webScreenSize === 'big' ? '24px' : '20px'}" style="margin-top:39px;line-height:29px;font-weight:bold;">团队协同多端分享，发现更多精彩！</p>
                  <div @click="intoAuthor" style="height:32px;margin-top:120px;padding:0 15px;border:1px solid #ffffff;border-radius:16px;float:left;cursor:pointer;">
                    <p style="line-height:32px;">https://earth.jl1.cn/tools/</p>
                  </div>
                </div>
              </div>
              <div style="flex:1;height:100%;display:flex;align-items:center;justify-content:center;">
                <img :style="{'width':webScreenSize === 'big' ? '100%' : '100%'}" src="../assets/App/appPage/screen1/swiper2Img.png" />
              </div>
            </div>


          </swiper-slide>
          <div class="screen1Swiper" slot="pagination"></div>
        </swiper>

      </div>

      <!--第二屏-->
      <div id="appScreen2" style="position:relative;" class="screen">
        <div :style="{'width':webScreenSize === 'big' ? '1160px' : '830px'}"
             style="position:relative;top:50%;left:0;transform:translate(0,-50%);margin:0 auto;display:flex;align-items:center;">
          <div :style="{'width':webScreenSize === 'big' ? '500px' : '360px'}">
            <img id="appScreen2_img" class="floatAnimationDom" style="width:100%;" src="../assets/App/appPage/screen2/img.png" />
          </div>
          <div :style="{'width':webScreenSize === 'big' ? '460px' : '390px','margin-left':webScreenSize === 'big' ? '120px' : '80px'}" style="position:relative;">
            <p id="appScreen2_title" class="floatAnimationDom screenTitle appPageColorTitle">共生地球APP</p>
            <div id="appScreen2_ps" class="floatAnimationDom">
              <p style="margin-top:35px;" class="screenPs">立体呈现地球脉动，全球热点资讯实时跟踪</p>
              <p class="screenPs">遥感图像前后比对，卫星星座直击事件进展</p>
              <p class="screenPs">一键分享生活，记录地球美好瞬间</p>
              <div @click="gotoProductPage('/ProductPageApp')" style="margin-top:35px;float:left" class="btn_outline">
                <p>了解详情</p>
              </div>
            </div>
          </div>
        </div>

        <!--底部渐变-->
        <div style="position:absolute;bottom:0;left:0;width:100%;height:120px;background:linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);"></div>

      </div>

      <!--第三屏-->
      <div id="appScreen3" style="position:relative;" class="screen">
        <div :style="{'width':webScreenSize === 'big' ? '1300px' : '970px'}"
             style="position:relative;top:50%;left:0;transform:translate(0,-50%);margin:0 auto;display:flex;align-items:center;">
          <div :style="{'width':webScreenSize === 'big' ? '520px' : '440px'}" style="position:relative;text-align:right;">
            <p id="appScreen3_title" class="floatAnimationDom screenTitle appPageColorTitle">共生地球web端</p>
            <div id="appScreen3_ps" class="floatAnimationDom">
              <p style="margin-top:35px;" class="screenPs">丰富的地理探索工具，助力户外探索与测绘规划</p>
              <p class="screenPs">场景丰富的地球录制特效，一键实现地球漫游</p>
              <p class="screenPs">打造一站式地理信息服务平台</p>
              <div @click="gotoProductPage('/ProductPageWebMarker')" style="margin-top:29px;float:right;" class="btn_outline">
                <p>了解详情</p>
              </div>
            </div>
          </div>
          <div :style="{'width':webScreenSize === 'big' ? '660px' : '450px','margin-left':webScreenSize === 'big' ? '120px' : '80px'}">
            <img id="appScreen3_img" class="floatAnimationDom" style="width:100%;" src="../assets/App/appPage/screen3/img.png" />
          </div>
        </div>
        <!--顶部渐变-->
        <div style="position:absolute;top:0;left:0;width:100%;height:113px;background:linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);"></div>
        <!--底部渐变-->
        <div style="position:absolute;bottom:0;left:0;width:100%;height:120px;background:linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);"></div>

      </div>

      <!--第四屏-->
      <div id="appScreen4" style="position:relative;" class="screen">
        <div :style="{'width':webScreenSize === 'big' ? '1300px' : '970px'}"
             style="position:relative;top:50%;left:0;transform:translate(0,-50%);margin:0 auto;display:flex;align-items:center;">
          <div :style="{'width':webScreenSize === 'big' ? '660px' : '450px'}">
            <img id="appScreen4_img" class="floatAnimationDom" style="width:100%;" src="../assets/App/appPage/screen4/img.png" />
          </div>
          <div :style="{'width':webScreenSize === 'big' ? '520px' : '440px','margin-left':webScreenSize === 'big' ? '120px' : '80px'}" style="position:relative;">
            <p id="appScreen4_title" class="floatAnimationDom screenTitle appPageColorTitle">遥感影像库</p>
            <div id="appScreen4_ps" class="floatAnimationDom screenPs">
              <p style="margin-top:35px;" class="screenPs">“吉林一号”星座独家提供最新遥感图源</p>
              <p class="screenPs">精选海量卫星影集，官方授权品质保障</p>
              <p class="screenPs">随时随地查看极致地球风景</p>
              <div @click="gotoProductPage('/ProductPageImageLib')" style="margin-top:29px;float:left" class="btn_outline">
                <p>了解详情</p>
              </div>
            </div>
          </div>
        </div>

        <!--顶部渐变-->
        <div style="position:absolute;top:0;left:0;width:100%;height:113px;background:linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);"></div>

        <!--底部渐变-->
        <div style="position:absolute;bottom:0;left:0;width:100%;height:120px;background:linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);"></div>


      </div>

      <!--第五屏-->
      <div id="appScreen5" class="screen">
        <div style="position:relative;top:50%;left:0;transform:translate(0,-50%);width:100%;">
          <p style="width:100%;text-align:center;" class="screenTitle appPageColorTitle">吉林一号·特色服务</p>

          <!--切换tab-->
          <div style="position:relative;left:50%;transform:translate(-50%,0);width:400px;height:52px;margin-top:36px;padding:0 2px;background:rgba(255, 255, 255, 0.23);border-radius:30px;
        font-size:18px;display:flex;align-items:center;justify-content:space-around;">
            <div id="screen5TabChecked" style="position:absolute;top:5px;left:6px;width:120px;height:42px;border-radius:30px;background:rgba(42, 130, 228, 1);
          animation-duration:200ms;animation-fill-mode: forwards;"></div>
            <p @click="clickScreen5TabItem(item)" v-for="(item,index) in screen5TabList" :key="'screen5Tab'+index" style="z-index:1;cursor:pointer;">{{item}}</p>
          </div>

          <!--第一项tab-->
          <div v-show="screen5CurrTab === '卫星观察'" :style="{'width':webScreenSize === 'big' ? '1200px' : '980px'}" style="margin:80px auto 0 auto;display:flex;align-items:center;">
            <div :style="{'width':webScreenSize === 'big' ? '320px' : '280px'}" style="position:relative;text-align:right;">
              <p id="appScreen5_1_title" :style="{'font-size':webScreenSize === 'big' ? '38px' : '34px'}" class="floatAnimationDom">卫星观察时刻</p>
              <div id="appScreen5_1_ps" class="floatAnimationDom">
                <p style="margin-top:20px;" class="screenPs">新闻纵横 卫星追踪</p>
                <p class="screenPs">卫星视角 看世界新闻动态</p>
                <p class="screenPs">掌握一手资讯 尽在卫星观察</p>

                <p style="margin-top:35px;" class="screenPs">敬请期待</p>

<!--                <div style="margin-top:35px;float:right;text-align:center;">-->
<!--                  <img style="width:78px;height:78px;" src="../assets/logo.png" />-->
<!--                  <p style="margin-top:5px;">扫码体验</p>-->
<!--                </div>-->
              </div>
            </div>
            <div :style="{'width':webScreenSize === 'big' ? '740px' : '600px','margin-left':webScreenSize === 'big' ? '140px' : '100px'}">
              <video id="appScreen5_1_img" class="floatAnimationDom" controls="controls"
                     style="width:100%;border-radius:8px;" autoplay loop muted>
                <source src="https://market.obs.cn-north-4.myhuaweicloud.com:443/202405291133000295884.mp4" type="video/mp4" />
              </video>
            </div>

          </div>

          <!--第二项tab-->
          <div v-show="screen5CurrTab === '地球星评官'" :style="{'width':webScreenSize === 'big' ? '1200px' : '980px'}" style="margin:80px auto 0 auto;display:flex;align-items:center;">
            <div :style="{'width':webScreenSize === 'big' ? '320px' : '280px'}" style="position:relative;text-align:right;">
              <p id="appScreen5_2_title" class="floatAnimationDom" :style="{'font-size':webScreenSize === 'big' ? '38px' : '34px'}">最美地球评选</p>
              <div id="appScreen5_2_ps" class="floatAnimationDom">
                <p style="margin-top:20px;" class="screenPs">立即参与地球星评官活动</p>
                <p class="screenPs">领略不同视角下的图像魅力</p>
                <p class="screenPs">一起创造最美地球</p>

                <p style="margin-top:35px;" class="screenPs">敬请期待</p>
<!--                <div style="margin-top:35px;float:right;text-align:center;">-->
<!--                  <img style="width:78px;height:78px;" src="../assets/logo.png" />-->
<!--                  <p style="margin-top:5px;">扫码体验</p>-->
<!--                </div>-->
              </div>
            </div>
            <div :style="{'width':webScreenSize === 'big' ? '740px' : '600px','margin-left':webScreenSize === 'big' ? '140px' : '100px'}">
              <video id="appScreen5_2_img" class="floatAnimationDom" controls="controls"
                     style="width:100%;border-radius:8px;" autoplay loop muted>
                <source src="https://market.obs.cn-north-4.myhuaweicloud.com:443/202405291135000452919.mp4" type="video/mp4" />
              </video>
            </div>

          </div>

          <!--第三项tab-->
          <div v-show="screen5CurrTab === '卫星快闪'" :style="{'width':webScreenSize === 'big' ? '1200px' : '980px'}" style="margin:80px auto 0 auto;display:flex;align-items:center;">
            <div :style="{'width':webScreenSize === 'big' ? '320px' : '280px'}" style="position:relative;text-align:right;">
              <p id="appScreen5_3_title" class="floatAnimationDom" :style="{'font-size':webScreenSize === 'big' ? '38px' : '34px'}">卫星快闪进行时</p>
              <div id="appScreen5_3_ps" class="floatAnimationDom">
                <p style="margin-top:20px;" class="screenPs">在净月潭、在国贸CBD</p>
                <p class="screenPs">来卫星打卡地 与众人一起欢聚</p>
                <p class="screenPs">从500公里高空记录美好瞬间</p>

                <p style="margin-top:35px;" class="screenPs">敬请期待</p>
<!--                <div style="margin-top:35px;float:right;text-align:center;">-->
<!--                  <img style="width:78px;height:78px;" src="../assets/logo.png" />-->
<!--                  <p style="margin-top:5px;">扫码体验</p>-->
<!--                </div>-->
              </div>
            </div>
            <div :style="{'width':webScreenSize === 'big' ? '740px' : '600px','margin-left':webScreenSize === 'big' ? '140px' : '100px'}">
              <video id="appScreen5_3_img" class="floatAnimationDom" controls="controls"
                     style="width:100%;border-radius:8px;" autoplay loop muted>
                <source src="https://market.obs.cn-north-4.myhuaweicloud.com:443/202405291145000019773.mp4" type="video/mp4" />
              </video>
            </div>

          </div>

        </div>

        <!--顶部渐变-->
        <div style="position:absolute;top:0;left:0;width:100%;height:113px;background:linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);"></div>

        <!--底部渐变-->
        <div style="position:absolute;bottom:0;left:0;width:100%;height:113px;background:linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);"></div>

      </div>

      <!--第六屏-->
      <div id="appScreen6" class="screen">
        <div style="width:100%;height:100%;padding-top:14vh;">
          <p style="width:100%;text-align:center;" class="screenTitle appPageColorTitle">吉林一号·品牌服务</p>
          <swiper :options="screen6SwiperOption" style="width:100%;height:calc(82vh - 48px);margin-top:42px;">
            <swiper-slide v-for="i in Math.ceil(screen6ContentList.length - 1)" :key="'screen6SwiperItem'+i">
              <div style="width:100%;height:calc(100% - 125px);display:flex;align-items:center;justify-content:space-evenly">
                <div v-for="j in [i-1,i]" :key="'screen6SwiperContentItem'+j"
                     :style="{'width':webScreenSize === 'big' ? screen6SwiperCurrIndex === j ? '46%' : '34%' : '46%'}"
                     style="height:100%;display:flex;flex-direction:column;justify-content:center;">
                  <img @click="screen6ImgClick(screen6ContentList[j].picture,screen6ContentList[j].href)" @mouseenter="screen6ImgMouseEnter(j)"
                       style="width:90%;margin:0 auto;border-radius:24px;cursor:pointer;" :src="screen6ContentList[j].picture" />
                  <p style="width:90%;margin:15px auto 0 auto;font-size:18px;">{{screen6ContentList[j].name}}</p>
                  <p style="width:90%;margin:8px auto 0 auto;font-size:16px;">{{screen6ContentList[j].ps}}</p>
                </div>
              </div>
            </swiper-slide>
            <div class="screen6Swiper" slot="pagination"></div>
          </swiper>

        </div>
      </div>

      <!--第七屏-->
      <div id="appScreen7" class="screen">
        <div style="position:relative;top:50%;left:0;transform:translate(0,-50%);width:100%;display:flex;flex-direction:column;align-items:center;">
          <p class="screenTitle appPageColorTitle">吉林一号海量卫星服务</p>
          <p style="margin-top:14px;line-height:46px;font-size:24px;">遥感星座&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;全天候关注地球</p>
          <!--卫星模型-->
          <img style="position:relative;top:0;width:300px;margin-top:100px;animation:verticalShakeAnimation 1500ms infinite;" src="../assets/App/appPage/screen7/img.png" />
          <!--数据-->
          <div style="margin-top:100px;width:100%;height:100px;display:flex;justify-content:center;">
            <div v-for="(item,index) in screen7DataList" :key="'screen7DataItem'+index"
                 style="width:310px;height:100%;display:flex;">
              <div style="flex:1;"></div>
              <div style="display:flex;flex-direction:column;">
                <p class="screen7DataNumber" :style="{'font-size':webScreenSize === 'big' ? '72px' : '52px'}" style="font-weight:bold;
              color:#ffffff;-webkit-text-fill-color: transparent;background:linear-gradient(180deg, rgba(201, 202, 203, 1) 0%, rgba(101, 102, 103, 1) 100%);">
                  {{item.number}}{{item.numberText}}
                </p>
                <p :style="{'font-size':webScreenSize === 'big' ? '24px' : '18px'}" style="color:rgba(141, 141, 141, 1);">{{item.ps}}</p>
              </div>
              <div style="flex:1;"></div>
            </div>

          </div>


        </div>
      </div>

      <!--第八屏-->
      <div id="appScreen8" style="display:flex;flex-direction:column;" class="screen">

        <div style="flex:1;">
          <div style="position:relative;top:60%;left:0;transform:translate(0,-50%);width:100%;display:flex;flex-direction:column;align-items:center;">
            <!--标题-->
            <p id="screen8Title" :style="{'font-size':webScreenSize === 'big' ? '60px' : '38px'}" style="font-weight:bold;color:#ffffff;
          -webkit-text-fill-color: transparent;background:linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(159, 160, 167, 1) 100%);">开启吉林一号·共生地球探索之旅</p>
            <!--按钮-->
            <div style="margin-top:59px;display:flex;align-items:center;">
              <div @click="gotoAppStore" style="width:135px;height:42px;border:1px solid rgba(138, 138, 138, 1);border-radius:4px;display:flex;align-items:center;cursor:pointer;">
                <p style="margin-left:12px;font-size:26px;" class="iconfontFamily">&#xe78a;</p>
                <p style="margin-left:8px;font-size:16px;">App Store</p>
              </div>
              <div @click="gotoAndroid" style="width:135px;height:42px;border:1px solid rgba(138, 138, 138, 1);border-radius:4px;margin-left:17px;display:flex;align-items:center;cursor:pointer;">
                <p style="margin-left:14px;font-size:26px;" class="iconfontFamily">&#xe78c;</p>
                <p style="margin-left:14px;font-size:16px;">Android</p>
              </div>
            </div>
          </div>
        </div>

        <!--底栏-->
        <div style="width:100%;height:364px;">
          <app-page-foot-bar :produceList="footBarProductList"></app-page-foot-bar>

        </div>

        <!--顶部渐变-->
        <div style="position:absolute;top:0;left:0;width:100%;height:113px;background:linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);"></div>


      </div>





      <!--全屏看图片-->
      <div @click="fullScreenImageShow=false;" v-if="fullScreenImageShow" style="position:fixed;top:0;left:0;width:100vw;height:100vh;background:rgba(0, 0, 0, 0.6);z-index:999;cursor:pointer;">
        <img style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);
      max-width:83%;max-height:83%;border-radius:24px;" :src="fullScreenImageSrc" />
      </div>



    </div>


    <!--phone-->
    <div v-if="device==='mobile'" style="padding-top:80px;" class="appPage">
      <!--顶栏-->
      <app-page-top-bar-mobile fromPage="app"></app-page-top-bar-mobile>

      <!--第一屏-->
      <div id="appScreen1_mobile" class="screen">
        <img style="position:absolute;right:0;top:165px;width:209px;" src="../assets/App/appPage/screen1/background_mobile.png">

        <div style="position:relative;width:calc(100% - 22px);margin-left:22px;padding-top:30px;">
          <p :style="{'font-size':mobileScreenSize === 'small' ? '32px' : '46px'}" class="appPageColorTitle_mobile">吉林一号·共生地球</p>
          <p :style="{'font-size':mobileScreenSize === 'small' ? '18px' : '24px'}" style="margin-top:11px;font-weight:bold;" class="appPageColorTitle_mobile">连接太空卫星，探索地球边界！</p>
          <div style="margin-top:30px;display:flex;">
            <div style="width:95px;">
              <div @click="gotoAppStore" style="width:100%;height:28px;border:1px solid rgba(138, 138, 138, 1);border-radius:4px;
              display:flex;align-items:center;">
                <p style="margin-left:8px;font-size:18px;" class="iconfontFamily">&#xe78a;</p>
                <p style="margin-left:8px;font-size:11px;">App Store</p>
              </div>
              <div @click="gotoAndroid" style="width:100%;height:28px;border:1px solid rgba(138, 138, 138, 1);border-radius:4px;margin-top:5px;
              display:flex;align-items:center;cursor:pointer;">
                <p style="margin-left:8px;font-size:18px;" class="iconfontFamily">&#xe78c;</p>
                <p style="margin-left:13px;font-size:11px;">Android</p>
              </div>
            </div>
            <img style="width:65px;height:65px;margin-left:12px;" src="../assets/App/appCode.png" />
<!--            <img style="width:65px;height:65px;margin-left:12px;" src="../assets/App/iosAppCode_mobile.png" />-->
          </div>

          <img :style="{'max-width':mobileScreenSize === 'small' ? '400px' : '500px'}" style="width:calc(100% - 22px);margin-top:40px;margin-right:5%;float:right;" src="../assets/App/appPage/screen1/mainPicture_mobile.png" />

        </div>
      </div>

      <!--第二屏-->
      <div id="appScreen2_mobile" style="position:relative;" class="screen">
        <div style="width:calc(100% - 40px);margin:0 auto;padding-top:190px;text-align:center;">
          <p id="appScreen2_title" class="appPageColorTitle_mobile floatAnimationDom">共生地球APP</p>
          <div id="appScreen2_ps" class="floatAnimationDom">
            <p style="margin-top:20px;" class="appPagePs_mobile">立体呈现地球脉动</p>
            <p class="appPagePs_mobile">全球热点资讯实时跟踪</p>
            <p class="appPagePs_mobile">遥感图像前后比对，卫星星座直击事件进展</p>
            <p class="appPagePs_mobile">一键分享生活，记录地球美好瞬间</p>
            <div @click="gotoProductPage('/ProductPageApp')" style="width:96px;margin:25px auto 0 auto;" class="btn_outline_mobile">
              <p>了解详情</p>
            </div>
          </div>
          <img id="appScreen2_img" class="floatAnimationDom"
               :style="{'max-width':mobileScreenSize === 'small' ? '400px' : '500px','left':mobileScreenSize === 'small' ? '31px' : '50px'}"
               style="position:relative;width:calc(100% - 62px);margin-top:40px;" src="../assets/App/appPage/screen2/img.png" />

        </div>
        <!--底部渐变-->
        <div style="position:absolute;bottom:0;left:0;width:100%;height:120px;background:linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);"></div>

      </div>

      <!--第三屏-->
      <div id="appScreen3_mobile" style="position:relative;" class="screen">

        <div style="width:calc(100% - 40px);margin:0 auto;padding-top:100px;text-align:center;">

          <p id="appScreen3_title" class="appPageColorTitle_mobile floatAnimationDom">共生地球web端</p>
          <div id="appScreen3_ps" class="floatAnimationDom">
            <p style="margin-top:20px;" class="appPagePs_mobile">丰富的地理探索工具</p>
            <p class="appPagePs_mobile">助力户外探索与测绘规划</p>
            <p class="appPagePs_mobile">场景丰富的地球录制特效，一键实现地球漫游</p>
            <p class="appPagePs_mobile">打造一站式地理信息服务平台</p>
            <div @click="gotoProductPage('/ProductPageWebMarker')" style="width:96px;margin:25px auto 0 auto;" class="btn_outline_mobile">
              <p>了解详情</p>
            </div>
          </div>
          <img id="appScreen3_img" class="floatAnimationDom" :style="{'max-width':mobileScreenSize === 'small' ? '500px' : '580px'}"
               style="position:relative;width:calc(100% - 7px);max-width:500px;margin-top:40px;" src="../assets/App/appPage/screen3/img.png" />

        </div>

        <!--顶部渐变-->
        <div style="position:absolute;top:0;left:0;width:100%;height:113px;background:linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);"></div>
        <!--底部渐变-->
        <div style="position:absolute;bottom:0;left:0;width:100%;height:120px;background:linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);"></div>


      </div>

      <!--第四屏-->
      <div id="appScreen4_mobile" style="position:relative;" class="screen">

        <div style="width:calc(100% - 40px);margin:0 auto;padding-top:145px;text-align:center;">
          <p id="appScreen4_title" class="appPageColorTitle_mobile floatAnimationDom">遥感影像库</p>
          <div id="appScreen4_ps" class="floatAnimationDom">
            <p style="margin-top:20px;" class="appPagePs_mobile">“吉林一号”星座独家提供最新遥感图源</p>
            <p class="appPagePs_mobile">精选海量卫星影集，官方授权品质保障</p>
            <p class="appPagePs_mobile">随时随地查看极致地球风景</p>
            <div @click="gotoProductPage('/ProductPageImageLib')" style="width:96px;margin:25px auto 0 auto;" class="btn_outline_mobile">
              <p>了解详情</p>
            </div>
          </div>
          <img id="appScreen4_img" class="floatAnimationDom" :style="{'max-width':mobileScreenSize === 'small' ? '500px' : '580px'}"
               style="position:relative;width:calc(100% - 7px);max-width:500px;margin-top:40px;" src="../assets/App/appPage/screen4/img.png" />
        </div>

        <!--顶部渐变-->
        <div style="position:absolute;top:0;left:0;width:100%;height:113px;background:linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);"></div>
        <!--底部渐变-->
        <div style="position:absolute;bottom:0;left:0;width:100%;height:120px;background:linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);"></div>


      </div>


      <!--第五屏-->
      <div id="appScreen5_mobile" class="screen">
        <div style="position:relative;padding-top:85px;text-align:center;z-index:1;">
          <p id="appScreen5_title" class="appPageColorTitle_mobile floatAnimationDom">吉林一号·特色服务</p>

          <!--切换tab-->
          <div id="appScreen5_ps" class="floatAnimationDom" style="position:relative;width:336px;height:46px;margin:24px auto 0 auto;padding:0 2px;background:rgba(255, 255, 255, 0.23);border-radius:30px;
          font-size:16px;display:flex;align-items:center;justify-content:space-around;">
            <div id="screen5TabChecked_mobile" style="position:absolute;top:5px;left:5px;width:101px;height:36px;border-radius:30px;background:rgba(42, 130, 228, 1);
          animation-duration:200ms;animation-fill-mode: forwards;"></div>
            <p @click="clickScreen5TabItem_mobile(item)" v-for="(item,index) in screen5TabList" :key="'screen5Tab'+index" style="z-index:1;cursor:pointer;">{{item}}</p>
          </div>

          <div id="appScreen5_img" class="floatAnimationDom">
            <!--卫星观察-->
            <div v-if="screen5CurrTab === '卫星观察'" style="width:100%;margin-top:46px;flex-direction:column;">
<!--              <div :style="{'width':mobileScreenSize === 'small' ? '305px' : '380px'}" style="height:115px;margin:0 auto;display:flex;align-items:center;">-->
<!--                <div style="text-align:left;">-->
<!--                  <p :style="{'font-size':mobileScreenSize === 'small' ? '24px' : '32px'}" class="appPageColorTitle_mobile">卫星观察时刻</p>-->
<!--                  <p :style="{'font-size':mobileScreenSize === 'small' ? '14px' : '18px'}" style="margin-top:10px;" class="appPagePs_mobile">新闻纵横 卫星追踪</p>-->
<!--                  <p :style="{'font-size':mobileScreenSize === 'small' ? '14px' : '18px'}" class="appPagePs_mobile">卫星视角 看世界新闻动态</p>-->
<!--                  <p :style="{'font-size':mobileScreenSize === 'small' ? '14px' : '18px'}" class="appPagePs_mobile">掌握一手资讯 尽在卫星观察</p>-->
<!--                </div>-->
<!--                <div style="flex:1;"></div>-->
<!--                <img :style="{'width':mobileScreenSize === 'small' ? '78px' : '90px','height':mobileScreenSize === 'small' ? '78px' : '90px'}" src="../assets/logo.png" />-->
<!--              </div>-->

              <div style="width:100%;height:115px;margin:0 auto;display:flex;align-items:center;justify-content: center;">
                <div>
                  <p :style="{'font-size':mobileScreenSize === 'small' ? '24px' : '32px'}" class="appPageColorTitle_mobile">卫星观察时刻</p>
                  <p :style="{'font-size':mobileScreenSize === 'small' ? '14px' : '18px'}" style="margin-top:10px;" class="appPagePs_mobile">新闻纵横 卫星追踪</p>
                  <p :style="{'font-size':mobileScreenSize === 'small' ? '14px' : '18px'}" class="appPagePs_mobile">卫星视角 看世界新闻动态</p>
                  <p :style="{'font-size':mobileScreenSize === 'small' ? '14px' : '18px'}" class="appPagePs_mobile">掌握一手资讯 尽在卫星观察</p>
                </div>
              </div>

              <div style="width:100%;margin-top:40px;">
                <video controls="controls" poster="../assets/App/appPage/screen5/videoPoster1.jpg" style="width:100%;">
                  <source src="https://market.obs.cn-north-4.myhuaweicloud.com:443/202405291133000295884.mp4" type="video/mp4" />
                </video>
              </div>
            </div>

            <!--地球星评官-->
            <div v-if="screen5CurrTab === '地球星评官'" style="width:100%;margin-top:46px;flex-direction:column;">
<!--              <div :style="{'width':mobileScreenSize === 'small' ? '305px' : '380px'}" style="height:115px;margin:0 auto;display:flex;align-items:center;">-->
<!--                <div style="text-align:left;">-->
<!--                  <p :style="{'font-size':mobileScreenSize === 'small' ? '24px' : '32px'}" class="appPageColorTitle_mobile">最美地球评选</p>-->
<!--                  <p :style="{'font-size':mobileScreenSize === 'small' ? '14px' : '18px'}" style="margin-top:10px;" class="appPagePs_mobile">立即参与地球星评官活动</p>-->
<!--                  <p :style="{'font-size':mobileScreenSize === 'small' ? '14px' : '18px'}" class="appPagePs_mobile">领略不同视角下的图像魅力</p>-->
<!--                  <p :style="{'font-size':mobileScreenSize === 'small' ? '14px' : '18px'}" class="appPagePs_mobile">一起创造最美地球</p>-->
<!--                </div>-->
<!--                <div style="flex:1;"></div>-->
<!--                <img :style="{'width':mobileScreenSize === 'small' ? '78px' : '90px','height':mobileScreenSize === 'small' ? '78px' : '90px'}" src="../assets/logo.png" />-->
<!--              </div>-->

              <div style="width:100%;height:115px;margin:0 auto;display:flex;align-items:center;justify-content: center;">
                <div>
                  <p :style="{'font-size':mobileScreenSize === 'small' ? '24px' : '32px'}" class="appPageColorTitle_mobile">最美地球评选</p>
                  <p :style="{'font-size':mobileScreenSize === 'small' ? '14px' : '18px'}" style="margin-top:10px;" class="appPagePs_mobile">立即参与地球星评官活动</p>
                  <p :style="{'font-size':mobileScreenSize === 'small' ? '14px' : '18px'}" class="appPagePs_mobile">领略不同视角下的图像魅力</p>
                  <p :style="{'font-size':mobileScreenSize === 'small' ? '14px' : '18px'}" class="appPagePs_mobile">一起创造最美地球</p>
                </div>
              </div>

              <div style="width:100%;margin-top:40px;">
                <video controls="controls" poster="../assets/App/appPage/screen5/videoPoster2.jpg"  style="width:100%;">
                  <source src="https://market.obs.cn-north-4.myhuaweicloud.com:443/202405291135000452919.mp4" type="video/mp4" />
                </video>
              </div>
            </div>

            <!--卫星快闪-->
            <div v-if="screen5CurrTab === '卫星快闪'" style="width:100%;margin-top:46px;flex-direction:column;">
<!--              <div :style="{'width':mobileScreenSize === 'small' ? '305px' : '400px'}" style="height:115px;margin:0 auto;display:flex;align-items:center;">-->
<!--                <div style="text-align:left;">-->
<!--                  <p :style="{'font-size':mobileScreenSize === 'small' ? '24px' : '32px'}" class="appPageColorTitle_mobile">卫星快闪进行时</p>-->
<!--                  <p :style="{'font-size':mobileScreenSize === 'small' ? '14px' : '18px'}" style="margin-top:10px;" class="appPagePs_mobile">在净月潭、在国贸CBD</p>-->
<!--                  <p :style="{'font-size':mobileScreenSize === 'small' ? '14px' : '18px'}" class="appPagePs_mobile">来卫星打卡地 与众人一起欢聚</p>-->
<!--                  <p :style="{'font-size':mobileScreenSize === 'small' ? '14px' : '18px'}" class="appPagePs_mobile">让卫星从500公里高空记录美好瞬间</p>-->
<!--                </div>-->
<!--                <div style="flex:1;"></div>-->
<!--                <img :style="{'width':mobileScreenSize === 'small' ? '78px' : '90px','height':mobileScreenSize === 'small' ? '78px' : '90px'}" src="../assets/logo.png" />-->
<!--              </div>-->

              <div style="width:100%;height:115px;margin:0 auto;display:flex;align-items:center;justify-content: center;">
                <div>
                  <p :style="{'font-size':mobileScreenSize === 'small' ? '24px' : '32px'}" class="appPageColorTitle_mobile">卫星快闪进行时</p>
                  <p :style="{'font-size':mobileScreenSize === 'small' ? '14px' : '18px'}" style="margin-top:10px;" class="appPagePs_mobile">在净月潭、在国贸CBD</p>
                  <p :style="{'font-size':mobileScreenSize === 'small' ? '14px' : '18px'}" class="appPagePs_mobile">来卫星打卡地 与众人一起欢聚</p>
                  <p :style="{'font-size':mobileScreenSize === 'small' ? '14px' : '18px'}" class="appPagePs_mobile">让卫星从500公里高空记录美好瞬间</p>
                </div>
              </div>

              <div style="width:100%;margin-top:40px;">
                <video controls="controls" poster="../assets/App/appPage/screen5/videoPoster3.jpg"  style="width:100%;">
                  <source src="https://market.obs.cn-north-4.myhuaweicloud.com:443/202405291145000019773.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
          </div>

        </div>

        <!--顶部渐变-->
        <div style="position:absolute;top:0;left:0;width:100%;height:63px;background:linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);"></div>

        <!--底部渐变-->
        <div style="position:absolute;bottom:0;left:0;width:100%;height:63px;background:linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);"></div>


      </div>


      <!--第六屏-->
      <div id="appScreen6_mobile" class="screen">
        <div style="width:calc(100% - 40px);margin:0 auto;padding-top:105px;text-align:center;">
          <p class="appPageColorTitle_mobile">吉林一号·品牌服务</p>
<!--          height:320px;-->
          <swiper :options="screen6SwiperOption_mobile" style="position:relative;width:100%;margin-top:42px;">
            <swiper-slide v-for="i in screen6ContentList.length" :key="'screen6SwiperItem'+i">
              <div style="width:100%;margin-bottom:50px;text-align:left;">
                <img @click="screen6ImgClick(screen6ContentList[i-1].picture,screen6ContentList[i-1].href)"
                     :style="{'width':mobileScreenSize === 'small' ? '100%' : '80%'}"
                     style="margin:0 auto;border-radius:16px;display:block;" :src="screen6ContentList[i-1].picture" />

                <p :style="{'width':mobileScreenSize === 'small' ? '100%' : '80%','font-size':mobileScreenSize === 'small' ? '16px' : '20px'}"
                   style="margin:12px auto 0 auto;">{{screen6ContentList[i-1].name}}</p>
                <p :style="{'width':mobileScreenSize === 'small' ? '100%' : '80%','font-size':mobileScreenSize === 'small' ? '14px' : '18px'}"
                   style="margin:8px auto 0 auto;">{{screen6ContentList[i-1].ps}}</p>
              </div>
            </swiper-slide>
            <div class="screen6Swiper_mobile" slot="pagination"></div>
          </swiper>
        </div>
      </div>


      <!--第七屏-->
      <div id="appScreen7_mobile" class="screen">
        <div style="width:calc(100% - 40px);margin:0 auto;padding-top:125px;text-align:center;">
          <p class="appPageColorTitle_mobile">吉林一号海量卫星服务</p>
          <p style="margin-top:20px;" class="appPagePs_mobile">遥感星座 全天候关注地球</p>
          <!--卫星模型-->
          <img style="position:relative;top:0;width:200px;margin-top:60px;animation:verticalShakeAnimation 1500ms infinite;" src="../assets/App/appPage/screen7/img.png" />

          <!--数据-->
          <div style="width:100%;height:100px;margin-top:45px;display:flex;justify-content:center;">
            <div v-for="(item,index) in screen7DataList" :key="'screen7DataItem'+index"
                 style="flex:1;height:100%;display:flex;">
              <div style="flex:1;"></div>
              <div style="display:flex;flex-direction:column;align-items:start;">
                <p class="appPageColorTitle_mobile" style="font-size:36px;background:linear-gradient(180deg, rgba(201, 202, 203, 1) 0%, rgba(101, 102, 103, 1) 100%);">
                  {{item.number}}{{item.numberText}}
                </p>
                <p style="font-size:16px;color:rgba(141, 141, 141, 1);">{{item.ps}}</p>
              </div>
              <div style="flex:1;"></div>
            </div>
          </div>



        </div>


      </div>


      <!--第八屏-->
      <div id="appScreen8_mobile" class="screen">
        <div style="width:100%;margin:0 auto;padding-top:125px;padding-bottom:200px;text-align:center;">
          <p class="appPageColorTitle_mobile">开启吉林一号·共生地球探索之旅</p>
          <!--按钮-->
          <div style="width:208px;margin:30px auto 0 auto;display:flex;align-items:center;">
            <div @click="gotoAppStore" style="width:96px;height:30px;border:1px solid rgba(138, 138, 138, 1);border-radius:4px;display:flex;align-items:center;">
              <p style="margin-left:8px;font-size:18px;" class="iconfontFamily">&#xe78a;</p>
              <p style="margin-left:8px;font-size:11px;">App Store</p>
            </div>
            <div @click="gotoAndroid" style="width:96px;height:30px;border:1px solid rgba(138, 138, 138, 1);border-radius:4px;margin-left:17px;display:flex;align-items:center;">
              <p style="margin-left:8px;font-size:18px;" class="iconfontFamily">&#xe78c;</p>
              <p style="margin-left:13px;font-size:11px;">Android</p>
            </div>
          </div>



        </div>

        <!--顶部渐变-->
        <div style="position:absolute;top:0;left:0;width:100%;height:63px;background:linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);"></div>

        <!--底部渐变-->
        <div style="position:absolute;bottom:0;left:0;width:100%;height:120px;background:linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);"></div>


      </div>

      <!--底栏-->
      <div style="position:relative;width:100%;margin-top:-100px;">
        <app-page-foot-bar-mobile :produceList="footBarProductList"></app-page-foot-bar-mobile>
      </div>



      <!--全屏看图片-->
      <div @click="fullScreenImageShow=false;" v-if="fullScreenImageShow" style="position:fixed;top:0;left:0;width:100vw;height:100vh;background:rgba(0, 0, 0, 1);z-index:999;cursor:pointer;">
        <img style="width:100%;height:100%;object-fit:contain;" :src="fullScreenImageSrc" />
      </div>


    </div>


    <!--提示-->
    <div v-if="toastShow" class="toast">
      <p style="margin:0;line-height:32px;">{{toastText}}</p>
    </div>



  </div>


</template>

<script>
// import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// gsap.registerPlugin(ScrollTrigger);

import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

import AppPageTopBar from "@/views/AppPage/AppPageTopBar"
import AppPageFootBar from "@/views/AppPage/AppPageFootBar"

import AppPageTopBarMobile from "@/views/AppPage/AppPageTopBar_mobile"
import AppPageFootBarMobile from "@/views/AppPage/AppPageFootBar_mobile"

export default {
  name: "App",
  components: {
    swiper,
    swiperSlide,
    AppPageTopBar,
    AppPageFootBar,
    AppPageTopBarMobile,
    AppPageFootBarMobile
  },
  data(){
    return{
      device:"web",
      webScreenSize:"big",
      mobileScreenSize:"small",


      //第一屏轮播
      screen1SwiperOption: {
        pagination: {
          el: '.screen1Swiper', //与slot="pagination"处 class 一致
          clickable: true, //轮播按钮支持点击
        },
        //自动播放
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        //循环
        loop:true
      },

      screen5CurrTab:"卫星观察",
      screen5TabList:["卫星观察","地球星评官","卫星快闪"],


      //第六屏轮播
      screen6SwiperOption: {
        pagination: {
          el: '.screen6Swiper', //与slot="pagination"处 class 一致
          clickable: true, //轮播按钮支持点击
        },
        //自动播放
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        //循环
        // loop:true,
      },
      screen6ContentList:[
        {
          picture:require('../assets/App/appPage/screen6/img1.jpg'),
          name:"《卫星瞰中国》共生地球x湖南出版社",
          ps:"卫星瞰祖国，感受大美中华",
          href:"https://detail.tmall.com/item.htm?id=750320330042&spm=a21dvs.23580594.0.0.1d293d0dtwV2fJ&securitydegrade=true",
        },
        {
          picture:require('../assets/App/appPage/screen6/img2.jpg'),
          name:"吉林一号·共生地球文创周边",
          ps:"趣味文创 创造潮流 传递美好",
          href:"",
        },
        {
          picture:require('../assets/App/appPage/screen6/img3.jpg'),
          name:"长光卫星x森马",
          ps:"破格未知 诠释未来 邂逅壮阔领地 映照无畏旅程",
          href:"",
        },
      ],
      screen6SwiperCurrIndex:0,


      //第七屏数据
      screen7DataList:[
        {
          number:0,
          numberText:"次",
          ps:"成功发射"
        },
        {
          number:0,
          numberText:"颗",
          ps:"在轨卫星"
        },
        {
          number:"KB",
          numberText:"级",
          ps:"海量影像"
        },
      ],
      screen7Temp2Times:0,


      //底栏项目列表
      footBarProductList:[
        {
          title:"吉林一号·共生地球APP",
          href:"/ProductPageApp"
        },
        {
          title:"吉林一号·共生地球web端",
          href:"/ProductPageWebMarker"
        },
        {
          title:"吉林一号·遥感影像库",
          href:"/ProductPageImageLib"
        },

      ],



      //全屏大图
      fullScreenImageShow:false,
      fullScreenImageSrc:require('../assets/App/appPage/screen6/img1.jpg'),




      /**移动端**********************************************************************************************/
      //第六屏轮播
      screen6SwiperOption_mobile: {
        pagination: {
          el: '.screen6Swiper_mobile', //与slot="pagination"处 class 一致
          clickable: true, //轮播按钮支持点击
        },
        //自动播放
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        //循环
        loop:true,
      },


      toastShow:false,
      toastText:"",


    }


  },
  watch:{
    screen6SwiperCurrIndex(){
      console.log(this.screen6SwiperCurrIndex)
    }
  },
  methods:{

    /**web端*******************************************************************************************/

    //第一屏轮播控制块位置 和内容文字对齐
    screen1SwiperLeft(){
      let left = window.innerWidth * 0.2 - 220
      if(left >= 30){
        document.getElementsByClassName("screen1Swiper")[0].style.left = left + 'px'
      }
      else{
        document.getElementsByClassName("screen1Swiper")[0].style.left = '30px'
      }
    },


    //第五屏 点击tab项
    clickScreen5TabItem(item){
      if(item !== this.screen5CurrTab){
        let oldTabLeft = this.screen5CurrTab === '卫星观察' ? '6px' : this.screen5CurrTab === '地球星评官' ? '140px' : '277px'
        let newTabLeft = item === '卫星观察' ? '6px' : item === '地球星评官' ? '140px' : '277px'

        //选中背景动画
        const tempStyle = `@keyframes tabChangeAnimation {
                            from {left: ${oldTabLeft};}
                            to {left: ${newTabLeft};}
                        }`
        const style = document.createElement('style')
        style.setAttribute('type', 'text/css')
        document.head.appendChild(style)
        style.sheet.insertRule(tempStyle, 0)

        document.getElementById("screen5TabChecked").style.animationName = "tabChangeAnimation"
        setTimeout(()=>{
          document.getElementById("screen5TabChecked").style.left = newTabLeft
          document.getElementById("screen5TabChecked").style.animationName = ""
        },200)

        //更改选中项
        this.screen5CurrTab = item;

        this.screen5ContentAnimation()

      }

    },


    //第六屏 鼠标进入图片
    screen6ImgMouseEnter(swiperContentIndex){
      this.screen6SwiperCurrIndex = swiperContentIndex;
      console.log(this.screen6SwiperCurrIndex)
    },
    //点击图片
    screen6ImgClick(pictureSrc,href){
      console.log(href)
      if(href === ""){
        this.openFullScreenImage(pictureSrc)
      }
      else{
        window.open(href, '_blank');
      }
    },


    //第七屏 数字增加渐变颜色效果(直接写在css里不生效)
    screen7DataNumberColor(){
      let numberList = document.querySelectorAll(".screen7DataNumber")
      console.log(numberList)
      for(let i = 0;i<numberList.length;i++) {
        numberList[i].style["-webkit-background-clip"] = 'text'
      }
    },


    //第八屏 标题增加渐变颜色效果(直接写在css里不生效)
    screen8TitleColor(){
      document.getElementById("screen8Title").style["-webkit-background-clip"] = 'text'
    },


    /**动画*********************************************************************/

    //鼠标滚动位置相关的动效
    listenerScrollFun(){
      const scrollTop = window.scrollY || window.pageYOffset;

      //第二屏
      if(scrollTop >= 400){
        this.titleAndPsAndImgFloatAnimation("appScreen2_title","appScreen2_ps","appScreen2_img")
      }
      //第三屏
      if(scrollTop >= 1200){
        this.titleAndPsAndImgFloatAnimation("appScreen3_title","appScreen3_ps","appScreen3_img")
      }
      //第四屏
      if(scrollTop >= 2000){
        this.titleAndPsAndImgFloatAnimation("appScreen4_title","appScreen4_ps","appScreen4_img")
      }
      //第五屏
      if(scrollTop >= 2400 + window.innerHeight * 0.5){
        this.screen5ContentAnimation();
      }
      //第七屏
      if(scrollTop >= 2400 + window.innerHeight * 3){

        let temp0 = setInterval(()=>{
          if(this.screen7DataList[0].number < 23){
            this.screen7DataList[0].number++
          }
          else{
            clearInterval(temp0)
          }
        },52.17)

        let temp1 = setInterval(()=>{
          if(this.screen7DataList[1].number < 108){
            this.screen7DataList[1].number++
          }
          else{
            clearInterval(temp1)
          }
        },11.11)


        let temp2 = setInterval(()=>{
          if(this.screen7Temp2Times < 3 || (this.screen7Temp2Times === 3 && this.screen7DataList[2].number !== "PB")){
            switch(this.screen7DataList[2].number){
              case "KB":{
                this.screen7DataList[2].number = "MB";
                break;
              }
              case "MB":{
                this.screen7DataList[2].number = "TB";
                break;
              }
              case "TB":{
                this.screen7DataList[2].number = "PB";
                break;
              }
              case "PB":{
                this.screen7Temp2Times ++;
                this.screen7DataList[2].number = "KB";
                break;
              }

            }
          }
          else{
            clearInterval(temp2)
          }

        },100)


      }


    },

    //标题+详情+图片 三者上浮动画
    titleAndPsAndImgFloatAnimation(titleDomId,psDomId,imgDomId){
      document.getElementById(titleDomId).style.animationName = "floatAnimation"
      document.getElementById(titleDomId).style.animationDuration = "1.5s"

      document.getElementById(psDomId).style.animationName = "floatAnimation"
      document.getElementById(psDomId).style.animationDuration = "2s"

      document.getElementById(imgDomId).style.animationName = "floatAnimation"
      document.getElementById(imgDomId).style.animationDuration = "2s"
    },

    //第五屏内容动画 + 背景切换
    screen5ContentAnimation(){
      switch(this.screen5CurrTab){
        case "卫星观察":{
          let backgroundUrl = require('../assets/App/appPage/screen5/background1.jpg')
          document.getElementById("appScreen5").style.backgroundImage = "url(" + backgroundUrl + ")"
          this.titleAndPsAndImgFloatAnimation("appScreen5_1_title","appScreen5_1_ps","appScreen5_1_img")
          break;
        }
        case "地球星评官":{
          let backgroundUrl = require('../assets/App/appPage/screen5/background2.jpg')
          document.getElementById("appScreen5").style.backgroundImage = "url(" + backgroundUrl + ")"
          this.titleAndPsAndImgFloatAnimation("appScreen5_2_title","appScreen5_2_ps","appScreen5_2_img")
          break;
        }
        case "卫星快闪":{
          let backgroundUrl = require('../assets/App/appPage/screen5/background3.jpg')
          document.getElementById("appScreen5").style.backgroundImage = "url(" + backgroundUrl + ")"
          this.titleAndPsAndImgFloatAnimation("appScreen5_3_title","appScreen5_3_ps","appScreen5_3_img")
          break;
        }
      }

    },



    //颜色渐变文字
    appPageAppColorTitle(){
      let titleList = document.querySelectorAll(".appPageColorTitle")
      for(let i = 0;i<titleList.length;i++) {
        titleList[i].style["-webkit-background-clip"] = 'text'
      }
    },



    /**通用*********************************************************/
    //查看大图
    openFullScreenImage(pictureSrc){
      this.fullScreenImageSrc = pictureSrc;
      this.fullScreenImageShow = true;
    },

    //跳转appstore
    gotoAppStore(){
      let u = window.navigator.userAgent;
      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android终端或者uc浏览器
      let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if(isAndroid){
        window.open("https://apps.apple.com/cn/app/%E5%85%B1%E7%94%9F%E5%9C%B0%E7%90%83/id1628699115", '_blank');
      }
      if(isiOS){
        this.ios();
      }
      if(!isAndroid && !isiOS){
        //web
        window.open("https://apps.apple.com/cn/app/%E5%85%B1%E7%94%9F%E5%9C%B0%E7%90%83/id1628699115", '_blank');
      }
    },
    ios(){
      let _clickTime = +(new Date());
      let ifr = document.createElement("iframe");
      ifr.src = 'charmingearth://earth.jl1.cn'
      ifr.style.display = "none";
      document.body.appendChild(ifr);
      //启动间隔20ms运⾏的定时器，并检测累计消耗时间是否超过3000ms，超过则结束
      let _count = 0, intHandle;
      intHandle = setInterval(function(){
        _count++;
        let elsTime =  +(new Date()) - _clickTime;
        console.log(_count,elsTime,+(new Date()),_clickTime)
        if (_count>=100 || elsTime > 3000 ) {
          clearInterval(intHandle);
          document.body.removeChild(ifr);
          //检查app是否打开
          if ( document.hidden || document.webkitHidden) {
            // 打开了
            window.close();
          } else {
            // 没打开
            // alert('没打开')
            window.location.href = "https://apps.apple.com/cn/app/id1628699115";//下载链接
          }
        }
      },20);
    },


    //跳转android
    gotoAndroid(){
      window.open("https://sj.qq.com/appdetail/com.charmingglobe.charmingearth", '_blank');
    },




    //跳转产品页
    gotoProductPage(pageUrl){
      this.$router.push(
          {
            path: pageUrl,
          }
      )
    },


    //跳转遥感工作台
    intoAuthor(){
      window.open('https://earth.jl1.cn/tools/', '_blank');
    },





    /**移动端**************************************************************************************/

    //第五屏 点击tab项
    clickScreen5TabItem_mobile(item){
      if(item !== this.screen5CurrTab){
        let oldTabLeft = this.screen5CurrTab === '卫星观察' ? '6px' : this.screen5CurrTab === '地球星评官' ? '119px' : '233px'
        let newTabLeft = item === '卫星观察' ? '6px' : item === '地球星评官' ? '119px' : '233px'

        //选中背景动画
        const tempStyle = `@keyframes tabChangeAnimation_mobile {
                            from {left: ${oldTabLeft};}
                            to {left: ${newTabLeft};}
                        }`
        const style = document.createElement('style')
        style.setAttribute('type', 'text/css')
        document.head.appendChild(style)
        style.sheet.insertRule(tempStyle, 0)

        document.getElementById("screen5TabChecked_mobile").style.animationName = "tabChangeAnimation_mobile"
        setTimeout(()=>{
          document.getElementById("screen5TabChecked_mobile").style.left = newTabLeft
          document.getElementById("screen5TabChecked_mobile").style.animationName = ""
        },200)

        //更改选中项
        this.screen5CurrTab = item;
        setTimeout(()=>{
          this.appPageAppColorTitle_mobile();
        },0)

        switch(item){
          case "卫星观察":{
            let backgroundUrl = require('../assets/App/appPage/screen5/background1.jpg')
            document.getElementById("appScreen5_mobile").style.backgroundImage = "url(" + backgroundUrl + ")"
            // this.titleAndPsAndImgFloatAnimation("appScreen5_1_title","appScreen5_1_ps","appScreen5_1_img")
            break;
          }
          case "地球星评官":{
            let backgroundUrl = require('../assets/App/appPage/screen5/background2.jpg')
            document.getElementById("appScreen5_mobile").style.backgroundImage = "url(" + backgroundUrl + ")"
            // this.titleAndPsAndImgFloatAnimation("appScreen5_2_title","appScreen5_2_ps","appScreen5_2_img")
            break;
          }
          case "卫星快闪":{
            let backgroundUrl = require('../assets/App/appPage/screen5/background3.jpg')
            document.getElementById("appScreen5_mobile").style.backgroundImage = "url(" + backgroundUrl + ")"
            // this.titleAndPsAndImgFloatAnimation("appScreen5_3_title","appScreen5_3_ps","appScreen5_3_img")
            break;
          }
        }

      }

    },

    //渐变颜色文字
    appPageAppColorTitle_mobile(){
      let titleList = document.querySelectorAll(".appPageColorTitle_mobile")
      for(let i = 0;i<titleList.length;i++) {
        titleList[i].style["-webkit-background-clip"] = 'text'
      }
    },

    //鼠标滚动位置相关的动效
    listenerScrollFun_mobile(){
      let screen1Height = document.getElementById("appScreen1_mobile").offsetHeight;
      let screen2Height = document.getElementById("appScreen2_mobile").offsetHeight;
      let screen3Height = document.getElementById("appScreen3_mobile").offsetHeight;
      let screen4Height = document.getElementById("appScreen4_mobile").offsetHeight;
      let screen5Height = document.getElementById("appScreen5_mobile").offsetHeight;
      let screen6Height = document.getElementById("appScreen6_mobile").offsetHeight;

      const scrollTop = window.scrollY || window.pageYOffset;

      //第二屏
      if(scrollTop >= screen1Height * 0.5){
        this.titleAndPsAndImgFloatAnimation("appScreen2_title","appScreen2_ps","appScreen2_img")
      }
      //第三屏
      if(scrollTop >= screen1Height + screen2Height * 0.5){
        this.titleAndPsAndImgFloatAnimation("appScreen3_title","appScreen3_ps","appScreen3_img")
      }
      //第四屏
      if(scrollTop >= screen1Height + screen2Height + screen3Height * 0.5){
        this.titleAndPsAndImgFloatAnimation("appScreen4_title","appScreen4_ps","appScreen4_img")
      }
      //第五屏
      if(scrollTop >= screen1Height + screen2Height + screen3Height + screen4Height * 0.5){
        this.titleAndPsAndImgFloatAnimation("appScreen5_title","appScreen5_ps","appScreen5_img")
      }
      //第七屏
      if(scrollTop >= screen1Height + screen2Height + screen3Height + screen4Height + screen5Height + screen6Height * 0.5){

        let temp0 = setInterval(()=>{
          if(this.screen7DataList[0].number < 23){
            this.screen7DataList[0].number++
          }
          else{
            clearInterval(temp0)
          }
        },10)

        let temp1 = setInterval(()=>{
          if(this.screen7DataList[1].number < 108){
            this.screen7DataList[1].number++
          }
          else{
            clearInterval(temp1)
          }
        },10)

        let temp2 = setInterval(()=>{
          if(this.screen7Temp2Times < 3 || (this.screen7Temp2Times === 3 && this.screen7DataList[2].number !== "PB")){
            switch(this.screen7DataList[2].number){
              case "KB":{
                this.screen7DataList[2].number = "MB";
                break;
              }
              case "MB":{
                this.screen7DataList[2].number = "TB";
                break;
              }
              case "TB":{
                this.screen7DataList[2].number = "PB";
                break;
              }
              case "PB":{
                this.screen7Temp2Times ++;
                this.screen7DataList[2].number = "KB";
                break;
              }

            }
          }
          else{
            clearInterval(temp2)
          }

        },100)

      }


    },


  },
  mounted(){
    setTimeout(()=>{
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    },100)

    if(window.innerWidth >= 1000){
      //web端
      this.device = "web";
      //适配web尺寸
      if(window.innerWidth < 1330){
        this.webScreenSize = "small";
        this.$refs.appPage.style.setProperty('--web-title-font-size', "40px")
        this.$refs.appPage.style.setProperty('--web-ps-font-size', "20px")
        this.$refs.appPage.style.setProperty('--web-ps-line-height', "30px")
      }
      else{
        this.$refs.appPage.style.setProperty('--web-title-font-size', "48px")
        this.$refs.appPage.style.setProperty('--web-ps-font-size', "24px")
        this.$refs.appPage.style.setProperty('--web-ps-line-height', "46px")
      }


      setTimeout(()=>{
        window.addEventListener('scroll', this.listenerScrollFun);
      },100)

      this.appPageAppColorTitle();

      this.screen1SwiperLeft();
      this.screen7DataNumberColor();
      this.screen8TitleColor();
    }
    else{
      //手机
      this.device = "mobile";

      //适配手机尺寸
      if(window.innerWidth > 650){
        this.mobileScreenSize = "big";
        console.log(this.mobileScreenSize)
        this.$refs.appPage.style.setProperty('--mobile-title-font-size', "40px")
        this.$refs.appPage.style.setProperty('--mobile-ps-font-size', "20px")
        this.$refs.appPage.style.setProperty('--mobile-ps-line-height', "30px")

        // this.$refs.appPage.style.setProperty('--mobile-btn_outline-font-size', "16px")
      }
      else{
        this.$refs.appPage.style.setProperty('--mobile-title-font-size', "24px")
        this.$refs.appPage.style.setProperty('--mobile-ps-font-size', "16px")
        this.$refs.appPage.style.setProperty('--mobile-ps-line-height', "24px")

        // this.$refs.appPage.style.setProperty('--mobile-btn_outline-font-size', "14px")

      }

      //如果带有滚动到位置参数，滚动到该id元素位置
      if(this.$route.query.target !== undefined){
        let scrollTarget = this.$route.query.target
        setTimeout(()=>{
          let scrollHeight = document.getElementById(scrollTarget).offsetTop
          window.scrollTo({top:scrollHeight})
        },100)
      }

      setTimeout(()=>{
        window.addEventListener('scroll', this.listenerScrollFun_mobile);
      },100)

      //device默认是web，手机端需要延时
      setTimeout(()=>{
        this.appPageAppColorTitle_mobile();
      },0)


    }

  },
  destroyed() {
    if(window.innerWidth >= 1000){
      window.removeEventListener('scroll', this.listenerScrollFun);
    }
    else{
      window.removeEventListener('scroll', this.listenerScrollFun_mobile);
    }
  }
}
</script>

<style scoped>

.appPage{
  background:#000000;
  color:#ffffff;
  /*overflow:hidden;*/
  font-family:HarmonyOSSansSCRegular;
}
.appPage p{
  margin:0;
}


.screen{
  width: 100%;
}

#appScreen1{
  background-image:url('../assets/App/appPage/screen1/background.jpg');
  background-size:100% 100%;
  height:100vh;
  position:relative;
}
.screen1Swiper{
  position: absolute;
  bottom:62px;
  z-index: 1;
}


#appScreen2{
  background-image:url('../assets/App/appPage/screen2/background.jpg');
  background-size:100% 100%;
  height:800px;
  margin-top:160px;
}
#appScreen3{
  background-image:url('../assets/App/appPage/screen3/background.jpg');
  background-size:100% 100%;
  height:800px;
}
#appScreen4{
  background-image:url('../assets/App/appPage/screen4/background.jpg');
  background-size:100% 100%;
  height:800px;
}
#appScreen5{
  background-image:url('../assets/App/appPage/screen5/background1.jpg');
  background-size:100% 100%;
  height:100vh;
  position:relative;
}
#appScreen6{
  background-image:url('../assets/App/appPage/screen6/background.jpg');
  background-size:100% 100%;
  height:100vh;
}
#appScreen7{
  background-image:url('../assets/App/appPage/screen7/background.jpg');
  background-size:100% 100%;
  height:100vh;
}
#appScreen8{
  background-image:url('../assets/App/appPage/screen8/background.jpg');
  background-size:100% 100%;
  height:100vh;
  position:relative;
}


.screen6Swiper{
  position: absolute;
  bottom:62px;
  z-index: 1;
  text-align:center;
}




/*通用*/
.appPageColorTitle{
  color:#ffffff;
  -webkit-text-fill-color: transparent;
  background:linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(159, 160, 167, 1) 100%);
}


.screenTitle{
  line-height:50px;
  /*font-size:48px;*/
  font-size:var(--web-title-font-size);
  font-weight:bold;
}
.screenPs{
  /*line-height:46px;*/
  line-height:var(--web-ps-line-height);
  /*font-size:24px;*/
  font-size:var(--web-ps-font-size);
}




.btn_outline{
  height:42px;
  padding:0 24px;
  border:2px solid rgba(56, 56, 56, 1);
  box-shadow: 0px 2px 8px  rgba(0, 60, 102, 0.4);
  font-size:16px;
  cursor:pointer;
}
.btn_outline p{
  line-height:42px;
}

/*上浮动画的元素*/
.floatAnimationDom{
  position:relative;
  top:100px;
  opacity:0;
  animation-fill-mode: forwards;
}







#appScreen1_mobile{
  /*background-image:url('../assets/App/appPage/screen1/background_mobile.png');*/
  /*background-size:100% 100%;*/
  /*background-repeat:no-repeat;*/
  overflow:hidden;
}
#appScreen2_mobile{
  background-image:url('../assets/App/appPage/screen2/background.jpg');
  background-size:100% 100%;
}
#appScreen3_mobile{
  background-image:url('../assets/App/appPage/screen3/background.jpg');
  background-size:100% 100%;
  /*height:750px;*/
}
#appScreen4_mobile{
  background-image:url('../assets/App/appPage/screen4/background.jpg');
  background-size:100% 100%;
}
#appScreen5_mobile{
  background-image:url('../assets/App/appPage/screen5/background1.jpg');
  background-size:100% calc(100% - 20px);
  position:relative;
  margin-top:60px;
}
#appScreen6_mobile{
  background-image:url('../assets/App/appPage/screen6/background.jpg');
  background-size:100% 100%;
}
#appScreen7_mobile{
  background-image:url('../assets/App/appPage/screen7/background.jpg');
  background-size:100% 100%;
}
#appScreen8_mobile{
  background-image:url('../assets/App/appPage/screen8/background.jpg');
  background-size:420% 350%;
  background-position:center -200px;
  position:relative;
}


.screen6Swiper_mobile{
  position: absolute;
  bottom:10px;
  width:100%;
  z-index: 1;
  text-align:center;
}




.appPageColorTitle_mobile{
  /*font-size:24px;*/
  font-size:var(--mobile-title-font-size);
  font-weight:bold;
  color:#ffffff;
  -webkit-text-fill-color: transparent;
  background:linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(159, 160, 167, 1) 100%);
}
.appPagePs_mobile{
  /*font-size:16px;*/
  font-size:var(--mobile-ps-font-size);
  /*line-height:24px;*/
  line-height:var(--mobile-ps-line-height);

}

.btn_outline_mobile{
  height:32px;
  border:2px solid rgba(56, 56, 56, 1);
  border-radius:4px;
  box-shadow: 0px 2px 8px  rgba(0, 60, 102, 0.4);
  font-size:14px;
  /*font-size:var(--mobile-btn_outline-font-size)*/
}
.btn_outline_mobile p{
  line-height:32px;
}


.toast{
  position:absolute;
  top:112px;
  left:calc((100% - 278px) / 2);
  width:278px;
  height:32px;
  background:rgba(255,255,255,0.9);
  border-radius:6px;
  color:#393939;
  font-size:14px;
  text-align:center;
  z-index:99;
}


</style>

<style>

.screen1Swiper .swiper-pagination-bullet{
  width:40px;
  height:4px;
  border-radius:0;
  background:rgba(56, 56, 56, 1);
  opacity:1;
}
.screen1Swiper .swiper-pagination-bullet-active{
  background:#ffffff;
}


.screen6Swiper .swiper-pagination-bullet{
  width:14px;
  height:14px;
  background:rgba(128, 128, 128, 0.5);
  opacity:1;
}
.screen6Swiper .swiper-pagination-bullet-active{
  background:rgba(204, 204, 204, 1);
}


/*上浮动画*/
@keyframes floatAnimation {
  from {top:100px;opacity:0;}
  to {top:0px;opacity:1;}
}
/*上下抖动动画*/
@keyframes verticalShakeAnimation {
  0% {top:0px;}
  50% {top:6px;}
  100% {top:0px;}
  /*0% {transform:rotateY(0deg);}*/
  /*50% {transform:rotateY(30deg);}*/
  /*100% {transform:rotateY(0deg);}*/
}




/**移动端******************************************************************************************************/
/*第六屏 轮播导航*/
.screen6Swiper_mobile .swiper-pagination-bullet{
  width:10px;
  height:10px;
  background:rgba(128, 128, 128, 0.5);
  margin:0 6px;
  opacity:1;
}
.screen6Swiper_mobile .swiper-pagination-bullet-active{
  background:rgba(204, 204, 204, 1);
}


</style>