
<script>
    import L from "leaflet";
    import { findRealParent, propsBinder } from 'vue2-leaflet';
    const props={
      imgUrl:{
          type:String,
      },
      color1:{
          type:String,
      },
        color2:{
          type:String,
        },
        title:{
          type:String,
        },
        latlon:{
          type:Array,
        },
        width:{
          type:Number,
        },
        index:{
          type:Number,
        }
    };
    export default {
        name: "LCustomMarker",
        props,
        data(){
            return{
                ready:false,
            };
        },
        methods:{
            loadMarker(){
                let num =0-21 -(this.width-83)/2
                   let icon = L.divIcon({
                        className:'custom-div-icon',
                        html:  "<div><div style='position: absolute;width: 42px;height:42px;transform: rotate(-45deg);background-color: "+this.color1+";border-radius: 50% 50% 50% 0;'></div>"+
                            "<div style='position: absolute;background-color: "+this.color1+";width: 50px;height: 50px;border: 3px solid rgba(151,151,151,0.00);border-radius: 50%;top: -18px;left:-7px'><div style='border-radius: 50%;width: 49px; height: 49px; margin:0.5px;background-image: url("+this.imgUrl+");background-position: center;background-size: cover'></div></div>"+
                            "<div style='position: absolute;height: 22px;white-space:nowrap;text-overflow:ellipsis;width: "+this.width+"px;background-color:"+this.color2+";border-radius: 11px;color:#ffffff;text-align: center;line-height: 22px;top:55px;left:"+num+"px'>"+this.title+"</div> </div>",
                        iconSize: [128, 83],
                        iconAnchor: [21, 83]
                    })//<img style='border-radius: 50%' src='"+this.imgUrl+"'width='49' height='49'/>


















                this.mapObject =  L.marker(this.latlon, {icon:icon,zIndexOffset:this.index})
                L.DomEvent.on(this.mapObject, this.$listeners);
                propsBinder(this,this.mapObject,props)
                this.ready = true
                this.parentContainer = findRealParent(this.$parent)
                this.parentContainer.addLayer(this)

            }
        },
        mounted() {
            this.loadMarker();
        },
        beforeDestroy() {
            this.parentContainer.removeLayer(this);
        },

        render() {
            return null;
        },
    }
</script>

<style scoped>

</style>
