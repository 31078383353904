<template>
    <div class="MarkHelpPage">
        <div style="width: 100%;height: 100%;display: flex;flex-direction: column;padding: 0 26px;background: white;overflow-y: scroll">
            <div style="height: 24px;width: calc(100% - 52px);display: flex;align-items: center">
                <div style="width: 4px;height: 18px;background: rgba(105, 94, 192, 1)"></div>
                <div style="line-height: 24px;font-size: 16px;font-weight: bold;margin-left: 12px">世界-标记模块使用说明</div>
            </div>
            <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 10px;text-align: justify">选择标记模块，进入标记文件主界面。标记主界面功能说明简介：</div>
            <img src="../../assets/MarkFileDetail/MarkHelp/P1.png" style="width: calc(100% - 52px);margin-top: 10px;object-fit: contain" />
            <img src="../../assets/MarkFileDetail/MarkHelp/P2.png" style="width: calc(100% - 52px);margin-top: 40px;object-fit: contain" />
            <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 10px;text-align: justify">点击页面左边操作栏中的的【文件】按钮，或者点击上方文件名称，屏幕下方弹出文件管理弹窗。</div>
            <img src="../../assets/MarkFileDetail/MarkHelp/P3.png" style="width: calc(100% - 52px);margin-top: 10px;object-fit: contain" />
            <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 10px;text-align: justify">弹窗中显示当前打开的文件名称，点击【打开文件列表】，即可查看所有标记文件。</div>
            <img src="../../assets/MarkFileDetail/MarkHelp/P4.png" style="width: calc(100% - 52px);margin-top: 10px;object-fit: contain" />
            <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 10px;text-align: justify">点击列表中某个文件，此文件所在行展开，显示基本信息。点击【打开】，即可打开此标记文件进行预览；点击【详情】，跳转至文件详情页面；点击分享按钮，加入文件进行共同创作，如果打开，则其他用户可依据此次分享加入标记文件，与您进行共同标记创作；点击【删除】，即可删除文件。</div>
            <img src="../../assets/MarkFileDetail/MarkHelp/P5.png" style="width: calc(100% - 52px);margin-top: 10px;object-fit: contain" />
            <img src="../../assets/MarkFileDetail/MarkHelp/P6.png" style="width: calc(100% - 52px);margin-top: 40px;object-fit: contain" />
            <img src="../../assets/MarkFileDetail/MarkHelp/P7.png" style="width: calc(100% - 52px);margin-top: 40px;object-fit: contain" />
            <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 10px;text-align: justify">点击页面左边操作栏中的【数据】按钮，弹出子窗口，子窗口中有标记列表与图层列表两个按钮。</div>
            <img src="../../assets/MarkFileDetail/MarkHelp/P8.png" style="width: calc(100% - 52px);margin-top: 10px;object-fit: contain" />
            <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 10px;text-align: justify">点击标记列表后，弹窗中显示当前文件标记的点、线、面数据。</div>
            <img src="../../assets/MarkFileDetail/MarkHelp/P9.png" style="width: calc(100% - 52px);margin-top: 10px;object-fit: contain" />
            <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 10px;text-align: justify">点击某个点标记后，可查看该点位位置，点击隐藏按钮可将该标记点隐藏，再次点击即可显示。</div>
            <img src="../../assets/MarkFileDetail/MarkHelp/P10.png" style="width: calc(100% - 52px);margin-top: 10px;object-fit: contain" />
            <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 10px;text-align: justify">点击图层列表后，显示当前文件中所有可视化图层。</div>
            <img src="../../assets/MarkFileDetail/MarkHelp/P11.png" style="width: calc(100% - 52px);margin-top: 10px;object-fit: contain" />
            <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 10px;text-align: justify">点击某个图层后，显示该图层，可调整图层样式、透明度，是否显示该图层。</div>
            <img src="../../assets/MarkFileDetail/MarkHelp/P12.png" style="width: calc(100% - 52px);margin-top: 10px;object-fit: contain" />
            <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 10px;text-align: justify">点击显示按钮后，弹出子窗口。子窗口中包含地图样式、标记样式等选项。</div>
            <img src="../../assets/MarkFileDetail/MarkHelp/P13.png" style="width: calc(100% - 52px);margin-top: 10px;object-fit: contain" />
            <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 10px;text-align: justify">【底图选择】可切换地图；【标记点样式】可更改当前文件下标记点图例；【显示标记名称】可对标记点名称进行隐藏；【标记点聚合】可控制当前文件下点位是否聚合；【标记图标大小】可控制图标相对大小；【显示所有标记】默认打开，关闭则所有标记不显示。</div>
            <img src="../../assets/MarkFileDetail/MarkHelp/P14.png" style="width: calc(100% - 52px);margin-top: 10px;object-fit: contain" />
            <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 10px;text-align: justify">点击更多显示播放、帮助功能。</div>
            <img src="../../assets/MarkFileDetail/MarkHelp/P15.png" style="width: calc(100% - 52px);margin-top: 10px;object-fit: contain" />
            <div style="line-height: 19px;color: rgba(74, 74, 74, 1);font-size: 14px;width: calc(100% - 52px);margin-top: 10px;text-align: justify">点击【播放】按钮，跳转到播放页面，将针对第一个点、线素材生成空中视角视频，如果文件中没有素材，则无法播放。点击【帮助】按钮，可查看此文档。</div>
            <img src="../../assets/MarkFileDetail/MarkHelp/P16.png" style="width: calc(100% - 52px);margin-top: 10px;object-fit: contain" />
        </div>
    </div>
</template>

<script>
    export default {
        name: "MarkHelpPage"
    }
</script>

<style scoped>
    .MarkHelpPage{
        font-family: 微软雅黑;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        overflow: hidden;
        /*// 禁止长按选择文字*/
        -webkit-user-select: none;
        /*// 禁止长按呼出菜单*/
        -webkit-touch-callout: none;
    }
</style>
