<template>
  <!--分享说明-->
  <div style="position:fixed;bottom:0;left:0;right:0;display:flex;flex-direction: column-reverse;
      width:100vw;height:292px;background:linear-gradient(0deg, rgba(38, 38, 38, 0.6) 12.96%, rgba(0, 0, 0, 0) 100%);">
    <div style="width:calc(100% - 44px);padding:0 22px;margin-bottom:23px;color:#ffffff;font-size:14px;">
      <p style="margin:0;word-break: break-all;text-overflow: ellipsis;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 4;overflow: hidden;">{{shareReason}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShareReason",
  props:["shareReason"]
}
</script>

<style scoped>

</style>